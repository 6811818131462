<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-top: 1.6%;margin-right: 6.1%;margin-left: 1%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model v-if="LinkageFlag.linkageFlag==0" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%"  :label-col="{span:14}" :wrapper-col="{span:10}">
      <a-form-model-item label="监控室脱岗" prop="digital">
        <a-checkbox-group v-model="offworklists" @change="offworkChange">
          <a-row>
            <a-col :span="12">
              <a-checkbox value="offworkmessage">
                短信联动使能
              </a-checkbox>
            </a-col>
            <a-col :span="12">
              <a-checkbox value="offworkphone">
                电话联动使能
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="监控室脱岗联动号码" prop="offworkphonelists">
        <a-input v-model="offworkphonelists" placeholder="多个电话请使用英文逗号分隔"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电瓶车进入大厅">
        <a-checkbox-group v-model="vehiclelists" @change="vehicleChange">
          <a-row>
            <a-col :span="12">
              <a-checkbox value="vehiclemessage">
                短信联动使能
              </a-checkbox>
            </a-col>
            <a-col :span="12">
              <a-checkbox value="vehiclephone">
                电话联动使能
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="电瓶车进入大厅联动号码" prop="vehiclephonelists">
        <a-input v-model="vehiclephonelists" placeholder="多个电话请使用英文逗号分隔"></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import {
  addParamsBatchLog, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList,
  modifyDeviceInfo, modifyParamsBatchLog,
  setDeviceparamValueList
} from "A/ai";
import {
  addEventLinkage, getEventLinkageListByCondition,
  getLiftinfoById,
  getLiftinfoListByCondition, getLiftListByCondition,
  getUserdepListByCondition,
  modifyLiftinfo,
  modifyUserdep
} from "A/jcgn";
import {getUserByUserid} from "A/login";
import {getEventListByCondition} from "A/wlw";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "linkage",
  props:{
    LinkageFlag:{
      default: null
    }
  },
  data() {
    return {
      status:false,
      devicemoduletype:'',
      devicenum:'',
      modelnum:'',
      deviceinfoid:'',
      liftid:'',
      offworklists:[],
      vehiclelists:[],

      offworkmessage:1,
      offworkphone:0,
      offworkphonelists:'',
      vehiclemessage:1,
      vehiclephone:0,
      vehiclephonelists:'',

    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    offworkChange(checkedValues) {
      this.offworkmessage=0
      this.offworkphone = 0
      checkedValues.filter(item=>{
        if(item=="offworkmessage"){
          this.offworkmessage=1
        }
        if(item=="offworkphone") {
          this.offworkphone = 1
        }
      })
    },
    vehicleChange(checkedValues) {
      this.vehiclemessage=0
      this.vehiclephone = 0
      checkedValues.filter(item=>{
        if(item=="vehiclemessage"){
          this.vehiclemessage=1
        }
        if(item=="vehiclephone") {
          this.vehiclephone = 1
        }
      })
    },
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      this.deviceinfoid=this.$route.params.deviceinfoid||'';
      this.liftid = this.$route.params.liftid||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '88';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
        }
      })
    },
    getParamsConfig() {
      // this.paramsList = [];
      // let params = {
      //   devicenum: this.devicenum,
      //   data: [
      //     {
      //       modeltype: this.devicemoduletype,
      //       modelnum: this.modelnum,
      //       params: {
      //         methord: 'GET',
      //         url: '/linphone',
      //       }
      //     }
      //   ]
      // };
      // getDeviceparamValueList(params).then(res => {
      //   this.hideLoading();
      //   if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
      //   }
      // }).catch(err => {
      //   this.hideLoading();
      // })
      getEventLinkageListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'&&res.item.length>0){
          this.offworkmessage=res.item[0].offworkmessage
          this.offworkphone=res.item[0].offworkphone
          this.offworkphonelists=res.item[0].offworkphonelists
          this.vehiclemessage=res.item[0].vehiclemessage
          this.vehiclephone=res.item[0].vehiclephone
          this.vehiclephonelists=res.item[0].vehiclephonelists
          if(this.offworkmessage==1){
            this.offworklists.push('offworkmessage')
          }
          if(this.offworkphone==1){
            this.offworklists.push('offworkphone')
          }
          if(this.vehiclemessage==1){
            this.vehiclelists.push('vehiclemessage')
          }
          if(this.vehiclephone==1){
            this.vehiclelists.push('vehiclephone')
          }
        }
      })
    },
    setParamsConfig(){
      let params={
        devicenum:this.devicenum,
        offworkmessage:this.offworkmessage,
        offworkphone:this.offworkphone,
        offworkphonelists:this.offworkphonelists.toString(),
        vehiclemessage:this.vehiclemessage,
        vehiclephone:this.vehiclephone,
        vehiclephonelists:this.vehiclephonelists.toString(),
      }
      addEventLinkage(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("参数下发成功")
        }
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  background-color: white;
  border-radius: 5px;
}
</style>