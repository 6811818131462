<!--显示参数-->
<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 6.1%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model v-if="DisplayFlag.displayFlag==0" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:14}" :wrapper-col="{span:10}">
      <a-form-model-item label="日夜间模式设置(CPSL)" prop="CPSL_mode">
        <a-select id="status" placeholder="默认0，代表自动,1是黑夜模式,2是白天模式" v-model="iotConfigs.CPSL_mode">
          <a-select-option value="0">自动</a-select-option>
          <a-select-option value="1">黑夜</a-select-option>
          <a-select-option value="2">白天</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="亮度" prop="iBrightness">
        <a-input v-model="displayConfigs.imageAdjustment.iBrightness" placeholder="请填写0-100之间的数字"></a-input>
      </a-form-model-item>
      <a-form-model-item label="对比度" prop="iContrast">
        <a-input v-model="displayConfigs.imageAdjustment.iContrast" placeholder="请填写0-100之间的数字"></a-input>
      </a-form-model-item>
      <a-form-model-item label="饱和度" prop="iSaturation">
        <a-input v-model="displayConfigs.imageAdjustment.iSaturation" placeholder="请填写0-100之间的数字"></a-input>
      </a-form-model-item>
      <a-form-model-item label="锐度" prop="iSharpness">
        <a-input v-model="displayConfigs.imageAdjustment.iSharpness" placeholder="请填写0-100之间的数字"></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="displayConfigs.nightToDay.sNightToDay=='auto'" label="灵敏度" prop="iNightToDayFilterLevel">
        <a-input v-model="displayConfigs.nightToDay.iNightToDayFilterLevel" placeholder="请填写0-7之间的数字"></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检图像平均亮度阈值" prop="para_self_ins_average_brightness_threshold">
        <a-input v-model="selfTestConfigs.para_self_ins_average_brightness_threshold" placeholder="默认230"></a-input>
      </a-form-model-item>
      <a-form-model-item label="自检图像平均亮度连续检查个数" prop="para_self_ins_brightness_continuous_number">
        <a-input v-model="selfTestConfigs.para_self_ins_brightness_continuous_number" placeholder="默认3个" suffix="个"></a-input>
      </a-form-model-item>
    </a-form-model>

    <a-form-model v-if="DisplayFlag.displayFlag==1" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
<!--      <a-form-model-item label="曝光模式" prop="sExposureMode">-->
<!--        <a-select id="exposure" placeholder="请选择" v-model="displayConfigs.exposure.sExposureMode">-->
<!--          <a-select-option value="">请选择</a-select-option>-->
<!--          <a-select-option value="auto">自动</a-select-option>-->
<!--          <a-select-option value="manual">手动</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item v-if="displayConfigs.exposure.sExposureMode=='manual'" label="曝光时间" prop="sExposureTime">-->
<!--        <a-select placeholder="请选择" v-model="displayConfigs.exposure.sExposureTime">-->
<!--          <a-select-option value="">请选择</a-select-option>-->
<!--          <a-select-option value="1">1</a-select-option>-->
<!--          <a-select-option value="1/3">1/3</a-select-option>-->
<!--          <a-select-option value="1/6">1/6</a-select-option>-->
<!--          <a-select-option value="1/12">1/12</a-select-option>-->
<!--          <a-select-option value="1/25">1/25</a-select-option>-->
<!--          <a-select-option value="1/50">1/50</a-select-option>-->
<!--          <a-select-option value="1/100">1/100</a-select-option>-->
<!--          <a-select-option value="1/150">1/150</a-select-option>-->
<!--          <a-select-option value="1/200">1/200</a-select-option>-->
<!--          <a-select-option value="1/250">1/250</a-select-option>-->
<!--          <a-select-option value="1/500">1/500</a-select-option>-->
<!--          <a-select-option value="1/750">1/750</a-select-option>-->
<!--          <a-select-option value="1/1000">1/1000</a-select-option>-->
<!--          <a-select-option value="1/2000">1/2000</a-select-option>-->
<!--          <a-select-option value="1/4000">1/4000</a-select-option>-->
<!--          <a-select-option value="1/10000">1/1000</a-select-option>-->
<!--          <a-select-option value="1/100000">1/100000</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item v-if="displayConfigs.exposure.sExposureMode=='manual'" label="增益模式" prop="sGainMode">-->
<!--        <a-select id="gain" placeholder="请选择" v-model="displayConfigs.exposure.sGainMode">-->
<!--          <a-select-option value="">请选择</a-select-option>-->
<!--          <a-select-option value="auto">自动</a-select-option>-->
<!--          <a-select-option value="manual">手动</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item v-if="displayConfigs.exposure.sGainMode=='manual'" label="增益" prop="iExposureGain">-->
<!--        <div>-->
<!--          <a-row>-->
<!--            <a-col :span="12">-->
<!--              <a-slider v-model="displayConfigs.exposure.iExposureGain" :min="0" :max="100" :step="1" />-->
<!--            </a-col>-->
<!--            <a-col :span="4">-->
<!--              <a-input-number-->
<!--                  v-model="displayConfigs.exposure.iExposureGain"-->
<!--                  :min="0"-->
<!--                  :max="100"-->
<!--                  :step="1"-->
<!--                  style="marginLeft: 16px"-->
<!--              />-->
<!--            </a-col>-->
<!--          </a-row>-->
<!--        </div>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="CPSL使能标志" prop="CPSL_enbale">
        <a-select v-model="iotConfigs.CPSL_enbale" placeholder="1使能，0禁止，默认使能">
          <a-select-option value="1">使能</a-select-option>
          <a-select-option value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="CPSL强制黑白" prop="CPSL_force_gray">
        <a-select v-model="iotConfigs.CPSL_force_gray" placeholder="1使能，0禁止，默认使能">
          <a-select-option value="1">使能</a-select-option>
          <a-select-option value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="CPSL转换为白天模式的灵敏度" prop="CPSL_auto_on2off_th">
        <a-input v-model="iotConfigs.CPSL_auto_on2off_th" placeholder="默认5000"></a-input>
      </a-form-model-item>
      <a-form-model-item label="CPSL转换为黑夜模式的灵敏度" prop="CPSL_auto_off2on_th">
        <a-input v-model="iotConfigs.CPSL_auto_off2on_th" placeholder="默认10"></a-input>
      </a-form-model-item>
      <a-form-model-item label="CPSL模式切换时间间隔" prop="CPSL_auto_sw_interval">
        <a-input v-model="iotConfigs.CPSL_auto_sw_interval" placeholder="默认5s" suffix="秒"></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  addParamsBatchLog,
  getDeviceAudioparamValueList,
  getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList,
  modifyParamsBatchLog,
  setDeviceAudioparamValueList,
  setDeviceparamValueList,
} from "A/ai";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "display",
  props:{
    DisplayFlag:{
      default: null
    }
  },
  data() {
    return {
      // aiConfigs:{
      //   chromatic_aberration_da:'',
      //   chromatic_aberration_db:'',
      // },
      iotConfigs:{
        CPSL_enbale:'',
        CPSL_mode:'',
        CPSL_force_gray:'',
        CPSL_auto_on2off_th:'',
        CPSL_auto_off2on_th:'',
        CPSL_auto_sw_interval:'',
      },
      displayConfigs:{
        whiteBlance: {
          iWhiteBalanceBlue: null,
          iWhiteBalanceRed: null,
          iWhiteBalanceGreen: null,
          sWhiteBlanceStyle: ""
        },
        exposure: {
          iAutoIrisLevel: null,
          iAutoExposureEnabled: null,
          iAutoGainEnabled: null,
          sIrisType: "",
          iExposureGain: 1,
          sExposureMode: "",
          sGainMode: "",
          sExposureTime: null
        },
        BLC: {
          iWDRLevel: null,
          sWDR: "",
          iHDRLevel: null,
          sBLCRegion: "",
          iBLCRegionWidth: null,
          iPositionX: null,
          iPositionY: null,
          sHDR: "",
          iBLCStrength: null,
          sHLC: "",
          iDarkBoostLevel: null,
          iHLCLevel: null,
          iBLCRegionHeight: null
        },
        imageAdjustment: {
          iFPS: null,
          iSharpness: null,
          iSaturation: null,
          iBrightness: null,
          iContrast: null,
          iHue: null
        },
        imageEnhancement: {
          sFEC: "close",
          sGrayScaleMode: null,
          sDistortionCorrection: "close",
          iFecLevel: null,
          sDIS: "",
          sDehaze: "",
          iImageRotation: null,
          iDehazeLevel: null,
          iDenoiseLevel: null,
          sNoiseReduceMode: "",
          iLdchLevel: null,
          iSpatialDenoiseLevel: null,
          iTemporalDenoiseLevel: null
        },
        videoAdjustment: {
          sImageFlip: "",
          sPowerLineFrequencyMode: "",
          sSceneMode: ""
        },
        id: null,
        nightToDay: {
          iDistanceLevel: null,
          sOverexposeSuppress: "",
          sBrightnessAdjustmentMode: "",
          sDawnTime:"",
          sFillLightMode: "",
          sDuskTime: "",
          sIrcutFilterAction: "",
          sNightToDay: "",
          iLightBrightness: null,
          iNightToDayFilterLevel: null,
          sOverexposeSuppressType: "",
          iNightToDayFilterTime: null
        }
      },
      selfTestConfigs:{
        para_self_ins_average_brightness_threshold:'',
        para_self_ins_brightness_continuous_number:'',
      },
      methodList: [],
      devicenum:'',
      devicename:'',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      // let par = {
      //   devicenum: this.devicenum,
      //   data: [
      //     {
      //       modeltype: this.devicemoduletype,
      //       modelnum: this.modelnum,
      //       params: {
      //         methord: 'GET',
      //         url: '/ai',
      //       }
      //     }
      //   ]
      // };
      // getDeviceparamValueList(par).then(res => {
      //   if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
      //     this.aiConfigs.chromatic_aberration_da=res.item[0].params.body.chromatic_aberration_da
      //     this.aiConfigs.chromatic_aberration_db=res.item[0].params.body.chromatic_aberration_db
      //   }
      // })
      let para = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'GET',
              url: '/iot',
            }
          }
        ]
      };
      getDeviceparamValueList(para).then(res => {
        if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.iotConfigs.CPSL_enbale=res.item[0].params.body.CPSL_enbale.toString()
          this.iotConfigs.CPSL_mode=res.item[0].params.body.CPSL_mode.toString()
          this.iotConfigs.CPSL_force_gray=res.item[0].params.body.CPSL_force_gray.toString()
          this.iotConfigs.CPSL_auto_on2off_th=res.item[0].params.body.CPSL_auto_on2off_th
          this.iotConfigs.CPSL_auto_off2on_th=res.item[0].params.body.CPSL_auto_off2on_th
          this.iotConfigs.CPSL_auto_sw_interval=res.item[0].params.body.CPSL_auto_sw_interval
        }
      })
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              params: {
                method: 'GET',
                url: '/image/0',
              }
            }
          ]
        };
        getDeviceAudioparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.displayConfigs=res.item[0].params.body
          }
        }).catch(err => {
          this.hideLoading();
        })
      let param = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: "6",
            modelnum: this.modelnum,
            params: {
              method: 'GET',
              url: '/selfins',
            }
          }
        ]
      };
      getDeviceparamValueList(param).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.selfTestConfigs=res.item[0].params.body
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    setSelfConfigs(){
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            params: {
              modeltype: "6",
              modelnum: this.modelnum,
              methord: 'PUT',
              url:'/selfins',
              body: this.selfTestConfigs,
            }
          }
        ]
      };
      this.paramsdata="自检图像平均亮度阈值"+","+this.selfTestConfigs.para_self_ins_average_brightness_threshold+","
          +"自检图像平均亮度连续检查个数"+","+this.selfTestConfigs.para_self_ins_brightness_continuous_number+","
      let paramsdate=moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username:this.username,
        paramsobj:this.devicename,
        paramsdate:paramsdate,
        devicenum:this.devicenum,
        devicegroupid:"00",
        orderresult:'失败',
        paramstype:'0',
        parentid:"",
        paramsdata:this.paramsdata,
      }
      addParamsBatchLog(param)
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0' ) {
          this.$message.success('操作成功');
          let param = {
            username: this.username,
            paramsobj: this.devicename,
            paramsdate: paramsdate,
            devicenum: this.devicenum,
            devicegroupid: "00",
            orderresult: '成功',
            paramstype: '0',
            parentid: "",
            paramsdata: this.paramsdata,
          }
          modifyParamsBatchLog(param)
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    setParamsConfig() {
      this.setSelfConfigs();
      let para = {
        devicenum: this.devicenum,
        data: [
          {
            params: {
              modeltype: "1",
              modelnum: this.modelnum,
              methord: 'PUT',
              url:'/iot',
              body: this.iotConfigs,
            }
          }
        ]
      };
      setDeviceparamValueList(para)
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            params: {
              methord: 'PUT',
              url: '/image/0',
              body: this.displayConfigs,
            }
          }
        ]
      };
      this.paramsdata = "日夜间模式设置" + "," + this.displayConfigs.nightToDay.sNightToDay + ","
          + "亮度" + "," + this.displayConfigs.imageAdjustment.iBrightness + ","
          + "对比度" + "," + this.displayConfigs.imageAdjustment.iContrast + ","
          + "饱和度" + "," + this.displayConfigs.imageAdjustment.iSaturation + ","
          + "锐度" + "," + this.displayConfigs.imageAdjustment.iSharpness + ","
          + "曝光模式" + "," + this.displayConfigs.exposure.sExposureMode + ","
          + "曝光时间" + "," + this.displayConfigs.exposure.sExposureTime + ","
          + "增益模式" + "," + this.displayConfigs.exposure.sGainMode + ","
          + "增益" + "," + this.displayConfigs.exposure.iExposureGain + ","
          + "CPSL使能标志" + "," + this.iotConfigs.CPSL_enbale + ","
          + "CPSL模式" + "," + this.iotConfigs.CPSL_mode + ","
          + "CPSL强制黑白" + "," + this.iotConfigs.CPSL_force_gray + ","
          + "CPSL转换为白天模式的灵敏度" + "," + this.iotConfigs.CPSL_auto_on2off_th + ","
          + "CPSL转换为黑夜模式的灵敏度" + "," + this.iotConfigs.CPSL_auto_off2on_th + ","
          + "CPSL模式切换时间间隔" + "," + this.iotConfigs.CPSL_auto_sw_interval + ","
      let paramsdate = moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username: this.username,
        paramsobj: this.devicename,
        paramsdate: paramsdate,
        devicenum: this.devicenum,
        devicegroupid: "00",
        orderresult: '失败',
        paramstype: '0',
        parentid: "",
        paramsdata: this.paramsdata,
      }
      addParamsBatchLog(param)
      this.showLoading();
      setDeviceAudioparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          if (this.displayConfigs.nightToDay.sNightToDay != '' || this.displayConfigs.nightToDay.sNightToDay != null) {
            let param = {
              devicenum: this.devicenum,
              data: [
                {
                  params: {
                    methord: 'PUT',
                    url: '/iot',
                    body: {Reboot: '1'},
                  }
                }
              ]
            }
            setDeviceparamValueList(param).then(res => {
              if (res && res.returncode == '0') {
                this.$message.success('操作成功，设备正在重启，请稍后');
              }
            })
          } else {
            this.$message.success('操作成功');
            let param = {
              username: this.username,
              paramsobj: this.devicename,
              paramsdate: paramsdate,
              devicenum: this.devicenum,
              devicegroupid: "00",
              orderresult: '成功',
              paramstype: '0',
              parentid: "",
              paramsdata: this.paramsdata,
            }
            modifyParamsBatchLog(param)
          }
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
        }
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>