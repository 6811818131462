<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 5%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model v-if="dutyRoomIotFlag.iotFlag==0" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:14}" :wrapper-col="{span:10}">
      <a-form-model-item label="消防报警事件使能标志" prop="FIRE_ALARM_ENABLE">
        <a-select v-model="iotConfigs.FIRE_ALARM_ENABLE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="消防报警事件上传录像使能标志" prop="FIRE_ALARM_UPLOAD_VIDEO_ENABLE">
        <a-select v-model="iotConfigs.FIRE_ALARM_UPLOAD_VIDEO_ENABLE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="门禁周界报警事件使能标志" prop="DOOR_ACCESS_ALARM_ENABLE">
        <a-select v-model="iotConfigs.DOOR_ACCESS_ALARM_ENABLE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="门禁周界报警事件上传录像使能标志" prop="DOOR_ACCESS_ALARM_UPLOAD_VIDEO_ENABLE">
        <a-select v-model="iotConfigs.DOOR_ACCESS_ALARM_UPLOAD_VIDEO_ENABLE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="电梯报警事件使能标志" prop="LIFT_ALARM_ENABLE">
        <a-select v-model="iotConfigs.LIFT_ALARM_ENABLE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="电梯报警事件上传录像使能标志" prop="LIFT_ALARM_UPLOAD_VIDEO_ENABLE">
        <a-select v-model="iotConfigs.LIFT_ALARM_UPLOAD_VIDEO_ENABLE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="溢水报警事件使能标志" prop="OVERFLOW_ALARM_ENABLE">
        <a-select v-model="iotConfigs.OVERFLOW_ALARM_ENABLE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="溢水报警事件上传录像使能标志" prop="OVERFLOW_ALARM_UPLOAD_VIDEO_ENABLE">
        <a-select v-model="iotConfigs.OVERFLOW_ALARM_UPLOAD_VIDEO_ENABLE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="燃气报警事件使能标志" prop="GAS_ALARM_ENABLE">
        <a-select v-model="iotConfigs.GAS_ALARM_ENABLE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="燃气报警事件上传录像使能标志" prop="GAS_ALARM_UPLOAD_VIDEO_ENABLE">
        <a-select v-model="iotConfigs.GAS_ALARM_UPLOAD_VIDEO_ENABLE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="消防主机对接485使能" prop="SERIAL_485_ENABLE">
        <a-select v-model="iotConfigs.SERIAL_485_ENABLE" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="消防设备厂家的名称" prop="FIRE_EQUIPMENT_MANUFACTURER_NAME">
        <a-input v-model="iotConfigs.FIRE_EQUIPMENT_MANUFACTURER_NAME"></a-input>
      </a-form-model-item>
      <a-form-model-item label="消防设备厂家的型号" prop="FIRE_EQUIPMENT_MODEL">
        <a-input v-model="iotConfigs.FIRE_EQUIPMENT_MODEL"></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import {
  addParamsBatchLog, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList, modifyParamsBatchLog,
  setDeviceparamValueList
} from "A/ai";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "iot",
  props:{
    dutyRoomIotFlag:{
      default: null
    }
  },
  data() {
    return {
      iotConfigs:{
        //消防等报警使能
        FIRE_ALARM_ENABLE:'',
        DOOR_ACCESS_ALARM_ENABLE:'',
        LIFT_ALARM_ENABLE:'',
        OVERFLOW_ALARM_ENABLE:'',
        GAS_ALARM_ENABLE:'',

        //录像上传使能
        FIRE_ALARM_UPLOAD_VIDEO_ENABLE:'',
        DOOR_ACCESS_ALARM_UPLOAD_VIDEO_ENABLE:'',
        LIFT_ALARM_UPLOAD_VIDEO_ENABLE:'',
        OVERFLOW_ALARM_UPLOAD_VIDEO_ENABLE:'',
        GAS_ALARM_UPLOAD_VIDEO_ENABLE:'',

        //第三方消防报警参数
        SERIAL_485_ENABLE:'',
        FIRE_EQUIPMENT_MODEL:'',
        FIRE_EQUIPMENT_MANUFACTURER_NAME:'',
      },
      devicemoduletype: '',
      modelnum: '',
      methodList: [],
      devicenum:'',
      devicename:'',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
          this.iotConfigs.Xianshi_device_sn_num=res.item[0].monitornum
        }
      })
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      this.paramsList = [];
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              modeltype: this.devicemoduletype,
              modelnum: this.modelnum,
              params: {
                methord: 'GET',
                url: '/iot',
              }
            }
          ]
        };
        getDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
            this.iotConfigs.FIRE_ALARM_ENABLE = res.item[0].params.body.FIRE_ALARM_ENABLE
            this.iotConfigs.FIRE_ALARM_UPLOAD_VIDEO_ENABLE = res.item[0].params.body.FIRE_ALARM_UPLOAD_VIDEO_ENABLE
            this.iotConfigs.DOOR_ACCESS_ALARM_ENABLE = res.item[0].params.body.DOOR_ACCESS_ALARM_ENABLE
            this.iotConfigs.DOOR_ACCESS_ALARM_UPLOAD_VIDEO_ENABLE = res.item[0].params.body.DOOR_ACCESS_ALARM_UPLOAD_VIDEO_ENABLE
            this.iotConfigs.LIFT_ALARM_ENABLE = res.item[0].params.body.LIFT_ALARM_ENABLE
            this.iotConfigs.LIFT_ALARM_UPLOAD_VIDEO_ENABLE = res.item[0].params.body.LIFT_ALARM_UPLOAD_VIDEO_ENABLE
            this.iotConfigs.OVERFLOW_ALARM_ENABLE = res.item[0].params.body.OVERFLOW_ALARM_ENABLE
            this.iotConfigs.OVERFLOW_ALARM_UPLOAD_VIDEO_ENABLE = res.item[0].params.body.OVERFLOW_ALARM_UPLOAD_VIDEO_ENABLE
            this.iotConfigs.GAS_ALARM_ENABLE = res.item[0].params.body.GAS_ALARM_ENABLE
            this.iotConfigs.GAS_ALARM_UPLOAD_VIDEO_ENABLE = res.item[0].params.body.GAS_ALARM_UPLOAD_VIDEO_ENABLE
            this.iotConfigs.SERIAL_485_ENABLE = res.item[0].params.body.SERIAL_485_ENABLE
            this.iotConfigs.FIRE_EQUIPMENT_MODEL = res.item[0].params.body.FIRE_EQUIPMENT_MODEL
            this.iotConfigs.FIRE_EQUIPMENT_MANUFACTURER_NAME = res.item[0].params.body.FIRE_EQUIPMENT_MANUFACTURER_NAME
          }
        }).catch(err => {
          this.hideLoading();
        })
    },
    setParamsConfig() {
      let paramsBody = {}
      if(this.dutyRoomIotFlag.iotFlag==0) {
        paramsBody = {
          FIRE_ALARM_ENABLE: this.iotConfigs.FIRE_ALARM_ENABLE,
          FIRE_ALARM_UPLOAD_VIDEO_ENABLE: this.iotConfigs.FIRE_ALARM_UPLOAD_VIDEO_ENABLE,
          DOOR_ACCESS_ALARM_ENABLE: this.iotConfigs.DOOR_ACCESS_ALARM_ENABLE,
          DOOR_ACCESS_ALARM_UPLOAD_VIDEO_ENABLE: this.iotConfigs.DOOR_ACCESS_ALARM_UPLOAD_VIDEO_ENABLE,
          LIFT_ALARM_ENABLE: this.iotConfigs.LIFT_ALARM_ENABLE,
          LIFT_ALARM_UPLOAD_VIDEO_ENABLE: this.iotConfigs.LIFT_ALARM_UPLOAD_VIDEO_ENABLE,
          OVERFLOW_ALARM_ENABLE: this.iotConfigs.OVERFLOW_ALARM_ENABLE,
          OVERFLOW_ALARM_UPLOAD_VIDEO_ENABLE: this.iotConfigs.OVERFLOW_ALARM_UPLOAD_VIDEO_ENABLE,
          GAS_ALARM_ENABLE: this.iotConfigs.GAS_ALARM_ENABLE,
          GAS_ALARM_UPLOAD_VIDEO_ENABLE: this.iotConfigs.GAS_ALARM_UPLOAD_VIDEO_ENABLE,
          SERIAL_485_ENABLE: this.iotConfigs.SERIAL_485_ENABLE,
          FIRE_EQUIPMENT_MODEL: this.iotConfigs.FIRE_EQUIPMENT_MODEL,
          FIRE_EQUIPMENT_MANUFACTURER_NAME: this.iotConfigs.FIRE_EQUIPMENT_MANUFACTURER_NAME,
        }
        this.paramsdata = "消防报警事件使能" + "," + this.iotConfigs.FIRE_ALARM_ENABLE + ","
            + "消防报警事件上传录像使能" + "," + this.iotConfigs.FIRE_ALARM_UPLOAD_VIDEO_ENABLE + ","
            + "门禁周边报警事件使能" + "," + this.iotConfigs.DOOR_ACCESS_ALARM_ENABLE + ","
            + "门禁周边报警事件上传录像使能" + "," + this.iotConfigs.DOOR_ACCESS_ALARM_UPLOAD_VIDEO_ENABLE + ","
            + "电梯报警事件使能" + "," + this.iotConfigs.LIFT_ALARM_ENABLE + ","
            + "电梯报警事件上传录像使能" + "," + this.iotConfigs.LIFT_ALARM_UPLOAD_VIDEO_ENABLE + ","
            + "溢水报警事件使能" + "," + this.iotConfigs.OVERFLOW_ALARM_ENABLE + ","
            + "溢水报警事件上传录像使能" + "," + this.iotConfigs.OVERFLOW_ALARM_UPLOAD_VIDEO_ENABLE + ","
            + "燃气报警事件使能" + "," + this.iotConfigs.GAS_ALARM_ENABLE + ","
            + "燃气报警事件上传录像使能" + "," + this.iotConfigs.GAS_ALARM_UPLOAD_VIDEO_ENABLE + ","
            + "消防主机对接485使能" + "," + this.iotConfigs.SERIAL_485_ENABLE + ","
            + "消防设备厂家的名称" + "," + this.iotConfigs.FIRE_EQUIPMENT_MANUFACTURER_NAME + ","
            + "消防设备厂家的型号" + "," + this.iotConfigs.FIRE_EQUIPMENT_MODEL + ","
      }else{

      }
      let paramsdate = moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username: this.username,
        paramsobj: this.devicename,
        paramsdate: paramsdate,
        devicenum: this.devicenum,
        devicegroupid: "00",
        orderresult: '失败',
        paramstype: '0',
        parentid: "",
        paramsdata: this.paramsdata,
      }
      addParamsBatchLog(param)
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'PUT',
              url: '/iot',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.$message.success('操作成功');
          let param = {
            username: this.username,
            paramsobj: this.devicename,
            paramsdate: paramsdate,
            devicenum: this.devicenum,
            devicegroupid: "00",
            orderresult: '成功',
            paramstype: '0',
            parentid: "",
            paramsdata: this.paramsdata,
          }
          modifyParamsBatchLog(param)
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>