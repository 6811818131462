<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 5%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet(type)" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model v-if="waterSystemIotFlag.iotFlag==0" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:14}" :wrapper-col="{span:10}">
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">物联网参数</h1>
      <a-form-model-item label="拨打值班室电话使能" prop="Voip_Alarm_Enable">
        <a-select v-model="iotConfigs.Voip_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="IO状态判断时间间隔" prop="IO_Calculation_Interval">
        <a-input v-model="iotConfigs.IO_Calculation_Interval" placeholder="默认1秒" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="IO状态采样时间窗间隔" prop="IO_Sampling_Interval">
        <a-input v-model="iotConfigs.IO_Sampling_Interval" placeholder="默认15ms" suffix="毫秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="时间窗内IO有效数值占比" prop="IO_State_Effective_Threshold">
        <a-input v-model="iotConfigs.IO_State_Effective_Threshold" placeholder="默认0.0200，保留4位小数"></a-input>
      </a-form-model-item>
      <a-form-model-item label="温度过高阈值" prop="Celsius_Temperature_Max_Threshold">
        <a-input v-model="iotConfigs.Celsius_Temperature_Max_Threshold" placeholder="默认40摄氏度" suffix="℃"></a-input>
      </a-form-model-item>
      <a-form-model-item label="温度过低阈值" prop="Celsius_Temperature_Min_Threshold">
        <a-input v-model="iotConfigs.Celsius_Temperature_Min_Threshold" placeholder="默认5摄氏度" suffix="℃"></a-input>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过高阈值" prop="Relative_Humidity_Max_Threshold">
        <a-input v-model="iotConfigs.Relative_Humidity_Max_Threshold" placeholder="默认90%" suffix=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过低阈值" prop="Relative_Humidity_Min_Threshold">
        <a-input v-model="iotConfigs.Relative_Humidity_Min_Threshold" placeholder="默认10%" suffix=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="温度队列长度" prop="Celsius_Temperature_State_List_Max">
        <a-input v-model="iotConfigs.Celsius_Temperature_State_List_Max" placeholder="默认3个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="温度队列阈值" prop="Celsius_Temperature_State_List_Threshold">
        <a-input v-model="iotConfigs.Celsius_Temperature_State_List_Threshold" placeholder="默认2个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="湿度队列长度" prop="Relative_Humidity_State_List_Max">
        <a-input v-model="iotConfigs.Relative_Humidity_State_List_Max" placeholder="默认3个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="湿度队列阈值" prop="Relative_Humidity_State_List_Threshold">
        <a-input v-model="iotConfigs.Relative_Humidity_State_List_Threshold" placeholder="默认2个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="人体检测队列长度" prop="Person_Detect_List_Max">
        <a-input v-model="iotConfigs.Person_Detect_List_Max" placeholder="默认3个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="人体检测队列阈值" prop="Person_Detect_List_Threshold">
        <a-input v-model="iotConfigs.Person_Detect_List_Threshold" placeholder="默认2个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="摄氏温度过高事件使能" prop="Celsius_Temperature_Too_High_Event_Enable">
        <a-select v-model="iotConfigs.Celsius_Temperature_Too_High_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="摄氏温度过低事件使能" prop="Celsius_Temperature_Too_Low_Event_Enable">
        <a-select v-model="iotConfigs.Celsius_Temperature_Too_Low_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过高事件使能" prop="Relative_Humidity_Too_High_Event_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_Too_High_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过低事件使能" prop="Relative_Humidity_Too_Low_Event_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_Too_Low_Event_Enable" placeholder="1为使能，0为禁止，默认使能" :disabled="true">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房有人事件使能" prop="WaterPumpHouse_Person_Event_Enable">
        <a-select v-model="iotConfigs.WaterPumpHouse_Person_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房门打开事件使能" prop="EngineRoom_Door_Open_Event_Enable">
        <a-select v-model="iotConfigs.EngineRoom_Door_Open_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房送风机启动事件使能" prop="EngineRoom_FeedFan_Start_Event_Enable">
        <a-select v-model="iotConfigs.EngineRoom_FeedFan_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房排风机启动事件使能" prop="EngineRoom_ExhaustFan_Start_Event_Enable">
        <a-select v-model="iotConfigs.EngineRoom_ExhaustFan_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵1启动事件使能" prop="SubmersiblePump_1_Start_Event_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_1_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵2启动事件使能" prop="SubmersiblePump_2_Start_Event_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_2_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1主泵1启动事件使能" prop="WaterPumpGroup_1_FirstMainPump_Start_Event_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1主泵2启动事件使能" prop="WaterPumpGroup_1_SecondMainPump_Start_Event_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1主泵3启动事件使能" prop="WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1稳压泵启动事件使能" prop="WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2主泵1启动事件使能" prop="WaterPumpGroup_2_FirstMainPump_Start_Event_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2主泵2启动事件使能" prop="WaterPumpGroup_2_SecondMainPump_Start_Event_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2主泵3启动事件使能" prop="WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2稳压泵启动事件使能" prop="WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱补水开始事件使能" prop="Radiator_Supply_Start_Event_Enable">
        <a-select v-model="iotConfigs.Radiator_Supply_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱溢水开始事件使能" prop="Radiator_Overflow_Start_Event_Enable">
        <a-select v-model="iotConfigs.Radiator_Overflow_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱缺水开始事件使能" prop="Radiator_Lack_Start_Event_Enable">
        <a-select v-model="iotConfigs.Radiator_Lack_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房走水报警使能" prop="EngineRoom_OnFire_Alarm_Enable">
        <a-select v-model="iotConfigs.EngineRoom_OnFire_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵断电报警使能" prop="SubmersiblePump_Power_Cut_Alarm_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_Power_Cut_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵1故障报警使能" prop="SubmersiblePump_1_Fault_Alarm_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_1_Fault_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵2故障报警使能" prop="SubmersiblePump_2_Fault_Alarm_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_2_Fault_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1故障报警使能" prop="WaterPumpGroup_1_Fault_Alarm_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_Fault_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1超压报警使能" prop="WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1失压报警使能" prop="WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2故障报警使能" prop="WaterPumpGroup_2_Fault_Alarm_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_Fault_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2超压报警使能" prop="WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2失压报警使能" prop="WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱盖打开报警使能" prop="Radiator_Cap_Open_Alarm_Enable">
        <a-select v-model="iotConfigs.Radiator_Cap_Open_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="补水故障，高水位故障（111）报警使能" prop="Radiator_Supply_HighWaterLevel_Alarm_Enable">
        <a-select v-model="iotConfigs.Radiator_Supply_HighWaterLevel_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="补水故障，高、缺水位故障（110）报警使能" prop="Radiator_Supply_High_LackWaterLevel_Alarm_Enable">
        <a-select v-model="iotConfigs.Radiator_Supply_High_LackWaterLevel_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="正在补水，水位过低（100）报警使能" prop="Radiator_Supply_LackWaterLevel_Alarm_Enable">
        <a-select v-model="iotConfigs.Radiator_Supply_LackWaterLevel_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="高水位故障（011）报警使能" prop="Radiator_HighWaterLevel_Alarm_Enable">
        <a-select v-model="iotConfigs.Radiator_HighWaterLevel_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="高、缺水位故障（010）报警使能" prop="Radiator_High_LackWaterLevel_Alarm_Enable">
        <a-select v-model="iotConfigs.Radiator_High_LackWaterLevel_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="补水故障或无外水；低水位故障（000）报警使能" prop="Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable">
        <a-select v-model="iotConfigs.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">录像上传使能参数</h1>
      <a-form-model-item label="拨打值班室电话事件上传录像使能" prop="Voip_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Voip_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="摄氏温度过高事件上传录像使能" prop="Celsius_Temperature_Too_High_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="摄氏温度过低事件上传录像使能" prop="Celsius_Temperature_Too_Low_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过高事件上传录像使能" prop="Relative_Humidity_Too_High_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_Too_High_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过低事件上传录像使能" prop="Relative_Humidity_Too_Low_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房有人事件上传录像使能" prop="WaterPumpHouse_Person_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpHouse_Person_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房门打开事件上传录像使能" prop="EngineRoom_Door_Open_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.EngineRoom_Door_Open_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房送风机启动事件上传录像使能" prop="EngineRoom_FeedFan_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房排风机启动事件上传录像使能" prop="EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵1启动事件上传录像使能" prop="SubmersiblePump_1_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_1_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵2启动事件上传录像使能" prop="SubmersiblePump_2_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_2_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1主泵1启动事件上传录像使能" prop="WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1主泵2启动事件上传录像使能" prop="WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1主泵3启动事件上传录像使能" prop="WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1稳压泵启动事件上传录像使能" prop="WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2主泵1启动事件上传录像使能" prop="WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2主泵2启动事件上传录像使能" prop="WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2主泵3启动事件上传录像使能" prop="WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2稳压泵启动事件上传录像使能" prop="WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱补水开始事件上传录像使能" prop="Radiator_Supply_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Radiator_Supply_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱溢水开始事件上传录像使能" prop="WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱缺水开始事件上传录像使能" prop="Radiator_Lack_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Radiator_Lack_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房走水报警上传录像使能" prop="EngineRoom_OnFire_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.EngineRoom_OnFire_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵断电报警上传录像使能" prop="SubmersiblePump_Power_Cut_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_Power_Cut_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵1故障报警上传录像使能" prop="SubmersiblePump_Power_Cut_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_Power_Cut_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵2故障报警上传录像使能" prop="SubmersiblePump_2_Fault_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_2_Fault_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1故障报警上传录像使能" prop="WaterPumpGroup_1_Fault_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_Fault_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1超压报警上传录像使能" prop="WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1失压报警上传录像使能" prop="WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2故障报警上传录像使能" prop="WaterPumpGroup_2_Fault_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_Fault_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2超压报警上传录像使能" prop="WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2失压报警上传录像使能" prop="WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱盖打开报警上传录像使能" prop="Radiator_Cap_Open_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Radiator_Cap_Open_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="补水故障，高水位故障（111）报警上传录像使能" prop="Radiator_Supply_HighWaterLevel_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Radiator_Supply_HighWaterLevel_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="补水故障，高、缺水位故障（110）报警上传录像使能" prop="Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="正在补水，水位过低（100）报警上传录像使能" prop="Radiator_Supply_LackWaterLevel_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Radiator_Supply_LackWaterLevel_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="高水位故障（011）报警上传录像使能" prop="Radiator_HighWaterLevel_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Radiator_HighWaterLevel_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="高、缺水位故障（010）报警上传录像使能" prop="Radiator_High_LackWaterLevel_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Radiator_High_LackWaterLevel_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="补水故障或无外水；低水位故障（000）报警上传录像使能" prop="Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">图像上传使能参数</h1>
      <a-form-model-item label="摄氏温度过高事件上传图片使能" prop="Celsius_Temperature_Too_High_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="摄氏温度过低事件上传图片使能" prop="Celsius_Temperature_Too_Low_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过高事件上传图片使能" prop="Relative_Humidity_Too_High_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_Too_High_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过低事件上传图片使能" prop="Relative_Humidity_Too_Low_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房有人事件上传图片使能" prop="WaterPumpHouse_Person_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpHouse_Person_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房门打开事件上传图片使能" prop="EngineRoom_Door_Open_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.EngineRoom_Door_Open_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房送风机启动事件上传图片使能" prop="EngineRoom_FeedFan_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房排风机启动事件上传图片使能" prop="EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵1启动事件上传图片使能" prop="SubmersiblePump_1_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_1_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵2启动事件上传图片使能" prop="SubmersiblePump_2_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_2_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1主泵1启动事件上传图片使能" prop="WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1主泵2启动事件上传图片使能" prop="WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1主泵3启动事件上传图片使能" prop="WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1稳压泵启动事件上传图片使能" prop="WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2主泵1启动事件上传图片使能" prop="WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2主泵2启动事件上传图片使能" prop="WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2主泵3启动事件上传图片使能" prop="WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2稳压泵启动事件上传图片使能" prop="WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱补水开始事件上传图片使能" prop="Radiator_Supply_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Radiator_Supply_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱溢水开始事件上传图片使能" prop="WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱缺水开始事件上传图片使能" prop="Radiator_Lack_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Radiator_Lack_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房走水报警上传图片使能" prop="EngineRoom_OnFire_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.EngineRoom_OnFire_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵断电报警上传图片使能" prop="SubmersiblePump_Power_Cut_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_Power_Cut_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵1故障报警上传图片使能" prop="SubmersiblePump_Power_Cut_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_Power_Cut_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵2故障报警上传图片使能" prop="SubmersiblePump_2_Fault_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.SubmersiblePump_2_Fault_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1故障报警上传图片使能" prop="WaterPumpGroup_1_Fault_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_Fault_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1超压报警上传图片使能" prop="WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1失压报警上传图片使能" prop="WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2故障报警上传图片使能" prop="WaterPumpGroup_2_Fault_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_Fault_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2超压报警上传图片使能" prop="WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2失压报警上传图片使能" prop="WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱盖打开报警上传图片使能" prop="Radiator_Cap_Open_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Radiator_Cap_Open_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="补水故障，高水位故障（111）报警上传图片使能" prop="Radiator_Supply_HighWaterLevel_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Radiator_Supply_HighWaterLevel_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="补水故障，高、缺水位故障（110）报警上传图片使能" prop="Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="正在补水，水位过低（100）报警上传图片使能" prop="Radiator_Supply_LackWaterLevel_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Radiator_Supply_LackWaterLevel_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="高水位故障（011）报警上传图片使能" prop="Radiator_HighWaterLevel_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Radiator_HighWaterLevel_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="拨打值班室电话事件上传图片使能" prop="Voip_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Voip_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认1">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <a-form-model v-if="waterSystemIotFlag.iotFlag==1" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:14}" :wrapper-col="{span:10}">
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">GPIO绑定事件</h1>
      <a-button style="margin-left: 10%" type="primary" @click="setDefault('UK01')">UK01默认参数</a-button>
      <a-button style="margin-left: 5%" type="primary" @click="setDefault('UK_16IOTo485_V01')">UK_16IOTo485_V01默认参数</a-button>
      <a-divider></a-divider>
      <a-form-model-item label="机房门打开信号绑定Gpio端口" prop="EngineRoom_Door_Open_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.EngineRoom_Door_Open_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.EngineRoom_Door_Open_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1超压报警信号绑定Gpio端口" prop="WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1失压报警信号绑定Gpio端口" prop="WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2超压报警信号绑定Gpio端口" prop="WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2失压报警信号绑定Gpio端口" prop="WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱盖打开报警信号绑定Gpio端口" prop="Radiator_Cap_Open_Alarm_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Radiator_Cap_Open_Alarm_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Radiator_Cap_Open_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱补水开始信号绑定Gpio端口" prop="Radiator_Supply_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Radiator_Supply_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Radiator_Supply_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱溢水开始信号绑定Gpio端口" prop="Radiator_Overflow_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Radiator_Overflow_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Radiator_Overflow_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水箱缺水开始信号绑定Gpio端口" prop="Radiator_Lack_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Radiator_Lack_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Radiator_Lack_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="拨打值班室电话信号绑定Gpio端口" prop="Voip_Alarm_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Voip_Alarm_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Voip_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房走水报警信号绑定Gpio端口" prop="EngineRoom_OnFire_Alarm_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.EngineRoom_OnFire_Alarm_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.EngineRoom_OnFire_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房送风机启动信号绑定Gpio端口" prop="EngineRoom_FeedFan_Start_Bind_Port">
        <a-select v-model="iotConfigs.EngineRoom_FeedFan_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.EngineRoom_FeedFan_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房排风机启动信号绑定Gpio端口" prop="EngineRoom_ExhaustFan_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.EngineRoom_ExhaustFan_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.EngineRoom_ExhaustFan_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵断电报警信号绑定Gpio端口" prop="SubmersiblePump_Power_Cut_Alarm_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.SubmersiblePump_Power_Cut_Alarm_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.SubmersiblePump_Power_Cut_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵1启动信号绑定Gpio端口" prop="SubmersiblePump_1_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.SubmersiblePump_1_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.SubmersiblePump_1_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵1故障报警信号绑定Gpio端口" prop="SubmersiblePump_1_Fault_Alarm_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.SubmersiblePump_1_Fault_Alarm_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.SubmersiblePump_1_Fault_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵2启动信号绑定Gpio端口" prop="SubmersiblePump_2_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.SubmersiblePump_2_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.SubmersiblePump_2_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="潜水泵2故障报警信号绑定Gpio端口" prop="SubmersiblePump_2_Fault_Alarm_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.SubmersiblePump_2_Fault_Alarm_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.SubmersiblePump_2_Fault_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1故障报警信号绑定Gpio端口" prop="WaterPumpGroup_1_Fault_Alarm_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_1_Fault_Alarm_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_1_Fault_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2故障报警信号绑定Gpio端口" prop="WaterPumpGroup_2_Fault_Alarm_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_2_Fault_Alarm_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_2_Fault_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1主泵1启动信号绑定Gpio端口" prop="WaterPumpGroup_1_FirstMainPump_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1主泵2启动信号绑定Gpio端口" prop="WaterPumpGroup_1_SecondMainPump_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1主泵3启动信号绑定Gpio端口" prop="WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组1稳压泵启动信号绑定Gpio端口" prop="WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2主泵1启动信号绑定Gpio端口" prop="WaterPumpGroup_2_FirstMainPump_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2主泵2启动信号绑定Gpio端口" prop="WaterPumpGroup_2_SecondMainPump_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2主泵3启动信号绑定Gpio端口" prop="WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="水泵组2稳压泵启动信号绑定Gpio端口" prop="WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">事件有效电平配置</h1>-->
<!--      <span style="color: red;font-weight: bold;margin-left: 16%">注：下列参数仅限电流互感器穿孔电流为直流的情况使用</span>-->
<!--      <a-form-model-item></a-form-model-item>-->
<!--      <a-form-model-item label="机房门打开信号有效电平(I1)" prop="EngineRoom_Door_Open_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.EngineRoom_Door_Open_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组1超压报警信号有效电平(I2)" prop="WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组1失压报警信号有效电平(I3)" prop="WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组2超压报警信号有效电平(I4)" prop="WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组2失压报警信号有效电平(I5)" prop="WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水箱盖打开报警信号有效电平(I6)" prop="Radiator_Cap_Open_Alarm_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Radiator_Cap_Open_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水箱补水开始信号有效电平(I7)" prop="Radiator_Supply_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Radiator_Supply_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水箱溢水开始信号有效电平(I8/B1)" prop="Radiator_Overflow_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Radiator_Overflow_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水箱缺水开始信号有效电平(I9/B2)" prop="Radiator_Lack_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Radiator_Lack_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="备用I10有效电平(I10)" prop="Reserve_I10_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Reserve_I10_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="备用I11有效电平(I11)" prop="Reserve_I11_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Reserve_I11_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="拨打值班室电话信号有效电平(I12)" prop="Voip_Alarm_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Voip_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="机房走水报警信号有效电平(H1)" prop="EngineRoom_OnFire_Alarm_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.EngineRoom_OnFire_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="机房送风机启动信号有效电平(H2)" prop="EngineRoom_FeedFan_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.EngineRoom_FeedFan_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="机房排风机启动信号有效电平(H3)" prop="EngineRoom_ExhaustFan_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.EngineRoom_ExhaustFan_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="潜水泵断电报警信号有效电平(H4)" prop="SubmersiblePump_Power_Cut_Alarm_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.SubmersiblePump_Power_Cut_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="潜水泵1启动信号有效电平(H5)" prop="SubmersiblePump_1_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.SubmersiblePump_1_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="潜水泵1故障报警信号有效电平(H6)" prop="SubmersiblePump_1_Fault_Alarm_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.SubmersiblePump_1_Fault_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="潜水泵2启动信号有效电平(H7)" prop="SubmersiblePump_2_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.SubmersiblePump_2_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="潜水泵2故障报警信号有效电平(H8)" prop="SubmersiblePump_2_Fault_Alarm_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.SubmersiblePump_2_Fault_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组1故障报警信号有效电平(H9)" prop="WaterPumpGroup_1_Fault_Alarm_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_1_Fault_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组1主泵1启动信号有效电平(H10)" prop="WaterPumpGroup_1_FirstMainPump_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组1主泵2启动信号有效电平(H11)" prop="WaterPumpGroup_1_SecondMainPump_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组1主泵3启动信号有效电平(H12)" prop="WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组1稳压泵启动信号有效电平(H13)" prop="WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组2故障报警信号有效电平(H14)" prop="WaterPumpGroup_2_Fault_Alarm_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_2_Fault_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组2主泵1启动信号有效电平(H15)" prop="WaterPumpGroup_2_FirstMainPump_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组2主泵2启动信号有效电平(H16)" prop="WaterPumpGroup_2_SecondMainPump_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组2主泵3启动信号有效电平(H17)" prop="WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="水泵组2稳压泵启动信号有效电平(H18)" prop="WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
    </a-form-model>
  </div>
</template>
<script>
import {
  addParamsBatchLog, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList, modifyParamsBatchLog,
  setDeviceparamValueList
} from "A/ai";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "iot",
  props:{
    waterSystemIotFlag:{
      default: null
    }
  },
  data() {
    return {
      iotConfigs:{
        //拨打值班室电话使能，1为使能，0为禁止，默认使能
        Voip_Alarm_Enable:'',
        //拨打值班室电话事件上传录像使能，1为使能，0为禁止，默认使能
        Voip_Alarm_Upload_Video_Enable:'',
        //IO状态判断时间间隔,默认1秒
        IO_Calculation_Interval:'',
        //IO状态采样时间间隔，默认15ms
        IO_Sampling_Interval:'',
        //时间窗内IO有效数值占比，默认0.0200,保留4位小数
        IO_State_Effective_Threshold:'',
        //温度过高阈值，默认45摄氏度
        Celsius_Temperature_Max_Threshold:'',
        //温度过低阈值，默认0摄氏度
        Celsius_Temperature_Min_Threshold:'',
        //相对湿度过高阈值，默认90%
        Relative_Humidity_Max_Threshold:'',
        //相对湿度过低阈值，默认10%
        Relative_Humidity_Min_Threshold:'',
        //温度队列长度，默认3个
        Celsius_Temperature_State_List_Max:'',
        //温度队列阈值，默认2个
        Celsius_Temperature_State_List_Threshold:'',
        //湿度队列长度，默认3个
        Relative_Humidity_State_List_Max:'',
        //湿度队列阈值，默认2个
        Relative_Humidity_State_List_Threshold:'',
        //人体检测队列长度，默认3个
        Person_Detect_List_Max:'',
        //人体检测队列阈值，默认2个
        Person_Detect_List_Threshold:'',
        //摄氏温度过高事件使能，1为使能，0为禁止，默认使能
        Celsius_Temperature_Too_High_Event_Enable:'',
        //摄氏温度过低事件使能，1为使能，0为禁止，默认使能
        Celsius_Temperature_Too_Low_Event_Enable:'',
        //相对湿度过高事件使能，1为使能，0为禁止，默认使能
        Relative_Humidity_Too_High_Event_Enable:'',
        //相对湿度过低事件使能，1为使能，0为禁止，默认使能
        Relative_Humidity_Too_Low_Event_Enable:'',
        //机房有人事件使能，1为使能，0为禁止，默认使能
        WaterPumpHouse_Person_Event_Enable:'',
        //机房门打开事件使能，1为使能，0为禁止，默认使能
        EngineRoom_Door_Open_Event_Enable:'',
        //机房送风机启动事件使能，1为使能，0为禁止，默认使能
        EngineRoom_FeedFan_Start_Event_Enable:'',
        //机房排风机启动事件使能，1为使能，0为禁止，默认使能
        EngineRoom_ExhaustFan_Start_Event_Enable:'',
        //潜水泵1启动事件使能，1为使能，0为禁止，默认使能
        SubmersiblePump_1_Start_Event_Enable:'',
        //潜水泵2启动事件使能，1为使能，0为禁止，默认使能
        SubmersiblePump_2_Start_Event_Enable:'',
        //水泵组1主泵1启动事件使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_FirstMainPump_Start_Event_Enable:'',
        //水泵组1主泵2启动事件使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_SecondMainPump_Start_Event_Enable:'',
        //水泵组1主泵3启动事件使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable:'',
        //水泵组1稳压泵启动事件使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable:'',
        //水泵组2主泵1启动事件使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_FirstMainPump_Start_Event_Enable:'',
        //水泵组2主泵2启动事件使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_SecondMainPump_Start_Event_Enable:'',
        //水泵组2主泵3启动事件使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable:'',
        //水泵组2稳压泵启动事件使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable:'',
        //水箱补水开始事件使能，1为使能，0为禁止，默认使能
        Radiator_Supply_Start_Event_Enable:'',
        //水箱溢水开始事件使能，1为使能，0为禁止，默认使能
        Radiator_Overflow_Start_Event_Enable:'',
        //水箱缺水开始事件使能，1为使能，0为禁止，默认使能
        Radiator_Lack_Start_Event_Enable:'',
        //机房走水报警使能，1为使能，0为禁止，默认使能
        EngineRoom_OnFire_Alarm_Enable:'',
        //潜水泵断电报警使能，1为使能，0为禁止，默认使能
        SubmersiblePump_Power_Cut_Alarm_Enable:'',
        //潜水泵1故障报警使能，1为使能，0为禁止，默认使能
        SubmersiblePump_1_Fault_Alarm_Enable:'',
        //潜水泵2故障报警使能，1为使能，0为禁止，默认使能
        SubmersiblePump_2_Fault_Alarm_Enable:'',
        //水泵组1故障报警使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_Fault_Alarm_Enable:'',
        //水泵组1超压报警使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable:'',
        //水泵组1失压报警使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable:'',
        //水泵组2故障报警使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_Fault_Alarm_Enable:'',
        //水泵组2超压报警使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable:'',
        //水泵组2失压报警使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable:'',
        //水箱盖打开报警使能，1为使能，0为禁止，默认使能
        Radiator_Cap_Open_Alarm_Enable:'',
        //补水故障，高水位故障(111)报警使能，1为使能，0为禁止，默认使能
        Radiator_Supply_HighWaterLevel_Alarm_Enable:'',
        //补水故障，高、缺水位故障(110)报警使能，1为使能，0为禁止，默认使能
        Radiator_Supply_High_LackWaterLevel_Alarm_Enable:'',
        //正在补水，水位过低(100)报警使能，1为使能，0为禁止，默认使能
        Radiator_Supply_LackWaterLevel_Alarm_Enable:'',
        //高水位故障(011)报警使能，1为使能，0为禁止，默认使能
        Radiator_HighWaterLevel_Alarm_Enable:'',
        //高、缺水位故障(010)报警使能，1为使能，0为禁止，默认使能
        Radiator_High_LackWaterLevel_Alarm_Enable:'',
        //补水故障或无外水；低水位故障(000)报警使能，1为使能，0为禁止，默认使能
        Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable:'',


        //摄氏温度过高事件上传录像使能，1为使能，0为禁止，默认使能
        Celsius_Temperature_Too_High_Event_Upload_Video_Enable:'',
        //摄氏温度过低事件上传录像使能，1为使能，0为禁止，默认使能
        Celsius_Temperature_Too_Low_Event_Upload_Video_Enable:'',
        //相对湿度过高事件上传录像使能，1为使能，0为禁止，默认使能
        Relative_Humidity_Too_High_Event_Upload_Video_Enable:'',
        //相对湿度过低事件上传录像使能，1为使能，0为禁止，默认使能
        Relative_Humidity_Too_Low_Event_Upload_Video_Enable:'',
        //机房有人事件上传录像使能，1为使能，0为禁止，默认使能
        WaterPumpHouse_Person_Event_Upload_Video_Enable:'',
        //机房门打开事件上传录像使能，1为使能，0为禁止，默认使能
        EngineRoom_Door_Open_Event_Upload_Video_Enable:'',
        //机房送风机启动事件上传录像使能，1为使能，0为禁止，默认使能
        EngineRoom_FeedFan_Start_Event_Upload_Video_Enable:'',
        //机房排风机启动事件上传录像使能，1为使能，0为禁止，默认使能
        EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable:'',
        //潜水泵1启动事件上传录像使能，1为使能，0为禁止，默认使能
        SubmersiblePump_1_Start_Event_Upload_Video_Enable:'',
        //潜水泵2启动事件上传录像使能，1为使能，0为禁止，默认使能
        SubmersiblePump_2_Start_Event_Upload_Video_Enable:'',
        //水泵组1主泵1启动事件上传录像使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Video_Enable:'',
        //水泵组1主泵2启动事件上传录像使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Video_Enable:'',
        //水泵组1主泵3启动事件上传录像使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Video_Enable:'',
        //水泵组1稳压泵启动事件上传录像使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Video_Enable:'',
        //水泵组2主泵1启动事件上传录像使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Video_Enable:'',
        //水泵组2主泵2启动事件上传录像使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Video_Enable:'',
        //水泵组2主泵3启动事件上传录像使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Video_Enable:'',
        //水泵组2稳压泵启动事件上传录像使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Video_Enable:'',
        //水箱补水开始事件上传录像使能，1为使能，0为禁止，默认使能
        Radiator_Supply_Start_Event_Upload_Video_Enable:'',
        //水箱溢水开始事件上传录像使能，1为使能，0为禁止，默认使能
        Radiator_Overflow_Start_Event_Upload_Video_Enable:'',
        //水箱缺水开始事件上传录像使能，1为使能，0为禁止，默认使能
        Radiator_Lack_Start_Event_Upload_Video_Enable:'',
        //机房走水报警使能，1为使能，0为禁止，默认使能
        EngineRoom_OnFire_Alarm_Upload_Video_Enable:'',
        //潜水泵断电报警使能，1为使能，0为禁止，默认使能
        SubmersiblePump_Power_Cut_Alarm_Upload_Video_Enable:'',
        //潜水泵1故障报警使能，1为使能，0为禁止，默认使能
        SubmersiblePump_1_Fault_Alarm_Upload_Video_Enable:'',
        //潜水泵2故障报警使能，1为使能，0为禁止，默认使能
        SubmersiblePump_2_Fault_Alarm_Upload_Video_Enable:'',
        //水泵组1故障报警使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_Fault_Alarm_Upload_Video_Enable:'',
        //水泵组1超压报警使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Video_Enable:'',
        //水泵组1失压报警使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Video_Enable:'',
        //水泵组2故障报警使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_Fault_Alarm_Upload_Video_Enable:'',
        //水泵组2超压报警使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Video_Enable:'',
        //水泵组2失压报警使能，1为使能，0为禁止，默认使能
        WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Video_Enable:'',
        //水箱盖打开报警使能，1为使能，0为禁止，默认使能
        Radiator_Cap_Open_Alarm_Upload_Video_Enable:'',
        //补水故障，高水位故障(111)报警使能，1为使能，0为禁止，默认使能
        Radiator_Supply_HighWaterLevel_Alarm_Upload_Video_Enable:'',
        //补水故障，高、缺水位故障(110)报警使能，1为使能，0为禁止，默认使能
        Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Video_Enable:'',
        //正在补水，水位过低(100)报警使能，1为使能，0为禁止，默认使能
        Radiator_Supply_LackWaterLevel_Alarm_Upload_Video_Enable:'',
        //高水位故障(011)报警使能，1为使能，0为禁止，默认使能
        Radiator_HighWaterLevel_Alarm_Upload_Video_Enable:'',
        //高、缺水位故障(010)报警使能，1为使能，0为禁止，默认使能
        Radiator_High_LackWaterLevel_Alarm_Upload_Video_Enable:'',
        //补水故障或无外水；低水位故障(000)报警使能，1为使能，0为禁止，默认使能
        Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Video_Enable:'',


        //摄氏温度过高事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Celsius_Temperature_Too_High_Event_Upload_Image_Enable:'',
        //摄氏温度过低事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Celsius_Temperature_Too_Low_Event_Upload_Image_Enable:'',
        //相对湿度过高事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Relative_Humidity_Too_High_Event_Upload_Image_Enable:'',
        //相对湿度过低事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Relative_Humidity_Too_Low_Event_Upload_Image_Enable:'',
        //机房有人事件上传图片使能，1为上传图片使能，0为禁止，默认1
        WaterPumpHouse_Person_Event_Upload_Image_Enable:'',
        //机房门打开事件上传图片使能，1为上传图片使能，0为禁止，默认1
        EngineRoom_Door_Open_Event_Upload_Image_Enable:'',
        //机房送风机启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        EngineRoom_FeedFan_Start_Event_Upload_Image_Enable:'',
        //机房排风机启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable:'',
        //潜水泵1启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        SubmersiblePump_1_Start_Event_Upload_Image_Enable:'',
        //潜水泵2启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        SubmersiblePump_2_Start_Event_Upload_Image_Enable:'',
        //水泵组1主泵1启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Image_Enable:'',
        //水泵组1主泵2启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Image_Enable:'',
        //水泵组1主泵3启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Image_Enable:'',
        //水泵组1稳压泵启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Image_Enable:'',
        //水泵组2主泵1启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Image_Enable:'',
        //水泵组2主泵2启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Image_Enable:'',
        //水泵组2主泵3启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Image_Enable:'',
        //水泵组2稳压泵启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Image_Enable:'',
        //水箱补水开始事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_Supply_Start_Event_Upload_Image_Enable:'',
        //水箱溢水开始事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_Overflow_Start_Event_Upload_Image_Enable:'',
        //水箱缺水开始事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_Lack_Start_Event_Upload_Image_Enable:'',
        //机房走水报警上传图片使能，1为上传图片使能，0为禁止，默认1
        EngineRoom_OnFire_Alarm_Upload_Image_Enable:'',
        //潜水泵断电报警上传图片使能，1为上传图片使能，0为禁止，默认0
        SubmersiblePump_Power_Cut_Alarm_Upload_Image_Enable:'',
        //潜水泵1故障报警上传图片使能，1为上传图片使能，0为禁止，默认0
        SubmersiblePump_1_Fault_Alarm_Upload_Image_Enable:'',
        //潜水泵2故障报警上传图片使能，1为上传图片使能，0为禁止，默认0
        SubmersiblePump_2_Fault_Alarm_Upload_Image_Enable:'',
        //水泵组1故障报警上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_Fault_Alarm_Upload_Image_Enable:'',
        //水泵组1超压报警上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Image_Enable:'',
        //水泵组1失压报警上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Image_Enable:'',
        //水泵组2故障报警上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_Fault_Alarm_Upload_Image_Enable:'',
        //水泵组2超压报警上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Image_Enable:'',
        //水泵组2失压报警上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Image_Enable:'',
        //水箱盖打开报警上传图片使能，1为上传图片使能，0为禁止，默认1
        Radiator_Cap_Open_Alarm_Upload_Image_Enable:'',
        //水箱补水中（高水位故障）报警上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_Supply_HighWaterLevel_Alarm_Upload_Image_Enable:'',
        //水箱补水中（高、缺水位故障）报警上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Image_Enable:'',
        //水箱补水中（水位过低）报警上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_Supply_LackWaterLevel_Alarm_Upload_Image_Enable:'',
        //水箱高水位报警上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_HighWaterLevel_Alarm_Upload_Image_Enable:'',
        //水箱高、缺水位报警上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_High_LackWaterLevel_Alarm_Upload_Image_Enable:'',
        //水箱补水故障或无外水、低水位报警上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Image_Enable:'',
        //拨打值班室电话事件上传图片使能，1为上传图片使能，0为禁止，默认1
        Voip_Alarm_Upload_Image_Enable:'',


        //机房门打开信号有效电平，1为高电平有效，0为低电平有效，默认0
        EngineRoom_Door_Open_Gpio_State:'',
        //机房送风机启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        EngineRoom_FeedFan_Start_Gpio_State:'',
        //机房排风机启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        EngineRoom_ExhaustFan_Start_Gpio_State:'',
        //潜水泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        SubmersiblePump_1_Start_Gpio_State:'',
        //潜水泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        SubmersiblePump_2_Start_Gpio_State:'',
        //水泵组1主泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_1_FirstMainPump_Start_Gpio_State:'',
        //水泵组1主泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_1_SecondMainPump_Start_Gpio_State:'',
        //水泵组1主泵3启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State:'',
        //水泵组1稳压泵启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State:'',
        //水泵组2主泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_2_FirstMainPump_Start_Gpio_State:'',
        //水泵组2主泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_2_SecondMainPump_Start_Gpio_State:'',
        //水泵组2主泵3启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State:'',
        //水泵组2稳压泵启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State:'',
        //水箱补水开始信号有效电平，1为高电平有效，0为低电平有效，默认0
        Radiator_Supply_Start_Gpio_State:'',
        //水箱溢水开始信号有效电平，1为高电平有效，0为低电平有效，默认0
        Radiator_Overflow_Start_Gpio_State:'',
        //水箱缺水开始信号有效电平，1为高电平有效，0为低电平有效，默认0
        Radiator_Lack_Start_Gpio_State:'',
        //机房走水报警信号有效电平，1为高电平有效，0为低电平有效，默认1
        EngineRoom_OnFire_Alarm_Gpio_State:'',
        //潜水泵断电报警信号有效电平，1为高电平有效，0为低电平有效，默认1
        SubmersiblePump_Power_Cut_Alarm_Gpio_State:'',
        //潜水泵1故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
        SubmersiblePump_1_Fault_Alarm_Gpio_State:'',
        //潜水泵2故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
        SubmersiblePump_2_Fault_Alarm_Gpio_State:'',
        //水泵组1故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_1_Fault_Alarm_Gpio_State:'',
        //水泵组1超压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
        WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State:'',
        //水泵组1失压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
        WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State:'',
        //水泵组2故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_2_Fault_Alarm_Gpio_State:'',
        //水泵组2超压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
        WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State:'',
        //水泵组2失压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
        WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State:'',
        //水箱盖打开报警信号有效电平，1为高电平有效，0为低电平有效，默认0
        Radiator_Cap_Open_Alarm_Gpio_State:'',
        //备用I10有效电平，1为高电平有效，0为低电平有效，默认0
        Reserve_I10_Gpio_State:'',
        //备用I11有效电平，1为高电平有效，0为低电平有效，默认0
        Reserve_I11_Gpio_State:'',
        //拨打值班室电话信号有效电平，1为高电平有效，0为低电平有效，默认0
        Voip_Alarm_Gpio_State:'',


        //机房门打开信号绑定Gpio端口，默认I1
        EngineRoom_Door_Open_Bind_Port:'',
        //机房送风机启动信号绑定Gpio端口，默认H2
        EngineRoom_FeedFan_Start_Bind_Port:'',
        //机房排风机启动信号绑定Gpio端口，默认H3
        EngineRoom_ExhaustFan_Start_Bind_Port:'',
        //潜水泵1启动信号绑定Gpio端口，默认H5
        SubmersiblePump_1_Start_Bind_Port:'',
        //潜水泵2启动信号绑定Gpio端口，默认H7
        SubmersiblePump_2_Start_Bind_Port:'',
        //水泵组1主泵1启动信号绑定Gpio端口，默认H10
        WaterPumpGroup_1_FirstMainPump_Start_Bind_Port:'',
        //水泵组1主泵2启动信号绑定Gpio端口，默认H11
        WaterPumpGroup_1_SecondMainPump_Start_Bind_Port:'',
        //水泵组1主泵3启动信号绑定Gpio端口，默认H12
        WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port:'',
        //水泵组1稳压泵启动信号绑定Gpio端口，默认H13
        WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port:'',
        //水泵组2主泵1启动信号绑定Gpio端口，默认H15
        WaterPumpGroup_2_FirstMainPump_Start_Bind_Port:'',
        //水泵组2主泵2启动信号绑定Gpio端口，默认H16
        WaterPumpGroup_2_SecondMainPump_Start_Bind_Port:'',
        //水泵组2主泵3启动信号绑定Gpio端口，默认H17
        WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port:'',
        //水泵组2稳压泵启动信号绑定Gpio端口，默认H18
        WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port:'',
        //水箱补水开始信号绑定Gpio端口，默认I7
        Radiator_Supply_Start_Bind_Port:'',
        //水箱溢水开始信号绑定Gpio端口，默认B1
        Radiator_Overflow_Start_Bind_Port:'',
        //水箱缺水开始信号绑定Gpio端口，默认B2
        Radiator_Lack_Start_Bind_Port:'',
        //机房走水报警信号绑定Gpio端口，默认H1
        EngineRoom_OnFire_Alarm_Bind_Port:'',
        //潜水泵断电报警信号绑定Gpio端口，默认H4
        SubmersiblePump_Power_Cut_Alarm_Bind_Port:'',
        //潜水泵1故障报警信号绑定Gpio端口，默认H6
        SubmersiblePump_1_Fault_Alarm_Bind_Port:'',
        //潜水泵2故障报警信号绑定Gpio端口，默认H8
        SubmersiblePump_2_Fault_Alarm_Bind_Port:'',
        //水泵组1故障报警信号绑定Gpio端口，默认H9
        WaterPumpGroup_1_Fault_Alarm_Bind_Port:'',
        //水泵组1超压报警信号绑定Gpio端口，默认I2
        WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port:'',
        //水泵组1失压报警信号绑定Gpio端口，默认I3
        WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port:'',
        //水泵组2故障报警信号绑定Gpio端口，默认H14
        WaterPumpGroup_2_Fault_Alarm_Bind_Port:'',
        //水泵组2超压报警信号绑定Gpio端口，默认I4
        WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port:'',
        //水泵组2失压报警信号绑定Gpio端口，默认I5
        WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port:'',
        //水箱盖打开报警信号绑定Gpio端口，默认I6
        Radiator_Cap_Open_Alarm_Bind_Port:'',
        //拨打值班室电话信号绑定Gpio端口，默认B5
        Voip_Alarm_Bind_Port:'',
      },
      type:'all',
      GPIOList: [],
      GPIOListBackups: [],
      devicemoduletype: '',
      modelnum: '',
      methodList: [],
      devicenum:'',
      devicename:'',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      let dictionary = getCache('dictionary', true);
      if (dictionary) {
        this.GPIOList = dictionary.GPIOList;
        this.GPIOListBackups = dictionary.GPIOList;
      }
      this.getDevicenum();
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
          this.iotConfigs.Xianshi_device_sn_num=res.item[0].monitornum
        }
      })
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      this.iotConfigs={
        //拨打值班室电话使能，1为使能，0为禁止，默认使能
        Voip_Alarm_Enable:'',
            //拨打值班室电话事件上传录像使能，1为使能，0为禁止，默认使能
            Voip_Alarm_Upload_Video_Enable:'',
            //IO状态判断时间间隔,默认1秒
            IO_Calculation_Interval:'',
            //IO状态采样时间间隔，默认15ms
            IO_Sampling_Interval:'',
            //时间窗内IO有效数值占比，默认0.0200,保留4位小数
            IO_State_Effective_Threshold:'',
            //温度过高阈值，默认45摄氏度
            Celsius_Temperature_Max_Threshold:'',
            //温度过低阈值，默认0摄氏度
            Celsius_Temperature_Min_Threshold:'',
            //相对湿度过高阈值，默认90%
            Relative_Humidity_Max_Threshold:'',
            //相对湿度过低阈值，默认10%
            Relative_Humidity_Min_Threshold:'',
            //温度队列长度，默认3个
            Celsius_Temperature_State_List_Max:'',
            //温度队列阈值，默认2个
            Celsius_Temperature_State_List_Threshold:'',
            //湿度队列长度，默认3个
            Relative_Humidity_State_List_Max:'',
            //湿度队列阈值，默认2个
            Relative_Humidity_State_List_Threshold:'',
            //人体检测队列长度，默认3个
            Person_Detect_List_Max:'',
            //人体检测队列阈值，默认2个
            Person_Detect_List_Threshold:'',
            //摄氏温度过高事件使能，1为使能，0为禁止，默认使能
            Celsius_Temperature_Too_High_Event_Enable:'',
            //摄氏温度过低事件使能，1为使能，0为禁止，默认使能
            Celsius_Temperature_Too_Low_Event_Enable:'',
            //相对湿度过高事件使能，1为使能，0为禁止，默认使能
            Relative_Humidity_Too_High_Event_Enable:'',
            //相对湿度过低事件使能，1为使能，0为禁止，默认使能
            Relative_Humidity_Too_Low_Event_Enable:'',
            //机房有人事件使能，1为使能，0为禁止，默认使能
            WaterPumpHouse_Person_Event_Enable:'',
            //机房门打开事件使能，1为使能，0为禁止，默认使能
            EngineRoom_Door_Open_Event_Enable:'',
            //机房送风机启动事件使能，1为使能，0为禁止，默认使能
            EngineRoom_FeedFan_Start_Event_Enable:'',
            //机房排风机启动事件使能，1为使能，0为禁止，默认使能
            EngineRoom_ExhaustFan_Start_Event_Enable:'',
            //潜水泵1启动事件使能，1为使能，0为禁止，默认使能
            SubmersiblePump_1_Start_Event_Enable:'',
            //潜水泵2启动事件使能，1为使能，0为禁止，默认使能
            SubmersiblePump_2_Start_Event_Enable:'',
            //水泵组1主泵1启动事件使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_FirstMainPump_Start_Event_Enable:'',
            //水泵组1主泵2启动事件使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_SecondMainPump_Start_Event_Enable:'',
            //水泵组1主泵3启动事件使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable:'',
            //水泵组1稳压泵启动事件使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable:'',
            //水泵组2主泵1启动事件使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_FirstMainPump_Start_Event_Enable:'',
            //水泵组2主泵2启动事件使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_SecondMainPump_Start_Event_Enable:'',
            //水泵组2主泵3启动事件使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable:'',
            //水泵组2稳压泵启动事件使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable:'',
            //水箱补水开始事件使能，1为使能，0为禁止，默认使能
            Radiator_Supply_Start_Event_Enable:'',
            //水箱溢水开始事件使能，1为使能，0为禁止，默认使能
            Radiator_Overflow_Start_Event_Enable:'',
            //水箱缺水开始事件使能，1为使能，0为禁止，默认使能
            Radiator_Lack_Start_Event_Enable:'',
            //机房走水报警使能，1为使能，0为禁止，默认使能
            EngineRoom_OnFire_Alarm_Enable:'',
            //潜水泵断电报警使能，1为使能，0为禁止，默认使能
            SubmersiblePump_Power_Cut_Alarm_Enable:'',
            //潜水泵1故障报警使能，1为使能，0为禁止，默认使能
            SubmersiblePump_1_Fault_Alarm_Enable:'',
            //潜水泵2故障报警使能，1为使能，0为禁止，默认使能
            SubmersiblePump_2_Fault_Alarm_Enable:'',
            //水泵组1故障报警使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_Fault_Alarm_Enable:'',
            //水泵组1超压报警使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable:'',
            //水泵组1失压报警使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable:'',
            //水泵组2故障报警使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_Fault_Alarm_Enable:'',
            //水泵组2超压报警使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable:'',
            //水泵组2失压报警使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable:'',
            //水箱盖打开报警使能，1为使能，0为禁止，默认使能
            Radiator_Cap_Open_Alarm_Enable:'',
            //补水故障，高水位故障(111)报警使能，1为使能，0为禁止，默认使能
            Radiator_Supply_HighWaterLevel_Alarm_Enable:'',
            //补水故障，高、缺水位故障(110)报警使能，1为使能，0为禁止，默认使能
            Radiator_Supply_High_LackWaterLevel_Alarm_Enable:'',
            //正在补水，水位过低(100)报警使能，1为使能，0为禁止，默认使能
            Radiator_Supply_LackWaterLevel_Alarm_Enable:'',
            //高水位故障(011)报警使能，1为使能，0为禁止，默认使能
            Radiator_HighWaterLevel_Alarm_Enable:'',
            //高、缺水位故障(010)报警使能，1为使能，0为禁止，默认使能
            Radiator_High_LackWaterLevel_Alarm_Enable:'',
            //补水故障或无外水；低水位故障(000)报警使能，1为使能，0为禁止，默认使能
            Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable:'',


            //摄氏温度过高事件上传录像使能，1为使能，0为禁止，默认使能
            Celsius_Temperature_Too_High_Event_Upload_Video_Enable:'',
            //摄氏温度过低事件上传录像使能，1为使能，0为禁止，默认使能
            Celsius_Temperature_Too_Low_Event_Upload_Video_Enable:'',
            //相对湿度过高事件上传录像使能，1为使能，0为禁止，默认使能
            Relative_Humidity_Too_High_Event_Upload_Video_Enable:'',
            //相对湿度过低事件上传录像使能，1为使能，0为禁止，默认使能
            Relative_Humidity_Too_Low_Event_Upload_Video_Enable:'',
            //机房有人事件上传录像使能，1为使能，0为禁止，默认使能
            WaterPumpHouse_Person_Event_Upload_Video_Enable:'',
            //机房门打开事件上传录像使能，1为使能，0为禁止，默认使能
            EngineRoom_Door_Open_Event_Upload_Video_Enable:'',
            //机房送风机启动事件上传录像使能，1为使能，0为禁止，默认使能
            EngineRoom_FeedFan_Start_Event_Upload_Video_Enable:'',
            //机房排风机启动事件上传录像使能，1为使能，0为禁止，默认使能
            EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable:'',
            //潜水泵1启动事件上传录像使能，1为使能，0为禁止，默认使能
            SubmersiblePump_1_Start_Event_Upload_Video_Enable:'',
            //潜水泵2启动事件上传录像使能，1为使能，0为禁止，默认使能
            SubmersiblePump_2_Start_Event_Upload_Video_Enable:'',
            //水泵组1主泵1启动事件上传录像使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Video_Enable:'',
            //水泵组1主泵2启动事件上传录像使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Video_Enable:'',
            //水泵组1主泵3启动事件上传录像使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Video_Enable:'',
            //水泵组1稳压泵启动事件上传录像使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Video_Enable:'',
            //水泵组2主泵1启动事件上传录像使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Video_Enable:'',
            //水泵组2主泵2启动事件上传录像使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Video_Enable:'',
            //水泵组2主泵3启动事件上传录像使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Video_Enable:'',
            //水泵组2稳压泵启动事件上传录像使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Video_Enable:'',
            //水箱补水开始事件上传录像使能，1为使能，0为禁止，默认使能
            Radiator_Supply_Start_Event_Upload_Video_Enable:'',
            //水箱溢水开始事件上传录像使能，1为使能，0为禁止，默认使能
            Radiator_Overflow_Start_Event_Upload_Video_Enable:'',
            //水箱缺水开始事件上传录像使能，1为使能，0为禁止，默认使能
            Radiator_Lack_Start_Event_Upload_Video_Enable:'',
            //机房走水报警使能，1为使能，0为禁止，默认使能
            EngineRoom_OnFire_Alarm_Upload_Video_Enable:'',
            //潜水泵断电报警使能，1为使能，0为禁止，默认使能
            SubmersiblePump_Power_Cut_Alarm_Upload_Video_Enable:'',
            //潜水泵1故障报警使能，1为使能，0为禁止，默认使能
            SubmersiblePump_1_Fault_Alarm_Upload_Video_Enable:'',
            //潜水泵2故障报警使能，1为使能，0为禁止，默认使能
            SubmersiblePump_2_Fault_Alarm_Upload_Video_Enable:'',
            //水泵组1故障报警使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_Fault_Alarm_Upload_Video_Enable:'',
            //水泵组1超压报警使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Video_Enable:'',
            //水泵组1失压报警使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Video_Enable:'',
            //水泵组2故障报警使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_Fault_Alarm_Upload_Video_Enable:'',
            //水泵组2超压报警使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Video_Enable:'',
            //水泵组2失压报警使能，1为使能，0为禁止，默认使能
            WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Video_Enable:'',
            //水箱盖打开报警使能，1为使能，0为禁止，默认使能
            Radiator_Cap_Open_Alarm_Upload_Video_Enable:'',
            //补水故障，高水位故障(111)报警使能，1为使能，0为禁止，默认使能
            Radiator_Supply_HighWaterLevel_Alarm_Upload_Video_Enable:'',
            //补水故障，高、缺水位故障(110)报警使能，1为使能，0为禁止，默认使能
            Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Video_Enable:'',
            //正在补水，水位过低(100)报警使能，1为使能，0为禁止，默认使能
            Radiator_Supply_LackWaterLevel_Alarm_Upload_Video_Enable:'',
            //高水位故障(011)报警使能，1为使能，0为禁止，默认使能
            Radiator_HighWaterLevel_Alarm_Upload_Video_Enable:'',
            //高、缺水位故障(010)报警使能，1为使能，0为禁止，默认使能
            Radiator_High_LackWaterLevel_Alarm_Upload_Video_Enable:'',
            //补水故障或无外水；低水位故障(000)报警使能，1为使能，0为禁止，默认使能
            Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Video_Enable:'',

        //摄氏温度过高事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Celsius_Temperature_Too_High_Event_Upload_Image_Enable:'',
        //摄氏温度过低事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Celsius_Temperature_Too_Low_Event_Upload_Image_Enable:'',
        //相对湿度过高事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Relative_Humidity_Too_High_Event_Upload_Image_Enable:'',
        //相对湿度过低事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Relative_Humidity_Too_Low_Event_Upload_Image_Enable:'',
        //机房有人事件上传图片使能，1为上传图片使能，0为禁止，默认1
        WaterPumpHouse_Person_Event_Upload_Image_Enable:'',
        //机房门打开事件上传图片使能，1为上传图片使能，0为禁止，默认1
        EngineRoom_Door_Open_Event_Upload_Image_Enable:'',
        //机房送风机启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        EngineRoom_FeedFan_Start_Event_Upload_Image_Enable:'',
        //机房排风机启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable:'',
        //潜水泵1启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        SubmersiblePump_1_Start_Event_Upload_Image_Enable:'',
        //潜水泵2启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        SubmersiblePump_2_Start_Event_Upload_Image_Enable:'',
        //水泵组1主泵1启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Image_Enable:'',
        //水泵组1主泵2启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Image_Enable:'',
        //水泵组1主泵3启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Image_Enable:'',
        //水泵组1稳压泵启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Image_Enable:'',
        //水泵组2主泵1启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Image_Enable:'',
        //水泵组2主泵2启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Image_Enable:'',
        //水泵组2主泵3启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Image_Enable:'',
        //水泵组2稳压泵启动事件上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Image_Enable:'',
        //水箱补水开始事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_Supply_Start_Event_Upload_Image_Enable:'',
        //水箱溢水开始事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_Overflow_Start_Event_Upload_Image_Enable:'',
        //水箱缺水开始事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_Lack_Start_Event_Upload_Image_Enable:'',
        //机房走水报警上传图片使能，1为上传图片使能，0为禁止，默认1
        EngineRoom_OnFire_Alarm_Upload_Image_Enable:'',
        //潜水泵断电报警上传图片使能，1为上传图片使能，0为禁止，默认0
        SubmersiblePump_Power_Cut_Alarm_Upload_Image_Enable:'',
        //潜水泵1故障报警上传图片使能，1为上传图片使能，0为禁止，默认0
        SubmersiblePump_1_Fault_Alarm_Upload_Image_Enable:'',
        //潜水泵2故障报警上传图片使能，1为上传图片使能，0为禁止，默认0
        SubmersiblePump_2_Fault_Alarm_Upload_Image_Enable:'',
        //水泵组1故障报警上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_Fault_Alarm_Upload_Image_Enable:'',
        //水泵组1超压报警上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Image_Enable:'',
        //水泵组1失压报警上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Image_Enable:'',
        //水泵组2故障报警上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_Fault_Alarm_Upload_Image_Enable:'',
        //水泵组2超压报警上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Image_Enable:'',
        //水泵组2失压报警上传图片使能，1为上传图片使能，0为禁止，默认0
        WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Image_Enable:'',
        //水箱盖打开报警上传图片使能，1为上传图片使能，0为禁止，默认1
        Radiator_Cap_Open_Alarm_Upload_Image_Enable:'',
        //水箱补水中（高水位故障）报警上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_Supply_HighWaterLevel_Alarm_Upload_Image_Enable:'',
        //水箱补水中（高、缺水位故障）报警上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Image_Enable:'',
        //水箱补水中（水位过低）报警上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_Supply_LackWaterLevel_Alarm_Upload_Image_Enable:'',
        //水箱高水位报警上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_HighWaterLevel_Alarm_Upload_Image_Enable:'',
        //水箱高、缺水位报警上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_High_LackWaterLevel_Alarm_Upload_Image_Enable:'',
        //水箱补水故障或无外水、低水位报警上传图片使能，1为上传图片使能，0为禁止，默认0
        Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Image_Enable:'',
        //拨打值班室电话事件上传图片使能，1为上传图片使能，0为禁止，默认1
        Voip_Alarm_Upload_Image_Enable:'',


            //机房门打开信号有效电平，1为高电平有效，0为低电平有效，默认0
            EngineRoom_Door_Open_Gpio_State:'',
            //机房送风机启动信号有效电平，1为高电平有效，0为低电平有效，默认1
            EngineRoom_FeedFan_Start_Gpio_State:'',
            //机房排风机启动信号有效电平，1为高电平有效，0为低电平有效，默认1
            EngineRoom_ExhaustFan_Start_Gpio_State:'',
            //潜水泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
            SubmersiblePump_1_Start_Gpio_State:'',
            //潜水泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
            SubmersiblePump_2_Start_Gpio_State:'',
            //水泵组1主泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
            WaterPumpGroup_1_FirstMainPump_Start_Gpio_State:'',
            //水泵组1主泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
            WaterPumpGroup_1_SecondMainPump_Start_Gpio_State:'',
            //水泵组1主泵3启动信号有效电平，1为高电平有效，0为低电平有效，默认1
            WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State:'',
            //水泵组1稳压泵启动信号有效电平，1为高电平有效，0为低电平有效，默认1
            WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State:'',
            //水泵组2主泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
            WaterPumpGroup_2_FirstMainPump_Start_Gpio_State:'',
            //水泵组2主泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
            WaterPumpGroup_2_SecondMainPump_Start_Gpio_State:'',
            //水泵组2主泵3启动信号有效电平，1为高电平有效，0为低电平有效，默认1
            WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State:'',
            //水泵组2稳压泵启动信号有效电平，1为高电平有效，0为低电平有效，默认1
            WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State:'',
            //水箱补水开始信号有效电平，1为高电平有效，0为低电平有效，默认0
            Radiator_Supply_Start_Gpio_State:'',
            //水箱溢水开始信号有效电平，1为高电平有效，0为低电平有效，默认0
            Radiator_Overflow_Start_Gpio_State:'',
            //水箱缺水开始信号有效电平，1为高电平有效，0为低电平有效，默认0
            Radiator_Lack_Start_Gpio_State:'',
            //机房走水报警信号有效电平，1为高电平有效，0为低电平有效，默认1
            EngineRoom_OnFire_Alarm_Gpio_State:'',
            //潜水泵断电报警信号有效电平，1为高电平有效，0为低电平有效，默认1
            SubmersiblePump_Power_Cut_Alarm_Gpio_State:'',
            //潜水泵1故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
            SubmersiblePump_1_Fault_Alarm_Gpio_State:'',
            //潜水泵2故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
            SubmersiblePump_2_Fault_Alarm_Gpio_State:'',
            //水泵组1故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
            WaterPumpGroup_1_Fault_Alarm_Gpio_State:'',
            //水泵组1超压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
            WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State:'',
            //水泵组1失压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
            WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State:'',
            //水泵组2故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
            WaterPumpGroup_2_Fault_Alarm_Gpio_State:'',
            //水泵组2超压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
            WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State:'',
            //水泵组2失压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
            WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State:'',
            //水箱盖打开报警信号有效电平，1为高电平有效，0为低电平有效，默认0
            Radiator_Cap_Open_Alarm_Gpio_State:'',
            //备用I10有效电平，1为高电平有效，0为低电平有效，默认0
            Reserve_I10_Gpio_State:'',
            //备用I11有效电平，1为高电平有效，0为低电平有效，默认0
            Reserve_I11_Gpio_State:'',
            //拨打值班室电话信号有效电平，1为高电平有效，0为低电平有效，默认0
            Voip_Alarm_Gpio_State:'',


            //机房门打开信号绑定Gpio端口，默认I1
            EngineRoom_Door_Open_Bind_Port:'',
            //机房送风机启动信号绑定Gpio端口，默认H2
            EngineRoom_FeedFan_Start_Bind_Port:'',
            //机房排风机启动信号绑定Gpio端口，默认H3
            EngineRoom_ExhaustFan_Start_Bind_Port:'',
            //潜水泵1启动信号绑定Gpio端口，默认H5
            SubmersiblePump_1_Start_Bind_Port:'',
            //潜水泵2启动信号绑定Gpio端口，默认H7
            SubmersiblePump_2_Start_Bind_Port:'',
            //水泵组1主泵1启动信号绑定Gpio端口，默认H10
            WaterPumpGroup_1_FirstMainPump_Start_Bind_Port:'',
            //水泵组1主泵2启动信号绑定Gpio端口，默认H11
            WaterPumpGroup_1_SecondMainPump_Start_Bind_Port:'',
            //水泵组1主泵3启动信号绑定Gpio端口，默认H12
            WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port:'',
            //水泵组1稳压泵启动信号绑定Gpio端口，默认H13
            WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port:'',
            //水泵组2主泵1启动信号绑定Gpio端口，默认H15
            WaterPumpGroup_2_FirstMainPump_Start_Bind_Port:'',
            //水泵组2主泵2启动信号绑定Gpio端口，默认H16
            WaterPumpGroup_2_SecondMainPump_Start_Bind_Port:'',
            //水泵组2主泵3启动信号绑定Gpio端口，默认H17
            WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port:'',
            //水泵组2稳压泵启动信号绑定Gpio端口，默认H18
            WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port:'',
            //水箱补水开始信号绑定Gpio端口，默认I7
            Radiator_Supply_Start_Bind_Port:'',
            //水箱溢水开始信号绑定Gpio端口，默认B1
            Radiator_Overflow_Start_Bind_Port:'',
            //水箱缺水开始信号绑定Gpio端口，默认B2
            Radiator_Lack_Start_Bind_Port:'',
            //机房走水报警信号绑定Gpio端口，默认H1
            EngineRoom_OnFire_Alarm_Bind_Port:'',
            //潜水泵断电报警信号绑定Gpio端口，默认H4
            SubmersiblePump_Power_Cut_Alarm_Bind_Port:'',
            //潜水泵1故障报警信号绑定Gpio端口，默认H6
            SubmersiblePump_1_Fault_Alarm_Bind_Port:'',
            //潜水泵2故障报警信号绑定Gpio端口，默认H8
            SubmersiblePump_2_Fault_Alarm_Bind_Port:'',
            //水泵组1故障报警信号绑定Gpio端口，默认H9
            WaterPumpGroup_1_Fault_Alarm_Bind_Port:'',
            //水泵组1超压报警信号绑定Gpio端口，默认I2
            WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port:'',
            //水泵组1失压报警信号绑定Gpio端口，默认I3
            WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port:'',
            //水泵组2故障报警信号绑定Gpio端口，默认H14
            WaterPumpGroup_2_Fault_Alarm_Bind_Port:'',
            //水泵组2超压报警信号绑定Gpio端口，默认I4
            WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port:'',
            //水泵组2失压报警信号绑定Gpio端口，默认I5
            WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port:'',
            //水箱盖打开报警信号绑定Gpio端口，默认I6
            Radiator_Cap_Open_Alarm_Bind_Port:'',
            //拨打值班室电话信号绑定Gpio端口，默认B5
            Voip_Alarm_Bind_Port:'',
      }
      this.GPIOList=this.GPIOListBackups
      this.paramsList = [];
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'GET',
              url: '/iot',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.iotConfigs.Voip_Alarm_Enable = res.item[0].params.body.Voip_Alarm_Enable
          this.iotConfigs.Voip_Alarm_Upload_Video_Enable = res.item[0].params.body.Voip_Alarm_Upload_Video_Enable
          this.iotConfigs.IO_Calculation_Interval = res.item[0].params.body.IO_Calculation_Interval
          this.iotConfigs.IO_Sampling_Interval = res.item[0].params.body.IO_Sampling_Interval
          this.iotConfigs.IO_State_Effective_Threshold = parseFloat(res.item[0].params.body.IO_State_Effective_Threshold).toFixed(4)
          this.iotConfigs.Celsius_Temperature_Max_Threshold = res.item[0].params.body.Celsius_Temperature_Max_Threshold
          this.iotConfigs.Celsius_Temperature_Min_Threshold = res.item[0].params.body.Celsius_Temperature_Min_Threshold
          this.iotConfigs.Relative_Humidity_Max_Threshold = res.item[0].params.body.Relative_Humidity_Max_Threshold
          this.iotConfigs.Relative_Humidity_Min_Threshold = res.item[0].params.body.Relative_Humidity_Min_Threshold
          this.iotConfigs.Celsius_Temperature_State_List_Max = res.item[0].params.body.Celsius_Temperature_State_List_Max
          this.iotConfigs.Celsius_Temperature_State_List_Threshold = res.item[0].params.body.Celsius_Temperature_State_List_Threshold
          this.iotConfigs.Relative_Humidity_State_List_Max = res.item[0].params.body.Relative_Humidity_State_List_Max
          this.iotConfigs.Relative_Humidity_State_List_Threshold = res.item[0].params.body.Relative_Humidity_State_List_Threshold
          this.iotConfigs.Person_Detect_List_Max = res.item[0].params.body.Person_Detect_List_Max
          this.iotConfigs.Person_Detect_List_Threshold = res.item[0].params.body.Person_Detect_List_Threshold
          this.iotConfigs.Celsius_Temperature_Too_High_Event_Enable = res.item[0].params.body.Celsius_Temperature_Too_High_Event_Enable
          this.iotConfigs.Celsius_Temperature_Too_Low_Event_Enable = res.item[0].params.body.Celsius_Temperature_Too_Low_Event_Enable
          this.iotConfigs.Relative_Humidity_Too_High_Event_Enable = res.item[0].params.body.Relative_Humidity_Too_High_Event_Enable
          this.iotConfigs.Relative_Humidity_Too_Low_Event_Enable = res.item[0].params.body.Relative_Humidity_Too_Low_Event_Enable
          this.iotConfigs.WaterPumpHouse_Person_Event_Enable = res.item[0].params.body.WaterPumpHouse_Person_Event_Enable
          this.iotConfigs.EngineRoom_Door_Open_Event_Enable = res.item[0].params.body.EngineRoom_Door_Open_Event_Enable
          this.iotConfigs.EngineRoom_FeedFan_Start_Event_Enable = res.item[0].params.body.EngineRoom_FeedFan_Start_Event_Enable
          this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Enable = res.item[0].params.body.EngineRoom_ExhaustFan_Start_Event_Enable
          this.iotConfigs.SubmersiblePump_1_Start_Event_Enable = res.item[0].params.body.SubmersiblePump_1_Start_Event_Enable
          this.iotConfigs.SubmersiblePump_2_Start_Event_Enable = res.item[0].params.body.SubmersiblePump_2_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_1_FirstMainPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_1_SecondMainPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_2_FirstMainPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_2_SecondMainPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable
          this.iotConfigs.Radiator_Supply_Start_Event_Enable = res.item[0].params.body.Radiator_Supply_Start_Event_Enable
          this.iotConfigs.Radiator_Overflow_Start_Event_Enable = res.item[0].params.body.Radiator_Overflow_Start_Event_Enable
          this.iotConfigs.Radiator_Lack_Start_Event_Enable = res.item[0].params.body.Radiator_Lack_Start_Event_Enable
          this.iotConfigs.EngineRoom_OnFire_Alarm_Enable = res.item[0].params.body.EngineRoom_OnFire_Alarm_Enable
          this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Enable = res.item[0].params.body.SubmersiblePump_Power_Cut_Alarm_Enable
          this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Enable = res.item[0].params.body.SubmersiblePump_1_Fault_Alarm_Enable
          this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Enable = res.item[0].params.body.SubmersiblePump_2_Fault_Alarm_Enable
          this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Enable = res.item[0].params.body.WaterPumpGroup_1_Fault_Alarm_Enable
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable
          this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Enable = res.item[0].params.body.WaterPumpGroup_2_Fault_Alarm_Enable
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable
          this.iotConfigs.Radiator_Cap_Open_Alarm_Enable = res.item[0].params.body.Radiator_Cap_Open_Alarm_Enable
          this.iotConfigs.Radiator_Supply_HighWaterLevel_Alarm_Enable = res.item[0].params.body.Radiator_Supply_HighWaterLevel_Alarm_Enable
          this.iotConfigs.Radiator_Supply_High_LackWaterLevel_Alarm_Enable = res.item[0].params.body.Radiator_Supply_High_LackWaterLevel_Alarm_Enable
          this.iotConfigs.Radiator_Supply_LackWaterLevel_Alarm_Enable = res.item[0].params.body.Radiator_Supply_LackWaterLevel_Alarm_Enable
          this.iotConfigs.Radiator_HighWaterLevel_Alarm_Enable = res.item[0].params.body.Radiator_HighWaterLevel_Alarm_Enable
          this.iotConfigs.Radiator_High_LackWaterLevel_Alarm_Enable = res.item[0].params.body.Radiator_High_LackWaterLevel_Alarm_Enable
          this.iotConfigs.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable = res.item[0].params.body.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable

          this.iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Video_Enable = res.item[0].params.body.Celsius_Temperature_Too_High_Event_Upload_Video_Enable
          this.iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Video_Enable = res.item[0].params.body.Celsius_Temperature_Too_Low_Event_Upload_Video_Enable
          this.iotConfigs.Relative_Humidity_Too_High_Event_Upload_Video_Enable = res.item[0].params.body.Relative_Humidity_Too_High_Event_Upload_Video_Enable
          this.iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Video_Enable = res.item[0].params.body.Relative_Humidity_Too_Low_Event_Upload_Video_Enable
          this.iotConfigs.WaterPumpHouse_Person_Event_Upload_Video_Enable = res.item[0].params.body.WaterPumpHouse_Person_Event_Upload_Video_Enable
          this.iotConfigs.EngineRoom_Door_Open_Event_Upload_Video_Enable = res.item[0].params.body.EngineRoom_Door_Open_Event_Upload_Video_Enable
          this.iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Video_Enable = res.item[0].params.body.EngineRoom_FeedFan_Start_Event_Upload_Video_Enable
          this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable = res.item[0].params.body.EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable
          this.iotConfigs.SubmersiblePump_1_Start_Event_Upload_Video_Enable = res.item[0].params.body.SubmersiblePump_1_Start_Event_Upload_Video_Enable
          this.iotConfigs.SubmersiblePump_2_Start_Event_Upload_Video_Enable = res.item[0].params.body.SubmersiblePump_2_Start_Event_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Video_Enable
          this.iotConfigs.Radiator_Supply_Start_Event_Upload_Video_Enable = res.item[0].params.body.Radiator_Supply_Start_Event_Upload_Video_Enable
          this.iotConfigs.Radiator_Overflow_Start_Event_Upload_Video_Enable = res.item[0].params.body.Radiator_Overflow_Start_Event_Upload_Video_Enable
          this.iotConfigs.Radiator_Lack_Start_Event_Upload_Video_Enable = res.item[0].params.body.Radiator_Lack_Start_Event_Upload_Video_Enable
          this.iotConfigs.EngineRoom_OnFire_Alarm_Upload_Video_Enable = res.item[0].params.body.EngineRoom_OnFire_Alarm_Upload_Video_Enable
          this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Upload_Video_Enable = res.item[0].params.body.SubmersiblePump_Power_Cut_Alarm_Upload_Video_Enable
          this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Upload_Video_Enable = res.item[0].params.body.SubmersiblePump_1_Fault_Alarm_Upload_Video_Enable
          this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Upload_Video_Enable = res.item[0].params.body.SubmersiblePump_2_Fault_Alarm_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_1_Fault_Alarm_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_2_Fault_Alarm_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Video_Enable
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Video_Enable = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Video_Enable
          this.iotConfigs.Radiator_Cap_Open_Alarm_Upload_Video_Enable = res.item[0].params.body.Radiator_Cap_Open_Alarm_Upload_Video_Enable
          this.iotConfigs.Radiator_Supply_HighWaterLevel_Alarm_Upload_Video_Enable = res.item[0].params.body.Radiator_Supply_HighWaterLevel_Alarm_Upload_Video_Enable
          this.iotConfigs.Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Video_Enable = res.item[0].params.body.Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Video_Enable
          this.iotConfigs.Radiator_Supply_LackWaterLevel_Alarm_Upload_Video_Enable = res.item[0].params.body.Radiator_Supply_LackWaterLevel_Alarm_Upload_Video_Enable
          this.iotConfigs.Radiator_HighWaterLevel_Alarm_Upload_Video_Enable = res.item[0].params.body.Radiator_HighWaterLevel_Alarm_Upload_Video_Enable
          this.iotConfigs.Radiator_High_LackWaterLevel_Alarm_Upload_Video_Enable = res.item[0].params.body.Radiator_High_LackWaterLevel_Alarm_Upload_Video_Enable
          this.iotConfigs.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Video_Enable = res.item[0].params.body.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Video_Enable

          this.iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Image_Enable = res.item[0].params.body.Celsius_Temperature_Too_High_Event_Upload_Image_Enable
          this.iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Image_Enable = res.item[0].params.body.Celsius_Temperature_Too_Low_Event_Upload_Image_Enable
          this.iotConfigs.Relative_Humidity_Too_High_Event_Upload_Image_Enable = res.item[0].params.body.Relative_Humidity_Too_High_Event_Upload_Image_Enable
          this.iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Image_Enable = res.item[0].params.body.Relative_Humidity_Too_Low_Event_Upload_Image_Enable
          this.iotConfigs.WaterPumpHouse_Person_Event_Upload_Image_Enable = res.item[0].params.body.WaterPumpHouse_Person_Event_Upload_Image_Enable
          this.iotConfigs.EngineRoom_Door_Open_Event_Upload_Image_Enable = res.item[0].params.body.EngineRoom_Door_Open_Event_Upload_Image_Enable
          this.iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Image_Enable = res.item[0].params.body.EngineRoom_FeedFan_Start_Event_Upload_Image_Enable
          this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable = res.item[0].params.body.EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable
          this.iotConfigs.SubmersiblePump_1_Start_Event_Upload_Image_Enable = res.item[0].params.body.SubmersiblePump_1_Start_Event_Upload_Image_Enable
          this.iotConfigs.SubmersiblePump_2_Start_Event_Upload_Image_Enable = res.item[0].params.body.SubmersiblePump_2_Start_Event_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Image_Enable
          this.iotConfigs.Radiator_Supply_Start_Event_Upload_Image_Enable = res.item[0].params.body.Radiator_Supply_Start_Event_Upload_Image_Enable
          this.iotConfigs.Radiator_Overflow_Start_Event_Upload_Image_Enable = res.item[0].params.body.Radiator_Overflow_Start_Event_Upload_Image_Enable
          this.iotConfigs.Radiator_Lack_Start_Event_Upload_Image_Enable = res.item[0].params.body.Radiator_Lack_Start_Event_Upload_Image_Enable
          this.iotConfigs.EngineRoom_OnFire_Alarm_Upload_Image_Enable = res.item[0].params.body.EngineRoom_OnFire_Alarm_Upload_Image_Enable
          this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Upload_Image_Enable = res.item[0].params.body.SubmersiblePump_Power_Cut_Alarm_Upload_Image_Enable
          this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Upload_Image_Enable = res.item[0].params.body.SubmersiblePump_1_Fault_Alarm_Upload_Image_Enable
          this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Upload_Image_Enable = res.item[0].params.body.SubmersiblePump_2_Fault_Alarm_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_1_Fault_Alarm_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_2_Fault_Alarm_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Image_Enable
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Image_Enable = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Image_Enable
          this.iotConfigs.Radiator_Cap_Open_Alarm_Upload_Image_Enable = res.item[0].params.body.Radiator_Cap_Open_Alarm_Upload_Image_Enable
          this.iotConfigs.Radiator_Supply_HighWaterLevel_Alarm_Upload_Image_Enable = res.item[0].params.body.Radiator_Supply_HighWaterLevel_Alarm_Upload_Image_Enable
          this.iotConfigs.Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Image_Enable = res.item[0].params.body.Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Image_Enable
          this.iotConfigs.Radiator_Supply_LackWaterLevel_Alarm_Upload_Image_Enable = res.item[0].params.body.Radiator_Supply_LackWaterLevel_Alarm_Upload_Image_Enable
          this.iotConfigs.Radiator_HighWaterLevel_Alarm_Upload_Image_Enable = res.item[0].params.body.Radiator_HighWaterLevel_Alarm_Upload_Image_Enable
          this.iotConfigs.Radiator_High_LackWaterLevel_Alarm_Upload_Image_Enable = res.item[0].params.body.Radiator_High_LackWaterLevel_Alarm_Upload_Image_Enable
          this.iotConfigs.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Image_Enable = res.item[0].params.body.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Image_Enable
          this.iotConfigs.Voip_Alarm_Upload_Image_Enable = res.item[0].params.body.Voip_Alarm_Upload_Image_Enable
          
          this.iotConfigs.EngineRoom_Door_Open_Gpio_State = res.item[0].params.body.EngineRoom_Door_Open_Gpio_State
          this.iotConfigs.EngineRoom_FeedFan_Start_Gpio_State = res.item[0].params.body.EngineRoom_FeedFan_Start_Gpio_State
          this.iotConfigs.EngineRoom_ExhaustFan_Start_Gpio_State = res.item[0].params.body.EngineRoom_ExhaustFan_Start_Gpio_State
          this.iotConfigs.SubmersiblePump_1_Start_Gpio_State = res.item[0].params.body.SubmersiblePump_1_Start_Gpio_State
          this.iotConfigs.SubmersiblePump_2_Start_Gpio_State = res.item[0].params.body.SubmersiblePump_2_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_FirstMainPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_SecondMainPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_FirstMainPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_SecondMainPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State
          this.iotConfigs.Radiator_Supply_Start_Gpio_State = res.item[0].params.body.Radiator_Supply_Start_Gpio_State
          this.iotConfigs.Radiator_Overflow_Start_Gpio_State = res.item[0].params.body.Radiator_Overflow_Start_Gpio_State
          this.iotConfigs.Radiator_Lack_Start_Gpio_State = res.item[0].params.body.Radiator_Lack_Start_Gpio_State
          this.iotConfigs.EngineRoom_OnFire_Alarm_Gpio_State = res.item[0].params.body.EngineRoom_OnFire_Alarm_Gpio_State
          this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Gpio_State = res.item[0].params.body.SubmersiblePump_Power_Cut_Alarm_Gpio_State
          this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Gpio_State = res.item[0].params.body.SubmersiblePump_1_Fault_Alarm_Gpio_State
          this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Gpio_State = res.item[0].params.body.SubmersiblePump_2_Fault_Alarm_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_Fault_Alarm_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_Fault_Alarm_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State
          this.iotConfigs.Radiator_Cap_Open_Alarm_Gpio_State = res.item[0].params.body.Radiator_Cap_Open_Alarm_Gpio_State
          this.iotConfigs.Reserve_I10_Gpio_State = res.item[0].params.body.Reserve_I10_Gpio_State
          this.iotConfigs.Reserve_I11_Gpio_State = res.item[0].params.body.Reserve_I11_Gpio_State
          this.iotConfigs.Voip_Alarm_Gpio_State = res.item[0].params.body.Voip_Alarm_Gpio_State


          this.iotConfigs.EngineRoom_Door_Open_Bind_Port = res.item[0].params.body.EngineRoom_Door_Open_Bind_Port
          this.iotConfigs.EngineRoom_FeedFan_Start_Bind_Port = res.item[0].params.body.EngineRoom_FeedFan_Start_Bind_Port
          this.iotConfigs.EngineRoom_ExhaustFan_Start_Bind_Port = res.item[0].params.body.EngineRoom_ExhaustFan_Start_Bind_Port
          this.iotConfigs.SubmersiblePump_1_Start_Bind_Port = res.item[0].params.body.SubmersiblePump_1_Start_Bind_Port
          this.iotConfigs.SubmersiblePump_2_Start_Bind_Port = res.item[0].params.body.SubmersiblePump_2_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_FirstMainPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_SecondMainPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_FirstMainPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_SecondMainPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port
          this.iotConfigs.Radiator_Supply_Start_Bind_Port = res.item[0].params.body.Radiator_Supply_Start_Bind_Port
          this.iotConfigs.Radiator_Overflow_Start_Bind_Port = res.item[0].params.body.Radiator_Overflow_Start_Bind_Port
          this.iotConfigs.Radiator_Lack_Start_Bind_Port = res.item[0].params.body.Radiator_Lack_Start_Bind_Port
          this.iotConfigs.EngineRoom_OnFire_Alarm_Bind_Port = res.item[0].params.body.EngineRoom_OnFire_Alarm_Bind_Port
          this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Bind_Port = res.item[0].params.body.SubmersiblePump_Power_Cut_Alarm_Bind_Port
          this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Bind_Port = res.item[0].params.body.SubmersiblePump_1_Fault_Alarm_Bind_Port
          this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Bind_Port = res.item[0].params.body.SubmersiblePump_2_Fault_Alarm_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_Fault_Alarm_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_Fault_Alarm_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port
          this.iotConfigs.Radiator_Cap_Open_Alarm_Bind_Port = res.item[0].params.body.Radiator_Cap_Open_Alarm_Bind_Port
          this.iotConfigs.Voip_Alarm_Bind_Port = res.item[0].params.body.Voip_Alarm_Bind_Port
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    setParamsConfig() {
      let paramsBody = {}
      if (this.waterSystemIotFlag.iotFlag == 0) {
        paramsBody = {
          Voip_Alarm_Enable:this.iotConfigs.Voip_Alarm_Enable,
          Voip_Alarm_Upload_Video_Enable:this.iotConfigs.Voip_Alarm_Upload_Video_Enable,
          IO_Calculation_Interval:this.iotConfigs.IO_Calculation_Interval,
          IO_Sampling_Interval:this.iotConfigs.IO_Sampling_Interval,
          IO_State_Effective_Threshold:this.iotConfigs.IO_State_Effective_Threshold,
          Celsius_Temperature_Max_Threshold:this.iotConfigs.Celsius_Temperature_Max_Threshold,
          Celsius_Temperature_Min_Threshold:this.iotConfigs.Celsius_Temperature_Min_Threshold,
          Relative_Humidity_Max_Threshold:this.iotConfigs.Relative_Humidity_Max_Threshold,
          Relative_Humidity_Min_Threshold:this.iotConfigs.Relative_Humidity_Min_Threshold,
          Celsius_Temperature_State_List_Max:this.iotConfigs.Celsius_Temperature_State_List_Max,
          Celsius_Temperature_State_List_Threshold:this.iotConfigs.Celsius_Temperature_State_List_Threshold,
          Relative_Humidity_State_List_Max:this.iotConfigs.Relative_Humidity_State_List_Max,
          Relative_Humidity_State_List_Threshold:this.iotConfigs.Relative_Humidity_State_List_Threshold,
          Person_Detect_List_Max:this.iotConfigs.Person_Detect_List_Max,
          Person_Detect_List_Threshold:this.iotConfigs.Person_Detect_List_Threshold,
          Celsius_Temperature_Too_High_Event_Enable:this.iotConfigs.Celsius_Temperature_Too_High_Event_Enable,
          Celsius_Temperature_Too_Low_Event_Enable:this.iotConfigs.Celsius_Temperature_Too_Low_Event_Enable,
          Relative_Humidity_Too_High_Event_Enable:this.iotConfigs.Relative_Humidity_Too_High_Event_Enable,
          Relative_Humidity_Too_Low_Event_Enable:this.iotConfigs.Relative_Humidity_Too_Low_Event_Enable,
          WaterPumpHouse_Person_Event_Enable:this.iotConfigs.WaterPumpHouse_Person_Event_Enable,
          EngineRoom_Door_Open_Event_Enable:this.iotConfigs.EngineRoom_Door_Open_Event_Enable,
          EngineRoom_FeedFan_Start_Event_Enable:this.iotConfigs.EngineRoom_FeedFan_Start_Event_Enable,
          EngineRoom_ExhaustFan_Start_Event_Enable:this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Enable,
          SubmersiblePump_1_Start_Event_Enable:this.iotConfigs.SubmersiblePump_1_Start_Event_Enable,
          SubmersiblePump_2_Start_Event_Enable:this.iotConfigs.SubmersiblePump_2_Start_Event_Enable,
          WaterPumpGroup_1_FirstMainPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Enable,
          WaterPumpGroup_1_SecondMainPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Enable,
          WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable,
          WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable,
          WaterPumpGroup_2_FirstMainPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Enable,
          WaterPumpGroup_2_SecondMainPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Enable,
          WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable,
          WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable,
          Radiator_Supply_Start_Event_Enable:this.iotConfigs.Radiator_Supply_Start_Event_Enable,
          Radiator_Overflow_Start_Event_Enable:this.iotConfigs.Radiator_Overflow_Start_Event_Enable,
          Radiator_Lack_Start_Event_Enable:this.iotConfigs.Radiator_Lack_Start_Event_Enable,
          EngineRoom_OnFire_Alarm_Enable:this.iotConfigs.EngineRoom_OnFire_Alarm_Enable,
          SubmersiblePump_Power_Cut_Alarm_Enable:this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Enable,
          SubmersiblePump_1_Fault_Alarm_Enable:this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Enable,
          SubmersiblePump_2_Fault_Alarm_Enable:this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Enable,
          WaterPumpGroup_1_Fault_Alarm_Enable:this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Enable,
          WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable:this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable,
          WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable:this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable,
          WaterPumpGroup_2_Fault_Alarm_Enable:this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Enable,
          WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable:this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable,
          WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable:this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable,
          Radiator_Cap_Open_Alarm_Enable:this.iotConfigs.Radiator_Cap_Open_Alarm_Enable,
          Radiator_Supply_HighWaterLevel_Alarm_Enable:this.iotConfigs.Radiator_Supply_HighWaterLevel_Alarm_Enable,
          Radiator_Supply_High_LackWaterLevel_Alarm_Enable:this.iotConfigs.Radiator_Supply_High_LackWaterLevel_Alarm_Enable,
          Radiator_Supply_LackWaterLevel_Alarm_Enable:this.iotConfigs.Radiator_Supply_LackWaterLevel_Alarm_Enable,
          Radiator_HighWaterLevel_Alarm_Enable:this.iotConfigs.Radiator_HighWaterLevel_Alarm_Enable,
          Radiator_High_LackWaterLevel_Alarm_Enable:this.iotConfigs.Radiator_High_LackWaterLevel_Alarm_Enable,
          Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable:this.iotConfigs.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable,
          Celsius_Temperature_Too_High_Event_Upload_Video_Enable:this.iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Video_Enable,
          Celsius_Temperature_Too_Low_Event_Upload_Video_Enable:this.iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Video_Enable,
          Relative_Humidity_Too_High_Event_Upload_Video_Enable:this.iotConfigs.Relative_Humidity_Too_High_Event_Upload_Video_Enable,
          Relative_Humidity_Too_Low_Event_Upload_Video_Enable:this.iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Video_Enable,
          WaterPumpHouse_Person_Event_Upload_Video_Enable:this.iotConfigs.WaterPumpHouse_Person_Event_Upload_Video_Enable,
          EngineRoom_Door_Open_Event_Upload_Video_Enable:this.iotConfigs.EngineRoom_Door_Open_Event_Upload_Video_Enable,
          EngineRoom_FeedFan_Start_Event_Upload_Video_Enable:this.iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Video_Enable,
          EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable:this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable,
          SubmersiblePump_1_Start_Event_Upload_Video_Enable:this.iotConfigs.SubmersiblePump_1_Start_Event_Upload_Video_Enable,
          SubmersiblePump_2_Start_Event_Upload_Video_Enable:this.iotConfigs.SubmersiblePump_2_Start_Event_Upload_Video_Enable,
          WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Video_Enable,
          WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Video_Enable,
          WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Video_Enable,
          WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Video_Enable,
          WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Video_Enable,
          WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Video_Enable,
          WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Video_Enable,
          WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Video_Enable,
          Radiator_Supply_Start_Event_Upload_Video_Enable:this.iotConfigs.Radiator_Supply_Start_Event_Upload_Video_Enable,
          Radiator_Overflow_Start_Event_Upload_Video_Enable:this.iotConfigs.Radiator_Overflow_Start_Event_Upload_Video_Enable,
          Radiator_Lack_Start_Event_Upload_Video_Enable:this.iotConfigs.Radiator_Lack_Start_Event_Upload_Video_Enable,
          EngineRoom_OnFire_Alarm_Upload_Video_Enable:this.iotConfigs.EngineRoom_OnFire_Alarm_Upload_Video_Enable,
          SubmersiblePump_Power_Cut_Alarm_Upload_Video_Enable:this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Upload_Video_Enable,
          SubmersiblePump_1_Fault_Alarm_Upload_Video_Enable:this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Upload_Video_Enable,
          SubmersiblePump_2_Fault_Alarm_Upload_Video_Enable:this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Upload_Video_Enable,
          WaterPumpGroup_1_Fault_Alarm_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Upload_Video_Enable,
          WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Video_Enable,
          WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Video_Enable,
          WaterPumpGroup_2_Fault_Alarm_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Upload_Video_Enable,
          WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Video_Enable,
          WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Video_Enable:this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Video_Enable,
          Radiator_Cap_Open_Alarm_Upload_Video_Enable:this.iotConfigs.Radiator_Cap_Open_Alarm_Upload_Video_Enable,
          Radiator_Supply_HighWaterLevel_Alarm_Upload_Video_Enable:this.iotConfigs.Radiator_Supply_HighWaterLevel_Alarm_Upload_Video_Enable,
          Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Video_Enable:this.iotConfigs.Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Video_Enable,
          Radiator_Supply_LackWaterLevel_Alarm_Upload_Video_Enable:this.iotConfigs.Radiator_Supply_LackWaterLevel_Alarm_Upload_Video_Enable,
          Radiator_HighWaterLevel_Alarm_Upload_Video_Enable:this.iotConfigs.Radiator_HighWaterLevel_Alarm_Upload_Video_Enable,
          Radiator_High_LackWaterLevel_Alarm_Upload_Video_Enable:this.iotConfigs.Radiator_High_LackWaterLevel_Alarm_Upload_Video_Enable,
          Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Video_Enable:this.iotConfigs.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Video_Enable,

          Celsius_Temperature_Too_High_Event_Upload_Image_Enable:this.iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Image_Enable,
          Celsius_Temperature_Too_Low_Event_Upload_Image_Enable:this.iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Image_Enable,
          Relative_Humidity_Too_High_Event_Upload_Image_Enable:this.iotConfigs.Relative_Humidity_Too_High_Event_Upload_Image_Enable,
          Relative_Humidity_Too_Low_Event_Upload_Image_Enable:this.iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Image_Enable,
          WaterPumpHouse_Person_Event_Upload_Image_Enable:this.iotConfigs.WaterPumpHouse_Person_Event_Upload_Image_Enable,
          EngineRoom_Door_Open_Event_Upload_Image_Enable:this.iotConfigs.EngineRoom_Door_Open_Event_Upload_Image_Enable,
          EngineRoom_FeedFan_Start_Event_Upload_Image_Enable:this.iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Image_Enable,
          EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable:this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable,
          SubmersiblePump_1_Start_Event_Upload_Image_Enable:this.iotConfigs.SubmersiblePump_1_Start_Event_Upload_Image_Enable,
          SubmersiblePump_2_Start_Event_Upload_Image_Enable:this.iotConfigs.SubmersiblePump_2_Start_Event_Upload_Image_Enable,
          WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Image_Enable,
          WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Image_Enable,
          WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Image_Enable,
          WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Image_Enable,
          WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Image_Enable,
          WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Image_Enable,
          WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Image_Enable,
          WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Image_Enable,
          Radiator_Supply_Start_Event_Upload_Image_Enable:this.iotConfigs.Radiator_Supply_Start_Event_Upload_Image_Enable,
          Radiator_Overflow_Start_Event_Upload_Image_Enable:this.iotConfigs.Radiator_Overflow_Start_Event_Upload_Image_Enable,
          Radiator_Lack_Start_Event_Upload_Image_Enable:this.iotConfigs.Radiator_Lack_Start_Event_Upload_Image_Enable,
          EngineRoom_OnFire_Alarm_Upload_Image_Enable:this.iotConfigs.EngineRoom_OnFire_Alarm_Upload_Image_Enable,
          SubmersiblePump_Power_Cut_Alarm_Upload_Image_Enable:this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Upload_Image_Enable,
          SubmersiblePump_1_Fault_Alarm_Upload_Image_Enable:this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Upload_Image_Enable,
          SubmersiblePump_2_Fault_Alarm_Upload_Image_Enable:this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Upload_Image_Enable,
          WaterPumpGroup_1_Fault_Alarm_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Upload_Image_Enable,
          WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Image_Enable,
          WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Image_Enable,
          WaterPumpGroup_2_Fault_Alarm_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Upload_Image_Enable,
          WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Image_Enable,
          WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Image_Enable:this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Image_Enable,
          Radiator_Cap_Open_Alarm_Upload_Image_Enable:this.iotConfigs.Radiator_Cap_Open_Alarm_Upload_Image_Enable,
          Radiator_Supply_HighWaterLevel_Alarm_Upload_Image_Enable:this.iotConfigs.Radiator_Supply_HighWaterLevel_Alarm_Upload_Image_Enable,
          Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Image_Enable:this.iotConfigs.Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Image_Enable,
          Radiator_Supply_LackWaterLevel_Alarm_Upload_Image_Enable:this.iotConfigs.Radiator_Supply_LackWaterLevel_Alarm_Upload_Image_Enable,
          Radiator_HighWaterLevel_Alarm_Upload_Image_Enable:this.iotConfigs.Radiator_HighWaterLevel_Alarm_Upload_Image_Enable,
          Radiator_High_LackWaterLevel_Alarm_Upload_Image_Enable:this.iotConfigs.Radiator_High_LackWaterLevel_Alarm_Upload_Image_Enable,
          Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Image_Enable:this.iotConfigs.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Image_Enable,
          Voip_Alarm_Upload_Image_Enable:this.iotConfigs.Voip_Alarm_Upload_Image_Enable
        }
        this.paramsdata = "拨打值班室电话使能" + "," + this.iotConfigs.Voip_Alarm_Enable + ","
            + "拨打值班室电话事件上传录像使能" + "," + this.iotConfigs.Voip_Alarm_Upload_Video_Enable + ","
            + "IO状态判断时间间隔" + "," + this.iotConfigs.IO_Calculation_Interval + ","
            + "IO状态采样时间间隔" + "," + this.iotConfigs.IO_Sampling_Interval + ","
            + "时间窗内IO有效数值占比" + "," + this.iotConfigs.IO_State_Effective_Threshold + ","
            + "温度过高阈值" + "," + this.iotConfigs.Celsius_Temperature_Max_Threshold + ","
            + "温度过低阈值" + "," + this.iotConfigs.Celsius_Temperature_Min_Threshold + ","
            + "相对湿度过高阈值" + "," + this.iotConfigs.Relative_Humidity_Max_Threshold + ","
            + "相对湿度过低阈值" + "," + this.iotConfigs.Relative_Humidity_Min_Threshold + ","
            + "温度队列长度" + "," + this.iotConfigs.Celsius_Temperature_State_List_Max + ","
            + "温度队列阈值" + "," + this.iotConfigs.Celsius_Temperature_State_List_Threshold + ","
            + "湿度队列长度" + "," + this.iotConfigs.Relative_Humidity_State_List_Max + ","
            + "湿度队列阈值" + "," + this.iotConfigs.Relative_Humidity_State_List_Threshold + ","
            + "人体检测队列长度" + "," + this.iotConfigs.Person_Detect_List_Max + ","
            + "人体检测队列阈值" + "," + this.iotConfigs.Person_Detect_List_Threshold + ","
            + "摄氏温度过高事件使能" + "," + this.iotConfigs.Celsius_Temperature_Too_High_Event_Enable + ","
            + "摄氏温度过低事件使能" + "," + this.iotConfigs.Celsius_Temperature_Too_Low_Event_Enable + ","
            + "相对湿度过高事件使能" + "," + this.iotConfigs.Relative_Humidity_Too_High_Event_Enable + ","
            + "相对湿度过低事件使能" + "," + this.iotConfigs.Relative_Humidity_Too_Low_Event_Enable + ","
            + "机房有人事件使能" + "," + this.iotConfigs.WaterPumpHouse_Person_Event_Enable + ","
            + "机房门打开事件使能" + "," + this.iotConfigs.EngineRoom_Door_Open_Event_Enable + ","
            + "机房送风机启动事件使能" + "," + this.iotConfigs.EngineRoom_FeedFan_Start_Event_Enable + ","
            + "机房排风机启动事件使能" + "," + this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Enable + ","
            + "潜水泵1启动事件使能" + "," + this.iotConfigs.SubmersiblePump_1_Start_Event_Enable + ","
            + "潜水泵2启动事件使能" + "," + this.iotConfigs.SubmersiblePump_2_Start_Event_Enable + ","
            + "水泵组1主泵1启动事件使能" + "," + this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Enable + ","
            + "水泵组1主泵2启动事件使能" + "," + this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Enable + ","
            + "水泵组1主泵3启动事件使能" + "," + this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable + ","
            + "水泵组1稳压泵启动事件使能" + "," + this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable + ","
            + "水泵组2主泵1启动事件使能" + "," + this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Enable + ","
            + "水泵组2主泵2启动事件使能" + "," + this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Enable + ","
            + "水泵组2主泵3启动事件使能" + "," + this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable + ","
            + "水泵组2稳压泵启动事件使能" + "," + this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable + ","
            + "水箱补水开始事件使能" + "," + this.iotConfigs.Radiator_Supply_Start_Event_Enable + ","
            + "水箱溢水开始事件使能" + "," + this.iotConfigs.Radiator_Overflow_Start_Event_Enable + ","
            + "水箱缺水开始事件使能" + "," + this.iotConfigs.Radiator_Lack_Start_Event_Enable + ","
            + "机房走水报警使能" + "," + this.iotConfigs.EngineRoom_OnFire_Alarm_Enable + ","
            + "潜水泵断电报警使能" + "," + this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Enable + ","
            + "潜水泵1故障报警使能" + "," + this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Enable + ","
            + "潜水泵2故障报警使能" + "," + this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Enable + ","
            + "水泵组1故障报警使能" + "," + this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Enable + ","
            + "水泵组1超压报警使能" + "," + this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable + ","
            + "水泵组1失压报警使能" + "," + this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable + ","
            + "水泵组2故障报警使能" + "," + this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Enable + ","
            + "水泵组2超压报警使能" + "," + this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable + ","
            + "水泵组2失压报警使能" + "," + this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable + ","
            + "水箱盖打开报警使能" + "," + this.iotConfigs.Radiator_Cap_Open_Alarm_Enable + ","
            + "补水故障，高水位故障(111)报警使能" + "," + this.iotConfigs.Radiator_Supply_HighWaterLevel_Alarm_Enable + ","
            + "补水故障，高、缺水位故障(110)报警使能" + "," + this.iotConfigs.Radiator_Supply_High_LackWaterLevel_Alarm_Enable + ","
            + "正在补水，水位过低(100)报警使能" + "," + this.iotConfigs.Radiator_Supply_LackWaterLevel_Alarm_Enable + ","
            + "高水位故障(011)报警使能" + "," + this.iotConfigs.Radiator_HighWaterLevel_Alarm_Enable + ","
            + "高、缺水位故障(010)报警使能" + "," + this.iotConfigs.Radiator_High_LackWaterLevel_Alarm_Enable + ","
            + "补水故障或无外水；低水位故障(000)报警使能" + "," + this.iotConfigs.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable + ","
            + "摄氏温度过高事件上传录像使能" + "," + this.iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Video_Enable + ","
            + "摄氏温度过低事件上传录像使能" + "," + this.iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Video_Enable + ","
            + "相对湿度过高事件上传录像使能" + "," + this.iotConfigs.Relative_Humidity_Too_High_Event_Upload_Video_Enable + ","
            + "相对湿度过低事件上传录像使能" + "," + this.iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Video_Enable + ","
            + "机房有人事件上传录像使能" + "," + this.iotConfigs.WaterPumpHouse_Person_Event_Upload_Video_Enable + ","
            + "机房门打开事件上传录像使能" + "," + this.iotConfigs.EngineRoom_Door_Open_Event_Upload_Video_Enable + ","
            + "机房送风机启动事件上传录像使能" + "," + this.iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Video_Enable + ","
            + "机房排风机启动事件上传录像使能" + "," + this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable + ","
            + "潜水泵1启动事件上传录像使能" + "," + this.iotConfigs.SubmersiblePump_1_Start_Event_Upload_Video_Enable + ","
            + "潜水泵2启动事件上传录像使能" + "," + this.iotConfigs.SubmersiblePump_2_Start_Event_Upload_Video_Enable + ","
            + "水泵组1主泵1启动事件上传录像使能" + "," + this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Video_Enable + ","
            + "水泵组1主泵2启动事件上传录像使能" + "," + this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Video_Enable + ","
            + "水泵组1主泵3启动事件上传录像使能" + "," + this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Video_Enable + ","
            + "水泵组1稳压泵启动事件上传录像使能" + "," + this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Video_Enable + ","
            + "水泵组2主泵1启动事件上传录像使能" + "," + this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Video_Enable + ","
            + "水泵组2主泵2启动事件上传录像使能" + "," + this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Video_Enable + ","
            + "水泵组2主泵3启动事件上传录像使能" + "," + this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Video_Enable + ","
            + "水泵组2稳压泵启动事件上传录像使能" + "," + this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Video_Enable + ","
            + "水箱补水开始事件上传录像使能" + "," + this.iotConfigs.Radiator_Supply_Start_Event_Upload_Video_Enable + ","
            + "水箱溢水开始事件上传录像使能" + "," + this.iotConfigs.Radiator_Overflow_Start_Event_Upload_Video_Enable + ","
            + "水箱缺水开始事件上传录像使能" + "," + this.iotConfigs.Radiator_Lack_Start_Event_Upload_Video_Enable + ","
            + "摄氏温度过高事件上传图片使能" + "," + this.iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Image_Enable + ","
            + "摄氏温度过低事件上传图片使能" + "," + this.iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Image_Enable + ","
            + "相对湿度过高事件上传图片使能" + "," + this.iotConfigs.Relative_Humidity_Too_High_Event_Upload_Image_Enable + ","
            + "相对湿度过低事件上传图片使能" + "," + this.iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Image_Enable + ","
            + "机房有人事件上传图片使能" + "," + this.iotConfigs.WaterPumpHouse_Person_Event_Upload_Image_Enable + ","
            + "机房门打开事件上传图片使能" + "," + this.iotConfigs.EngineRoom_Door_Open_Event_Upload_Image_Enable + ","
            + "机房送风机启动事件上传图片使能" + "," + this.iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Image_Enable + ","
            + "机房排风机启动事件上传图片使能" + "," + this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable + ","
            + "潜水泵1启动事件上传图片使能" + "," + this.iotConfigs.SubmersiblePump_1_Start_Event_Upload_Image_Enable + ","
            + "潜水泵2启动事件上传图片使能" + "," + this.iotConfigs.SubmersiblePump_2_Start_Event_Upload_Image_Enable + ","
            + "水泵组1主泵1启动事件上传图片使能" + "," + this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Upload_Image_Enable + ","
            + "水泵组1主泵2启动事件上传图片使能" + "," + this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Upload_Image_Enable + ","
            + "水泵组1主泵3启动事件上传图片使能" + "," + this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Upload_Image_Enable + ","
            + "水泵组1稳压泵启动事件上传图片使能" + "," + this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Upload_Image_Enable + ","
            + "水泵组2主泵1启动事件上传图片使能" + "," + this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Upload_Image_Enable + ","
            + "水泵组2主泵2启动事件上传图片使能" + "," + this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Upload_Image_Enable + ","
            + "水泵组2主泵3启动事件上传图片使能" + "," + this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Upload_Image_Enable + ","
            + "水泵组2稳压泵启动事件上传图片使能" + "," + this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Upload_Image_Enable + ","
            + "水箱补水开始事件上传图片使能" + "," + this.iotConfigs.Radiator_Supply_Start_Event_Upload_Image_Enable + ","
            + "水箱溢水开始事件上传图片使能" + "," + this.iotConfigs.Radiator_Overflow_Start_Event_Upload_Image_Enable + ","
            + "水箱缺水开始事件上传图片使能" + "," + this.iotConfigs.Radiator_Lack_Start_Event_Upload_Image_Enable + ","
            + "补水故障，高水位故障(111)图片使能" + "," + this.iotConfigs.Radiator_Supply_HighWaterLevel_Image_Enable + ","
            + "补水故障，高、缺水位故障(110)图片使能" + "," + this.iotConfigs.Radiator_Supply_High_LackWaterLevel_Image_Enable + ","
            + "正在补水，水位过低(100)图片使能" + "," + this.iotConfigs.Radiator_Supply_LackWaterLevel_Image_Enable + ","
            + "高水位故障(011)图片使能" + "," + this.iotConfigs.Radiator_HighWaterLevel_Image_Enable + ","
            + "高、缺水位故障(010)图片使能" + "," + this.iotConfigs.Radiator_High_LackWaterLevel_Image_Enable + ","
            + "补水故障或无外水；低水位故障(000)图片使能" + "," + this.iotConfigs.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Image_Enable + ","
            + "拨打值班室电话事件上传图片使能" + "," + this.iotConfigs.Voip_Alarm_Upload_Image_Enable + ","
            + "机房走水报警使能" + "," + this.iotConfigs.EngineRoom_OnFire_Alarm_Upload_Video_Enable + ","
            + "潜水泵断电报警使能" + "," + this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Upload_Video_Enable + ","
            + "潜水泵1故障报警使能" + "," + this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Upload_Video_Enable + ","
            + "潜水泵2故障报警使能" + "," + this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Upload_Video_Enable + ","
            + "水泵组1故障报警使能" + "," + this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Upload_Video_Enable + ","
            + "水泵组1超压报警使能" + "," + this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Upload_Video_Enable + ","
            + "水泵组1失压报警使能" + "," + this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Upload_Video_Enable + ","
            + "水泵组2故障报警使能" + "," + this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Upload_Video_Enable + ","
            + "水泵组2超压报警使能" + "," + this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Upload_Video_Enable + ","
            + "水泵组2失压报警使能" + "," + this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Upload_Video_Enable + ","
            + "水箱盖打开报警使能" + "," + this.iotConfigs.Radiator_Cap_Open_Alarm_Upload_Video_Enable + ","
            + "补水故障，高水位故障(111)报警使能" + "," + this.iotConfigs.Radiator_Supply_HighWaterLevel_Alarm_Upload_Video_Enable + ","
            + "补水故障，高、缺水位故障(110)报警使能" + "," + this.iotConfigs.Radiator_Supply_High_LackWaterLevel_Alarm_Upload_Video_Enable + ","
            + "正在补水，水位过低(100)报警使能" + "," + this.iotConfigs.Radiator_Supply_LackWaterLevel_Alarm_Upload_Video_Enable + ","
            + "高水位故障(011)报警使能" + "," + this.iotConfigs.Radiator_HighWaterLevel_Alarm_Upload_Video_Enable + ","
            + "高、缺水位故障(010)报警使能" + "," + this.iotConfigs.Radiator_High_LackWaterLevel_Alarm_Upload_Video_Enable + ","
            + "补水故障或无外水；低水位故障(000)报警使能" + "," + this.iotConfigs.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Upload_Video_Enable + ","
      }else{
        paramsBody = {
          EngineRoom_Door_Open_Gpio_State:this.iotConfigs.EngineRoom_Door_Open_Gpio_State,
          EngineRoom_FeedFan_Start_Gpio_State:this.iotConfigs.EngineRoom_FeedFan_Start_Gpio_State,
          EngineRoom_ExhaustFan_Start_Gpio_State:this.iotConfigs.EngineRoom_ExhaustFan_Start_Gpio_State,
          SubmersiblePump_1_Start_Gpio_State:this.iotConfigs.SubmersiblePump_1_Start_Gpio_State,
          SubmersiblePump_2_Start_Gpio_State:this.iotConfigs.SubmersiblePump_2_Start_Gpio_State,
          WaterPumpGroup_1_FirstMainPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Gpio_State,
          WaterPumpGroup_1_SecondMainPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Gpio_State,
          WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State,
          WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State,
          WaterPumpGroup_2_FirstMainPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Gpio_State,
          WaterPumpGroup_2_SecondMainPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Gpio_State,
          WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State,
          WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State,
          Radiator_Supply_Start_Gpio_State:this.iotConfigs.Radiator_Supply_Start_Gpio_State,
          Radiator_Overflow_Start_Gpio_State:this.iotConfigs.Radiator_Overflow_Start_Gpio_State,
          Radiator_Lack_Start_Gpio_State:this.iotConfigs.Radiator_Lack_Start_Gpio_State,
          EngineRoom_OnFire_Alarm_Gpio_State:this.iotConfigs.EngineRoom_OnFire_Alarm_Gpio_State,
          SubmersiblePump_Power_Cut_Alarm_Gpio_State:this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Gpio_State,
          SubmersiblePump_1_Fault_Alarm_Gpio_State:this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Gpio_State,
          SubmersiblePump_2_Fault_Alarm_Gpio_State:this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Gpio_State,
          WaterPumpGroup_1_Fault_Alarm_Gpio_State:this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Gpio_State,
          WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State:this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State,
          WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State:this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State,
          WaterPumpGroup_2_Fault_Alarm_Gpio_State:this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Gpio_State,
          WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State:this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State,
          WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State:this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State,
          Radiator_Cap_Open_Alarm_Gpio_State:this.iotConfigs.Radiator_Cap_Open_Alarm_Gpio_State,
          Reserve_I10_Gpio_State:this.iotConfigs.Reserve_I10_Gpio_State,
          Reserve_I11_Gpio_State:this.iotConfigs.Reserve_I11_Gpio_State,
          Voip_Alarm_Gpio_State:this.iotConfigs.Voip_Alarm_Gpio_State,


          EngineRoom_Door_Open_Bind_Port:this.iotConfigs.EngineRoom_Door_Open_Bind_Port,
          EngineRoom_FeedFan_Start_Bind_Port:this.iotConfigs.EngineRoom_FeedFan_Start_Bind_Port,
          EngineRoom_ExhaustFan_Start_Bind_Port:this.iotConfigs.EngineRoom_ExhaustFan_Start_Bind_Port,
          SubmersiblePump_1_Start_Bind_Port:this.iotConfigs.SubmersiblePump_1_Start_Bind_Port,
          SubmersiblePump_2_Start_Bind_Port:this.iotConfigs.SubmersiblePump_2_Start_Bind_Port,
          WaterPumpGroup_1_FirstMainPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Bind_Port,
          WaterPumpGroup_1_SecondMainPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Bind_Port,
          WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port,
          WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port,
          WaterPumpGroup_2_FirstMainPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Bind_Port,
          WaterPumpGroup_2_SecondMainPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Bind_Port,
          WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port,
          WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port,
          Radiator_Supply_Start_Bind_Port:this.iotConfigs.Radiator_Supply_Start_Bind_Port,
          Radiator_Overflow_Start_Bind_Port:this.iotConfigs.Radiator_Overflow_Start_Bind_Port,
          Radiator_Lack_Start_Bind_Port:this.iotConfigs.Radiator_Lack_Start_Bind_Port,
          EngineRoom_OnFire_Alarm_Bind_Port:this.iotConfigs.EngineRoom_OnFire_Alarm_Bind_Port,
          SubmersiblePump_Power_Cut_Alarm_Bind_Port:this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Bind_Port,
          SubmersiblePump_1_Fault_Alarm_Bind_Port:this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Bind_Port,
          SubmersiblePump_2_Fault_Alarm_Bind_Port:this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Bind_Port,
          WaterPumpGroup_1_Fault_Alarm_Bind_Port:this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Bind_Port,
          WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port:this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port,
          WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port:this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port,
          WaterPumpGroup_2_Fault_Alarm_Bind_Port:this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Bind_Port,
          WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port:this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port,
          WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port:this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port,
          Radiator_Cap_Open_Alarm_Bind_Port:this.iotConfigs.Radiator_Cap_Open_Alarm_Bind_Port,
          Voip_Alarm_Bind_Port:this.iotConfigs.Voip_Alarm_Bind_Port,
        }
        this.paramsdata = "机房门打开信号有效电平" + "," + this.iotConfigs.EngineRoom_Door_Open_Gpio_State + ","
            + "机房送风机启动信号有效电平" + "," + this.iotConfigs.EngineRoom_FeedFan_Start_Gpio_State + ","
            + "机房排风机启动信号有效电平" + "," + this.iotConfigs.EngineRoom_ExhaustFan_Start_Gpio_State + ","
            + "潜水泵1启动信号有效电平" + "," + this.iotConfigs.SubmersiblePump_1_Start_Gpio_State + ","
            + "潜水泵2启动信号有效电平" + "," + this.iotConfigs.SubmersiblePump_2_Start_Gpio_State + ","
            + "水泵组1主泵1启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Gpio_State + ","
            + "水泵组1主泵2启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Gpio_State + ","
            + "水泵组1主泵3启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State + ","
            + "水泵组1稳压泵启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State + ","
            + "水泵组2主泵1启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Gpio_State + ","
            + "水泵组2主泵2启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Gpio_State + ","
            + "水泵组2主泵3启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State + ","
            + "水泵组2稳压泵启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State + ","
            + "水箱补水开始信号有效电平" + "," + this.iotConfigs.Radiator_Supply_Start_Gpio_State + ","
            + "水箱溢水开始信号有效电平" + "," + this.iotConfigs.Radiator_Overflow_Start_Gpio_State + ","
            + "水箱缺水开始信号有效电平" + "," + this.iotConfigs.Radiator_Lack_Start_Gpio_State + ","
            + "机房走水报警信号有效电平" + "," + this.iotConfigs.EngineRoom_OnFire_Alarm_Gpio_State + ","
            + "潜水泵断电报警信号有效电平" + "," + this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Gpio_State + ","
            + "潜水泵1故障报警信号有效电平" + "," + this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Gpio_State + ","
            + "潜水泵2故障报警信号有效电平" + "," + this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Gpio_State + ","
            + "水泵组1故障报警信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Gpio_State + ","
            + "水泵组1超压报警信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State + ","
            + "水泵组1失压报警信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State + ","
            + "水泵组2故障报警信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Gpio_State + ","
            + "水泵组2超压报警信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State + ","
            + "水泵组2失压报警信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State + ","
            + "水箱盖打开报警信号有效电平" + "," + this.iotConfigs.Radiator_Cap_Open_Alarm_Gpio_State + ","
            + "备用I10有效电平，1为高电平有效" + "," + this.iotConfigs.Reserve_I10_Gpio_State + ","
            + "备用I11有效电平" + "," + this.iotConfigs.Reserve_I11_Gpio_State + ","
            + "拨打值班室电话信号有效电平" + "," + this.iotConfigs.Voip_Alarm_Gpio_State + ","
            + "机房门打开信号绑定IO端口" + "," + this.iotConfigs.EngineRoom_Door_Open_Bind_Port + ","
            + "机房送风机启动信号绑定Gpio端口" + "," + this.iotConfigs.EngineRoom_FeedFan_Start_Bind_Port + ","
            + "机房排风机启动信号绑定Gpio端口" + "," + this.iotConfigs.EngineRoom_ExhaustFan_Start_Bind_Port + ","
            + "潜水泵1启动信号绑定Gpio端口" + "," + this.iotConfigs.SubmersiblePump_1_Start_Bind_Port + ","
            + "潜水泵2启动信号绑定Gpio端口" + "," + this.iotConfigs.SubmersiblePump_2_Start_Bind_Port + ","
            + "水泵组1主泵1启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Bind_Port + ","
            + "水泵组1主泵2启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Bind_Port + ","
            + "水泵组1主泵3启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port + ","
            + "水泵组1稳压泵启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port + ","
            + "水泵组2主泵1启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Bind_Port + ","
            + "水泵组2主泵2启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Bind_Port + ","
            + "水泵组2主泵3启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port + ","
            + "水泵组2稳压泵启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port + ","
            + "水箱补水开始信号绑定Gpio端口" + "," + this.iotConfigs.Radiator_Supply_Start_Bind_Port + ","
            + "水箱溢水开始信号绑定Gpio端口" + "," + this.iotConfigs.Radiator_Overflow_Start_Bind_Port + ","
            + "水箱缺水开始信号绑定Gpio端口" + "," + this.iotConfigs.Radiator_Lack_Start_Bind_Port + ","
            + "机房走水报警信号绑定Gpio端口" + "," + this.iotConfigs.EngineRoom_OnFire_Alarm_Bind_Port + ","
            + "潜水泵断电报警信号绑定Gpio端口" + "," + this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Bind_Port + ","
            + "潜水泵1故障报警信号绑定Gpio端口" + "," + this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Bind_Port + ","
            + "潜水泵2故障报警信号绑定Gpio端口" + "," + this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Bind_Port + ","
            + "水泵组1故障报警信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Bind_Port + ","
            + "水泵组1超压报警信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port + ","
            + "水泵组1失压报警信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port + ","
            + "水泵组2故障报警信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Bind_Port + ","
            + "水泵组2超压报警信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port + ","
            + "水泵组2失压报警信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port + ","
            + "水箱盖打开报警信号绑定Gpio端口" + "," + this.iotConfigs.Radiator_Cap_Open_Alarm_Bind_Port + ","
            + "拨打值班室电话信号绑定Gpio端口" + "," + this.iotConfigs.Voip_Alarm_Bind_Port + ","
      }
      let paramsdate = moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username: this.username,
        paramsobj: this.devicename,
        paramsdate: paramsdate,
        devicenum: this.devicenum,
        devicegroupid: "00",
        orderresult: '失败',
        paramstype: '0',
        parentid: "",
        paramsdata: this.paramsdata,
      }
      addParamsBatchLog(param)
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'PUT',
              url: '/iot',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.$message.success('操作成功');
          let param = {
            username: this.username,
            paramsobj: this.devicename,
            paramsdate: paramsdate,
            devicenum: this.devicenum,
            devicegroupid: "00",
            orderresult: '成功',
            paramstype: '0',
            parentid: "",
            paramsdata: this.paramsdata,
          }
          modifyParamsBatchLog(param)
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    setDefaultParamsConfig() {
      this.$confirm('提示消息：“您确认要下发出厂默认参数配置吗？如果您已经有修改过该终端参数，那么该终端参数会被清除，请仔细确认该操作是否继续，谢谢！”',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.type='all'
        let paramsBody = {}
        if (this.waterSystemIotFlag.iotFlag == 0) {
          paramsBody = {}
        }else{
          paramsBody = {
            EngineRoom_Door_Open_Gpio_State:this.iotConfigs.EngineRoom_Door_Open_Gpio_State,
            EngineRoom_FeedFan_Start_Gpio_State:this.iotConfigs.EngineRoom_FeedFan_Start_Gpio_State,
            EngineRoom_ExhaustFan_Start_Gpio_State:this.iotConfigs.EngineRoom_ExhaustFan_Start_Gpio_State,
            SubmersiblePump_1_Start_Gpio_State:this.iotConfigs.SubmersiblePump_1_Start_Gpio_State,
            SubmersiblePump_2_Start_Gpio_State:this.iotConfigs.SubmersiblePump_2_Start_Gpio_State,
            WaterPumpGroup_1_FirstMainPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Gpio_State,
            WaterPumpGroup_1_SecondMainPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Gpio_State,
            WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State,
            WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State,
            WaterPumpGroup_2_FirstMainPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Gpio_State,
            WaterPumpGroup_2_SecondMainPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Gpio_State,
            WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State,
            WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State:this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State,
            Radiator_Supply_Start_Gpio_State:this.iotConfigs.Radiator_Supply_Start_Gpio_State,
            Radiator_Overflow_Start_Gpio_State:this.iotConfigs.Radiator_Overflow_Start_Gpio_State,
            Radiator_Lack_Start_Gpio_State:this.iotConfigs.Radiator_Lack_Start_Gpio_State,
            EngineRoom_OnFire_Alarm_Gpio_State:this.iotConfigs.EngineRoom_OnFire_Alarm_Gpio_State,
            SubmersiblePump_Power_Cut_Alarm_Gpio_State:this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Gpio_State,
            SubmersiblePump_1_Fault_Alarm_Gpio_State:this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Gpio_State,
            SubmersiblePump_2_Fault_Alarm_Gpio_State:this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Gpio_State,
            WaterPumpGroup_1_Fault_Alarm_Gpio_State:this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Gpio_State,
            WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State:this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State,
            WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State:this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State,
            WaterPumpGroup_2_Fault_Alarm_Gpio_State:this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Gpio_State,
            WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State:this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State,
            WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State:this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State,
            Radiator_Cap_Open_Alarm_Gpio_State:this.iotConfigs.Radiator_Cap_Open_Alarm_Gpio_State,
            Reserve_I10_Gpio_State:this.iotConfigs.Reserve_I10_Gpio_State,
            Reserve_I11_Gpio_State:this.iotConfigs.Reserve_I11_Gpio_State,
            Voip_Alarm_Gpio_State:this.iotConfigs.Voip_Alarm_Gpio_State,

            EngineRoom_Door_Open_Bind_Port:this.iotConfigs.EngineRoom_Door_Open_Bind_Port,
            EngineRoom_FeedFan_Start_Bind_Port:this.iotConfigs.EngineRoom_FeedFan_Start_Bind_Port,
            EngineRoom_ExhaustFan_Start_Bind_Port:this.iotConfigs.EngineRoom_ExhaustFan_Start_Bind_Port,
            SubmersiblePump_1_Start_Bind_Port:this.iotConfigs.SubmersiblePump_1_Start_Bind_Port,
            SubmersiblePump_2_Start_Bind_Port:this.iotConfigs.SubmersiblePump_2_Start_Bind_Port,
            WaterPumpGroup_1_FirstMainPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Bind_Port,
            WaterPumpGroup_1_SecondMainPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Bind_Port,
            WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port,
            WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port,
            WaterPumpGroup_2_FirstMainPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Bind_Port,
            WaterPumpGroup_2_SecondMainPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Bind_Port,
            WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port,
            WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port:this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port,
            Radiator_Supply_Start_Bind_Port:this.iotConfigs.Radiator_Supply_Start_Bind_Port,
            Radiator_Overflow_Start_Bind_Port:this.iotConfigs.Radiator_Overflow_Start_Bind_Port,
            Radiator_Lack_Start_Bind_Port:this.iotConfigs.Radiator_Lack_Start_Bind_Port,
            EngineRoom_OnFire_Alarm_Bind_Port:this.iotConfigs.EngineRoom_OnFire_Alarm_Bind_Port,
            SubmersiblePump_Power_Cut_Alarm_Bind_Port:this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Bind_Port,
            SubmersiblePump_1_Fault_Alarm_Bind_Port:this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Bind_Port,
            SubmersiblePump_2_Fault_Alarm_Bind_Port:this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Bind_Port,
            WaterPumpGroup_1_Fault_Alarm_Bind_Port:this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Bind_Port,
            WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port:this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port,
            WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port:this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port,
            WaterPumpGroup_2_Fault_Alarm_Bind_Port:this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Bind_Port,
            WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port:this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port,
            WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port:this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port,
            Radiator_Cap_Open_Alarm_Bind_Port:this.iotConfigs.Radiator_Cap_Open_Alarm_Bind_Port,
            Voip_Alarm_Bind_Port:this.iotConfigs.Voip_Alarm_Bind_Port,
          }
          this.paramsdata = "机房门打开信号有效电平" + "," + this.iotConfigs.EngineRoom_Door_Open_Gpio_State + ","
              + "机房送风机启动信号有效电平" + "," + this.iotConfigs.EngineRoom_FeedFan_Start_Gpio_State + ","
              + "机房排风机启动信号有效电平" + "," + this.iotConfigs.EngineRoom_ExhaustFan_Start_Gpio_State + ","
              + "潜水泵1启动信号有效电平" + "," + this.iotConfigs.SubmersiblePump_1_Start_Gpio_State + ","
              + "潜水泵2启动信号有效电平" + "," + this.iotConfigs.SubmersiblePump_2_Start_Gpio_State + ","
              + "水泵组1主泵1启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Gpio_State + ","
              + "水泵组1主泵2启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Gpio_State + ","
              + "水泵组1主泵3启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State + ","
              + "水泵组1稳压泵启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State + ","
              + "水泵组2主泵1启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Gpio_State + ","
              + "水泵组2主泵2启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Gpio_State + ","
              + "水泵组2主泵3启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State + ","
              + "水泵组2稳压泵启动信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State + ","
              + "水箱补水开始信号有效电平" + "," + this.iotConfigs.Radiator_Supply_Start_Gpio_State + ","
              + "水箱溢水开始信号有效电平" + "," + this.iotConfigs.Radiator_Overflow_Start_Gpio_State + ","
              + "水箱缺水开始信号有效电平" + "," + this.iotConfigs.Radiator_Lack_Start_Gpio_State + ","
              + "机房走水报警信号有效电平" + "," + this.iotConfigs.EngineRoom_OnFire_Alarm_Gpio_State + ","
              + "潜水泵断电报警信号有效电平" + "," + this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Gpio_State + ","
              + "潜水泵1故障报警信号有效电平" + "," + this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Gpio_State + ","
              + "潜水泵2故障报警信号有效电平" + "," + this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Gpio_State + ","
              + "水泵组1故障报警信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Gpio_State + ","
              + "水泵组1超压报警信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State + ","
              + "水泵组1失压报警信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State + ","
              + "水泵组2故障报警信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Gpio_State + ","
              + "水泵组2超压报警信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State + ","
              + "水泵组2失压报警信号有效电平" + "," + this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State + ","
              + "水箱盖打开报警信号有效电平" + "," + this.iotConfigs.Radiator_Cap_Open_Alarm_Gpio_State + ","
              + "备用I10有效电平，1为高电平有效" + "," + this.iotConfigs.Reserve_I10_Gpio_State + ","
              + "备用I11有效电平" + "," + this.iotConfigs.Reserve_I11_Gpio_State + ","
              + "拨打值班室电话信号有效电平" + "," + this.iotConfigs.Voip_Alarm_Gpio_State + ","
              + "机房门打开信号绑定IO端口" + "," + this.iotConfigs.EngineRoom_Door_Open_Bind_Port + ","
              + "机房送风机启动信号绑定Gpio端口" + "," + this.iotConfigs.EngineRoom_FeedFan_Start_Bind_Port + ","
              + "机房排风机启动信号绑定Gpio端口" + "," + this.iotConfigs.EngineRoom_ExhaustFan_Start_Bind_Port + ","
              + "潜水泵1启动信号绑定Gpio端口" + "," + this.iotConfigs.SubmersiblePump_1_Start_Bind_Port + ","
              + "潜水泵2启动信号绑定Gpio端口" + "," + this.iotConfigs.SubmersiblePump_2_Start_Bind_Port + ","
              + "水泵组1主泵1启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Bind_Port + ","
              + "水泵组1主泵2启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Bind_Port + ","
              + "水泵组1主泵3启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port + ","
              + "水泵组1稳压泵启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port + ","
              + "水泵组2主泵1启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Bind_Port + ","
              + "水泵组2主泵2启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Bind_Port + ","
              + "水泵组2主泵3启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port + ","
              + "水泵组2稳压泵启动信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port + ","
              + "水箱补水开始信号绑定Gpio端口" + "," + this.iotConfigs.Radiator_Supply_Start_Bind_Port + ","
              + "水箱溢水开始信号绑定Gpio端口" + "," + this.iotConfigs.Radiator_Overflow_Start_Bind_Port + ","
              + "水箱缺水开始信号绑定Gpio端口" + "," + this.iotConfigs.Radiator_Lack_Start_Bind_Port + ","
              + "机房走水报警信号绑定Gpio端口" + "," + this.iotConfigs.EngineRoom_OnFire_Alarm_Bind_Port + ","
              + "潜水泵断电报警信号绑定Gpio端口" + "," + this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Bind_Port + ","
              + "潜水泵1故障报警信号绑定Gpio端口" + "," + this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Bind_Port + ","
              + "潜水泵2故障报警信号绑定Gpio端口" + "," + this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Bind_Port + ","
              + "水泵组1故障报警信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Bind_Port + ","
              + "水泵组1超压报警信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port + ","
              + "水泵组1失压报警信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port + ","
              + "水泵组2故障报警信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Bind_Port + ","
              + "水泵组2超压报警信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port + ","
              + "水泵组2失压报警信号绑定Gpio端口" + "," + this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port + ","
              + "水箱盖打开报警信号绑定Gpio端口" + "," + this.iotConfigs.Radiator_Cap_Open_Alarm_Bind_Port + ","
              + "拨打值班室电话信号绑定Gpio端口" + "," + this.iotConfigs.Voip_Alarm_Bind_Port + ","
        }
        let paramsdate = moment(new Date()).format("YYYYMMDDHHmmss")
        let param = {
          username: this.username,
          paramsobj: this.devicename,
          paramsdate: paramsdate,
          devicenum: this.devicenum,
          devicegroupid: "00",
          orderresult: '失败',
          paramstype: '0',
          parentid: "",
          paramsdata: this.paramsdata,
        }
        addParamsBatchLog(param)
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              modeltype: this.devicemoduletype,
              modelnum: this.modelnum,
              params: {
                methord: 'PUT',
                url: '/iot',
                body: paramsBody,
              }
            }
          ]
        };
        this.showLoading();
        setDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            let param = {
              username: this.username,
              paramsobj: this.devicename,
              paramsdate: paramsdate,
              devicenum: this.devicenum,
              devicegroupid: "00",
              orderresult: '成功',
              paramstype: '0',
              parentid: "",
              paramsdata: this.paramsdata,
            }
            modifyParamsBatchLog(param)
          }
        }).catch(err => {
          this.hideLoading();
          this.$message.error('操作失败');
        })
      }).catch(()=>{
      });
    },
    getOrSet(type){
      if(this.status){
        if(type==='default'){
          this.setDefaultParamsConfig()
        }else{
          this.setParamsConfig();
        }
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
    setDefault(type){
      if(type==='UK01'){
        this.GPIOList=this.GPIOListBackups.filter(item=>!item.dickey.includes('BOARD'))
        this.status=true
        this.type="default"
        this.iotConfigs= {
          //机房门打开信号有效电平，1为高电平有效，0为低电平有效，默认1
          EngineRoom_Door_Open_Gpio_State:1,
          //机房送风机启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          EngineRoom_FeedFan_Start_Gpio_State:1,
          //机房排风机启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          EngineRoom_ExhaustFan_Start_Gpio_State:1,
          //潜水泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          SubmersiblePump_1_Start_Gpio_State:1,
          //潜水泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          SubmersiblePump_2_Start_Gpio_State:1,
          //水泵组1主泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_1_FirstMainPump_Start_Gpio_State:1,
          //水泵组1主泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_1_SecondMainPump_Start_Gpio_State:1,
          //水泵组1主泵3启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State:1,
          //水泵组1稳压泵启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State:1,
          //水泵组2主泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_2_FirstMainPump_Start_Gpio_State:1,
          //水泵组2主泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_2_SecondMainPump_Start_Gpio_State:1,
          //水泵组2主泵3启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State:1,
          //水泵组2稳压泵启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State:1,
          //水箱补水开始信号有效电平，1为高电平有效，0为低电平有效，默认0
          Radiator_Supply_Start_Gpio_State:0,
          //水箱溢水开始信号有效电平，1为高电平有效，0为低电平有效，默认0
          Radiator_Overflow_Start_Gpio_State:0,
          //水箱缺水开始信号有效电平，1为高电平有效，0为低电平有效，默认1
          Radiator_Lack_Start_Gpio_State:1,
          //机房走水报警信号有效电平，1为高电平有效，0为低电平有效，默认1
          EngineRoom_OnFire_Alarm_Gpio_State:1,
          //潜水泵断电报警信号有效电平，1为高电平有效，0为低电平有效，默认0
          SubmersiblePump_Power_Cut_Alarm_Gpio_State:0,
          //潜水泵1故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
          SubmersiblePump_1_Fault_Alarm_Gpio_State:1,
          //潜水泵2故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
          SubmersiblePump_2_Fault_Alarm_Gpio_State:1,
          //水泵组1故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_1_Fault_Alarm_Gpio_State:1,
          //水泵组1超压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
          WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State:0,
          //水泵组1失压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
          WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State:0,
          //水泵组2故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_2_Fault_Alarm_Gpio_State:1,
          //水泵组2超压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
          WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State:0,
          //水泵组2失压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
          WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State:0,
          //水箱盖打开报警信号有效电平，1为高电平有效，0为低电平有效，默认1
          Radiator_Cap_Open_Alarm_Gpio_State:1,
          //备用I10有效电平，1为高电平有效，0为低电平有效，默认0
          Reserve_I10_Gpio_State:0,
          //备用I11有效电平，1为高电平有效，0为低电平有效，默认0
          Reserve_I11_Gpio_State:0,
          //拨打值班室电话信号有效电平，1为高电平有效，0为低电平有效，默认0
          Voip_Alarm_Gpio_State:0,


          //机房门打开信号绑定Gpio端口，默认I1
          EngineRoom_Door_Open_Bind_Port:'I1',
          //机房送风机启动信号绑定Gpio端口，默认H2
          EngineRoom_FeedFan_Start_Bind_Port:'H2',
          //机房排风机启动信号绑定Gpio端口，默认H3
          EngineRoom_ExhaustFan_Start_Bind_Port:'H3',
          //潜水泵1启动信号绑定Gpio端口，默认H5
          SubmersiblePump_1_Start_Bind_Port:'H5',
          //潜水泵2启动信号绑定Gpio端口，默认H7
          SubmersiblePump_2_Start_Bind_Port:'H7',
          //水泵组1主泵1启动信号绑定Gpio端口，默认H10
          WaterPumpGroup_1_FirstMainPump_Start_Bind_Port:'H10',
          //水泵组1主泵2启动信号绑定Gpio端口，默认H11
          WaterPumpGroup_1_SecondMainPump_Start_Bind_Port:'H11',
          //水泵组1主泵3启动信号绑定Gpio端口，默认H12
          WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port:'H12',
          //水泵组1稳压泵启动信号绑定Gpio端口，默认H13
          WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port:'H13',
          //水泵组2主泵1启动信号绑定Gpio端口，默认H15
          WaterPumpGroup_2_FirstMainPump_Start_Bind_Port:'H15',
          //水泵组2主泵2启动信号绑定Gpio端口，默认H16
          WaterPumpGroup_2_SecondMainPump_Start_Bind_Port:'H16',
          //水泵组2主泵3启动信号绑定Gpio端口，默认H17
          WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port:'H17',
          //水泵组2稳压泵启动信号绑定Gpio端口，默认H18
          WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port:'H18',
          //水箱补水开始信号绑定Gpio端口，默认I7
          Radiator_Supply_Start_Bind_Port:'I7',
          //水箱溢水开始信号绑定Gpio端口，默认B1
          Radiator_Overflow_Start_Bind_Port:'I8/B1',
          //水箱缺水开始信号绑定Gpio端口，默认B2
          Radiator_Lack_Start_Bind_Port:'I9/B2',
          //机房走水报警信号绑定Gpio端口，默认H1
          EngineRoom_OnFire_Alarm_Bind_Port:'H1',
          //潜水泵断电报警信号绑定Gpio端口，默认H4
          SubmersiblePump_Power_Cut_Alarm_Bind_Port:'H4',
          //潜水泵1故障报警信号绑定Gpio端口，默认H6
          SubmersiblePump_1_Fault_Alarm_Bind_Port:'H6',
          //潜水泵2故障报警信号绑定Gpio端口，默认H8
          SubmersiblePump_2_Fault_Alarm_Bind_Port:'H8',
          //水泵组1故障报警信号绑定Gpio端口，默认H9
          WaterPumpGroup_1_Fault_Alarm_Bind_Port:'H9',
          //水泵组1超压报警信号绑定Gpio端口，默认I2
          WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port:'I2',
          //水泵组1失压报警信号绑定Gpio端口，默认I3
          WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port:'I3',
          //水泵组2故障报警信号绑定Gpio端口，默认H14
          WaterPumpGroup_2_Fault_Alarm_Bind_Port:'H14',
          //水泵组2超压报警信号绑定Gpio端口，默认I4
          WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port:'I4',
          //水泵组2失压报警信号绑定Gpio端口，默认I5
          WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port:'I5',
          //水箱盖打开报警信号绑定Gpio端口，默认I6
          Radiator_Cap_Open_Alarm_Bind_Port:'I6',
          //拨打值班室电话信号绑定Gpio端口，默认B5
          Voip_Alarm_Bind_Port:'I12/B5',
        }
      }else if(type==='UK_16IOTo485_V01'){
        this.GPIOList=this.GPIOListBackups.filter(item=>item.dickey.includes('BOARD'))
        this.status=true
        this.type="default"
        this.iotConfigs= {
          //机房门打开信号有效电平，1为高电平有效，0为低电平有效，默认1
          EngineRoom_Door_Open_Gpio_State:0,
          //机房送风机启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          EngineRoom_FeedFan_Start_Gpio_State:1,
          //机房排风机启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          EngineRoom_ExhaustFan_Start_Gpio_State:1,
          //潜水泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          SubmersiblePump_1_Start_Gpio_State:1,
          //潜水泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          SubmersiblePump_2_Start_Gpio_State:1,
          //水泵组1主泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_1_FirstMainPump_Start_Gpio_State:1,
          //水泵组1主泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_1_SecondMainPump_Start_Gpio_State:1,
          //水泵组1主泵3启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State:1,
          //水泵组1稳压泵启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State:1,
          //水泵组2主泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_2_FirstMainPump_Start_Gpio_State:1,
          //水泵组2主泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_2_SecondMainPump_Start_Gpio_State:1,
          //水泵组2主泵3启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State:1,
          //水泵组2稳压泵启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State:1,
          //水箱补水开始信号有效电平，1为高电平有效，0为低电平有效，默认0
          Radiator_Supply_Start_Gpio_State:1,
          //水箱溢水开始信号有效电平，1为高电平有效，0为低电平有效，默认0
          Radiator_Overflow_Start_Gpio_State:1,
          //水箱缺水开始信号有效电平，1为高电平有效，0为低电平有效，默认1
          Radiator_Lack_Start_Gpio_State:0,
          //机房走水报警信号有效电平，1为高电平有效，0为低电平有效，默认1
          EngineRoom_OnFire_Alarm_Gpio_State:1,
          //潜水泵断电报警信号有效电平，1为高电平有效，0为低电平有效，默认0
          SubmersiblePump_Power_Cut_Alarm_Gpio_State:0,
          //潜水泵1故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
          SubmersiblePump_1_Fault_Alarm_Gpio_State:1,
          //潜水泵2故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
          SubmersiblePump_2_Fault_Alarm_Gpio_State:1,
          //水泵组1故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_1_Fault_Alarm_Gpio_State:1,
          //水泵组1超压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
          WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State:1,
          //水泵组1失压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
          WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State:1,
          //水泵组2故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
          WaterPumpGroup_2_Fault_Alarm_Gpio_State:1,
          //水泵组2超压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
          WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State:1,
          //水泵组2失压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
          WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State:1,
          //水箱盖打开报警信号有效电平，1为高电平有效，0为低电平有效，默认0
          Radiator_Cap_Open_Alarm_Gpio_State:0,
          //备用I10有效电平，1为高电平有效，0为低电平有效，默认0
          Reserve_I10_Gpio_State:0,
          //备用I11有效电平，1为高电平有效，0为低电平有效，默认0
          Reserve_I11_Gpio_State:0,
          //拨打值班室电话信号有效电平，1为高电平有效，0为低电平有效，默认1
          Voip_Alarm_Gpio_State:1,


          //机房门打开信号绑定Gpio端口，默认BOARD1_X1
          EngineRoom_Door_Open_Bind_Port:'BOARD1_X1',
          //机房送风机启动信号绑定Gpio端口，默认BOARD1_X11
          EngineRoom_FeedFan_Start_Bind_Port:'BOARD1_X11',
          //机房排风机启动信号绑定Gpio端口，默认BOARD1_X12
          EngineRoom_ExhaustFan_Start_Bind_Port:'BOARD1_X12',
          //潜水泵1启动信号绑定Gpio端口，默认BOARD1_X14
          SubmersiblePump_1_Start_Bind_Port:'BOARD1_X14',
          //潜水泵2启动信号绑定Gpio端口，默认BOARD1_X16
          SubmersiblePump_2_Start_Bind_Port:'BOARD1_X16',
          //水泵组1主泵1启动信号绑定Gpio端口，默认BOARD2_X3
          WaterPumpGroup_1_FirstMainPump_Start_Bind_Port:'BOARD2_X3',
          //水泵组1主泵2启动信号绑定Gpio端口，默认BOARD2_X4
          WaterPumpGroup_1_SecondMainPump_Start_Bind_Port:'BOARD2_X4',
          //水泵组1主泵3启动信号绑定Gpio端口，默认BOARD2_X5
          WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port:'BOARD2_X5',
          //水泵组1稳压泵启动信号绑定Gpio端口，默认BOARD2_X6
          WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port:'BOARD2_X6',
          //水泵组2主泵1启动信号绑定Gpio端口，默认BOARD2_X8
          WaterPumpGroup_2_FirstMainPump_Start_Bind_Port:'BOARD2_X8',
          //水泵组2主泵2启动信号绑定Gpio端口，默认BOARD2_X9
          WaterPumpGroup_2_SecondMainPump_Start_Bind_Port:'BOARD2_X9',
          //水泵组2主泵3启动信号绑定Gpio端口，默认BOARD2_X10
          WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port:'BOARD2_X10',
          //水泵组2稳压泵启动信号绑定Gpio端口，默认BOARD2_X11
          WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port:'BOARD2_X11',
          //水箱补水开始信号绑定Gpio端口，默认BOARD1_X7
          Radiator_Supply_Start_Bind_Port:'BOARD1_X7',
          //水箱溢水开始信号绑定Gpio端口，默认BOARD1_X8
          Radiator_Overflow_Start_Bind_Port:'BOARD1_X8',
          //水箱缺水开始信号绑定Gpio端口，默认BOARD1_X9
          Radiator_Lack_Start_Bind_Port:'BOARD1_X9',
          //机房走水报警信号绑定Gpio端口，默认BOARD1_X10
          EngineRoom_OnFire_Alarm_Bind_Port:'BOARD1_X10',
          //潜水泵断电报警信号绑定Gpio端口，默认BOARD1_X13
          SubmersiblePump_Power_Cut_Alarm_Bind_Port:'BOARD1_X13',
          //潜水泵1故障报警信号绑定Gpio端口，默认BOARD1_X15
          SubmersiblePump_1_Fault_Alarm_Bind_Port:'BOARD1_X15',
          //潜水泵2故障报警信号绑定Gpio端口，默认BOARD2_X1
          SubmersiblePump_2_Fault_Alarm_Bind_Port:'BOARD2_X1',
          //水泵组1故障报警信号绑定Gpio端口，默认BOARD2_X2
          WaterPumpGroup_1_Fault_Alarm_Bind_Port:'BOARD2_X2',
          //水泵组1超压报警信号绑定Gpio端口，默认BOARD1_X2
          WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port:'BOARD1_X2',
          //水泵组1失压报警信号绑定Gpio端口，默认BOARD1_X3
          WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port:'BOARD1_X3',
          //水泵组2故障报警信号绑定Gpio端口，默认BOARD2_X7
          WaterPumpGroup_2_Fault_Alarm_Bind_Port:'BOARD2_X7',
          //水泵组2超压报警信号绑定Gpio端口，默认BOARD1_X4
          WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port:'BOARD1_X4',
          //水泵组2失压报警信号绑定Gpio端口，默认BOARD1_X5
          WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port:'BOARD1_X5',
          //水箱盖打开报警信号绑定Gpio端口，默认BOARD1_X6
          Radiator_Cap_Open_Alarm_Bind_Port:'BOARD1_X6',
          //拨打值班室电话信号绑定Gpio端口，默认BOARD2_X16
          Voip_Alarm_Bind_Port:'BOARD2_X16',
        }
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
}
</script>
<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>