<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-top: 1.6%;margin-right: 5%;margin-left: 1%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model v-if="fdaAiFlag.videoAiFlag==0" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:14}" :wrapper-col="{span:10}">
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">视频AI参数</h1>
      <a-form-model-item label="大厅电动车识别事件使能标志" prop="AI_HALL_EV_ENABLE">
        <a-select v-model="aiConfigs.AI_HALL_EV_ENABLE" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="大厅电动车识别事件报警使能" prop="AI_HALL_EV_ALARM">
        <a-select v-model="aiConfigs.AI_HALL_EV_ALARM" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="大厅电动车识别自行车和电动车绑定使能" prop="AI_HALL_EV_AND_BIKE">
        <a-select v-model="aiConfigs.AI_HALL_EV_AND_BIKE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="脱岗事件识别使能" prop="AI_UNATTENDED_ENABLE">
        <a-select v-model="aiConfigs.AI_UNATTENDED_ENABLE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="脱岗事件触发时长设置" prop="AI_UNATTENDED_TIME">
        <a-input v-model="aiConfigs.AI_UNATTENDED_TIME" placeholder="默认5分钟" suffix="分钟"></a-input>
      </a-form-model-item>
      <a-form-model-item label="脱岗无人百分比门限触发上报事件" prop="AI_UNATTENDED_THRESHOLD">
        <a-input v-model="aiConfigs.AI_UNATTENDED_THRESHOLD" placeholder="无人事件数量大于此阈值，则上报，默认10%" suffix="%"></a-input>
      </a-form-model-item>
      <a-form-model-item label="脱岗有人百分比门限触发取消事件" prop="AI_UNATTENDED_CANCEL_THRESHOLD">
        <a-input v-model="aiConfigs.AI_UNATTENDED_CANCEL_THRESHOLD" placeholder="有人事件数量大于此阈值，则取消，默认80%" suffix="%"></a-input>
      </a-form-model-item>
      <a-form-model-item label="是否有人的置信度阈值" prop="AI_UNATTENDED_CONF_THRESHOLD">
        <a-input v-model="aiConfigs.AI_UNATTENDED_CONF_THRESHOLD" placeholder="默认0.05"></a-input>
      </a-form-model-item>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">录像参数</h1>
      <a-form-model-item label="大厅电动车录像上传使能标志" prop="AI_HALL_EV_UPLOAD_VIDEO_ENABLE">
        <a-select v-model="aiConfigs.AI_HALL_EV_UPLOAD_VIDEO_ENABLE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="脱岗事件上传录像使能" prop="AI_UNATTENDED_UPLOAD_VIDEO_ENABLE">
        <a-select v-model="aiConfigs.AI_UNATTENDED_UPLOAD_VIDEO_ENABLE" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>


<!--    <a-form-model v-if="fdaAiFlag.videoAiFlag==1" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:14}" :wrapper-col="{span:10}">-->
<!--      <a-form-model-item label="ftp地址" prop="ftp_host">-->
<!--        <a-input v-model="aiConfigs.ftp_host" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="ftp端口号" prop="heartbeat_cycle">-->
<!--        <a-input v-model="aiConfigs.ftp_port" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="ftp用户名" prop="ftp_user_name">-->
<!--        <a-input v-model="aiConfigs.ftp_user_name" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="ftp密码" prop=" ftp_user_passwd">-->
<!--        <a-input type="password" v-model="aiConfigs.ftp_user_passwd" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="监听端口" prop="LISTEN_PORT">-->
<!--        <a-input v-model="aiConfigs.LISTEN_PORT" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="mqtt发送端口" prop="mqtt_port">-->
<!--        <a-input v-model="aiConfigs.mqtt_port" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="打架识别置信值阀值" prop="INIT_WITH_FIGHT_THRESHOLD">-->
<!--        <a-input v-model="aiConfigs.INIT_WITH_FIGHT_THRESHOLD" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="手碰头识别置信值阀值" prop="INIT_WITH_FISTHEAD_THRESHOLD">-->
<!--        <a-input v-model="aiConfigs.INIT_WITH_FISTHEAD_THRESHOLD" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="电动车事件队列长度" prop="VEHICLE_QUEUE_SIZE">-->
<!--        <a-input v-model="aiConfigs.VEHICLE_QUEUE_SIZE" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="电动车事件个数阀值" prop="VEHICLE_QUEUE_NUM">-->
<!--        <a-input v-model="aiConfigs.VEHICLE_QUEUE_NUM" placeholder="" suffix="个"></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="电动车头和车座事件队列长度" prop="VEHICLE_CHAIR_HEAD_SIZE">-->
<!--        <a-input v-model="aiConfigs.VEHICLE_CHAIR_HEAD_SIZE" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="电动车头和车座事件个数阈值" prop="VEHICLE_CHAIR_HEAD_NUM">-->
<!--        <a-input v-model="aiConfigs.VEHICLE_CHAIR_HEAD_NUM" placeholder="" suffix="个"></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="打架事件队列长度" prop="FIGHT_QUEUE_SIZE">-->
<!--        <a-input v-model="aiConfigs.FIGHT_QUEUE_SIZE" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="打架事件个数阀值" prop="FIGHT_QUEUE_NUM">-->
<!--        <a-input v-model="aiConfigs.FIGHT_QUEUE_NUM" placeholder="" suffix="个"></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="手碰头事件队列长度" prop="FISTHEAD_QUEUE_SIZE">-->
<!--        <a-input v-model="aiConfigs.FISTHEAD_QUEUE_SIZE" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="手碰头事件个数阀值" prop="FISTHEAD_QUEUE_NUM">-->
<!--        <a-input v-model="aiConfigs.FISTHEAD_QUEUE_NUM" placeholder="" suffix="个"></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="设备温度阀值" prop="temp_threshold">-->
<!--        <a-input v-model="aiConfigs.temp_threshold" placeholder="" suffix="℃"></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="开关门区域判断位置设置" prop="door_area">-->
<!--        <a-input v-model="aiConfigs.door_area" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="打架识别AI事件是否启用开关" prop="abnormal_event_enable">-->
<!--        <a-select v-model="aiConfigs.abnormal_event_enable" placeholder="1使能，0禁用，默认使能">-->
<!--          <a-select-option :value="1">使能</a-select-option>-->
<!--          <a-select-option :value="0">禁用</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="手碰头识别AI事件是否启用开关" prop="fisthead_enable">-->
<!--        <a-select v-model="aiConfigs.fisthead_enable" placeholder="1使能，0禁用，默认使能">-->
<!--          <a-select-option :value="1">使能</a-select-option>-->
<!--          <a-select-option :value="0">禁用</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="与物联网程序内部通信端口" prop="iot_port">-->
<!--        <a-input v-model="aiConfigs.iot_port" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="有人队列长度" prop="PERSON_STATUS_LIST_MAXLEN">-->
<!--        <a-input v-model="aiConfigs.PERSON_STATUS_LIST_MAXLEN" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="有人确认阈值" prop="PERSON_STATUS_LIST_NUM">-->
<!--        <a-input v-model="aiConfigs.PERSON_STATUS_LIST_NUM" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="开门队列长度" prop="DOOR_STATUS_LIST_MAXLEN">-->
<!--        <a-input v-model="aiConfigs.DOOR_STATUS_LIST_MAXLEN" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="开门确认阈值" prop="DOOR_STATUS_LIST_NUM">-->
<!--        <a-input v-model="aiConfigs.DOOR_STATUS_LIST_NUM" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="AI心跳周期" prop="heartbeat_cycle">-->
<!--        <a-input v-model="aiConfigs.heartbeat_cycle" placeholder="" suffix="秒"></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="AI事件联动终端通话使能" prop="eventcode_event">-->
<!--        <a-input placeholder="请选择" @click="eventModal=true"></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-modal v-model="eventModal" title="AI事件联动通话使能分配" :bodyStyle="{minHeight: '200px', maxHeight: '400px', overflow: 'auto'}">-->
<!--        <template slot="footer">-->
<!--          <a-button @click="eventModal=false">取消</a-button>-->
<!--          <a-button type="primary" @click="setEventAble">确认</a-button>-->
<!--        </template>-->
<!--        <div class="role-modal-container">-->
<!--          <a-transfer :titles="['禁用', '使能']" :render="item => item.title" :target-keys="checkedEventKeys" :data-source="EventData" @change="eventAbleCheckedChange" />-->
<!--        </div>-->
<!--      </a-modal>-->
<!--    </a-form-model>-->
  </div>
</template>

<script>
import {
  addParamsBatchLog, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList, modifyParamsBatchLog,
  setDeviceparamValueList
} from "A/ai";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "fdaAi",
  props:{
    fdaAiFlag:{
      default: null
    }
  },
  data() {
    return {
      eventModal:false,
      checkedEventKeys:[],
      EventData:[
        {key:"ev_event_alarm_enable",title:"电瓶车进入轿厢联动终端通话使能"},
        {key:"fight_event_alarm_enable",title:"轿厢内危险动作联动终端通话使能"},
      ],
      iotConfigs:{
        long_stay_event_enable:'',
        TRAPPED_PEOPLE_TIME:'',
      },
      aiConfigs:{
        heartbeat_cycle:'',
        INIT_WITH_VEHICLE_THRESHOLD:'',
        INIT_WITH_VEHICLE_HEAD_THRESHOLD:'',
        INIT_WITH_VEHICLE_CHAIR_THRESHOLD:'',
        INIT_WITH_FIGHT_THRESHOLD:'',
        INIT_WITH_FISTHEAD_THRESHOLD:'',
        VEHICLE_QUEUE_SIZE:'',
        VEHICLE_QUEUE_NUM:'',
        VEHICLE_CHAIR_HEAD_SIZE:'',
        VEHICLE_CHAIR_HEAD_NUM:'',
        FIGHT_QUEUE_SIZE:'',
        FIGHT_QUEUE_NUM:'',
        FISTHEAD_QUEUE_SIZE:'',
        FISTHEAD_QUEUE_NUM:'',
        temp_threshold:'',
        door_area:'',
        ev_event_enable:'',
        ev_event_cancel:'',
        abnormal_event_enable:'',
        fisthead_enable:'',
        block_event_enable:'',
        LISTEN_PORT:'',
        mqtt_port:'',
        iot_port:'',
        PERSON_STATUS_LIST_MAXLEN:'',
        PERSON_STATUS_LIST_NUM:'',
        DOOR_STATUS_LIST_MAXLEN:'',
        DOOR_STATUS_LIST_NUM:'',
        Ev_event_code:'',
        Ev_event_cancle_code:'',
        Block_event_code:'',
        Block_event_cancle_code:'',
        Abnormal_event_code:'',
        Abnormal_event_cancle_code:'',
        ev_event_door_enable:'',
        ev_event_door_time:'',
        ftp_host:'',
        ftp_port:'',
        ftp_user_name:'',
        ftp_user_passwd:'',
        ev_event_alarm_enable:'',
        fight_event_alarm_enable:'',


        AI_HALL_EV_ENABLE:'',
        AI_HALL_EV_ALARM:'',
        AI_HALL_EV_AND_BIKE:'',
        AI_UNATTENDED_TIME:'',
        AI_UNATTENDED_THRESHOLD:'',
        AI_UNATTENDED_CANCEL_THRESHOLD:'',
        AI_UNATTENDED_CONF_THRESHOLD:'',


        UPLOAD_VIDEO_ENABLE:'',
        AI_UNATTENDED_ENABLE:'',
        AI_UNATTENDED_UPLOAD_VIDEO_ENABLE:'',
        AI_HALL_EV_UPLOAD_VIDEO_ENABLE:'',

      },
      devicemoduletype: '',
      devicenum: '',
      modelnum: '',
      methodList: [],
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    setEventAble(){
      this.aiConfigs.ev_event_alarm_enable="0"
      this.aiConfigs.fight_event_alarm_enable="0"
      for(let i=0;i<this.checkedEventKeys.length;i++){
        if(this.checkedEventKeys[i]=="ev_event_alarm_enable"){
          this.aiConfigs.ev_event_alarm_enable="1"
        }else if(this.checkedEventKeys[i]=="fight_event_alarm_enable"){
          this.aiConfigs.fight_event_alarm_enable="1"
        }
      }
      this.eventModal=false
    },
    eventAbleCheckedChange(targetKeys){
      this.checkedEventKeys = targetKeys;
    },
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '44';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      this.checkedEventKeys=[];
      this.paramsList = [];
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'GET',
              url: '/ai',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.aiConfigs.UPLOAD_VIDEO_ENABLE=res.item[0].params.body.UPLOAD_VIDEO_ENABLE
          this.aiConfigs.heartbeat_cycle=res.item[0].params.body.heartbeat_cycle
          this.aiConfigs.INIT_WITH_VEHICLE_THRESHOLD=res.item[0].params.body.INIT_WITH_VEHICLE_THRESHOLD
          this.aiConfigs.INIT_WITH_VEHICLE_HEAD_THRESHOLD=res.item[0].params.body.INIT_WITH_VEHICLE_HEAD_THRESHOLD
          this.aiConfigs.INIT_WITH_VEHICLE_CHAIR_THRESHOLD=res.item[0].params.body.INIT_WITH_VEHICLE_CHAIR_THRESHOLD
          this.aiConfigs.INIT_WITH_FIGHT_THRESHOLD=res.item[0].params.body.INIT_WITH_FIGHT_THRESHOLD
          this.aiConfigs.INIT_WITH_FISTHEAD_THRESHOLD=res.item[0].params.body.INIT_WITH_FISTHEAD_THRESHOLD
          this.aiConfigs.VEHICLE_QUEUE_SIZE=res.item[0].params.body.VEHICLE_QUEUE_SIZE
          this.aiConfigs.VEHICLE_QUEUE_NUM=res.item[0].params.body.VEHICLE_QUEUE_NUM
          this.aiConfigs.VEHICLE_CHAIR_HEAD_SIZE=res.item[0].params.body.VEHICLE_CHAIR_HEAD_SIZE
          this.aiConfigs.VEHICLE_CHAIR_HEAD_NUM=res.item[0].params.body.VEHICLE_CHAIR_HEAD_NUM
          this.aiConfigs.FIGHT_QUEUE_SIZE=res.item[0].params.body.FIGHT_QUEUE_SIZE
          this.aiConfigs.FIGHT_QUEUE_NUM=res.item[0].params.body.FIGHT_QUEUE_NUM
          this.aiConfigs.FISTHEAD_QUEUE_SIZE=res.item[0].params.body.FISTHEAD_QUEUE_SIZE
          this.aiConfigs.FISTHEAD_QUEUE_NUM=res.item[0].params.body.FISTHEAD_QUEUE_NUM
          this.aiConfigs.temp_threshold=res.item[0].params.body.temp_threshold
          this.aiConfigs.door_area=res.item[0].params.body.door_area
          this.aiConfigs.ev_event_enable=res.item[0].params.body.ev_event_enable
          this.aiConfigs.long_stay_event_enable=res.item[0].params.body.long_stay_event_enable
          this.aiConfigs.ev_event_cancel=res.item[0].params.body.ev_event_cancel
          this.aiConfigs.abnormal_event_enable=res.item[0].params.body.abnormal_event_enable
          this.aiConfigs.fisthead_enable=res.item[0].params.body.fisthead_enable
          this.aiConfigs.block_event_enable=res.item[0].params.body.block_event_enable
          this.aiConfigs.LISTEN_PORT=res.item[0].params.body.LISTEN_PORT
          this.aiConfigs.mqtt_port=res.item[0].params.body.mqtt_port
          this.aiConfigs.iot_port=res.item[0].params.body.iot_port
          this.aiConfigs.PERSON_STATUS_LIST_MAXLEN=res.item[0].params.body.PERSON_STATUS_LIST_MAXLEN
          this.aiConfigs.PERSON_STATUS_LIST_NUM=res.item[0].params.body.PERSON_STATUS_LIST_NUM
          this.aiConfigs.DOOR_STATUS_LIST_MAXLEN=res.item[0].params.body.DOOR_STATUS_LIST_MAXLEN
          this.aiConfigs.DOOR_STATUS_LIST_NUM=res.item[0].params.body.DOOR_STATUS_LIST_NUM
          this.aiConfigs.Ev_event_code=res.item[0].params.body.Ev_event_code
          this.aiConfigs.Ev_event_cancle_code=res.item[0].params.body.Ev_event_cancle_code
          this.aiConfigs.Block_event_code=res.item[0].params.body.Block_event_code
          this.aiConfigs.Block_event_cancle_code=res.item[0].params.body.Block_event_cancle_code
          this.aiConfigs.Abnormal_event_code=res.item[0].params.body.Abnormal_event_code
          this.aiConfigs.Abnormal_event_cancle_code=res.item[0].params.body.Abnormal_event_cancle_code
          this.aiConfigs.ev_event_door_enable=res.item[0].params.body.ev_event_door_enable
          this.aiConfigs.ev_event_door_time=res.item[0].params.body.ev_event_door_time
          this.aiConfigs.ftp_host=res.item[0].params.body.ftp_host
          this.aiConfigs.ftp_port=res.item[0].params.body.ftp_port
          this.aiConfigs.ftp_user_name=res.item[0].params.body.ftp_user_name
          this.aiConfigs.ftp_user_passwd=res.item[0].params.body.ftp_user_passwd
          this.aiConfigs.ev_event_alarm_enable=res.item[0].params.body.ev_event_alarm_enable
          this.aiConfigs.fight_event_alarm_enable=res.item[0].params.body.fight_event_alarm_enable
          this.aiConfigs.ev_event_alarm_enable=res.item[0].params.body.ev_event_alarm_enable
          if(this.aiConfigs.ev_event_alarm_enable=="1"){this.checkedEventKeys.push("ev_event_alarm_enable")}
          this.aiConfigs.fight_event_alarm_enable=res.item[0].params.body.fight_event_alarm_enable
          if(this.aiConfigs.fight_event_alarm_enable=="1"){this.checkedEventKeys.push("fight_event_alarm_enable")}
          this.aiConfigs.AI_HALL_EV_ENABLE=res.item[0].params.body.AI_HALL_EV_ENABLE
          this.aiConfigs.AI_HALL_EV_UPLOAD_VIDEO_ENABLE=res.item[0].params.body.AI_HALL_EV_UPLOAD_VIDEO_ENABLE
          this.aiConfigs.AI_HALL_EV_ALARM=res.item[0].params.body.AI_HALL_EV_ALARM
          this.aiConfigs.AI_HALL_EV_AND_BIKE=res.item[0].params.body.AI_HALL_EV_AND_BIKE
          this.aiConfigs.AI_UNATTENDED_ENABLE=res.item[0].params.body.AI_UNATTENDED_ENABLE
          this.aiConfigs.AI_UNATTENDED_UPLOAD_VIDEO_ENABLE=res.item[0].params.body.AI_UNATTENDED_UPLOAD_VIDEO_ENABLE
          this.aiConfigs.AI_UNATTENDED_TIME=res.item[0].params.body.AI_UNATTENDED_TIME
          this.aiConfigs.AI_UNATTENDED_THRESHOLD=res.item[0].params.body.AI_UNATTENDED_THRESHOLD
          this.aiConfigs.AI_UNATTENDED_CANCEL_THRESHOLD=res.item[0].params.body.AI_UNATTENDED_CANCEL_THRESHOLD
          this.aiConfigs.AI_UNATTENDED_CONF_THRESHOLD=res.item[0].params.body.AI_UNATTENDED_CONF_THRESHOLD.toFixed(3)
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    setParamsConfig(){
      let paramsBody={};
      if(this.fdaAiFlag.videoAiFlag==0){
        paramsBody = {
          ev_event_door_time : this.aiConfigs.ev_event_door_time,
          ev_event_door_enable : this.aiConfigs.ev_event_door_enable,
          block_event_enable : this.aiConfigs.block_event_enable,
          ev_event_enable : this.aiConfigs.ev_event_enable,
          ev_event_cancel : this.aiConfigs.ev_event_cancel,
          long_stay_event_enable:this.aiConfigs.long_stay_event_enable,
          INIT_WITH_VEHICLE_THRESHOLD : this.aiConfigs.INIT_WITH_VEHICLE_THRESHOLD,
          INIT_WITH_VEHICLE_HEAD_THRESHOLD : this.aiConfigs.INIT_WITH_VEHICLE_HEAD_THRESHOLD,
          INIT_WITH_VEHICLE_CHAIR_THRESHOLD : this.aiConfigs.INIT_WITH_VEHICLE_CHAIR_THRESHOLD,

          UPLOAD_VIDEO_ENABLE:this.aiConfigs.UPLOAD_VIDEO_ENABLE,
          AI_HALL_EV_ENABLE:this.aiConfigs.AI_HALL_EV_ENABLE,
          AI_HALL_EV_UPLOAD_VIDEO_ENABLE:this.aiConfigs.AI_HALL_EV_UPLOAD_VIDEO_ENABLE,
          AI_HALL_EV_ALARM:this.aiConfigs.AI_HALL_EV_ALARM,
          AI_HALL_EV_AND_BIKE:this.aiConfigs.AI_HALL_EV_AND_BIKE,
          AI_UNATTENDED_ENABLE:this.aiConfigs.AI_UNATTENDED_ENABLE,
          AI_UNATTENDED_UPLOAD_VIDEO_ENABLE:this.aiConfigs.AI_UNATTENDED_UPLOAD_VIDEO_ENABLE,
          AI_UNATTENDED_TIME:this.aiConfigs.AI_UNATTENDED_TIME,
          AI_UNATTENDED_THRESHOLD:this.aiConfigs.AI_UNATTENDED_THRESHOLD,
          AI_UNATTENDED_CANCEL_THRESHOLD:this.aiConfigs.AI_UNATTENDED_CANCEL_THRESHOLD,
          AI_UNATTENDED_CONF_THRESHOLD : this.aiConfigs.AI_UNATTENDED_CONF_THRESHOLD,
        };
        this.paramsdata= "录像文件上传使能" + "," + this.aiConfigs.UPLOAD_VIDEO_ENABLE + ","
            + "大厅电动车识别事件使能标志"+","+this.aiConfigs.AI_HALL_EV_ENABLE+","
            + "大厅电动车录像上传使能标志"+","+this.aiConfigs.AI_HALL_EV_UPLOAD_VIDEO_ENABLE+","
            +"大厅电动车识别事件报警使能"+","+this.aiConfigs.AI_HALL_EV_ALARM+","
            +"大厅电动车识别自行车和电动车绑定使能"+","+this.aiConfigs.AI_HALL_EV_AND_BIKE+","
            +"脱岗事件识别使能"+","+this.aiConfigs.AI_UNATTENDED_ENABLE+","
            +"脱岗事件上传录像使能"+","+this.aiConfigs.AI_UNATTENDED_UPLOAD_VIDEO_ENABLE+","
            +"脱岗事件触发时长设置"+","+this.aiConfigs.AI_UNATTENDED_TIME+","
            +"脱岗无人百分比门限触发上报事件"+","+this.aiConfigs.AI_UNATTENDED_THRESHOLD+","
            +"脱岗有人百分比门限触发取消事件"+","+this.aiConfigs.AI_UNATTENDED_CANCEL_THRESHOLD+","
            +"脱岗识别是否有人的置信度阈值"+","+this.aiConfigs.AI_UNATTENDED_CONF_THRESHOLD+","
            +"控门不关时间"+","+this.aiConfigs.ev_event_door_time+","
            +"控制电动车不关门使能标志"+","+this.aiConfigs.ev_event_door_enable+","
            +"阻门识别AI事件是否启用开关"+","+this.aiConfigs.block_event_enable+","
            +"电动车事件解除标志"+","+this.aiConfigs.ev_event_cancel+","
            +"电动车识别AI事件是否启用开关"+","+this.aiConfigs.ev_event_enable+","
            +"电动车识别置信值阀值"+","+this.aiConfigs.INIT_WITH_VEHICLE_THRESHOLD+","
            +"电动车头识别置信值阀值"+","+this.aiConfigs.INIT_WITH_VEHICLE_HEAD_THRESHOLD+","
            +"电动车座识别置信值阀值"+","+this.aiConfigs.INIT_WITH_VEHICLE_CHAIR_THRESHOLD+","
            +"轿厢内有人长时间驻留事件使能"+","+this.aiConfigs.long_stay_event_enable+","
      }else{
        paramsBody = {
          heartbeat_cycle : this.aiConfigs.heartbeat_cycle,
          version_number : this.aiConfigs.version_number,
          INIT_WITH_FIGHT_THRESHOLD : this.aiConfigs.INIT_WITH_FIGHT_THRESHOLD,
          INIT_WITH_FISTHEAD_THRESHOLD : this.aiConfigs.INIT_WITH_FISTHEAD_THRESHOLD,
          VEHICLE_QUEUE_SIZE : this.aiConfigs.VEHICLE_QUEUE_SIZE,
          VEHICLE_QUEUE_NUM : this.aiConfigs.VEHICLE_QUEUE_NUM,
          VEHICLE_CHAIR_HEAD_SIZE : this.aiConfigs.VEHICLE_CHAIR_HEAD_SIZE,
          VEHICLE_CHAIR_HEAD_NUM : this.aiConfigs.VEHICLE_CHAIR_HEAD_NUM,
          FIGHT_QUEUE_SIZE : this.aiConfigs.FIGHT_QUEUE_SIZE,
          FIGHT_QUEUE_NUM : this.aiConfigs.FIGHT_QUEUE_NUM,
          FISTHEAD_QUEUE_SIZE : this.aiConfigs.FISTHEAD_QUEUE_SIZE,
          FISTHEAD_QUEUE_NUM : this.aiConfigs.FISTHEAD_QUEUE_NUM,
          BLOCK_DOOR_LIST_MAXLEN : this.aiConfigs.BLOCK_DOOR_LIST_MAXLEN,
          BLOCK_DOOR_LIST_NUM : this.aiConfigs.BLOCK_DOOR_LIST_NUM,
          temp_threshold : this.aiConfigs.temp_threshold,
          door_area : this.aiConfigs.door_area,
          abnormal_event_enable : this.aiConfigs.abnormal_event_enable,
          fisthead_enable : this.aiConfigs.fisthead_enable,
          LISTEN_PORT : this.aiConfigs.LISTEN_PORT,
          mqtt_port : this.aiConfigs.mqtt_port,
          iot_port : this.aiConfigs.iot_port,
          PERSON_STATUS_LIST_MAXLEN : this.aiConfigs.PERSON_STATUS_LIST_MAXLEN,
          PERSON_STATUS_LIST_NUM : this.aiConfigs.PERSON_STATUS_LIST_NUM,
          DOOR_STATUS_LIST_MAXLEN : this.aiConfigs.DOOR_STATUS_LIST_MAXLEN,
          DOOR_STATUS_LIST_NUM : this.aiConfigs.DOOR_STATUS_LIST_NUM,
          Ev_event_code : this.aiConfigs.Ev_event_code,
          Ev_event_cancle_code : this.aiConfigs.Ev_event_cancle_code,
          Block_event_code : this.aiConfigs.Block_event_code,
          Block_event_cancle_code : this.aiConfigs.Block_event_cancle_code,
          Abnormal_event_code : this.aiConfigs.Abnormal_event_code,
          Abnormal_event_cancle_code : this.aiConfigs.Abnormal_event_cancle_code,
          ftp_host :this.aiConfigs.ftp_host,
          ftp_port :this.aiConfigs.ftp_port,
          ftp_user_name :this.aiConfigs.ftp_user_name,
          ftp_user_passwd :this.aiConfigs.ftp_user_passwd,
          ev_event_alarm_enable:this.aiConfigs.ev_event_alarm_enable,
          fight_event_alarm_enable:this.aiConfigs.fight_event_alarm_enable,
        };
        let reg=new RegExp(',','g')//g代表全部
        this.paramsdata="ftp地址"+","+this.aiConfigs.ftp_host+","
            +"ftp端口号"+","+this.aiConfigs.ftp_port+","
            +"ftp用户名"+","+this.aiConfigs.ftp_user_name+","
            +"ftp密码"+","+this.aiConfigs.ftp_user_passwd+","
            +"监听端口"+","+this.aiConfigs.LISTEN_PORT+","
            +"mqtt发送端口"+","+this.aiConfigs.mqtt_port+","
            +"打架识别置信值阀值"+","+this.aiConfigs.INIT_WITH_FIGHT_THRESHOLD+","
            +"手碰头识别置信值阀值"+","+this.aiConfigs.INIT_WITH_FISTHEAD_THRESHOLD+","
            +"电动车事件队列长度"+","+this.aiConfigs.VEHICLE_QUEUE_SIZE+","
            +"电动车事件个数阀值"+","+this.aiConfigs.VEHICLE_QUEUE_NUM+","
            +"电动车头和车座事件队列长度"+","+this.aiConfigs.VEHICLE_CHAIR_HEAD_SIZE+","
            +"电动车头和车座事件个数阈值"+","+this.aiConfigs.VEHICLE_CHAIR_HEAD_NUM+","
            +"打架事件队列长度"+","+this.aiConfigs.FIGHT_QUEUE_SIZE+","
            +"打架事件个数阀值"+","+this.aiConfigs.FIGHT_QUEUE_NUM+","
            +"手碰头事件队列长度"+","+this.aiConfigs.FISTHEAD_QUEUE_SIZE+","
            +"手碰头事件个数阀值"+","+this.aiConfigs.FISTHEAD_QUEUE_NUM+","
            +"设备温度阀值"+","+this.aiConfigs.temp_threshold+","
            +"开关门区域判断位置设置"+","+JSON.stringify(this.aiConfigs.door_area).replace(reg,'、')+","
            +"打架识别AI事件是否启用开关"+","+this.aiConfigs.fisthead_enable+","
            +"手碰头识别AI事件是否启用开关"+","+this.aiConfigs.abnormal_event_enable+","
            +"与物联网程序内部通信端口"+","+this.aiConfigs.iot_port+","
            +"有人队列长度"+","+this.aiConfigs.PERSON_STATUS_LIST_MAXLEN+","
            +"有人确认阈值"+","+this.aiConfigs.PERSON_STATUS_LIST_NUM+","
            +"开门队列长度"+","+this.aiConfigs.DOOR_STATUS_LIST_MAXLEN+","
            +"开门确认阈值"+","+this.aiConfigs.DOOR_STATUS_LIST_NUM+","
            +"AI心跳周期"+","+this.aiConfigs.heartbeat_cycle+","
            +"电瓶车进入轿厢联动终端通话使能"+","+this.aiConfigs.ev_event_alarm_enable+","
            +"轿厢内危险动作联动终端通话使能"+","+this.aiConfigs.fight_event_alarm_enable+","
      }
      let paramsdate=moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username:this.username,
        paramsobj:this.devicename,
        paramsdate:paramsdate,
        devicenum:this.devicenum,
        devicegroupid:"00",
        orderresult:'失败',
        paramstype:'0',
        parentid:"",
        paramsdata:this.paramsdata,
      }
      addParamsBatchLog(param)
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'PUT',
              url:'/ai',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
          let param = {
            username:this.username,
            paramsobj:this.devicename,
            paramsdate:paramsdate,
            devicenum:this.devicenum,
            devicegroupid:"00",
            orderresult:'成功',
            paramstype:'0',
            parentid:"",
            paramsdata:this.paramsdata,
          }
          modifyParamsBatchLog(param)
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
        }
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>