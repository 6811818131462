<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 6.1%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:14}" :wrapper-col="{span:10}">
      <a-form-model-item v-if="VideoParamFlag.videoParamFlag==1" label="录像清理触发百分比" prop="PARA_VIDEO_DISK_RATE">
        <a-input v-model="videoConfigs.PARA_VIDEO_DISK_RATE" placeholder="超过则删除文件，默认95%"></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="VideoParamFlag.videoParamFlag==1" label="录像清理分片文件数" prop="PARA_VIDEO_DELETE_FILE_NUM">
        <a-input v-model="videoConfigs.PARA_VIDEO_DELETE_FILE_NUM" placeholder="一次行删除文件最大数量，默认100个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="VideoParamFlag.videoParamFlag==0" label="流媒体查询IP列表" prop="PARA_VIDEO_STREAM_IP_LIST">
        <a-input v-model="videoConfigs.PARA_VIDEO_STREAM_IP_LIST" placeholder="多个IP请使用英文逗号连接"></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="VideoParamFlag.videoParamFlag==0" label="推送端口" prop="VIDEO_STREAM_PUSH_PORT">
        <a-input v-model="videoConfigs.VIDEO_STREAM_PUSH_PORT" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="VideoParamFlag.videoParamFlag==0" label="流媒体拉流地址" prop="VIDEO_STREAM_USER_PLAY_URL">
        <a-input v-model="videoConfigs.VIDEO_STREAM_USER_PLAY_URL" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="VideoParamFlag.videoParamFlag==0" label="流媒体拉流端口" prop="VIDEO_STREAM_USER_PLAY_PORT">
        <a-input v-model="videoConfigs.VIDEO_STREAM_USER_PLAY_PORT" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="VideoParamFlag.videoParamFlag==0" label="流媒体推流流地址" prop="VIDEO_STREAM_API_URL">
        <a-input v-model="videoConfigs.VIDEO_STREAM_API_URL" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="VideoParamFlag.videoParamFlag==0" label="流媒体推流流地址" prop="VIDEO_STREAM_API_PORT">
        <a-input v-model="videoConfigs.VIDEO_STREAM_API_PORT" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="VideoParamFlag.videoParamFlag==0" label="推流超时时间" prop="PARA_VIDEO_PUSH_OUT_TIME">
        <a-input v-model="videoConfigs.PARA_VIDEO_PUSH_OUT_TIME" placeholder="默认10s" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="VideoParamFlag.videoParamFlag==0" label="直播推流限时模块使能" prop="PARA_VIDEO_PUSH_PLAY_TIME_LIMIT_ENABLE">
        <a-select v-model="videoConfigs.PARA_VIDEO_PUSH_PLAY_TIME_LIMIT_ENABLE" placeholder="1使能，0禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="videoConfigs.PARA_VIDEO_PUSH_PLAY_TIME_LIMIT_ENABLE==1&&VideoParamFlag.videoParamFlag==0" label="直播推流最长时间" prop="PARA_VIDEO_PUSH_MAX_TIME">
        <a-input v-model="videoConfigs.PARA_VIDEO_PUSH_MAX_TIME" placeholder="默认10min" suffix="分钟"></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="VideoParamFlag.videoParamFlag==0" label="回放推流限时模块使能" prop="PARA_VIDEO_PUSH_PLAYBACK_TIME_LIMIT_ENABLE">
        <a-select v-model="videoConfigs.PARA_VIDEO_PUSH_PLAYBACK_TIME_LIMIT_ENABLE" placeholder="1使能，0禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-if="videoConfigs.PARA_VIDEO_PUSH_PLAYBACK_TIME_LIMIT_ENABLE==1&&VideoParamFlag.videoParamFlag==0"  label="回放推流最长时间" prop="PARA_VIDEO_PUSH_PLAYBACK_MAX_TIME">
        <a-input v-model="videoConfigs.PARA_VIDEO_PUSH_PLAYBACK_MAX_TIME" placeholder="默认30min" suffix="分钟"></a-input>
      </a-form-model-item>
      <a-form-model-item v-if="VideoParamFlag.videoParamFlag==1" label="心跳间隔" prop="PARA_VIDEO_HEARTBEAT_CYCLE">
        <a-input v-model="videoConfigs.PARA_VIDEO_HEARTBEAT_CYCLE" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  addParamsBatchLog,
  getDeviceAudioparamValueList,
  getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList,
  modifyParamsBatchLog,
  setDeviceAudioparamValueList,
  setDeviceparamValueList,
} from "A/ai";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "videoParam",
  props:{
    VideoParamFlag:{
      default: null
    }
  },
  data() {
    return {
      videoConfigs: {
        PARA_VIDEO_DISK_RATE:'',
        PARA_VIDEO_DELETE_FILE_NUM:'',
        PARA_VIDEO_PUSH_OUT_TIME:'',
        PARA_VIDEO_PUSH_MAX_TIME:'',
        PARA_VIDEO_PUSH_PLAYBACK_MAX_TIME:'',
        PARA_VIDEO_HEARTBEAT_CYCLE:'',
        PARA_VIDEO_STREAM_IP_LIST:'',
        VIDEO_STREAM_PUSH_PORT:'',
        VIDEO_STREAM_USER_PLAY_URL:'',
        VIDEO_STREAM_USER_PLAY_PORT:'',
        VIDEO_STREAM_API_URL:'',
        VIDEO_STREAM_API_PORT:'',
        PARA_VIDEO_PUSH_PLAY_TIME_LIMIT_ENABLE:'',
        PARA_VIDEO_PUSH_PLAYBACK_TIME_LIMIT_ENABLE:'',
      },
      methodList: [],
      devicenum:'',
      devicename:'',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            params: {
              method: 'GET',
              url: '/video',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.videoConfigs = res.item[0].params.body
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    setParamsConfig() {
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            params: {
              methord: 'PUT',
              url: '/video',
              body: this.videoConfigs,
            }
          }
        ]
      };
      this.paramsdata = "录像存储占比" + "," + this.videoConfigs.PARA_VIDEO_DISK_RATE + ","
          + "超过录像存储占比" + "," + this.videoConfigs.PARA_VIDEO_DELETE_FILE_NUM + ","
          + "推流超时时间" + "," + this.videoConfigs.PARA_VIDEO_PUSH_OUT_TIME + ","
          + "直播推流最长时间" + "," + this.videoConfigs.PARA_VIDEO_PUSH_MAX_TIME + ","
          + "回放推流最长时间" + "," + this.videoConfigs.PARA_VIDEO_PUSH_PLAYBACK_MAX_TIME + ","
          + "心跳间隔" + "," + this.videoConfigs.PARA_VIDEO_HEARTBEAT_CYCLE + ","
          + "查询流媒体服务器列表" + "," + this.videoConfigs.PARA_VIDEO_STREAM_IP_LIST + ","
          + "直播推流限时模块使能" + "," + this.videoConfigs.PARA_VIDEO_PUSH_PLAY_TIME_LIMIT_ENABLE + ","
          + "回放推流限时模块使能" + "," + this.videoConfigs.PARA_VIDEO_PUSH_PLAYBACK_TIME_LIMIT_ENABLE + ","
      let paramsdate = moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username: this.username,
        paramsobj: this.devicename,
        paramsdate: paramsdate,
        devicenum: this.devicenum,
        devicegroupid: "00",
        orderresult: '失败',
        paramstype: '0',
        parentid: "",
        paramsdata: this.paramsdata,
      }
      addParamsBatchLog(param)
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.$message.success('操作成功');
          let param = {
            username: this.username,
            paramsobj: this.devicename,
            paramsdate: paramsdate,
            devicenum: this.devicenum,
            devicegroupid: "00",
            orderresult: '成功',
            paramstype: '0',
            parentid: "",
            paramsdata: this.paramsdata,
          }
          modifyParamsBatchLog(param)
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
        }
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>