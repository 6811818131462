<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 6.1%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:9}" :wrapper-col="{span:14}" style="margin-top: 2%">
      <a-form-model-item label="SIP服务IP" prop="ip">
        <a-input v-model="SIPsConfigs.ip" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item  label="SIP端口" prop="port">
        <a-input v-model="SIPsConfigs.port" placeholder="" ></a-input>
      </a-form-model-item>
      <a-form-model-item  label="传输协议" prop="transMode">
        <a-input v-model="SIPsConfigs.transMode" placeholder="" ></a-input>
      </a-form-model-item>
      <a-form-model-item  label="超时时间" prop="timeout">
        <a-input v-model="SIPsConfigs.timeout" placeholder="单位为秒" ></a-input>
      </a-form-model-item>
      <a-form-model-item  label="SIP用户名" prop="username">
        <a-input v-model="SIPsConfigs.username" placeholder="" ></a-input>
      </a-form-model-item>
      <a-form-model-item  label="SIP密码" prop="password">
        <a-input type="password" v-model="SIPsConfigs.password" placeholder="" ></a-input>
      </a-form-model-item>
      <a-form-model-item label="SIP服务ID" prop="sipId">
        <a-input v-model="SIPsConfigs.sipId" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="SIP服务域" prop="realm">
        <a-input v-model="SIPsConfigs.realm" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="注册间隔" prop="regInterval">
        <a-input v-model="SIPsConfigs.regInterval" placeholder="单位为秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="注册有效期" prop="regExpires">
        <a-input v-model="SIPsConfigs.regExpires" placeholder="单位为秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="心跳间隔" prop="heartInterval">
        <a-input v-model="SIPsConfigs.heartInterval" placeholder="单位为秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="心跳次数" prop="heartTimes">
        <a-input v-model="SIPsConfigs.heartTimes" placeholder=""></a-input>
      </a-form-model-item>
<!--      <a-form-model-item label="注册有效期" prop="expires">-->
<!--        <a-input v-model="SIPsConfigs.regExpires" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
    </a-form-model>
  </div>
</template>

<script>
import {getDeviceparamChildLisByParentId, getDeviceparamValueList, setDeviceparamValueList} from "A/ai";
import {arrayToObject, getItemFromArrayByKey} from "U/index";

export default {
  name: "sipregister",
  data() {
    return {
      SIPsConfigs: {
        ip:'',
        port:'',
        transMode:'',
        timeout:'',
        username:'',
        password:'',
        sipId:'',
        realm:'',
        regInterval:'',
        regExpires:'',
        heartInterval:'',
        heartTimes:'',
        expires:'',
      },
      devicemoduletype: '',
      devicenum: '',
      modelnum: '',
      methodList: [],
      tabKey: '406',
      status: false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '406';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      this.paramsList = [];
      // let selectedMethod = getItemFromArrayByKey(this.methodList, 'devparamcfgid', this.tabKey);
      // if(selectedMethod) {
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              modeltype: this.devicemoduletype,
              modelnum: this.modelnum,
              params: {
                methord: 'GET',
                url: '/smart/netwok/sipregister?service=servicesipregister&action=getconfig',
              }
            }
          ]
        };
        getDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
            this.SIPsConfigs.ip=res.item[0].params.body.ip
            this.SIPsConfigs.port=res.item[0].params.body.port
            this.SIPsConfigs.username=res.item[0].params.body.username
            this.SIPsConfigs.password=res.item[0].params.body.password
            this.SIPsConfigs.transMode=res.item[0].params.body.transMode
            this.SIPsConfigs.timeout=res.item[0].params.body.timeout
            this.SIPsConfigs.sipId=res.item[0].params.body.sipId
            this.SIPsConfigs.realm=res.item[0].params.body.realm
            this.SIPsConfigs.regInterval=res.item[0].params.body.regInterval
            this.SIPsConfigs.regExpires=res.item[0].params.body.regExpires
            this.SIPsConfigs.heartInterval=res.item[0].params.body.heartInterval
            this.SIPsConfigs.heartTimes=res.item[0].params.body.heartTimes
            this.SIPsConfigs.expires=res.item[0].params.body.expires
          }
        }).catch(err => {
          this.hideLoading();
        })
      // }
    },
    setParamsConfig(){
      // let selectedMethod = getItemFromArrayByKey(this.methodList, 'devparamcfgid', this.tabKey);
      // if(selectedMethod) {
        let paramsBody = {
          ip : this.SIPsConfigs.ip,
          port : Number(this.SIPsConfigs.port),
          username : this.SIPsConfigs.username,
          password : this.SIPsConfigs.password,
          transMode : Number(this.SIPsConfigs.transMode),
          timeout : Number(this.SIPsConfigs.timeout),
          sipId : this.SIPsConfigs.sipId,
          realm : this.SIPsConfigs.realm,
          regInterval : Number(this.SIPsConfigs.regInterval),
          regExpires : Number(this.SIPsConfigs.regExpires),
          heartInterval : Number(this.SIPsConfigs.heartInterval),
          heartTimes : Number(this.SIPsConfigs.heartTimes),
          expires : this.SIPsConfigs.expires,
        };
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              modeltype: this.devicemoduletype,
              modelnum: this.modelnum,
              params: {
                methord: 'PUT',
                url:'/smart/netwok/sipregister?service=servicesipregister&action=setconfig',
                body: paramsBody,
              }
            }
          ]
        };
        this.showLoading();
        setDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        }).catch(err => {
          this.hideLoading();
          this.$message.error('操作失败');
        })
      // }
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>