<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 6.1%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:14}" :wrapper-col="{span:10}">
      <a-form-model-item label="视频码流" prop="sNightToDay">
        <a-select id="status" placeholder="请选择" v-model="id" :disabled="true">
          <a-select-option :value="0">主码流</a-select-option>
          <a-select-option :value="1">子码流</a-select-option>
          <a-select-option :value="2">第三码流</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="视频分辨率" prop="sResolution">
        <a-input v-model="videoConfigs[id].sResolution" placeholder="" :disabled="true"></a-input>
      </a-form-model-item>
      <a-form-model-item label="视频帧率" prop="sFrameRate">
        <a-input v-model="videoConfigs[id].sFrameRate" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="视频码率" prop="iMaxRate">
        <a-input v-model="videoConfigs[id].iMaxRate" placeholder=""></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  addParamsBatchLog,
  getDeviceAudioparamValueList, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId, modifyParamsBatchLog, setDeviceAudioparamValueList,
} from "A/ai";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "videos",
  data() {
    return {
      id:0,
      videoConfigs:[
        {
        sVideoType: "",
        iTargetRate: null,
        sH264Profile: "",
        sRCMode: "",
        sVideoEncoderConfigurationToken: "",
        iStreamSmooth: null,
        iGOP: null,
        sResolution: null,
        iMinRate: null,
        sFrameRate: null,
        sFrameRateIn: null,
        sSmart: "",
        iMaxRate: null,
        sSVC: "",
        sOutputDataType: "",
        sRCQuality: "",
        sStreamType: "",
        id: 0,
        sVideoEncoderConfigurationName: "",
        sVideoSourceToken: ""
      },
    {
      sVideoType: "",
        iTargetRate: null,
        sH264Profile: "",
        sRCMode: "",
        sVideoEncoderConfigurationToken: "",
        iStreamSmooth: null,
        iGOP: null,
        sResolution: null,
        iMinRate: null,
        sFrameRate: null,
        sFrameRateIn: null,
        sSmart: "",
        iMaxRate: null,
        sSVC: "",
        sOutputDataType: "",
        sRCQuality: "",
        sStreamType: "",
        id: 1,
        sVideoEncoderConfigurationName: "",
        sVideoSourceToken: ""
    },
    {
      sVideoType: "",
        iTargetRate: null,
        sH264Profile: "",
        sRCMode: "",
        sVideoEncoderConfigurationToken: "",
        iStreamSmooth: null,
        iGOP: null,
        sResolution: null,
        iMinRate: null,
        sFrameRate: null,
        sFrameRateIn: null,
        sSmart: "",
        iMaxRate: null,
        sSVC: "",
        sOutputDataType: "",
        sRCQuality: "",
        sStreamType: "",
        id: 2,
        sVideoEncoderConfigurationName: "",
        sVideoSourceToken: ""
      }],
      methodList: [],
      devicenum:'',
      devicename:'',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              params: {
                method: 'GET',
                url: '/video',
              }
            }
          ]
        };
        getDeviceAudioparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.videoConfigs=res.item[0].params.body
          }
        }).catch(err => {
          this.hideLoading();
        })
    },
    setParamsConfig(){
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              params: {
                methord: 'PUT',
                url:'/video',
                body: this.videoConfigs,
              }
            }
          ]
        };
      this.paramsdata="视频帧率"+","+this.videoConfigs[this.id].sFrameRate+","
          +"视频码率"+","+this.videoConfigs[this.id].iMaxRate+","
      let paramsdate=moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username:this.username,
        paramsobj:this.devicename,
        paramsdate:paramsdate,
        devicenum:this.devicenum,
        devicegroupid:"00",
        orderresult:'失败',
        paramstype:'0',
        parentid:"",
        paramsdata:this.paramsdata,
      }
      addParamsBatchLog(param)
        this.showLoading();
        setDeviceAudioparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0' ) {
            this.$message.success('操作成功');
            let param = {
              username:this.username,
              paramsobj:this.devicename,
              paramsdate:paramsdate,
              devicenum:this.devicenum,
              devicegroupid:"00",
              orderresult:'成功',
              paramstype:'0',
              parentid:"",
              paramsdata:this.paramsdata,
            }
            modifyParamsBatchLog(param)
          }
        }).catch(err => {
          this.hideLoading();
          this.$message.error('操作失败');
        })
      // }
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
        }
      })
    },
    getOrSet(){
      this.getParamsConfig();
      // if(this.status){
      //   this.setParamsConfig();
      //   this.status=false;
      // }else{
      //   this.getParamsConfig();
      //   this.status=true
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>