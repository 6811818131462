<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 5%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet(type)" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model v-if="electricitySystemIotFlag.iotFlag==0" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:14}" :wrapper-col="{span:10}">
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">物联网配置参数</h1>
      <a-form-model-item label="IO状态判断时间间隔" prop="IO_Calculation_Interval">
        <a-input v-model="iotConfigs.IO_Calculation_Interval" placeholder="默认1秒" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="IO状态采样时间窗间隔" prop="IO_Sampling_Interval">
        <a-input v-model="iotConfigs.IO_Sampling_Interval" placeholder="默认15ms" suffix="毫秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="时间窗内IO有效数值占比" prop="IO_State_Effective_Threshold">
        <a-input v-model="iotConfigs.IO_State_Effective_Threshold" placeholder="默认0.0200，保留4位小数"></a-input>
      </a-form-model-item>
      <a-form-model-item label="温度过高阈值" prop="Celsius_Temperature_Max_Threshold">
        <a-input v-model="iotConfigs.Celsius_Temperature_Max_Threshold" placeholder="默认40摄氏度" suffix="℃"></a-input>
      </a-form-model-item>
      <a-form-model-item label="温度过低阈值" prop="Celsius_Temperature_Min_Threshold">
        <a-input v-model="iotConfigs.Celsius_Temperature_Min_Threshold" placeholder="默认5摄氏度" suffix="℃"></a-input>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过高阈值" prop="Relative_Humidity_Max_Threshold">
        <a-input v-model="iotConfigs.Relative_Humidity_Max_Threshold" placeholder="默认90%" suffix=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过低阈值" prop="Relative_Humidity_Min_Threshold">
        <a-input v-model="iotConfigs.Relative_Humidity_Min_Threshold" placeholder="默认10%" suffix=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="温度队列长度" prop="Celsius_Temperature_State_List_Max">
        <a-input v-model="iotConfigs.Celsius_Temperature_State_List_Max" placeholder="默认3个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="温度队列阈值" prop="Celsius_Temperature_State_List_Threshold">
        <a-input v-model="iotConfigs.Celsius_Temperature_State_List_Threshold" placeholder="默认2个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="湿度队列长度" prop="Relative_Humidity_State_List_Max">
        <a-input v-model="iotConfigs.Relative_Humidity_State_List_Max" placeholder="默认3个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="湿度队列阈值" prop="Relative_Humidity_State_List_Threshold">
        <a-input v-model="iotConfigs.Relative_Humidity_State_List_Threshold" placeholder="默认2个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="人体检测队列长度" prop="Person_Detect_List_Max">
        <a-input v-model="iotConfigs.Person_Detect_List_Max" placeholder="默认3个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="人体检测队列阈值" prop="Person_Detect_List_Threshold">
        <a-input v-model="iotConfigs.Person_Detect_List_Threshold" placeholder="默认2个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="进线电压最小阈值" prop="IncomingCabinet_Minimum_Voltage_Threshold">
        <a-input v-model="iotConfigs.IncomingCabinet_Minimum_Voltage_Threshold" placeholder="默认值340V" suffix="V"></a-input>
      </a-form-model-item>
      <a-form-model-item label="进线电压最大阈值" prop="IncomingCabinet_Maximum_Voltage_Threshold">
        <a-input v-model="iotConfigs.IncomingCabinet_Maximum_Voltage_Threshold" placeholder="默认值420V" suffix="V"></a-input>
      </a-form-model-item>
<!--      <a-form-model-item label="变压器功率因数最小阈值" prop="Transformer_Cos_Minimum_Threshold">-->
<!--        <a-input v-model="iotConfigs.Transformer_Cos_Minimum_Threshold" placeholder="默认0.8"></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="馈电回路电流最大阈值" prop="FeederCircuit_Maximum_Current_Threshold">-->
<!--        <a-input v-model="iotConfigs.FeederCircuit_Maximum_Current_Threshold" placeholder="默认60A" suffix="A"></a-input>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="变压器负载率最小阈值" prop="Transformer_LoadRate_Minimum_Threshold">
        <a-input v-model="iotConfigs.Transformer_LoadRate_Minimum_Threshold" placeholder="默认0.4"></a-input>
      </a-form-model-item>
      <a-form-model-item label="变压器超温后风扇最迟启动时间阈值" prop="Transformer_Overheat_Fan_Run_Time_Threshold">
        <a-input v-model="iotConfigs.Transformer_Overheat_Fan_Run_Time_Threshold" placeholder="默认180秒" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="变压器额定功率" prop="Transformer_Rated_Power">
        <a-input v-model="iotConfigs.Transformer_Rated_Power" placeholder="默认100KVA" suffix="KVA"></a-input>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器功率因数最小阈值" prop="Transformer_1_Cos_Minimum_Threshold">
        <a-input v-model="iotConfigs.Transformer_1_Cos_Minimum_Threshold" placeholder="默认0.85"></a-input>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器功率因数最小阈值" prop="Transformer_2_Cos_Minimum_Threshold">
        <a-input v-model="iotConfigs.Transformer_2_Cos_Minimum_Threshold" placeholder="默认0.85"></a-input>
      </a-form-model-item>
      <a-form-model-item label="第一组馈电回路电流最大阈值" prop="FeederCircuit_1_Maximum_Current_Threshold">
        <a-input v-model="iotConfigs.FeederCircuit_1_Maximum_Current_Threshold" placeholder="默认200A" suffix="A"></a-input>
      </a-form-model-item>
      <a-form-model-item label="第二组馈电回路电流最大阈值" prop="FeederCircuit_2_Maximum_Current_Threshold">
        <a-input v-model="iotConfigs.FeederCircuit_2_Maximum_Current_Threshold" placeholder="默认200A" suffix="A"></a-input>
      </a-form-model-item>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">事件使能参数</h1>
      <a-form-model-item label="摄氏温度过高事件使能" prop="Celsius_Temperature_Too_High_Event_Enable">
        <a-select v-model="iotConfigs.Celsius_Temperature_Too_High_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="摄氏温度过低事件使能" prop="Celsius_Temperature_Too_Low_Event_Enable">
        <a-select v-model="iotConfigs.Celsius_Temperature_Too_Low_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过高事件使能" prop="Relative_Humidity_Too_High_Event_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_Too_High_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过低事件使能" prop="Relative_Humidity_Too_Low_Event_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_Too_Low_Event_Enable" placeholder="1为使能，0为禁止，默认使能" :disabled="true">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房有人事件使能" prop="SwitchingHouse_Person_Event_Enable">
        <a-select v-model="iotConfigs.SwitchingHouse_Person_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房门打开事件使能" prop="SwitchingHouse_Door_Open_Event_Enable">
        <a-select v-model="iotConfigs.SwitchingHouse_Door_Open_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房送风机启动事件使能" prop="EngineRoom_FeedFan_Start_Event_Enable">
        <a-select v-model="iotConfigs.EngineRoom_FeedFan_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房排风机启动事件使能" prop="EngineRoom_ExhaustFan_Start_Event_Enable">
        <a-select v-model="iotConfigs.EngineRoom_ExhaustFan_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一路进线分闸事件使能" prop="IncomingCabinet_1_Opening_Event_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_1_Opening_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二路进线分闸事件使能" prop="IncomingCabinet_2_Opening_Event_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_2_Opening_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜市电分闸事件使能" prop="Group_1_ElectricSupplySwitch_Opening_Event_Enable">
        <a-select v-model="iotConfigs.Group_1_ElectricSupplySwitch_Opening_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜发电合闸事件使能" prop="Group_1_GenSwitch_Closing_Event_Enable">
        <a-select v-model="iotConfigs.Group_1_GenSwitch_Closing_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜市电分闸事件使能" prop="Group_2_ElectricSupplySwitch_Opening_Event_Enable">
        <a-select v-model="iotConfigs.Group_2_ElectricSupplySwitch_Opening_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜发电合闸事件使能" prop="Group_2_GenSwitch_Closing_Event_Enable">
        <a-select v-model="iotConfigs.Group_2_GenSwitch_Closing_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组补偿电容运行事件使能" prop="Group_1_CompensationCabinetCapacity_Run_Event_Enable">
        <a-select v-model="iotConfigs.Group_1_CompensationCabinetCapacity_Run_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组补偿电容运行事件使能" prop="Group_2_CompensationCabinetCapacity_Run_Event_Enable">
        <a-select v-model="iotConfigs.Group_2_CompensationCabinetCapacity_Run_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第三组补偿电容运行事件使能" prop="Group_3_CompensationCabinetCapacity_Run_Event_Enable">
        <a-select v-model="iotConfigs.Group_3_CompensationCabinetCapacity_Run_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第四组补偿电容运行事件使能" prop="Group_4_CompensationCabinetCapacity_Run_Event_Enable">
        <a-select v-model="iotConfigs.Group_4_CompensationCabinetCapacity_Run_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组补偿柜电容分闸事件使能" prop="CompensationCabinetCapacity_1_Opening_Event_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetCapacity_1_Opening_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组补偿柜电容分闸事件使能" prop="CompensationCabinetCapacity_2_Opening_Event_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetCapacity_2_Opening_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第三组补偿柜电容分闸事件使能" prop="CompensationCabinetCapacity_3_Opening_Event_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetCapacity_3_Opening_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第四组补偿柜电容分闸事件使能" prop="CompensationCabinetCapacity_4_Opening_Event_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetCapacity_4_Opening_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="联络柜开关合闸事件使能" prop="ContactCabinetSwitch_Closing_Event_Enable">
        <a-select v-model="iotConfigs.ContactCabinetSwitch_Closing_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="防潮灯开启事件使能" prop="MoistureProofLamps_On_Event_Enable">
        <a-select v-model="iotConfigs.MoistureProofLamps_On_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器降温风扇启动事件使能" prop="Group_1_Transformer_Fan_Start_Event_Enable">
        <a-select v-model="iotConfigs.Group_1_Transformer_Fan_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器降温风扇启动事件使能" prop="Group_2_Transformer_Fan_Start_Event_Enable">
        <a-select v-model="iotConfigs.Group_2_Transformer_Fan_Start_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器负载率过低事件使能" prop="Group_1_Transformer_LoadRate_TooLow_Event_Enable">
        <a-select v-model="iotConfigs.Group_1_Transformer_LoadRate_TooLow_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器负载率过低事件使能" prop="Group_2_Transformer_LoadRate_TooLow_Event_Enable">
        <a-select v-model="iotConfigs.Group_2_Transformer_LoadRate_TooLow_Event_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">报警使能参数</h1>
      <a-form-model-item label="两路进线柜同时分闸报警使能" prop="IncomingCabinetSwitch_AllOpening_Alarm_Enable">
        <a-select v-model="iotConfigs.IncomingCabinetSwitch_AllOpening_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一路进线柜电压过低报警使能" prop="IncomingCabinet_1_Voltage_TooLow_Alarm_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_1_Voltage_TooLow_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二路进线柜电压过低报警使能" prop="IncomingCabinet_2_Voltage_TooLow_Alarm_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_2_Voltage_TooLow_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一路进线柜电压过高报警使能" prop="IncomingCabinet_1_Voltage_TooHigh_Alarm_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_1_Voltage_TooHigh_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二路进线柜电压过高报警使能" prop="IncomingCabinet_2_Voltage_TooHigh_Alarm_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_2_Voltage_TooHigh_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="两路进线柜同时合闸联络柜合闸报警使能" prop="ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Enable">
        <a-select v-model="iotConfigs.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜同时合闸报警使能" prop="ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Enable">
        <a-select v-model="iotConfigs.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜同时合闸报警使能" prop="ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Enable">
        <a-select v-model="iotConfigs.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜同时分闸报警使能" prop="ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Enable">
        <a-select v-model="iotConfigs.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜同时分闸报警使能" prop="ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Enable">
        <a-select v-model="iotConfigs.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器功率因数过低报警使能" prop="Transformer_1_Cos_TooLow_Alarm_Enable">
        <a-select v-model="iotConfigs.Transformer_1_Cos_TooLow_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器功率因数过低报警使能" prop="Transformer_2_Cos_TooLow_Alarm_Enable">
        <a-select v-model="iotConfigs.Transformer_2_Cos_TooLow_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组补偿柜分闸报警使能" prop="CompensationCabinetSwitch_1_Opening_Alarm_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetSwitch_1_Opening_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组补偿柜分闸报警使能" prop="CompensationCabinetSwitch_2_Opening_Alarm_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetSwitch_2_Opening_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第三组补偿柜分闸报警使能" prop="CompensationCabinetSwitch_3_Opening_Alarm_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetSwitch_3_Opening_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第四组补偿柜分闸报警使能" prop="CompensationCabinetSwitch_4_Opening_Alarm_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetSwitch_4_Opening_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="功率因数过低第一组补偿电容停止运行报警使能" prop="Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable">
        <a-select v-model="iotConfigs.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="功率因数过低第二组补偿电容停止运行报警使能" prop="Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable">
        <a-select v-model="iotConfigs.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="功率因数过低第三组补偿电容停止运行报警使能" prop="Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable">
        <a-select v-model="iotConfigs.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="功率因数过低第四组补偿电容停止运行报警使能" prop="Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable">
        <a-select v-model="iotConfigs.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组馈电回路A相电流过大报警使能" prop="Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Enable">
        <a-select v-model="iotConfigs.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组馈电回路A相电流过大报警使能" prop="Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Enable">
        <a-select v-model="iotConfigs.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组馈电回路B相电流过大报警使能" prop="Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Enable">
        <a-select v-model="iotConfigs.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组馈电回路B相电流过大报警使能" prop="Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Enable">
        <a-select v-model="iotConfigs.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组馈电回路C相电流过大报警使能" prop="Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Enable">
        <a-select v-model="iotConfigs.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组馈电回路C相电流过大报警使能" prop="Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Enable">
        <a-select v-model="iotConfigs.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器超温报警使能" prop="Transformer_1_TempHigh_Alarm_Enable">
        <a-select v-model="iotConfigs.Transformer_1_TempHigh_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器超温报警使能" prop="Transformer_2_TempHigh_Alarm_Enable">
        <a-select v-model="iotConfigs.Transformer_2_TempHigh_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器超温降温风扇未启动报警使能" prop="Transformer_1_TempHigh_FanStop_Alarm_Enable">
        <a-select v-model="iotConfigs.Transformer_1_TempHigh_FanStop_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器超温降温风扇未启动报警使能" prop="Transformer_2_TempHigh_FanStop_Alarm_Enable">
        <a-select v-model="iotConfigs.Transformer_2_TempHigh_FanStop_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房温度过高送风机停止报警使能" prop="SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Enable">
        <a-select v-model="iotConfigs.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房温度过高排风机停止报警使能" prop="SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Enable">
        <a-select v-model="iotConfigs.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房湿度过高防潮灯关闭报警使能" prop="Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="拨打值班室电话使能" prop="Voip_Alarm_Enable">
        <a-select v-model="iotConfigs.Voip_Alarm_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">事件图片使能参数</h1>
      <a-form-model-item label="摄氏温度过高事件上传图片使能" prop="Celsius_Temperature_Too_High_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="摄氏温度过低事件上传图片使能" prop="Celsius_Temperature_Too_Low_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过高事件上传图片使能" prop="Relative_Humidity_Too_High_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_Too_High_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过低事件上传图片使能" prop="Relative_Humidity_Too_Low_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能" :disabled="true">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房有人事件上传图片使能" prop="SwitchingHouse_Person_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.SwitchingHouse_Person_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房门打开事件上传图片使能" prop="SwitchingHouse_Door_Open_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.SwitchingHouse_Door_Open_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房送风机启动事件上传图片使能" prop="EngineRoom_FeedFan_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房排风机启动事件上传图片使能" prop="EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一路进线分闸事件上传图片使能" prop="IncomingCabinet_1_Opening_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_1_Opening_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二路进线分闸事件上传图片使能" prop="IncomingCabinet_2_Opening_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_2_Opening_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜市电分闸事件上传图片使能" prop="Group_1_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_1_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜发电合闸事件上传图片使能" prop="Group_1_GenSwitch_Closing_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_1_GenSwitch_Closing_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜市电分闸事件上传图片使能" prop="Group_2_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_2_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜发电合闸事件上传图片使能" prop="Group_2_GenSwitch_Closing_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_2_GenSwitch_Closing_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组补偿电容运行事件上传图片使能" prop="Group_1_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_1_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组补偿电容运行事件上传图片使能" prop="Group_2_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_2_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第三组补偿电容运行事件上传图片使能" prop="Group_3_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_3_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第四组补偿电容运行事件上传图片使能" prop="Group_4_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_4_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组补偿柜电容分闸事件上传图片使能" prop="CompensationCabinetCapacity_1_Opening_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetCapacity_1_Opening_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组补偿柜电容分闸事件上传图片使能" prop="CompensationCabinetCapacity_2_Opening_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetCapacity_2_Opening_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第三组补偿柜电容分闸事件上传图片使能" prop="CompensationCabinetCapacity_3_Opening_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetCapacity_3_Opening_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第四组补偿柜电容分闸事件上传图片使能" prop="CompensationCabinetCapacity_4_Opening_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetCapacity_4_Opening_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="联络柜开关合闸事件上传图片使能" prop="ContactCabinetSwitch_Closing_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.ContactCabinetSwitch_Closing_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="防潮灯开启事件上传图片使能" prop="MoistureProofLamps_On_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.MoistureProofLamps_On_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器降温风扇启动事件上传图片使能" prop="Group_1_Transformer_Fan_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_1_Transformer_Fan_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器降温风扇启动事件上传图片使能" prop="Group_2_Transformer_Fan_Start_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_2_Transformer_Fan_Start_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器负载率过低事件上传图片使能" prop="Group_1_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_1_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器负载率过低事件上传图片使能" prop="Group_2_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_2_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">事件录像使能参数</h1>
      <a-form-model-item label="摄氏温度过高事件上传录像使能" prop="Celsius_Temperature_Too_High_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="摄氏温度过低事件上传录像使能" prop="Celsius_Temperature_Too_Low_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过高事件上传录像使能" prop="Relative_Humidity_Too_High_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_Too_High_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="相对湿度过低事件上传录像使能" prop="Relative_Humidity_Too_Low_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能" :disabled="true">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房有人事件上传录像使能" prop="SwitchingHouse_Person_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.SwitchingHouse_Person_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房门打开事件上传录像使能" prop="SwitchingHouse_Door_Open_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.SwitchingHouse_Door_Open_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房送风机启动事件上传录像使能" prop="EngineRoom_FeedFan_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房排风机启动事件上传录像使能" prop="EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一路进线分闸事件上传录像使能" prop="IncomingCabinet_1_Opening_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_1_Opening_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二路进线分闸事件上传录像使能" prop="IncomingCabinet_2_Opening_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_2_Opening_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜市电分闸事件上传录像使能" prop="Group_1_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_1_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜发电合闸事件上传录像使能" prop="Group_1_GenSwitch_Closing_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_1_GenSwitch_Closing_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜市电分闸事件上传录像使能" prop="Group_2_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_2_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜发电合闸事件上传录像使能" prop="Group_2_GenSwitch_Closing_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_2_GenSwitch_Closing_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组补偿电容运行事件上传录像使能" prop="Group_1_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_1_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组补偿电容运行事件上传录像使能" prop="Group_2_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_2_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第三组补偿电容运行事件上传录像使能" prop="Group_3_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_3_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第四组补偿电容运行事件上传录像使能" prop="Group_4_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_4_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组补偿柜电容分闸事件上传录像使能" prop="CompensationCabinetCapacity_1_Opening_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetCapacity_1_Opening_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组补偿柜电容分闸事件上传录像使能" prop="CompensationCabinetCapacity_2_Opening_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetCapacity_2_Opening_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第三组补偿柜电容分闸事件上传录像使能" prop="CompensationCabinetCapacity_3_Opening_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetCapacity_3_Opening_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第四组补偿柜电容分闸事件上传录像使能" prop="CompensationCabinetCapacity_4_Opening_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetCapacity_4_Opening_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="联络柜开关合闸事件上传录像使能" prop="ContactCabinetSwitch_Closing_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.ContactCabinetSwitch_Closing_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="防潮灯开启事件上传录像使能" prop="MoistureProofLamps_On_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.MoistureProofLamps_On_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器降温风扇启动事件上传录像使能" prop="Group_1_Transformer_Fan_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_1_Transformer_Fan_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器降温风扇启动事件上传录像使能" prop="Group_2_Transformer_Fan_Start_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_2_Transformer_Fan_Start_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器负载率过低事件上传录像使能" prop="Group_1_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_1_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器负载率过低事件上传录像使能" prop="Group_2_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_2_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">报警图片使能参数</h1>
      <a-form-model-item label="两路进线柜同时分闸报警上传图片使能" prop="IncomingCabinetSwitch_AllOpening_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.IncomingCabinetSwitch_AllOpening_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一路进线柜电压过低报警上传图片使能" prop="IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二路进线柜电压过低报警上传图片使能" prop="IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一路进线柜电压过高报警上传图片使能" prop="IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二路进线柜电压过高报警上传图片使能" prop="IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="两路进线柜同时分闸联络柜合闸报警上传图片使能" prop="ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜同时合闸报警上传图片使能" prop="ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜同时合闸报警上传图片使能" prop="ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜同时分闸报警上传图片使能" prop="ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜同时分闸报警上传图片使能" prop="ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器功率因数过低报警上传图片使能" prop="Transformer_1_Cos_TooLow_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Transformer_1_Cos_TooLow_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器功率因数过低报警上传图片使能" prop="Transformer_2_Cos_TooLow_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Transformer_2_Cos_TooLow_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组补偿柜分闸报警上传图片使能" prop="CompensationCabinetSwitch_1_Opening_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetSwitch_1_Opening_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组补偿柜分闸报警上传图片使能" prop="CompensationCabinetSwitch_2_Opening_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetSwitch_2_Opening_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第三组补偿柜分闸报警上传图片使能" prop="CompensationCabinetSwitch_3_Opening_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetSwitch_3_Opening_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第四组补偿柜分闸报警上传图片使能" prop="CompensationCabinetSwitch_4_Opening_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetSwitch_4_Opening_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="功率因数过低第一组补偿电容停止运行报警上传图片使能" prop="Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="功率因数过低第二组补偿电容停止运行报警上传图片使能" prop="Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="功率因数过低第三组补偿电容停止运行报警上传图片使能" prop="Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="功率因数过低第四组补偿电容停止运行报警上传图片使能" prop="Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组馈电回路A相电流过大报警上传图片使能" prop="Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组馈电回路A相电流过大报警上传图片使能" prop="Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组馈电回路B相电流过大报警上传图片使能" prop="Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组馈电回路B相电流过大报警上传图片使能" prop="Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组馈电回路C相电流过大报警上传图片使能" prop="Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组馈电回路C相电流过大报警上传图片使能" prop="Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器超温报警上传图片使能" prop="Transformer_1_TempHigh_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Transformer_1_TempHigh_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器超温报警上传图片使能" prop="Transformer_2_TempHigh_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Transformer_2_TempHigh_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器超温降温风扇未启动报警上传图片使能" prop="Transformer_1_TempHigh_FanStop_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Transformer_1_TempHigh_FanStop_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器超温降温风扇未启动报警上传图片使能" prop="Transformer_2_TempHigh_FanStop_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Transformer_2_TempHigh_FanStop_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房温度过高送风机停止事件上传图片使能" prop="SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房温度过高排风机停止事件上传图片使能" prop="SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房湿度过高防潮灯关闭报警上传图片使能" prop="Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="拨打值班室电话事件上传图片使能" prop="Voip_Alarm_Upload_Image_Enable">
        <a-select v-model="iotConfigs.Voip_Alarm_Upload_Image_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">报警录像使能参数</h1>
      <a-form-model-item label="两路进线柜同时分闸报警上传录像使能" prop="IncomingCabinetSwitch_AllOpening_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.IncomingCabinetSwitch_AllOpening_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一路进线柜电压过低报警上传录像使能" prop="IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二路进线柜电压过低报警上传录像使能" prop="IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一路进线柜电压过高报警上传录像使能" prop="IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二路进线柜电压过高报警上传录像使能" prop="IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="两路进线柜同时分闸联络柜合闸报警上传录像使能" prop="ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜同时合闸报警上传录像使能" prop="ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜同时合闸报警上传录像使能" prop="ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜同时分闸报警上传录像使能" prop="ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜同时分闸报警上传录像使能" prop="ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器功率因数过低报警上传录像使能" prop="Transformer_1_Cos_TooLow_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Transformer_1_Cos_TooLow_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器功率因数过低报警上传录像使能" prop="Transformer_2_Cos_TooLow_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Transformer_2_Cos_TooLow_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组补偿柜分闸报警上传录像使能" prop="CompensationCabinetSwitch_1_Opening_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetSwitch_1_Opening_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组补偿柜分闸报警上传录像使能" prop="CompensationCabinetSwitch_2_Opening_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetSwitch_2_Opening_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第三组补偿柜分闸报警上传录像使能" prop="CompensationCabinetSwitch_3_Opening_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetSwitch_3_Opening_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第四组补偿柜分闸报警上传录像使能" prop="CompensationCabinetSwitch_4_Opening_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.CompensationCabinetSwitch_4_Opening_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="功率因数过低第一组补偿电容停止运行报警上传录像使能" prop="Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="功率因数过低第二组补偿电容停止运行报警上传录像使能" prop="Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="功率因数过低第三组补偿电容停止运行报警上传录像使能" prop="Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="功率因数过低第四组补偿电容停止运行报警上传录像使能" prop="Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组馈电回路A相电流过大报警上传录像使能" prop="Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组馈电回路A相电流过大报警上传录像使能" prop="Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组馈电回路B相电流过大报警上传录像使能" prop="Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组馈电回路B相电流过大报警上传录像使能" prop="Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组馈电回路C相电流过大报警上传录像使能" prop="Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组馈电回路C相电流过大报警上传录像使能" prop="Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器超温报警上传录像使能" prop="Transformer_1_TempHigh_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Transformer_1_TempHigh_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器超温报警上传录像使能" prop="Transformer_2_TempHigh_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Transformer_2_TempHigh_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器超温降温风扇未启动报警上传录像使能" prop="Transformer_1_TempHigh_FanStop_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Transformer_1_TempHigh_FanStop_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器超温降温风扇未启动报警上传录像使能" prop="Transformer_2_TempHigh_FanStop_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Transformer_2_TempHigh_FanStop_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房温度过高送风机停止事件上传录像使能" prop="SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房温度过高排风机停止事件上传录像使能" prop="SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房湿度过高防潮灯关闭报警上传录像使能" prop="Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="拨打值班室电话事件上传录像使能" prop="Voip_Alarm_Upload_Video_Enable">
        <a-select v-model="iotConfigs.Voip_Alarm_Upload_Video_Enable" placeholder="1为使能，0为禁止，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <a-form-model v-if="electricitySystemIotFlag.iotFlag==1" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:14}" :wrapper-col="{span:10}">
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">GPIO绑定事件</h1>
      <a-button style="margin-left: 10%" type="primary" @click="setDefault('UK01')">UK01默认参数</a-button>
      <a-button style="margin-left: 5%" type="primary" @click="setDefault('UK_16IOTo485_V01')">UK_16IOTo485_V01默认参数</a-button>
      <a-divider></a-divider>
      <a-form-model-item label="机房门打开信号绑定IO端口" prop="SwitchingHouse_Door_Open_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.SwitchingHouse_Door_Open_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.SwitchingHouse_Door_Open_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效"  style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组补偿电容运行信号绑定IO端口" prop="Group_1_CompensationCabinetCapacity_Run_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_1_CompensationCabinetCapacity_Run_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_1_CompensationCabinetCapacity_Run_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组补偿电容运行信号绑定IO端口" prop="Group_2_CompensationCabinetCapacity_Run_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_2_CompensationCabinetCapacity_Run_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_2_CompensationCabinetCapacity_Run_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第三组补偿电容运行信号绑定IO端口" prop="Group_3_CompensationCabinetCapacity_Run_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_3_CompensationCabinetCapacity_Run_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_3_CompensationCabinetCapacity_Run_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第四组补偿电容运行信号绑定IO端口" prop="Group_4_CompensationCabinetCapacity_Run_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_4_CompensationCabinetCapacity_Run_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_4_CompensationCabinetCapacity_Run_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器温度过高信号绑定IO端口" prop="Group_1_Transformer_Temp_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_1_Transformer_Temp_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_1_Transformer_Temp_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组变压器降温风扇启动信号绑定IO端口" prop="Group_1_Transformer_Fan_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_1_Transformer_Fan_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_1_Transformer_Fan_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器温度过高信号绑定IO端口" prop="Group_2_Transformer_Temp_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_2_Transformer_Temp_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_2_Transformer_Temp_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组变压器降温风扇启动信号绑定IO端口" prop="Group_2_Transformer_Fan_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_2_Transformer_Fan_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_2_Transformer_Fan_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="拨打值班室电话信号绑定IO端口" prop="Voip_Alarm_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Voip_Alarm_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Voip_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一路进线分闸信号绑定IO端口" prop="IncomingCabinet_1_Switching_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.IncomingCabinet_1_Switching_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.IncomingCabinet_1_Switching_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二路进线分闸信号绑定IO端口" prop="IncomingCabinet_2_Switching_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.IncomingCabinet_2_Switching_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.IncomingCabinet_2_Switching_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="联络柜合闸信号绑定IO端口" prop="ContactCabinetSwitch_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.ContactCabinetSwitch_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.ContactCabinetSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜市电分闸信号绑定IO端口" prop="Group_1_ElectricSupplySwitch_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_1_ElectricSupplySwitch_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_1_ElectricSupplySwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组市/发电柜发电合闸信号绑定IO端口" prop="Group_1_GenSwitch_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_1_GenSwitch_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_1_GenSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜市电分闸信号绑定IO端口" prop="Group_2_ElectricSupplySwitch_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_2_ElectricSupplySwitch_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_2_ElectricSupplySwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组市/发电柜发电合闸信号绑定IO端口" prop="Group_2_GenSwitch_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_2_GenSwitch_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_2_GenSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第一组补偿柜分闸信号绑定IO端口" prop="Group_1_CompensationCabinetSwitch_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_1_CompensationCabinetSwitch_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_1_CompensationCabinetSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第二组补偿柜分闸信号绑定IO端口" prop="Group_2_CompensationCabinetSwitch_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_2_CompensationCabinetSwitch_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_2_CompensationCabinetSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第三组补偿柜分闸信号绑定IO端口" prop="Group_3_CompensationCabinetSwitch_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_3_CompensationCabinetSwitch_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_3_CompensationCabinetSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第四组补偿柜分闸信号绑定IO端口" prop="Group_4_CompensationCabinetSwitch_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.Group_4_CompensationCabinetSwitch_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.Group_4_CompensationCabinetSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="防潮灯开启信号绑定IO端口" prop="MoistureProofLamps_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.MoistureProofLamps_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.MoistureProofLamps_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房送风机启动信号绑定IO端口" prop="SwitchingHouse_FeedFan_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.SwitchingHouse_FeedFan_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.SwitchingHouse_FeedFan_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="机房排风机启动信号绑定IO端口" prop="SwitchingHouse_ExhaustFan_Start_Bind_Port">
        <a-select show-search :filter-option="filterOption" v-model="iotConfigs.SwitchingHouse_ExhaustFan_Start_Bind_Port" placeholder="" style="width: 50%">
          <a-select-option v-for="(item, index) in GPIOList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
        </a-select>
        <a-select v-model="iotConfigs.SwitchingHouse_ExhaustFan_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效" style="width: 50%">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">事件有效电平配置</h1>-->
<!--      <span style="color: red;font-weight: bold;margin-left: 16%">注：下列参数仅限电流互感器穿孔电流为直流的情况使用</span>-->
<!--      <a-form-model-item></a-form-model-item>-->
<!--      <a-form-model-item label="机房门打开信号有效电平" prop="SwitchingHouse_Door_Open_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.SwitchingHouse_Door_Open_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第一组变压器温度过高信号有效电平" prop="Group_1_Transformer_Temp_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_1_Transformer_Temp_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第一组变压器降温风扇启动信号有效电平" prop="Group_1_Transformer_Fan_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_1_Transformer_Fan_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第二组变压器温度过高信号有效电平" prop="Group_2_Transformer_Temp_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_2_Transformer_Temp_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第二组变压器降温风扇启动信号有效电平" prop="Group_2_Transformer_Fan_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_2_Transformer_Fan_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="拨打值班室电话信号有效电平" prop="Voip_Alarm_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Voip_Alarm_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第一路进线分闸信号有效电平" prop="IncomingCabinet_1_Switching_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.IncomingCabinet_1_Switching_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第二路进线分闸信号有效电平" prop="IncomingCabinet_2_Switching_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.IncomingCabinet_2_Switching_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="联络柜合闸信号有效电平" prop="ContactCabinetSwitch_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.ContactCabinetSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第一组市/发电柜市电分闸信号有效电平" prop="Group_1_ElectricSupplySwitch_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_1_ElectricSupplySwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第一组市/发电柜发电合闸信号有效电平" prop="Group_1_GenSwitch_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_1_GenSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第二组市/发电柜市电分闸信号有效电平" prop="Group_2_ElectricSupplySwitch_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_2_ElectricSupplySwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第二组市/发电柜发电合闸信号有效电平" prop="Group_2_GenSwitch_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_2_GenSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第一组补偿柜分闸信号有效电平" prop="Group_1_CompensationCabinetSwitch_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_1_CompensationCabinetSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第一组补偿电容运行信号有效电平" prop="Group_1_CompensationCabinetCapacity_Run_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_1_CompensationCabinetCapacity_Run_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第二组补偿柜分闸信号有效电平" prop="Group_2_CompensationCabinetSwitch_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_2_CompensationCabinetSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第二组补偿电容运行信号有效电平" prop="Group_2_CompensationCabinetCapacity_Run_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_2_CompensationCabinetCapacity_Run_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第三组补偿柜分闸信号有效电平" prop="Group_3_CompensationCabinetSwitch_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_3_CompensationCabinetSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第三组补偿电容运行信号有效电平" prop="Group_3_CompensationCabinetCapacity_Run_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_3_CompensationCabinetCapacity_Run_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第四组补偿柜分闸信号有效电平" prop="Group_4_CompensationCabinetSwitch_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_4_CompensationCabinetSwitch_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="第四组补偿电容运行信号有效电平" prop="Group_4_CompensationCabinetCapacity_Run_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.Group_4_CompensationCabinetCapacity_Run_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认低电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="防潮灯开启信号有效电平" prop="MoistureProofLamps_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.MoistureProofLamps_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="机房送风机启动信号有效电平" prop="SwitchingHouse_FeedFan_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.SwitchingHouse_FeedFan_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="机房排风机启动信号有效电平" prop="SwitchingHouse_ExhaustFan_Start_Gpio_State">-->
<!--        <a-select v-model="iotConfigs.SwitchingHouse_ExhaustFan_Start_Gpio_State" placeholder="1为高电平有效，0为低电平有效，默认高电平有效">-->
<!--          <a-select-option :value="1">高电平</a-select-option>-->
<!--          <a-select-option :value="0">低电平</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
    </a-form-model>
  </div>
</template>
<script>
import {
  addParamsBatchLog, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList, modifyParamsBatchLog,
  setDeviceparamValueList
} from "A/ai";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "iot",
  props:{
    electricitySystemIotFlag:{
      default: null
    }
  },
  data() {
    return {
      iotConfigs:{
        //IO状态判断时间间隔,默认1秒
        IO_Calculation_Interval:'',
        //IO状态采样时间间隔，默认15ms
        IO_Sampling_Interval:'',
        //时间窗内IO有效数值占比，默认0.0200,保留4位小数
        IO_State_Effective_Threshold:'',
        //温度过高阈值，默认45摄氏度
        Celsius_Temperature_Max_Threshold:'',
        //温度过低阈值，默认0摄氏度
        Celsius_Temperature_Min_Threshold:'',
        //相对湿度过高阈值，默认90%
        Relative_Humidity_Max_Threshold:'',
        //相对湿度过低阈值，默认10%
        Relative_Humidity_Min_Threshold:'',
        //温度队列长度，默认3个
        Celsius_Temperature_State_List_Max:'',
        //温度队列阈值，默认2个
        Celsius_Temperature_State_List_Threshold:'',
        //湿度队列长度，默认3个
        Relative_Humidity_State_List_Max:'',
        //湿度队列阈值，默认2个
        Relative_Humidity_State_List_Threshold:'',
        //人体检测队列长度，默认3个
        Person_Detect_List_Max:'',
        //人体检测队列阈值，默认2个
        Person_Detect_List_Threshold:'',
        //进线电压最小阈值
        IncomingCabinet_Minimum_Voltage_Threshold:'',
        //进线电压最大阈值
        IncomingCabinet_Maximum_Voltage_Threshold:'',
        //变压器功率因数最小阈值
        Transformer_Cos_Minimum_Threshold:'',
        //馈电回路电流最大阈值
        FeederCircuit_Maximum_Current_Threshold:'',
        //变压器负载率最小阈值
        Transformer_LoadRate_Minimum_Threshold:'',
        //变压器超温后风扇最迟启动时间阈值
        Transformer_Overheat_Fan_Run_Time_Threshold:'',
        //变压器额定功率，默认100KVA
        Transformer_Rated_Power:'',
        //第一组变压器功率因数最小阈值，默认0.85
        Transformer_1_Cos_Minimum_Threshold:'',
        //第二组变压器功率因数最小阈值，默认0.85
        Transformer_2_Cos_Minimum_Threshold:'',
        //第一组馈电回路电流最大阈值，默认200A
        FeederCircuit_1_Maximum_Current_Threshold:'',
        //第二组馈电回路电流最大阈值，默认200A
        FeederCircuit_2_Maximum_Current_Threshold:'',
        //摄氏温度过高事件使能，1为使能，0为禁止，默认使能
        Celsius_Temperature_Too_High_Event_Enable:'',
        //摄氏温度过低事件使能，1为使能，0为禁止，默认使能
        Celsius_Temperature_Too_Low_Event_Enable:'',
        //相对湿度过高事件使能，1为使能，0为禁止，默认使能
        Relative_Humidity_Too_High_Event_Enable:'',
        //相对湿度过低事件使能，1为使能，0为禁止，默认使能
        Relative_Humidity_Too_Low_Event_Enable:'',
        //机房有人事件使能，1为使能，0为禁止，默认使能
        SwitchingHouse_Person_Event_Enable:'',
        //机房门打开事件使能，1为使能，0为禁止，默认使能
        SwitchingHouse_Door_Open_Event_Enable:'',
        //机房送风机启动事件使能，1为使能，0为禁止，默认使能
        EngineRoom_FeedFan_Start_Event_Enable:'',
        //机房排风机启动事件使能，1为使能，0为禁止，默认使能
        EngineRoom_ExhaustFan_Start_Event_Enable:'',
        //第一路进线分闸事件使能
        IncomingCabinet_1_Opening_Event_Enable:'',
        //第二路进线分闸事件使能
        IncomingCabinet_2_Opening_Event_Enable:'',
        //联络柜开关合闸事件使能
        ContactCabinetSwitch_Closing_Event_Enable:'',
        //第一组市/发电柜市电分闸事件使能
        Group_1_ElectricSupplySwitch_Opening_Event_Enable:'',
        //第一组市/发电柜发电分闸事件使能
        Group_1_GenSwitch_Closing_Event_Enable:'',
        //第二组市/发电柜市电分闸事件使能
        Group_2_ElectricSupplySwitch_Opening_Event_Enable:'',
        //第二组市/发电柜发电分闸事件使能
        Group_2_GenSwitch_Closing_Event_Enable:'',
        //第一组补偿电容运行事件使能
        Group_1_CompensationCabinetCapacity_Run_Event_Enable:'',
        //第一组补偿柜电容分闸事件使能
        CompensationCabinetCapacity_1_Opening_Event_Enable:'',
        //第二组补偿电容运行事件使能
        Group_2_CompensationCabinetCapacity_Run_Event_Enable:'',
        //第二组补偿柜电容分闸事件使能
        CompensationCabinetCapacity_2_Opening_Event_Enable:'',
        //第三组补偿电容运行事件使能
        Group_3_CompensationCabinetCapacity_Run_Event_Enable:'',
        //第三组补偿柜电容分闸事件使能
        CompensationCabinetCapacity_3_Opening_Event_Enable:'',
        //第四组补偿电容运行事件使能
        Group_4_CompensationCabinetCapacity_Run_Event_Enable:'',
        //第四组补偿柜电容分闸事件使能
        CompensationCabinetCapacity_4_Opening_Event_Enable:'',
        //防潮灯开启事件使能
        MoistureProofLamps_On_Event_Enable:'',
        //第一组变压器降温风扇启动事件使能
        Group_1_Transformer_Fan_Start_Event_Enable:'',
        //第二组变压器降温风扇启动事件使能
        Group_2_Transformer_Fan_Start_Event_Enable:'',
        //第一组变压器负载率过低事件使能
        Group_1_Transformer_LoadRate_TooLow_Event_Enable:'',
        //第二组变压器负载率过低事件使能
        Group_2_Transformer_LoadRate_TooLow_Event_Enable:'',
        //拨打值班室电话使能
        Voip_Alarm_Enable:'',
        //两路进线柜同时分闸报警使能
        IncomingCabinetSwitch_AllOpening_Alarm_Enable:'',
        //第一路进线柜电压过低报警使能
        IncomingCabinet_1_Voltage_TooLow_Alarm_Enable:'',
        //第二路进线柜电压过低报警使能
        IncomingCabinet_2_Voltage_TooLow_Alarm_Enable:'',
        //第一路进线柜电压过高报警使能
        IncomingCabinet_1_Voltage_TooHigh_Alarm_Enable:'',
        //第二路进线柜电压过高报警使能
        IncomingCabinet_2_Voltage_TooHigh_Alarm_Enable:'',
        //两路进线柜同时合闸联络柜合闸报警使能
        ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Enable:'',
        //第一组市/发电柜同时合闸报警使能
        ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Enable:'',
        //第二组市/发电柜同时合闸报警使能
        ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Enable:'',
        //第一组市/发电柜同时分闸报警使能
        ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Enable:'',
        //第一组市/发电柜同时分闸报警使能
        ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Enable:'',
        //第一组变压器功率因数过低报警使能
        Transformer_1_Cos_TooLow_Alarm_Enable:'',
        //第二组变压器功率因数过低报警使能
        Transformer_2_Cos_TooLow_Alarm_Enable:'',
        //第一组补偿柜分闸报警使能
        CompensationCabinetSwitch_1_Opening_Alarm_Enable:'',
        //第二组补偿柜分闸报警使能
        CompensationCabinetSwitch_2_Opening_Alarm_Enable:'',
        //第三组补偿柜分闸报警使能
        CompensationCabinetSwitch_3_Opening_Alarm_Enable:'',
        //第四组补偿柜分闸报警使能
        CompensationCabinetSwitch_4_Opening_Alarm_Enable:'',
        //功率因数过低第一组补偿电容停止运行报警使能
        Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable:'',
        //功率因数过低第二组补偿电容停止运行报警使能
        Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable:'',
        //功率因数过低第三组补偿电容停止运行报警使能
        Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable:'',
        //功率因数过低第四组补偿电容停止运行报警使能
        Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable:'',
        //第一组馈电回路A相电流过大报警使能
        Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Enable:'',
        //第一组馈电回路B相电流过大报警使能
        Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Enable:'',
        //第一组馈电回路C相电流过大报警使能
        Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Enable:'',
        //第二组馈电回路A相电流过大报警使能
        Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Enable:'',
        //第二组馈电回路B相电流过大报警使能
        Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Enable:'',
        //第二组馈电回路C相电流过大报警使能
        Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Enable:'',
        //机房温度过高送风机停止报警上传录像使能
        SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Enable:'',
        //机房温度过高排风机停止报警上传录像使能
        SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Enable:'',
        //机房湿度过高防潮灯关闭报警使能
        Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Enable:'',
        //第一组变压器超温报警使能
        Transformer_1_TempHigh_Alarm_Enable:'',
        //第二组变压器超温报警使能
        Transformer_2_TempHigh_Alarm_Enable:'',
        //第一组变压器超温降温风扇未启动报警使能
        Transformer_1_TempHigh_FanStop_Alarm_Enable:'',
        //第二组变压器超温降温风扇未启动报警使能
        Transformer_2_TempHigh_FanStop_Alarm_Enable:'',
        
        
    //摄氏温度过高事件上传图片使能，1为上传图片使能，0为禁止，默认0
    Celsius_Temperature_Too_High_Event_Upload_Image_Enable:'',
    //摄氏温度过低事件上传图片使能，1为上传图片使能，0为禁止，默认0
    Celsius_Temperature_Too_Low_Event_Upload_Image_Enable:'',
    //相对湿度过高事件上传图片使能，1为上传图片使能，0为禁止，默认0
    Relative_Humidity_Too_High_Event_Upload_Image_Enable:'',
    //相对湿度过低事件上传图片使能，1为上传图片使能，0为禁止，默认0
    Relative_Humidity_Too_Low_Event_Upload_Image_Enable:'',
    //机房有人事件上传图片使能，1为上传图片使能，0为禁止，默认1
    SwitchingHouse_Person_Event_Upload_Image_Enable:'',
    //机房门打开事件上传图片使能，1为上传图片使能，0为禁止，默认1
    SwitchingHouse_Door_Open_Event_Upload_Image_Enable:'',
    //第一路进线分闸事件上传图片使能，1为使能，0为禁止，默认0
    IncomingCabinet_1_Opening_Event_Upload_Image_Enable:'',
    //第二路进线分闸事件上传图片使能，1为使能，0为禁止，默认0
    IncomingCabinet_2_Opening_Event_Upload_Image_Enable:'',
    //联络柜开关合闸事件上传图片使能，1为使能，0为禁止，默认0
    ContactCabinetSwitch_Closing_Event_Upload_Image_Enable:'',
    //第一组市/发电柜市电分闸事件上传图片使能，1为使能，0为禁止，默认0
    Group_1_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable:'',
    ///第一组市/发电柜发电合闸事件上传图片使能，1为使能，0为禁止，默认0
    Group_1_GenSwitch_Closing_Event_Upload_Image_Enable:'',
    //第二组市/发电柜市电分闸事件上传图片使能，1为使能，0为禁止，默认0
    Group_2_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable:'',
    ///第二组市/发电柜发电合闸事件上传图片使能，1为使能，0为禁止，默认0
    Group_2_GenSwitch_Closing_Event_Upload_Image_Enable:'',
    //第一组补偿电容运行事件上传图片使能，1为使能，0为禁止，默认0
    Group_1_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable:'',
    //第二组补偿电容运行事件上传图片使能，1为使能，0为禁止，默认0
    Group_2_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable:'',
    //第三组补偿电容运行事件上传图片使能，1为使能，0为禁止，默认0
    Group_3_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable:'',
    //第四组补偿电容运行事件上传图片使能，1为使能，0为禁止，默认0
    Group_4_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable:'',
    ///第一组补偿柜电容分闸事件上传图片使能，1为使能，0为禁止，默认0
    CompensationCabinetCapacity_1_Opening_Event_Upload_Image_Enable:'',
    ///第二组补偿柜电容分闸事件上传图片使能，1为使能，0为禁止，默认0
    CompensationCabinetCapacity_2_Opening_Event_Upload_Image_Enable:'',
    ///第三组补偿柜电容分闸事件上传图片使能，1为使能，0为禁止，默认0
    CompensationCabinetCapacity_3_Opening_Event_Upload_Image_Enable:'',
    ///第四组补偿柜电容分闸事件上传图片使能，1为使能，0为禁止，默认0
    CompensationCabinetCapacity_4_Opening_Event_Upload_Image_Enable:'',
    ///机房送风机启动事件上传图片使能，1为使能，0为禁止，默认0
    EngineRoom_FeedFan_Start_Event_Upload_Image_Enable:'',
    ///机房排风机启动事件上传图片使能，1为使能，0为禁止，默认0
    EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable:'',
    //防潮灯开启事件上传图片使能，1为使能，0为禁止，默认1
    MoistureProofLamps_On_Event_Upload_Image_Enable:'',
    //第一组变压器降温风扇启动事件上传图片使能，1为使能，0为禁止，默认0
    Group_1_Transformer_Fan_Start_Event_Upload_Image_Enable:'',
    //第二组变压器降温风扇启动事件上传图片使能，1为使能，0为禁止，默认0
    Group_2_Transformer_Fan_Start_Event_Upload_Image_Enable:'',
    //第一组变压器负载率过低事件上传图片使能，1为使能，0为禁止，默认0
    Group_1_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable:'',
    //第二组变压器负载率过低事件上传图片使能，1为使能，0为禁止，默认0
    Group_2_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable:'',
    //拨打值班室电话事件上传图片使能，1为上传图片使能，0为禁止，默认1
    Voip_Alarm_Upload_Image_Enable:'',
    //两路进线柜同时分闸报警上传图片使能，1为使能，0为禁止，默认0
    IncomingCabinetSwitch_AllOpening_Alarm_Upload_Image_Enable:'',
    ///第一路进线柜电压过低报警上传图片使能，1为使能，0为禁止，默认0
    IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Image_Enable:'',
    ///第二路进线柜电压过低报警上传图片使能，1为使能，0为禁止，默认0
    IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Image_Enable:'',
    //第一路进线柜电压过高报警上传图片使能，1为使能，0为禁止，默认0
    IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Image_Enable:'',
    //第二路进线柜电压过高报警上传图片使能，1为使能，0为禁止，默认0
    IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Image_Enable:'',
    ///两路进线柜同时合闸联络柜合闸报警上传图片使能，1为使能，0为禁止，默认0
    ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Image_Enable:'',
    //第一组市/发电柜同时合闸报警上传图片使能，1为使能，0为禁止，默认0
    ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Image_Enable:'',
    //第二组市/发电柜同时合闸报警上传图片使能，1为使能，0为禁止，默认0
    ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Image_Enable:'',
    //第一组市/发电柜同时分闸报警上传图片使能，1为使能，0为禁止，默认0
    ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Image_Enable:'',
    //第二组市/发电柜同时分闸报警上传图片使能，1为使能，0为禁止，默认0
    ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Image_Enable:'',
    ///第一组功率因数过低报警上传图片使能，1为使能，0为禁止，默认0
    Transformer_1_Cos_TooLow_Alarm_Upload_Image_Enable:'',
    ///第二组功率因数过低报警上传图片使能，1为使能，0为禁止，默认0
    Transformer_2_Cos_TooLow_Alarm_Upload_Image_Enable:'',
    //第一组补偿柜分闸报警上传图片使能，1为使能，0为禁止，默认0
    CompensationCabinetSwitch_1_Opening_Alarm_Upload_Image_Enable:'',
    //第二组补偿柜分闸报警上传图片使能，1为使能，0为禁止，默认0
    CompensationCabinetSwitch_2_Opening_Alarm_Upload_Image_Enable:'',
    //第三组补偿柜分闸报警上传图片使能，1为使能，0为禁止，默认0
    CompensationCabinetSwitch_3_Opening_Alarm_Upload_Image_Enable:'',
    //第四组补偿柜分闸报警上传图片使能，1为使能，0为禁止，默认0
    CompensationCabinetSwitch_4_Opening_Alarm_Upload_Image_Enable:'',
    ///功率因数过低第一组补偿电容停止运行报警上传图片使能，1为使能，0为禁止，默认0
    Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable:'',
    ///功率因数过低第二组补偿电容停止运行报警上传图片使能，1为使能，0为禁止，默认0
    Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable:'',
    ///功率因数过低第三组补偿电容停止运行报警上传图片使能，1为使能，0为禁止，默认0
    Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable:'',
    ///功率因数过低第四组补偿电容停止运行报警上传图片使能，1为使能，0为禁止，默认0
    Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable:'',
    ///第一组馈电回路A相电流过大报警上传图片使能，1为使能，0为禁止，默认0
    Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable:'',
    ///第一组馈电回路B相电流过大报警上传图片使能，1为使能，0为禁止，默认0
    Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable:'',
    ///第一组馈电回路C相电流过大报警上传图片使能，1为使能，0为禁止，默认0
    Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable:'',
    ///第二组馈电回路A相电流过大报警上传图片使能，1为使能，0为禁止，默认0
    Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable:'',
    ///第二组馈电回路B相电流过大报警上传图片使能，1为使能，0为禁止，默认0
    Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable:'',
    ///第二组馈电回路C相电流过大报警上传图片使能，1为使能，0为禁止，默认0
    Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable:'',
    ///机房温度过高送风机停止报警上传图片使能，1为使能，0为禁止，默认0
    SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Image_Enable:'',
    ///机房温度过高排风机停止报警上传图片使能，1为使能，0为禁止，默认0
    SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Image_Enable:'',
    ///机房湿度过高防潮灯关闭报警上传图片使能，1为使能，0为禁止，默认0
    Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Image_Enable:'',
    //第一组变压器超温报警上传图片使能，1为使能，0为禁止，默认1
    Transformer_1_TempHigh_Alarm_Upload_Image_Enable:'',
    //第二组变压器超温报警上传图片使能，1为使能，0为禁止，默认1
    Transformer_2_TempHigh_Alarm_Upload_Image_Enable:'',
    //第一组变压器超温降温风扇未启动报警上传图片使能，1为使能，0为禁止，默认0
    Transformer_1_TempHigh_FanStop_Alarm_Upload_Image_Enable:'',
    //第二组变压器超温降温风扇未启动报警上传图片使能，1为使能，0为禁止，默认0
    Transformer_2_TempHigh_FanStop_Alarm_Upload_Image_Enable:'',


        //摄氏温度过高事件上传录像使能，1为上传录像使能，0为禁止，默认1
        Celsius_Temperature_Too_High_Event_Upload_Video_Enable:'',
        //摄氏温度过低事件上传录像使能，1为上传录像使能，0为禁止，默认1
        Celsius_Temperature_Too_Low_Event_Upload_Video_Enable:'',
        //相对湿度过高事件上传录像使能，1为上传录像使能，0为禁止，默认1
        Relative_Humidity_Too_High_Event_Upload_Video_Enable:'',
        //相对湿度过低事件上传录像使能，1为上传录像使能，0为禁止，默认1
        Relative_Humidity_Too_Low_Event_Upload_Video_Enable:'',
        //机房有人事件上传录像使能，1为上传录像使能，0为禁止，默认1
        SwitchingHouse_Person_Event_Upload_Video_Enable:'',
        //机房门打开事件上传录像使能，1为上传录像使能，0为禁止，默认1
        SwitchingHouse_Door_Open_Event_Upload_Video_Enable:'',
        //第一路进线分闸事件上传录像使能
        IncomingCabinet_1_Opening_Event_Upload_Video_Enable:'',
        //第二路进线分闸事件上传录像使能
        IncomingCabinet_2_Opening_Event_Upload_Video_Enable:'',
        //第一组变压器功率因数过低报警上传录像使能
        Transformer_1_Cos_TooLow_Alarm_Upload_Video_Enable:'',
        //第二组变压器功率因数过低报警上传录像使能
        Transformer_2_Cos_TooLow_Alarm_Upload_Video_Enable:'',
        //联络柜开关合闸事件上传录像使能
        ContactCabinetSwitch_Closing_Event_Upload_Video_Enable:'',
        //第一组市/发电柜市电分闸事件上传录像使能
        Group_1_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable:'',
        //第一组市/发电柜发电合闸事件上传录像使能
        Group_1_GenSwitch_Closing_Event_Upload_Video_Enable:'',
        //第二组市/发电柜市电分闸事件上传录像使能
        Group_2_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable:'',
        //第二组市/发电柜发电合闸事件上传录像使能
        Group_2_GenSwitch_Closing_Event_Upload_Video_Enable:'',
        //第一组补偿电容运行事件上传录像使能
        Group_1_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable:'',
        //第二组补偿电容运行事件上传录像使能
        Group_2_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable:'',
        //第三组补偿电容运行事件上传录像使能
        Group_3_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable:'',
        //第四组补偿电容运行事件上传录像使能
        Group_4_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable:'',
        //第一组补偿柜电容分闸事件上传录像使能
        CompensationCabinetCapacity_1_Opening_Event_Upload_Video_Enable:'',
        //第二组补偿柜电容分闸事件上传录像使能
        CompensationCabinetCapacity_2_Opening_Event_Upload_Video_Enable:'',
        //第三组补偿柜电容分闸事件上传录像使能
        CompensationCabinetCapacity_3_Opening_Event_Upload_Video_Enable:'',
        //第四组补偿柜电容分闸事件上传录像使能
        CompensationCabinetCapacity_4_Opening_Event_Upload_Video_Enable:'',
        //机房送风机启动事件上传录像使能，1为上传录像使能，0为禁止，默认1
        EngineRoom_FeedFan_Start_Event_Upload_Video_Enable:'',
        //机房排风机启动事件上传录像使能，1为上传录像使能，0为禁止，默认1
        EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable:'',
        //防潮灯开启事件上传录像使能
        MoistureProofLamps_On_Event_Upload_Video_Enable:'',
        //第一组变压器降温风扇启动事件上传录像使能
        Group_1_Transformer_Fan_Start_Event_Upload_Video_Enable:'',
        //第二组变压器降温风扇启动事件上传录像使能
        Group_2_Transformer_Fan_Start_Event_Upload_Video_Enable:'',
        //第一组变压器负载率过低事件上传录像使能
        Group_1_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable:'',
        //第二组变压器负载率过低事件上传录像使能
        Group_2_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable:'',
        //拨打值班室电话事件上传录像使能，1为上传录像使能，0为禁止，默认1
        Voip_Alarm_Upload_Video_Enable:'',
        //两路进线柜同时分闸报警上传录像使能
        IncomingCabinetSwitch_AllOpening_Alarm_Upload_Video_Enable:'',
        //第一路进线柜电压过低报警上传录像使能
        IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Video_Enable:'',
        //第一路进线柜电压过低报警上传录像使能
        IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Video_Enable:'',
        //第一路进线柜电压过高报警上传录像使能
        IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Video_Enable:'',
        //第二路进线柜电压过高报警上传录像使能
        IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Video_Enable:'',
        //两路进线柜同时分闸联络柜合闸报警上传录像使能
        ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Video_Enable:'',
        //第一组市/发电柜同时分闸报警上传录像使能
        ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Video_Enable:'',
        //第二组市/发电柜同时分闸报警上传录像使能
        ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Video_Enable:'',
        //第一组市/发电柜同时合闸报警上传录像使能
        ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Video_Enable:'',
        //第二组市/发电柜同时合闸报警上传录像使能
        ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Video_Enable:'',
        //第一组补偿柜分闸报警上传录像使能
        CompensationCabinetSwitch_1_Opening_Alarm_Upload_Video_Enable:'',
        //第二组补偿柜分闸报警上传录像使能
        CompensationCabinetSwitch_2_Opening_Alarm_Upload_Video_Enable:'',
        //第三组补偿柜分闸报警上传录像使能
        CompensationCabinetSwitch_3_Opening_Alarm_Upload_Video_Enable:'',
        //第四组补偿柜分闸报警上传录像使能
        CompensationCabinetSwitch_4_Opening_Alarm_Upload_Video_Enable:'',
        //功率因数过低第一组补偿电容停止运行报警上传录像使能
        Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable:'',
        //功率因数过低第二组补偿电容停止运行报警上传录像使能
        Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable:'',
        //功率因数过低第三组补偿电容停止运行报警上传录像使能
        Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable:'',
        //功率因数过低第四组补偿电容停止运行报警上传录像使能
        Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable:'',
        //第一组馈电回路A相电流过大报警上传录像使能
        Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable:'',
        //第一组馈电回路B相电流过大报警上传录像使能
        Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable:'',
        //第一组馈电回路C相电流过大报警上传录像使能
        Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable:'',
        //第二组馈电回路A相电流过大报警上传录像使能
        Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable:'',
        //第二组馈电回路B相电流过大报警上传录像使能
        Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable:'',
        //第二组馈电回路C相电流过大报警上传录像使能
        Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable:'',
        //机房温度过高送风机停止报警上传录像使能
        SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Video_Enable:'',
        //机房温度过高排风机停止报警上传录像使能
        SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Video_Enable:'',
        //机房湿度过高防潮灯关闭报警上传录像使能
        Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Video_Enable:'',
        //第一组变压器超温报警上传录像使能
        Transformer_1_TempHigh_Alarm_Upload_Video_Enable:'',
        //第二组变压器超温报警上传录像使能
        Transformer_2_TempHigh_Alarm_Upload_Video_Enable:'',
        //第一组变压器超温降温风扇未启动报警上传录像使能
        Transformer_1_TempHigh_FanStop_Alarm_Upload_Video_Enable:'',
        //第二组变压器超温降温风扇未启动报警上传录像使能
        Transformer_2_TempHigh_FanStop_Alarm_Upload_Video_Enable:'',


        //机房门打开信号绑定IO端口，默认I1
        SwitchingHouse_Door_Open_Bind_Port:'',
        //第一组变压器温度过高信号绑定IO端口，默认B1
        Group_1_Transformer_Temp_Bind_Port:'',
        //第一组变压器降温风扇启动信号绑定IO端口，默认B2
        Group_1_Transformer_Fan_Start_Bind_Port:'',
        //第二组变压器温度过高信号绑定IO端口，默认B3
        Group_2_Transformer_Temp_Bind_Port:'',
        //第二组变压器降温风扇启动信号绑定IO端口，默认B4
        Group_2_Transformer_Fan_Start_Bind_Port:'',
        //拨打值班室电话信号绑定IO端口，默认B5
        Voip_Alarm_Bind_Port:'',
        //第一路进线分闸信号绑定IO端口，默认H1
        IncomingCabinet_1_Switching_Bind_Port:'',
        //第二路进线分闸信号绑定IO端口，默认H2
        IncomingCabinet_2_Switching_Bind_Port:'',
        //联络柜合闸信号绑定IO端口，默认H3
        ContactCabinetSwitch_Bind_Port:'',
        //第一组市/发电柜市电分闸信号绑定IO端口，默认H4
        Group_1_ElectricSupplySwitch_Bind_Port:'',
        //第一组市/发电柜发电合闸信号绑定IO端口，默认H5
        Group_1_GenSwitch_Bind_Port:'',
        //第二组市/发电柜市电分闸信号绑定IO端口，默认H6
        Group_2_ElectricSupplySwitch_Bind_Port:'',
        //第二组市/发电柜发电合闸信号绑定IO端口，默认H7
        Group_2_GenSwitch_Bind_Port:'',
        //第一组补偿柜分闸信号绑定IO端口，默认H8
        Group_1_CompensationCabinetSwitch_Bind_Port:'',
        //I2第一组补偿电容运行信号绑定IO端口，默认I2
        Group_1_CompensationCabinetCapacity_Run_Bind_Port:'',
        //第二组补偿柜分闸信号绑定IO端口，默认H9
        Group_2_CompensationCabinetSwitch_Bind_Port:'',
        //第二组补偿电容运行信号绑定IO端口，默认I3
        Group_2_CompensationCabinetCapacity_Run_Bind_Port:'',
        //第三组补偿柜分闸信号绑定IO端口，默认H10
        Group_3_CompensationCabinetSwitch_Bind_Port:'',
        //第三组补偿电容运行信号绑定IO端口，默认I4
        Group_3_CompensationCabinetCapacity_Run_Bind_Port:'',
        //第四组补偿柜分闸信号绑定IO端口，默认H11
        Group_4_CompensationCabinetSwitch_Bind_Port:'',
        //第四组补偿电容运行信号绑定IO端口，默认I5
        Group_4_CompensationCabinetCapacity_Run_Bind_Port:'',
        //防潮灯开启信号绑定IO端口，默认H12
        MoistureProofLamps_Bind_Port:'',
        //机房送风机启动信号绑定IO端口，默认H13
        SwitchingHouse_FeedFan_Start_Bind_Port:'',
        //机房排风机启动信号绑定IO端口，默认H14
        SwitchingHouse_ExhaustFan_Start_Bind_Port:'',


        //(I1)机房门打开信号有效电平，1为高电平有效，0为低电平有效，默认0
        SwitchingHouse_Door_Open_Gpio_State:'',
        // //(I2)第一组馈电回路A相电流信号有效电平，1为高电平有效，0为低电平有效，默认0
        // Group_1_FeederCircuit_A_Current_Gpio_State:'',
        // //(I3)第一组馈电回路B相电流信号有效电平，1为高电平有效，0为低电平有效，默认0
        // Group_1_FeederCircuit_B_Current_Gpio_State:'',
        // //(I4)第一组馈电回路C相电流信号有效电平，1为高电平有效，0为低电平有效，默认0
        // Group_1_FeederCircuit_C_Current_Gpio_State:'',
        // //(I5)第二组馈电回路A相电流信号有效电平，1为高电平有效，0为低电平有效，默认0
        // Group_2_FeederCircuit_A_Current_Gpio_State:'',
        // //(I6)第二组馈电回路B相电流信号有效电平，1为高电平有效，0为低电平有效，默认0
        // Group_2_FeederCircuit_B_Current_Gpio_State:'',
        // //(I7)第二组馈电回路C相电流信号有效电平，1为高电平有效，0为低电平有效，默认0
        // Group_2_FeederCircuit_C_Current_Gpio_State:'',
        //(B1)第一组变压器温度过高信号有效电平，1为高电平有效，0为低电平有效，默认0
        Group_1_Transformer_Temp_Gpio_State:'',
        //(B2)第一组变压器降温风扇启动信号有效电平，1为高电平有效，0为低电平有效，默认0
        Group_1_Transformer_Fan_Start_Gpio_State:'',
        //(B3)第二组变压器温度过高信号有效电平，1为高电平有效，0为低电平有效，默认0
        Group_2_Transformer_Temp_Gpio_State:'',
        //(B4)第二组变压器降温风扇启动信号有效电平，1为高电平有效，0为低电平有效，默认0
        Group_2_Transformer_Fan_Start_Gpio_State:'',
        //(B5)拨打值班室电话信号有效电平，1为高电平有效，0为低电平有效，默认0
        Voip_Alarm_Gpio_State:'',
        //(H1)第一路进线分闸信号有效电平
        IncomingCabinet_1_Switching_Gpio_State:'',
        //(H2)第二路进线分闸信号有效电平
        IncomingCabinet_2_Switching_Gpio_State:'',
        //(H3)联络柜合闸信号有效电平
        ContactCabinetSwitch_Gpio_State:'',
        //(H4)第一组市/发电柜市电分闸信号有效电平
        Group_1_ElectricSupplySwitch_Gpio_State:'',
        //(H5)第一组市/发电柜发电合闸信号有效电平
        Group_1_GenSwitch_Gpio_State:'',
        //(H6)第二组市/发电柜市电分闸信号有效电平
        Group_2_ElectricSupplySwitch_Gpio_State:'',
        //(H7)第二组市/发电柜发电合闸信号有效电平
        Group_2_GenSwitch_Gpio_State:'',
        //(H8)第一组补偿柜分闸信号有效电平
        Group_1_CompensationCabinetSwitch_Gpio_State:'',
        //(H9)第一组补偿电容运行信号有效电平
        Group_1_CompensationCabinetCapacity_Run_Gpio_State:'',
        //(H10)第二组补偿柜分闸信号有效电平
        Group_2_CompensationCabinetSwitch_Gpio_State:'',
        //(H11)第二组补偿电容运行信号有效电平
        Group_2_CompensationCabinetCapacity_Run_Gpio_State:'',
        //(H12)第三组补偿柜分闸信号有效电平
        Group_3_CompensationCabinetSwitch_Gpio_State:'',
        //(H13)第三组补偿电容运行信号有效电平
        Group_3_CompensationCabinetCapacity_Run_Gpio_State:'',
        //(H14)第四组补偿柜分闸信号有效电平
        Group_4_CompensationCabinetSwitch_Gpio_State:'',
        //(H15)第四组补偿电容运行信号有效电平
        Group_4_CompensationCabinetCapacity_Run_Gpio_State:'',
        //(H16)防潮灯开启信号有效电平
        MoistureProofLamps_Gpio_State:'',
        //(H17)机房送风机启动信号有效电平
        SwitchingHouse_FeedFan_Start_Gpio_State:'',
        //(H18)机房排风机启动信号有效电平
        SwitchingHouse_ExhaustFan_Start_Gpio_State:'',
      },
      type:'all',
      GPIOList: [],
      GPIOListBackups: [],
      devicemoduletype: '',
      modelnum: '',
      methodList: [],
      devicenum:'',
      devicename:'',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      let dictionary = getCache('dictionary', true);
      if (dictionary) {
        this.GPIOList = dictionary.GPIOList;
        this.GPIOListBackups = dictionary.GPIOList;
      }
      this.getDevicenum();
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
          this.iotConfigs.Xianshi_device_sn_num=res.item[0].monitornum
        }
      })
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      this.iotConfigs= {
        //IO状态判断时间间隔,默认1秒
        IO_Calculation_Interval: '',
        //IO状态采样时间间隔，默认15ms
        IO_Sampling_Interval: '',
        //时间窗内IO有效数值占比，默认0.0200,保留4位小数
        IO_State_Effective_Threshold: '',
        //温度过高阈值，默认45摄氏度
        Celsius_Temperature_Max_Threshold: '',
        //温度过低阈值，默认0摄氏度
        Celsius_Temperature_Min_Threshold: '',
        //相对湿度过高阈值，默认90%
        Relative_Humidity_Max_Threshold: '',
        //相对湿度过低阈值，默认10%
        Relative_Humidity_Min_Threshold: '',
        //温度队列长度，默认3个
        Celsius_Temperature_State_List_Max: '',
        //温度队列阈值，默认2个
        Celsius_Temperature_State_List_Threshold: '',
        //湿度队列长度，默认3个
        Relative_Humidity_State_List_Max: '',
        //湿度队列阈值，默认2个
        Relative_Humidity_State_List_Threshold: '',
        //人体检测队列长度，默认3个
        Person_Detect_List_Max: '',
        //人体检测队列阈值，默认2个
        Person_Detect_List_Threshold: '',
        //进线电压最小阈值
        IncomingCabinet_Minimum_Voltage_Threshold: '',
        //进线电压最大阈值
        IncomingCabinet_Maximum_Voltage_Threshold: '',
        //变压器功率因数最小阈值
        Transformer_Cos_Minimum_Threshold: '',
        //馈电回路电流最大阈值
        FeederCircuit_Maximum_Current_Threshold: '',
        //变压器负载率最小阈值
        Transformer_LoadRate_Minimum_Threshold: '',
        //变压器超温后风扇最迟启动时间阈值
        Transformer_Overheat_Fan_Run_Time_Threshold: '',
        //变压器额定功率，默认100KVA
        Transformer_Rated_Power: '',
        //第一组变压器功率因数最小阈值，默认0.85
        Transformer_1_Cos_Minimum_Threshold:'',
        //第二组变压器功率因数最小阈值，默认0.85
        Transformer_2_Cos_Minimum_Threshold:'',
        //第一组馈电回路电流最大阈值，默认200A
        FeederCircuit_1_Maximum_Current_Threshold:'',
        //第二组馈电回路电流最大阈值，默认200A
        FeederCircuit_2_Maximum_Current_Threshold:'',
        //摄氏温度过高事件使能，1为使能，0为禁止，默认使能
        Celsius_Temperature_Too_High_Event_Enable: '',
        //摄氏温度过低事件使能，1为使能，0为禁止，默认使能
        Celsius_Temperature_Too_Low_Event_Enable: '',
        //相对湿度过高事件使能，1为使能，0为禁止，默认使能
        Relative_Humidity_Too_High_Event_Enable: '',
        //相对湿度过低事件使能，1为使能，0为禁止，默认使能
        Relative_Humidity_Too_Low_Event_Enable: '',
        //机房有人事件使能，1为使能，0为禁止，默认使能
        SwitchingHouse_Person_Event_Enable: '',
        //机房门打开事件使能，1为使能，0为禁止，默认使能
        SwitchingHouse_Door_Open_Event_Enable: '',
        //机房送风机启动事件使能，1为使能，0为禁止，默认使能
        EngineRoom_FeedFan_Start_Event_Enable: '',
        //机房排风机启动事件使能，1为使能，0为禁止，默认使能
        EngineRoom_ExhaustFan_Start_Event_Enable: '',
        //第一路进线分闸事件使能
        IncomingCabinet_1_Opening_Event_Enable: '',
        //第二路进线分闸事件使能
        IncomingCabinet_2_Opening_Event_Enable: '',
        //联络柜开关合闸事件使能
        ContactCabinetSwitch_Closing_Event_Enable: '',
        //第一组市/发电柜市电分闸事件使能
        Group_1_ElectricSupplySwitch_Opening_Event_Enable: '',
        //第一组市/发电柜发电分闸事件使能
        Group_1_GenSwitch_Closing_Event_Enable: '',
        //第二组市/发电柜市电分闸事件使能
        Group_2_ElectricSupplySwitch_Opening_Event_Enable: '',
        //第二组市/发电柜发电分闸事件使能
        Group_2_GenSwitch_Closing_Event_Enable: '',
        //第一组补偿电容运行事件使能
        Group_1_CompensationCabinetCapacity_Run_Event_Enable: '',
        //第一组补偿柜电容分闸事件使能
        CompensationCabinetCapacity_1_Opening_Event_Enable: '',
        //第二组补偿电容运行事件使能
        Group_2_CompensationCabinetCapacity_Run_Event_Enable: '',
        //第二组补偿柜电容分闸事件使能
        CompensationCabinetCapacity_2_Opening_Event_Enable: '',
        //第三组补偿电容运行事件使能
        Group_3_CompensationCabinetCapacity_Run_Event_Enable: '',
        //第三组补偿柜电容分闸事件使能
        CompensationCabinetCapacity_3_Opening_Event_Enable: '',
        //第四组补偿电容运行事件使能
        Group_4_CompensationCabinetCapacity_Run_Event_Enable: '',
        //第四组补偿柜电容分闸事件使能
        CompensationCabinetCapacity_4_Opening_Event_Enable: '',
        //防潮灯开启事件使能
        MoistureProofLamps_On_Event_Enable: '',
        //第一组变压器降温风扇启动事件使能
        Group_1_Transformer_Fan_Start_Event_Enable: '',
        //第二组变压器降温风扇启动事件使能
        Group_2_Transformer_Fan_Start_Event_Enable: '',
        //第一组变压器负载率过低事件使能
        Group_1_Transformer_LoadRate_TooLow_Event_Enable: '',
        //第二组变压器负载率过低事件使能
        Group_2_Transformer_LoadRate_TooLow_Event_Enable: '',
        //拨打值班室电话使能
        Voip_Alarm_Enable: '',
        //两路进线柜同时分闸报警使能
        IncomingCabinetSwitch_AllOpening_Alarm_Enable: '',
        //第一路进线柜电压过低报警使能
        IncomingCabinet_1_Voltage_TooLow_Alarm_Enable: '',
        //第二路进线柜电压过低报警使能
        IncomingCabinet_2_Voltage_TooLow_Alarm_Enable: '',
        //第一路进线柜电压过高报警使能
        IncomingCabinet_1_Voltage_TooHigh_Alarm_Enable: '',
        //第二路进线柜电压过高报警使能
        IncomingCabinet_2_Voltage_TooHigh_Alarm_Enable: '',
        //两路进线柜同时合闸联络柜合闸报警使能
        ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Enable: '',
        //第一组市/发电柜同时合闸报警使能
        ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Enable: '',
        //第二组市/发电柜同时合闸报警使能
        ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Enable: '',
        //第一组市/发电柜同时分闸报警使能
        ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Enable: '',
        //第一组市/发电柜同时分闸报警使能
        ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Enable: '',
        //第一组变压器功率因数过低报警使能
        Transformer_1_Cos_TooLow_Alarm_Enable: '',
        //第二组变压器功率因数过低报警使能
        Transformer_2_Cos_TooLow_Alarm_Enable: '',
        //第一组补偿柜分闸报警使能
        CompensationCabinetSwitch_1_Opening_Alarm_Enable: '',
        //第二组补偿柜分闸报警使能
        CompensationCabinetSwitch_2_Opening_Alarm_Enable: '',
        //第三组补偿柜分闸报警使能
        CompensationCabinetSwitch_3_Opening_Alarm_Enable: '',
        //第四组补偿柜分闸报警使能
        CompensationCabinetSwitch_4_Opening_Alarm_Enable: '',
        //功率因数过低第一组补偿电容停止运行报警使能
        Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable: '',
        //功率因数过低第二组补偿电容停止运行报警使能
        Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable: '',
        //功率因数过低第三组补偿电容停止运行报警使能
        Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable: '',
        //功率因数过低第四组补偿电容停止运行报警使能
        Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable: '',
        //第一组馈电回路A相电流过大报警使能
        Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Enable: '',
        //第一组馈电回路B相电流过大报警使能
        Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Enable: '',
        //第一组馈电回路C相电流过大报警使能
        Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Enable: '',
        //第二组馈电回路A相电流过大报警使能
        Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Enable: '',
        //第二组馈电回路B相电流过大报警使能
        Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Enable: '',
        //第二组馈电回路C相电流过大报警使能
        Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Enable: '',
        //机房温度过高送风机停止报警上传录像使能
        SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Enable: '',
        //机房温度过高排风机停止报警上传录像使能
        SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Enable: '',
        //机房湿度过高防潮灯关闭报警使能
        Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Enable: '',
        //第一组变压器超温报警使能
        Transformer_1_TempHigh_Alarm_Enable: '',
        //第二组变压器超温报警使能
        Transformer_2_TempHigh_Alarm_Enable: '',
        //第一组变压器超温降温风扇未启动报警使能
        Transformer_1_TempHigh_FanStop_Alarm_Enable: '',
        //第二组变压器超温降温风扇未启动报警使能
        Transformer_2_TempHigh_FanStop_Alarm_Enable: '',


        //摄氏温度过高事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Celsius_Temperature_Too_High_Event_Upload_Image_Enable:'',
        //摄氏温度过低事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Celsius_Temperature_Too_Low_Event_Upload_Image_Enable:'',
        //相对湿度过高事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Relative_Humidity_Too_High_Event_Upload_Image_Enable:'',
        //相对湿度过低事件上传图片使能，1为上传图片使能，0为禁止，默认0
        Relative_Humidity_Too_Low_Event_Upload_Image_Enable:'',
        //机房有人事件上传图片使能，1为上传图片使能，0为禁止，默认1
        SwitchingHouse_Person_Event_Upload_Image_Enable:'',
        //机房门打开事件上传图片使能，1为上传图片使能，0为禁止，默认1
        SwitchingHouse_Door_Open_Event_Upload_Image_Enable:'',
        //第一路进线分闸事件上传图片使能，1为使能，0为禁止，默认0
        IncomingCabinet_1_Opening_Event_Upload_Image_Enable:'',
        //第二路进线分闸事件上传图片使能，1为使能，0为禁止，默认0
        IncomingCabinet_2_Opening_Event_Upload_Image_Enable:'',
        //联络柜开关合闸事件上传图片使能，1为使能，0为禁止，默认0
        ContactCabinetSwitch_Closing_Event_Upload_Image_Enable:'',
        //第一组市/发电柜市电分闸事件上传图片使能，1为使能，0为禁止，默认0
        Group_1_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable:'',
        ///第一组市/发电柜发电合闸事件上传图片使能，1为使能，0为禁止，默认0
        Group_1_GenSwitch_Closing_Event_Upload_Image_Enable:'',
        //第二组市/发电柜市电分闸事件上传图片使能，1为使能，0为禁止，默认0
        Group_2_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable:'',
        ///第二组市/发电柜发电合闸事件上传图片使能，1为使能，0为禁止，默认0
        Group_2_GenSwitch_Closing_Event_Upload_Image_Enable:'',
        //第一组补偿电容运行事件上传图片使能，1为使能，0为禁止，默认0
        Group_1_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable:'',
        //第二组补偿电容运行事件上传图片使能，1为使能，0为禁止，默认0
        Group_2_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable:'',
        //第三组补偿电容运行事件上传图片使能，1为使能，0为禁止，默认0
        Group_3_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable:'',
        //第四组补偿电容运行事件上传图片使能，1为使能，0为禁止，默认0
        Group_4_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable:'',
        ///第一组补偿柜电容分闸事件上传图片使能，1为使能，0为禁止，默认0
        CompensationCabinetCapacity_1_Opening_Event_Upload_Image_Enable:'',
        ///第二组补偿柜电容分闸事件上传图片使能，1为使能，0为禁止，默认0
        CompensationCabinetCapacity_2_Opening_Event_Upload_Image_Enable:'',
        ///第三组补偿柜电容分闸事件上传图片使能，1为使能，0为禁止，默认0
        CompensationCabinetCapacity_3_Opening_Event_Upload_Image_Enable:'',
        ///第四组补偿柜电容分闸事件上传图片使能，1为使能，0为禁止，默认0
        CompensationCabinetCapacity_4_Opening_Event_Upload_Image_Enable:'',
        ///机房送风机启动事件上传图片使能，1为使能，0为禁止，默认0
        EngineRoom_FeedFan_Start_Event_Upload_Image_Enable:'',
        ///机房排风机启动事件上传图片使能，1为使能，0为禁止，默认0
        EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable:'',
        //防潮灯开启事件上传图片使能，1为使能，0为禁止，默认1
        MoistureProofLamps_On_Event_Upload_Image_Enable:'',
        //第一组变压器降温风扇启动事件上传图片使能，1为使能，0为禁止，默认0
        Group_1_Transformer_Fan_Start_Event_Upload_Image_Enable:'',
        //第二组变压器降温风扇启动事件上传图片使能，1为使能，0为禁止，默认0
        Group_2_Transformer_Fan_Start_Event_Upload_Image_Enable:'',
        //第一组变压器负载率过低事件上传图片使能，1为使能，0为禁止，默认0
        Group_1_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable:'',
        //第二组变压器负载率过低事件上传图片使能，1为使能，0为禁止，默认0
        Group_2_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable:'',
        //拨打值班室电话事件上传图片使能，1为上传图片使能，0为禁止，默认1
        Voip_Alarm_Upload_Image_Enable:'',
        //两路进线柜同时分闸报警上传图片使能，1为使能，0为禁止，默认0
        IncomingCabinetSwitch_AllOpening_Alarm_Upload_Image_Enable:'',
        ///第一路进线柜电压过低报警上传图片使能，1为使能，0为禁止，默认0
        IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Image_Enable:'',
        ///第二路进线柜电压过低报警上传图片使能，1为使能，0为禁止，默认0
        IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Image_Enable:'',
        //第一路进线柜电压过高报警上传图片使能，1为使能，0为禁止，默认0
        IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Image_Enable:'',
        //第二路进线柜电压过高报警上传图片使能，1为使能，0为禁止，默认0
        IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Image_Enable:'',
        ///两路进线柜同时合闸联络柜合闸报警上传图片使能，1为使能，0为禁止，默认0
        ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Image_Enable:'',
        //第一组市/发电柜同时合闸报警上传图片使能，1为使能，0为禁止，默认0
        ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Image_Enable:'',
        //第二组市/发电柜同时合闸报警上传图片使能，1为使能，0为禁止，默认0
        ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Image_Enable:'',
        //第一组市/发电柜同时分闸报警上传图片使能，1为使能，0为禁止，默认0
        ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Image_Enable:'',
        //第二组市/发电柜同时分闸报警上传图片使能，1为使能，0为禁止，默认0
        ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Image_Enable:'',
        ///第一组功率因数过低报警上传图片使能，1为使能，0为禁止，默认0
        Transformer_1_Cos_TooLow_Alarm_Upload_Image_Enable:'',
        ///第二组功率因数过低报警上传图片使能，1为使能，0为禁止，默认0
        Transformer_2_Cos_TooLow_Alarm_Upload_Image_Enable:'',
        //第一组补偿柜分闸报警上传图片使能，1为使能，0为禁止，默认0
        CompensationCabinetSwitch_1_Opening_Alarm_Upload_Image_Enable:'',
        //第二组补偿柜分闸报警上传图片使能，1为使能，0为禁止，默认0
        CompensationCabinetSwitch_2_Opening_Alarm_Upload_Image_Enable:'',
        //第三组补偿柜分闸报警上传图片使能，1为使能，0为禁止，默认0
        CompensationCabinetSwitch_3_Opening_Alarm_Upload_Image_Enable:'',
        //第四组补偿柜分闸报警上传图片使能，1为使能，0为禁止，默认0
        CompensationCabinetSwitch_4_Opening_Alarm_Upload_Image_Enable:'',
        ///功率因数过低第一组补偿电容停止运行报警上传图片使能，1为使能，0为禁止，默认0
        Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable:'',
        ///功率因数过低第二组补偿电容停止运行报警上传图片使能，1为使能，0为禁止，默认0
        Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable:'',
        ///功率因数过低第三组补偿电容停止运行报警上传图片使能，1为使能，0为禁止，默认0
        Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable:'',
        ///功率因数过低第四组补偿电容停止运行报警上传图片使能，1为使能，0为禁止，默认0
        Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable:'',
        ///第一组馈电回路A相电流过大报警上传图片使能，1为使能，0为禁止，默认0
        Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable:'',
        ///第一组馈电回路B相电流过大报警上传图片使能，1为使能，0为禁止，默认0
        Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable:'',
        ///第一组馈电回路C相电流过大报警上传图片使能，1为使能，0为禁止，默认0
        Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable:'',
        ///第二组馈电回路A相电流过大报警上传图片使能，1为使能，0为禁止，默认0
        Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable:'',
        ///第二组馈电回路B相电流过大报警上传图片使能，1为使能，0为禁止，默认0
        Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable:'',
        ///第二组馈电回路C相电流过大报警上传图片使能，1为使能，0为禁止，默认0
        Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable:'',
        ///机房温度过高送风机停止报警上传图片使能，1为使能，0为禁止，默认0
        SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Image_Enable:'',
        ///机房温度过高排风机停止报警上传图片使能，1为使能，0为禁止，默认0
        SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Image_Enable:'',
        ///机房湿度过高防潮灯关闭报警上传图片使能，1为使能，0为禁止，默认0
        Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Image_Enable:'',
        //第一组变压器超温报警上传图片使能，1为使能，0为禁止，默认1
        Transformer_1_TempHigh_Alarm_Upload_Image_Enable:'',
        //第二组变压器超温报警上传图片使能，1为使能，0为禁止，默认1
        Transformer_2_TempHigh_Alarm_Upload_Image_Enable:'',
        //第一组变压器超温降温风扇未启动报警上传图片使能，1为使能，0为禁止，默认0
        Transformer_1_TempHigh_FanStop_Alarm_Upload_Image_Enable:'',
        //第二组变压器超温降温风扇未启动报警上传图片使能，1为使能，0为禁止，默认0
        Transformer_2_TempHigh_FanStop_Alarm_Upload_Image_Enable:'',


        //摄氏温度过高事件上传录像使能，1为上传录像使能，0为禁止，默认1
        Celsius_Temperature_Too_High_Event_Upload_Video_Enable: '',
        //摄氏温度过低事件上传录像使能，1为上传录像使能，0为禁止，默认1
        Celsius_Temperature_Too_Low_Event_Upload_Video_Enable: '',
        //相对湿度过高事件上传录像使能，1为上传录像使能，0为禁止，默认1
        Relative_Humidity_Too_High_Event_Upload_Video_Enable: '',
        //相对湿度过低事件上传录像使能，1为上传录像使能，0为禁止，默认1
        Relative_Humidity_Too_Low_Event_Upload_Video_Enable: '',
        //机房有人事件上传录像使能，1为上传录像使能，0为禁止，默认1
        SwitchingHouse_Person_Event_Upload_Video_Enable: '',
        //机房门打开事件上传录像使能，1为上传录像使能，0为禁止，默认1
        SwitchingHouse_Door_Open_Event_Upload_Video_Enable: '',
        //第一路进线分闸事件上传录像使能
        IncomingCabinet_1_Opening_Event_Upload_Video_Enable: '',
        //第二路进线分闸事件上传录像使能
        IncomingCabinet_2_Opening_Event_Upload_Video_Enable: '',
        //第一组变压器功率因数过低报警上传录像使能
        Transformer_1_Cos_TooLow_Alarm_Upload_Video_Enable: '',
        //第二组变压器功率因数过低报警上传录像使能
        Transformer_2_Cos_TooLow_Alarm_Upload_Video_Enable: '',
        //联络柜开关合闸事件上传录像使能
        ContactCabinetSwitch_Closing_Event_Upload_Video_Enable: '',
        //第一组市/发电柜市电分闸事件上传录像使能
        Group_1_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable: '',
        //第一组市/发电柜发电合闸事件上传录像使能
        Group_1_GenSwitch_Closing_Event_Upload_Video_Enable: '',
        //第二组市/发电柜市电分闸事件上传录像使能
        Group_2_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable: '',
        //第二组市/发电柜发电合闸事件上传录像使能
        Group_2_GenSwitch_Closing_Event_Upload_Video_Enable: '',
        //第一组补偿电容运行事件上传录像使能
        Group_1_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable: '',
        //第二组补偿电容运行事件上传录像使能
        Group_2_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable: '',
        //第三组补偿电容运行事件上传录像使能
        Group_3_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable: '',
        //第四组补偿电容运行事件上传录像使能
        Group_4_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable: '',
        //第一组补偿柜电容分闸事件上传录像使能
        CompensationCabinetCapacity_1_Opening_Event_Upload_Video_Enable: '',
        //第二组补偿柜电容分闸事件上传录像使能
        CompensationCabinetCapacity_2_Opening_Event_Upload_Video_Enable: '',
        //第三组补偿柜电容分闸事件上传录像使能
        CompensationCabinetCapacity_3_Opening_Event_Upload_Video_Enable: '',
        //第四组补偿柜电容分闸事件上传录像使能
        CompensationCabinetCapacity_4_Opening_Event_Upload_Video_Enable: '',
        //机房送风机启动事件上传录像使能，1为上传录像使能，0为禁止，默认1
        EngineRoom_FeedFan_Start_Event_Upload_Video_Enable: '',
        //机房排风机启动事件上传录像使能，1为上传录像使能，0为禁止，默认1
        EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable: '',
        //防潮灯开启事件上传录像使能
        MoistureProofLamps_On_Event_Upload_Video_Enable: '',
        //第一组变压器降温风扇启动事件上传录像使能
        Group_1_Transformer_Fan_Start_Event_Upload_Video_Enable: '',
        //第二组变压器降温风扇启动事件上传录像使能
        Group_2_Transformer_Fan_Start_Event_Upload_Video_Enable: '',
        //第一组变压器负载率过低事件上传录像使能
        Group_1_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable: '',
        //第二组变压器负载率过低事件上传录像使能
        Group_2_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable: '',
        //拨打值班室电话事件上传录像使能，1为上传录像使能，0为禁止，默认1
        Voip_Alarm_Upload_Video_Enable: '',
        //两路进线柜同时分闸报警上传录像使能
        IncomingCabinetSwitch_AllOpening_Alarm_Upload_Video_Enable: '',
        //第一路进线柜电压过低报警上传录像使能
        IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Video_Enable: '',
        //第一路进线柜电压过低报警上传录像使能
        IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Video_Enable: '',
        //第一路进线柜电压过高报警上传录像使能
        IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Video_Enable: '',
        //第二路进线柜电压过高报警上传录像使能
        IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Video_Enable: '',
        //两路进线柜同时分闸联络柜合闸报警上传录像使能
        ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Video_Enable: '',
        //第一组市/发电柜同时分闸报警上传录像使能
        ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Video_Enable: '',
        //第二组市/发电柜同时分闸报警上传录像使能
        ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Video_Enable: '',
        //第一组市/发电柜同时合闸报警上传录像使能
        ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Video_Enable: '',
        //第二组市/发电柜同时合闸报警上传录像使能
        ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Video_Enable: '',
        //第一组补偿柜分闸报警上传录像使能
        CompensationCabinetSwitch_1_Opening_Alarm_Upload_Video_Enable: '',
        //第二组补偿柜分闸报警上传录像使能
        CompensationCabinetSwitch_2_Opening_Alarm_Upload_Video_Enable: '',
        //第三组补偿柜分闸报警上传录像使能
        CompensationCabinetSwitch_3_Opening_Alarm_Upload_Video_Enable: '',
        //第四组补偿柜分闸报警上传录像使能
        CompensationCabinetSwitch_4_Opening_Alarm_Upload_Video_Enable: '',
        //功率因数过低第一组补偿电容停止运行报警上传录像使能
        Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable: '',
        //功率因数过低第二组补偿电容停止运行报警上传录像使能
        Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable: '',
        //功率因数过低第三组补偿电容停止运行报警上传录像使能
        Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable: '',
        //功率因数过低第四组补偿电容停止运行报警上传录像使能
        Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable: '',
        //第一组馈电回路A相电流过大报警上传录像使能
        Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable: '',
        //第一组馈电回路B相电流过大报警上传录像使能
        Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable: '',
        //第一组馈电回路C相电流过大报警上传录像使能
        Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable: '',
        //第二组馈电回路A相电流过大报警上传录像使能
        Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable: '',
        //第二组馈电回路B相电流过大报警上传录像使能
        Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable: '',
        //第二组馈电回路C相电流过大报警上传录像使能
        Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable: '',
        //机房温度过高送风机停止报警上传录像使能
        SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Video_Enable: '',
        //机房温度过高排风机停止报警上传录像使能
        SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Video_Enable: '',
        //机房湿度过高防潮灯关闭报警上传录像使能
        Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Video_Enable: '',
        //第一组变压器超温报警上传录像使能
        Transformer_1_TempHigh_Alarm_Upload_Video_Enable: '',
        //第二组变压器超温报警上传录像使能
        Transformer_2_TempHigh_Alarm_Upload_Video_Enable: '',
        //第一组变压器超温降温风扇未启动报警上传录像使能
        Transformer_1_TempHigh_FanStop_Alarm_Upload_Video_Enable: '',
        //第二组变压器超温降温风扇未启动报警上传录像使能
        Transformer_2_TempHigh_FanStop_Alarm_Upload_Video_Enable: '',


        //机房门打开信号绑定IO端口，默认I1
        SwitchingHouse_Door_Open_Bind_Port: '',
        //第一组变压器温度过高信号绑定IO端口，默认B1
        Group_1_Transformer_Temp_Bind_Port: '',
        //第一组变压器降温风扇启动信号绑定IO端口，默认B2
        Group_1_Transformer_Fan_Start_Bind_Port: '',
        //第二组变压器温度过高信号绑定IO端口，默认B3
        Group_2_Transformer_Temp_Bind_Port: '',
        //第二组变压器降温风扇启动信号绑定IO端口，默认B4
        Group_2_Transformer_Fan_Start_Bind_Port: '',
        //拨打值班室电话信号绑定IO端口，默认B5
        Voip_Alarm_Bind_Port: '',
        //第一路进线分闸信号绑定IO端口，默认H1
        IncomingCabinet_1_Switching_Bind_Port: '',
        //第二路进线分闸信号绑定IO端口，默认H2
        IncomingCabinet_2_Switching_Bind_Port: '',
        //联络柜合闸信号绑定IO端口，默认H3
        ContactCabinetSwitch_Bind_Port: '',
        //第一组市/发电柜市电分闸信号绑定IO端口，默认H4
        Group_1_ElectricSupplySwitch_Bind_Port: '',
        //第一组市/发电柜发电合闸信号绑定IO端口，默认H5
        Group_1_GenSwitch_Bind_Port: '',
        //第二组市/发电柜市电分闸信号绑定IO端口，默认H6
        Group_2_ElectricSupplySwitch_Bind_Port: '',
        //第二组市/发电柜发电合闸信号绑定IO端口，默认H7
        Group_2_GenSwitch_Bind_Port: '',
        //第一组补偿柜分闸信号绑定IO端口，默认H8
        Group_1_CompensationCabinetSwitch_Bind_Port: '',
        //I2第一组补偿电容运行信号绑定IO端口，默认I2
        Group_1_CompensationCabinetCapacity_Run_Bind_Port: '',
        //第二组补偿柜分闸信号绑定IO端口，默认H9
        Group_2_CompensationCabinetSwitch_Bind_Port: '',
        //第二组补偿电容运行信号绑定IO端口，默认I3
        Group_2_CompensationCabinetCapacity_Run_Bind_Port: '',
        //第三组补偿柜分闸信号绑定IO端口，默认H10
        Group_3_CompensationCabinetSwitch_Bind_Port: '',
        //第三组补偿电容运行信号绑定IO端口，默认I4
        Group_3_CompensationCabinetCapacity_Run_Bind_Port: '',
        //第四组补偿柜分闸信号绑定IO端口，默认H11
        Group_4_CompensationCabinetSwitch_Bind_Port: '',
        //第四组补偿电容运行信号绑定IO端口，默认I5
        Group_4_CompensationCabinetCapacity_Run_Bind_Port: '',
        //防潮灯开启信号绑定IO端口，默认H12
        MoistureProofLamps_Bind_Port: '',
        //机房送风机启动信号绑定IO端口，默认H13
        SwitchingHouse_FeedFan_Start_Bind_Port: '',
        //机房排风机启动信号绑定IO端口，默认H14
        SwitchingHouse_ExhaustFan_Start_Bind_Port: '',


        //(I1)机房门打开信号有效电平，1为高电平有效，0为低电平有效，默认0
        SwitchingHouse_Door_Open_Gpio_State: '',
        // //(I2)第一组馈电回路A相电流信号有效电平，1为高电平有效，0为低电平有效，默认0
        // Group_1_FeederCircuit_A_Current_Gpio_State:'',
        // //(I3)第一组馈电回路B相电流信号有效电平，1为高电平有效，0为低电平有效，默认0
        // Group_1_FeederCircuit_B_Current_Gpio_State:'',
        // //(I4)第一组馈电回路C相电流信号有效电平，1为高电平有效，0为低电平有效，默认0
        // Group_1_FeederCircuit_C_Current_Gpio_State:'',
        // //(I5)第二组馈电回路A相电流信号有效电平，1为高电平有效，0为低电平有效，默认0
        // Group_2_FeederCircuit_A_Current_Gpio_State:'',
        // //(I6)第二组馈电回路B相电流信号有效电平，1为高电平有效，0为低电平有效，默认0
        // Group_2_FeederCircuit_B_Current_Gpio_State:'',
        // //(I7)第二组馈电回路C相电流信号有效电平，1为高电平有效，0为低电平有效，默认0
        // Group_2_FeederCircuit_C_Current_Gpio_State:'',
        //(B1)第一组变压器温度过高信号有效电平，1为高电平有效，0为低电平有效，默认0
        Group_1_Transformer_Temp_Gpio_State: '',
        //(B2)第一组变压器降温风扇启动信号有效电平，1为高电平有效，0为低电平有效，默认0
        Group_1_Transformer_Fan_Start_Gpio_State: '',
        //(B3)第二组变压器温度过高信号有效电平，1为高电平有效，0为低电平有效，默认0
        Group_2_Transformer_Temp_Gpio_State: '',
        //(B4)第二组变压器降温风扇启动信号有效电平，1为高电平有效，0为低电平有效，默认0
        Group_2_Transformer_Fan_Start_Gpio_State: '',
        //(B5)拨打值班室电话信号有效电平，1为高电平有效，0为低电平有效，默认0
        Voip_Alarm_Gpio_State: '',
        //(H1)第一路进线分闸信号有效电平
        IncomingCabinet_1_Switching_Gpio_State: '',
        //(H2)第二路进线分闸信号有效电平
        IncomingCabinet_2_Switching_Gpio_State: '',
        //(H3)联络柜合闸信号有效电平
        ContactCabinetSwitch_Gpio_State: '',
        //(H4)第一组市/发电柜市电分闸信号有效电平
        Group_1_ElectricSupplySwitch_Gpio_State: '',
        //(H5)第一组市/发电柜发电合闸信号有效电平
        Group_1_GenSwitch_Gpio_State: '',
        //(H6)第二组市/发电柜市电分闸信号有效电平
        Group_2_ElectricSupplySwitch_Gpio_State: '',
        //(H7)第二组市/发电柜发电合闸信号有效电平
        Group_2_GenSwitch_Gpio_State: '',
        //(H8)第一组补偿柜分闸信号有效电平
        Group_1_CompensationCabinetSwitch_Gpio_State: '',
        //(H9)第一组补偿电容运行信号有效电平
        Group_1_CompensationCabinetCapacity_Run_Gpio_State: '',
        //(H10)第二组补偿柜分闸信号有效电平
        Group_2_CompensationCabinetSwitch_Gpio_State: '',
        //(H11)第二组补偿电容运行信号有效电平
        Group_2_CompensationCabinetCapacity_Run_Gpio_State: '',
        //(H12)第三组补偿柜分闸信号有效电平
        Group_3_CompensationCabinetSwitch_Gpio_State: '',
        //(H13)第三组补偿电容运行信号有效电平
        Group_3_CompensationCabinetCapacity_Run_Gpio_State: '',
        //(H14)第四组补偿柜分闸信号有效电平
        Group_4_CompensationCabinetSwitch_Gpio_State: '',
        //(H15)第四组补偿电容运行信号有效电平
        Group_4_CompensationCabinetCapacity_Run_Gpio_State: '',
        //(H16)防潮灯开启信号有效电平
        MoistureProofLamps_Gpio_State: '',
        //(H17)机房送风机启动信号有效电平
        SwitchingHouse_FeedFan_Start_Gpio_State: '',
        //(H18)机房排风机启动信号有效电平
        SwitchingHouse_ExhaustFan_Start_Gpio_State: '',
      }
      this.GPIOList=this.GPIOListBackups
      this.paramsList = [];
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'GET',
              url: '/iot',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.iotConfigs.Voip_Alarm_Enable = res.item[0].params.body.Voip_Alarm_Enable
          this.iotConfigs.Voip_Alarm_Upload_Video_Enable = res.item[0].params.body.Voip_Alarm_Upload_Video_Enable
          this.iotConfigs.IO_Calculation_Interval = res.item[0].params.body.IO_Calculation_Interval
          this.iotConfigs.IO_Sampling_Interval = res.item[0].params.body.IO_Sampling_Interval
          this.iotConfigs.IO_State_Effective_Threshold = parseFloat(res.item[0].params.body.IO_State_Effective_Threshold).toFixed(4)
          this.iotConfigs.Celsius_Temperature_Max_Threshold = res.item[0].params.body.Celsius_Temperature_Max_Threshold
          this.iotConfigs.Celsius_Temperature_Min_Threshold = res.item[0].params.body.Celsius_Temperature_Min_Threshold
          this.iotConfigs.Relative_Humidity_Max_Threshold = res.item[0].params.body.Relative_Humidity_Max_Threshold
          this.iotConfigs.Relative_Humidity_Min_Threshold = res.item[0].params.body.Relative_Humidity_Min_Threshold
          this.iotConfigs.Celsius_Temperature_State_List_Max = res.item[0].params.body.Celsius_Temperature_State_List_Max
          this.iotConfigs.Celsius_Temperature_State_List_Threshold = res.item[0].params.body.Celsius_Temperature_State_List_Threshold
          this.iotConfigs.Relative_Humidity_State_List_Max = res.item[0].params.body.Relative_Humidity_State_List_Max
          this.iotConfigs.Relative_Humidity_State_List_Threshold = res.item[0].params.body.Relative_Humidity_State_List_Threshold
          this.iotConfigs.Person_Detect_List_Max = res.item[0].params.body.Person_Detect_List_Max
          this.iotConfigs.Person_Detect_List_Threshold = res.item[0].params.body.Person_Detect_List_Threshold
          this.iotConfigs.IncomingCabinet_Minimum_Voltage_Threshold = res.item[0].params.body.IncomingCabinet_Minimum_Voltage_Threshold
          this.iotConfigs.IncomingCabinet_Maximum_Voltage_Threshold = res.item[0].params.body.IncomingCabinet_Maximum_Voltage_Threshold
          this.iotConfigs.Transformer_Cos_Minimum_Threshold = res.item[0].params.body.Transformer_Cos_Minimum_Threshold
          this.iotConfigs.FeederCircuit_Maximum_Current_Threshold = res.item[0].params.body.FeederCircuit_Maximum_Current_Threshold
          this.iotConfigs.Transformer_LoadRate_Minimum_Threshold = res.item[0].params.body.Transformer_LoadRate_Minimum_Threshold
          this.iotConfigs.Transformer_Overheat_Fan_Run_Time_Threshold = res.item[0].params.body.Transformer_Overheat_Fan_Run_Time_Threshold
          this.iotConfigs.Transformer_Rated_Power = res.item[0].params.body.Transformer_Rated_Power
          this.iotConfigs.Transformer_1_Cos_Minimum_Threshold = res.item[0].params.body.Transformer_1_Cos_Minimum_Threshold
          this.iotConfigs.Transformer_2_Cos_Minimum_Threshold = res.item[0].params.body.Transformer_2_Cos_Minimum_Threshold
          this.iotConfigs.FeederCircuit_1_Maximum_Current_Threshold = res.item[0].params.body.FeederCircuit_1_Maximum_Current_Threshold
          this.iotConfigs.FeederCircuit_2_Maximum_Current_Threshold = res.item[0].params.body.FeederCircuit_2_Maximum_Current_Threshold

          //事件/报警使能参数
          this.iotConfigs.Celsius_Temperature_Too_High_Event_Enable = res.item[0].params.body.Celsius_Temperature_Too_High_Event_Enable
          this.iotConfigs.Celsius_Temperature_Too_Low_Event_Enable = res.item[0].params.body.Celsius_Temperature_Too_Low_Event_Enable
          this.iotConfigs.Relative_Humidity_Too_High_Event_Enable = res.item[0].params.body.Relative_Humidity_Too_High_Event_Enable
          this.iotConfigs.Relative_Humidity_Too_Low_Event_Enable = res.item[0].params.body.Relative_Humidity_Too_Low_Event_Enable
          this.iotConfigs.SwitchingHouse_Person_Event_Enable = res.item[0].params.body.SwitchingHouse_Person_Event_Enable
          this.iotConfigs.SwitchingHouse_Door_Open_Event_Enable = res.item[0].params.body.SwitchingHouse_Door_Open_Event_Enable
          this.iotConfigs.EngineRoom_FeedFan_Start_Event_Enable = res.item[0].params.body.EngineRoom_FeedFan_Start_Event_Enable
          this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Enable = res.item[0].params.body.EngineRoom_ExhaustFan_Start_Event_Enable
          this.iotConfigs.IncomingCabinet_1_Opening_Event_Enable = res.item[0].params.body.IncomingCabinet_1_Opening_Event_Enable
          this.iotConfigs.IncomingCabinet_2_Opening_Event_Enable = res.item[0].params.body.IncomingCabinet_2_Opening_Event_Enable
          this.iotConfigs.Transformer_1_Cos_TooLow_Alarm_Enable = res.item[0].params.body.Transformer_1_Cos_TooLow_Alarm_Enable
          this.iotConfigs.Transformer_2_Cos_TooLow_Alarm_Enable = res.item[0].params.body.Transformer_2_Cos_TooLow_Alarm_Enable
          this.iotConfigs.ContactCabinetSwitch_Closing_Event_Enable = res.item[0].params.body.ContactCabinetSwitch_Closing_Event_Enable
          this.iotConfigs.Group_1_ElectricSupplySwitch_Opening_Event_Enable = res.item[0].params.body.Group_1_ElectricSupplySwitch_Opening_Event_Enable
          this.iotConfigs.Group_1_GenSwitch_Closing_Event_Enable = res.item[0].params.body.Group_1_GenSwitch_Closing_Event_Enable
          this.iotConfigs.Group_2_ElectricSupplySwitch_Opening_Event_Enable = res.item[0].params.body.Group_2_ElectricSupplySwitch_Opening_Event_Enable
          this.iotConfigs.Group_2_GenSwitch_Closing_Event_Enable = res.item[0].params.body.Group_2_GenSwitch_Closing_Event_Enable
          this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Event_Enable = res.item[0].params.body.Group_1_CompensationCabinetCapacity_Run_Event_Enable
          this.iotConfigs.CompensationCabinetCapacity_1_Opening_Event_Enable = res.item[0].params.body.CompensationCabinetCapacity_1_Opening_Event_Enable
          this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Event_Enable = res.item[0].params.body.Group_2_CompensationCabinetCapacity_Run_Event_Enable
          this.iotConfigs.CompensationCabinetCapacity_2_Opening_Event_Enable = res.item[0].params.body.CompensationCabinetCapacity_2_Opening_Event_Enable
          this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Event_Enable = res.item[0].params.body.Group_3_CompensationCabinetCapacity_Run_Event_Enable
          this.iotConfigs.CompensationCabinetCapacity_3_Opening_Event_Enable = res.item[0].params.body.CompensationCabinetCapacity_3_Opening_Event_Enable
          this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Event_Enable = res.item[0].params.body.Group_4_CompensationCabinetCapacity_Run_Event_Enable
          this.iotConfigs.CompensationCabinetCapacity_4_Opening_Event_Enable = res.item[0].params.body.CompensationCabinetCapacity_4_Opening_Event_Enable
          this.iotConfigs.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Enable = res.item[0].params.body.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Enable
          this.iotConfigs.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Enable = res.item[0].params.body.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Enable
          this.iotConfigs.MoistureProofLamps_On_Event_Enable = res.item[0].params.body.MoistureProofLamps_On_Event_Enable
          this.iotConfigs.Group_1_Transformer_Fan_Start_Event_Enable = res.item[0].params.body.Group_1_Transformer_Fan_Start_Event_Enable
          this.iotConfigs.Group_2_Transformer_Fan_Start_Event_Enable = res.item[0].params.body.Group_2_Transformer_Fan_Start_Event_Enable
          this.iotConfigs.Group_1_Transformer_LoadRate_TooLow_Event_Enable = res.item[0].params.body.Group_1_Transformer_LoadRate_TooLow_Event_Enable
          this.iotConfigs.Group_2_Transformer_LoadRate_TooLow_Event_Enable = res.item[0].params.body.Group_2_Transformer_LoadRate_TooLow_Event_Enable
          this.iotConfigs.IncomingCabinetSwitch_AllOpening_Alarm_Enable = res.item[0].params.body.IncomingCabinetSwitch_AllOpening_Alarm_Enable
          this.iotConfigs.IncomingCabinet_1_Voltage_TooLow_Alarm_Enable = res.item[0].params.body.IncomingCabinet_1_Voltage_TooLow_Alarm_Enable
          this.iotConfigs.IncomingCabinet_2_Voltage_TooLow_Alarm_Enable = res.item[0].params.body.IncomingCabinet_2_Voltage_TooLow_Alarm_Enable
          this.iotConfigs.IncomingCabinet_1_Voltage_TooHigh_Alarm_Enable = res.item[0].params.body.IncomingCabinet_1_Voltage_TooHigh_Alarm_Enable
          this.iotConfigs.IncomingCabinet_2_Voltage_TooHigh_Alarm_Enable = res.item[0].params.body.IncomingCabinet_2_Voltage_TooHigh_Alarm_Enable
          this.iotConfigs.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Enable = res.item[0].params.body.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Enable
          this.iotConfigs.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Enable = res.item[0].params.body.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Enable
          this.iotConfigs.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Enable = res.item[0].params.body.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Enable
          this.iotConfigs.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Enable = res.item[0].params.body.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Enable
          this.iotConfigs.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Enable = res.item[0].params.body.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Enable
          this.iotConfigs.CompensationCabinetSwitch_1_Opening_Alarm_Enable = res.item[0].params.body.CompensationCabinetSwitch_1_Opening_Alarm_Enable
          this.iotConfigs.CompensationCabinetSwitch_2_Opening_Alarm_Enable = res.item[0].params.body.CompensationCabinetSwitch_2_Opening_Alarm_Enable
          this.iotConfigs.CompensationCabinetSwitch_3_Opening_Alarm_Enable = res.item[0].params.body.CompensationCabinetSwitch_3_Opening_Alarm_Enable
          this.iotConfigs.CompensationCabinetSwitch_4_Opening_Alarm_Enable = res.item[0].params.body.CompensationCabinetSwitch_4_Opening_Alarm_Enable
          this.iotConfigs.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable = res.item[0].params.body.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable
          this.iotConfigs.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable = res.item[0].params.body.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable
          this.iotConfigs.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable = res.item[0].params.body.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable
          this.iotConfigs.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable = res.item[0].params.body.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable
          this.iotConfigs.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Enable = res.item[0].params.body.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Enable
          this.iotConfigs.Transformer_1_TempHigh_Alarm_Enable = res.item[0].params.body.Transformer_1_TempHigh_Alarm_Enable
          this.iotConfigs.Transformer_2_TempHigh_Alarm_Enable = res.item[0].params.body.Transformer_2_TempHigh_Alarm_Enable
          this.iotConfigs.Transformer_1_TempHigh_FanStop_Alarm_Enable = res.item[0].params.body.Transformer_1_TempHigh_FanStop_Alarm_Enable
          this.iotConfigs.Transformer_2_TempHigh_FanStop_Alarm_Enable = res.item[0].params.body.Transformer_2_TempHigh_FanStop_Alarm_Enable
          this.iotConfigs.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Enable = res.item[0].params.body.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Enable
          this.iotConfigs.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Enable = res.item[0].params.body.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Enable
          this.iotConfigs.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Enable = res.item[0].params.body.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Enable
          this.iotConfigs.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Enable = res.item[0].params.body.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Enable
          this.iotConfigs.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Enable = res.item[0].params.body.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Enable
          this.iotConfigs.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Enable = res.item[0].params.body.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Enable
          
          //报警、事件上传图片参数
          this.iotConfigs.Voip_Alarm_Upload_Image_Enable = res.item[0].params.body.Voip_Alarm_Upload_Image_Enable
          this.iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Image_Enable = res.item[0].params.body.Celsius_Temperature_Too_High_Event_Upload_Image_Enable
          this.iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Image_Enable = res.item[0].params.body.Celsius_Temperature_Too_Low_Event_Upload_Image_Enable
          this.iotConfigs.Relative_Humidity_Too_High_Event_Upload_Image_Enable = res.item[0].params.body.Relative_Humidity_Too_High_Event_Upload_Image_Enable
          this.iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Image_Enable = res.item[0].params.body.Relative_Humidity_Too_Low_Event_Upload_Image_Enable
          this.iotConfigs.SwitchingHouse_Person_Event_Upload_Image_Enable = res.item[0].params.body.SwitchingHouse_Person_Event_Upload_Image_Enable
          this.iotConfigs.SwitchingHouse_Door_Open_Event_Upload_Image_Enable = res.item[0].params.body.SwitchingHouse_Door_Open_Event_Upload_Image_Enable
          this.iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Image_Enable = res.item[0].params.body.EngineRoom_FeedFan_Start_Event_Upload_Image_Enable
          this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable = res.item[0].params.body.EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable
          this.iotConfigs.IncomingCabinet_1_Opening_Event_Upload_Image_Enable = res.item[0].params.body.IncomingCabinet_1_Opening_Event_Upload_Image_Enable
          this.iotConfigs.IncomingCabinet_2_Opening_Event_Upload_Image_Enable = res.item[0].params.body.IncomingCabinet_2_Opening_Event_Upload_Image_Enable
          this.iotConfigs.Transformer_1_Cos_TooLow_Alarm_Upload_Image_Enable = res.item[0].params.body.Transformer_1_Cos_TooLow_Alarm_Upload_Image_Enable
          this.iotConfigs.Transformer_2_Cos_TooLow_Alarm_Upload_Image_Enable = res.item[0].params.body.Transformer_2_Cos_TooLow_Alarm_Upload_Image_Enable
          this.iotConfigs.ContactCabinetSwitch_Closing_Event_Upload_Image_Enable = res.item[0].params.body.ContactCabinetSwitch_Closing_Event_Upload_Image_Enable
          this.iotConfigs.Group_1_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable = res.item[0].params.body.Group_1_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable
          this.iotConfigs.Group_1_GenSwitch_Closing_Event_Upload_Image_Enable = res.item[0].params.body.Group_1_GenSwitch_Closing_Event_Upload_Image_Enable
          this.iotConfigs.Group_2_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable = res.item[0].params.body.Group_2_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable
          this.iotConfigs.Group_2_GenSwitch_Closing_Event_Upload_Image_Enable = res.item[0].params.body.Group_2_GenSwitch_Closing_Event_Upload_Image_Enable
          this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable = res.item[0].params.body.Group_1_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable
          this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable = res.item[0].params.body.Group_2_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable
          this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable = res.item[0].params.body.Group_3_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable
          this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable = res.item[0].params.body.Group_4_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable
          this.iotConfigs.CompensationCabinetCapacity_1_Opening_Event_Upload_Image_Enable = res.item[0].params.body.CompensationCabinetCapacity_1_Opening_Event_Upload_Image_Enable
          this.iotConfigs.CompensationCabinetCapacity_2_Opening_Event_Upload_Image_Enable = res.item[0].params.body.CompensationCabinetCapacity_2_Opening_Event_Upload_Image_Enable
          this.iotConfigs.CompensationCabinetCapacity_3_Opening_Event_Upload_Image_Enable = res.item[0].params.body.CompensationCabinetCapacity_3_Opening_Event_Upload_Image_Enable
          this.iotConfigs.CompensationCabinetCapacity_4_Opening_Event_Upload_Image_Enable = res.item[0].params.body.CompensationCabinetCapacity_4_Opening_Event_Upload_Image_Enable
          this.iotConfigs.MoistureProofLamps_On_Event_Upload_Image_Enable = res.item[0].params.body.MoistureProofLamps_On_Event_Upload_Image_Enable
          this.iotConfigs.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Image_Enable = res.item[0].params.body.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Image_Enable
          this.iotConfigs.Group_1_Transformer_Fan_Start_Event_Upload_Image_Enable = res.item[0].params.body.Group_1_Transformer_Fan_Start_Event_Upload_Image_Enable
          this.iotConfigs.Group_2_Transformer_Fan_Start_Event_Upload_Image_Enable = res.item[0].params.body.Group_2_Transformer_Fan_Start_Event_Upload_Image_Enable
          this.iotConfigs.Group_1_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable = res.item[0].params.body.Group_1_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable
          this.iotConfigs.Group_2_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable = res.item[0].params.body.Group_2_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable
          this.iotConfigs.IncomingCabinetSwitch_AllOpening_Alarm_Upload_Image_Enable = res.item[0].params.body.IncomingCabinetSwitch_AllOpening_Alarm_Upload_Image_Enable
          this.iotConfigs.IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Image_Enable = res.item[0].params.body.IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Image_Enable
          this.iotConfigs.IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Image_Enable = res.item[0].params.body.IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Image_Enable
          this.iotConfigs.IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Image_Enable = res.item[0].params.body.IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Image_Enable
          this.iotConfigs.IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Image_Enable = res.item[0].params.body.IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Image_Enable
          this.iotConfigs.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Image_Enable = res.item[0].params.body.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Image_Enable
          this.iotConfigs.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Image_Enable = res.item[0].params.body.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Image_Enable
          this.iotConfigs.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Image_Enable = res.item[0].params.body.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Image_Enable
          this.iotConfigs.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Image_Enable = res.item[0].params.body.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Image_Enable
          this.iotConfigs.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Image_Enable = res.item[0].params.body.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Image_Enable
          this.iotConfigs.CompensationCabinetSwitch_1_Opening_Alarm_Upload_Image_Enable = res.item[0].params.body.CompensationCabinetSwitch_1_Opening_Alarm_Upload_Image_Enable
          this.iotConfigs.CompensationCabinetSwitch_2_Opening_Alarm_Upload_Image_Enable = res.item[0].params.body.CompensationCabinetSwitch_2_Opening_Alarm_Upload_Image_Enable
          this.iotConfigs.CompensationCabinetSwitch_3_Opening_Alarm_Upload_Image_Enable = res.item[0].params.body.CompensationCabinetSwitch_3_Opening_Alarm_Upload_Image_Enable
          this.iotConfigs.CompensationCabinetSwitch_4_Opening_Alarm_Upload_Image_Enable = res.item[0].params.body.CompensationCabinetSwitch_4_Opening_Alarm_Upload_Image_Enable
          this.iotConfigs.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable = res.item[0].params.body.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable
          this.iotConfigs.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable = res.item[0].params.body.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable
          this.iotConfigs.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable = res.item[0].params.body.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable
          this.iotConfigs.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable = res.item[0].params.body.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable
          this.iotConfigs.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable = res.item[0].params.body.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable
          this.iotConfigs.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable = res.item[0].params.body.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable
          this.iotConfigs.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable = res.item[0].params.body.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable
          this.iotConfigs.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable = res.item[0].params.body.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable
          this.iotConfigs.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable = res.item[0].params.body.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable
          this.iotConfigs.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable = res.item[0].params.body.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable
          this.iotConfigs.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Image_Enable = res.item[0].params.body.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Image_Enable
          this.iotConfigs.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Image_Enable = res.item[0].params.body.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Image_Enable
          this.iotConfigs.Transformer_1_TempHigh_Alarm_Upload_Image_Enable = res.item[0].params.body.Transformer_1_TempHigh_Alarm_Upload_Image_Enable
          this.iotConfigs.Transformer_2_TempHigh_Alarm_Upload_Image_Enable = res.item[0].params.body.Transformer_2_TempHigh_Alarm_Upload_Image_Enable
          this.iotConfigs.Transformer_1_TempHigh_FanStop_Alarm_Upload_Image_Enable = res.item[0].params.body.Transformer_1_TempHigh_FanStop_Alarm_Upload_Image_Enable
          this.iotConfigs.Transformer_2_TempHigh_FanStop_Alarm_Upload_Image_Enable = res.item[0].params.body.Transformer_2_TempHigh_FanStop_Alarm_Upload_Image_Enable
          
          
          //上传录像使能参数
          this.iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Video_Enable = res.item[0].params.body.Celsius_Temperature_Too_High_Event_Upload_Video_Enable
          this.iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Video_Enable = res.item[0].params.body.Celsius_Temperature_Too_Low_Event_Upload_Video_Enable
          this.iotConfigs.Relative_Humidity_Too_High_Event_Upload_Video_Enable = res.item[0].params.body.Relative_Humidity_Too_High_Event_Upload_Video_Enable
          this.iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Video_Enable = res.item[0].params.body.Relative_Humidity_Too_Low_Event_Upload_Video_Enable
          this.iotConfigs.SwitchingHouse_Person_Event_Upload_Video_Enable = res.item[0].params.body.SwitchingHouse_Person_Event_Upload_Video_Enable
          this.iotConfigs.SwitchingHouse_Door_Open_Event_Upload_Video_Enable = res.item[0].params.body.SwitchingHouse_Door_Open_Event_Upload_Video_Enable
          this.iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Video_Enable = res.item[0].params.body.EngineRoom_FeedFan_Start_Event_Upload_Video_Enable
          this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable = res.item[0].params.body.EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable
          this.iotConfigs.IncomingCabinet_1_Opening_Event_Upload_Video_Enable = res.item[0].params.body.IncomingCabinet_1_Opening_Event_Upload_Video_Enable
          this.iotConfigs.IncomingCabinet_2_Opening_Event_Upload_Video_Enable = res.item[0].params.body.IncomingCabinet_2_Opening_Event_Upload_Video_Enable
          this.iotConfigs.Transformer_1_Cos_TooLow_Alarm_Upload_Video_Enable = res.item[0].params.body.Transformer_1_Cos_TooLow_Alarm_Upload_Video_Enable
          this.iotConfigs.Transformer_2_Cos_TooLow_Alarm_Upload_Video_Enable = res.item[0].params.body.Transformer_2_Cos_TooLow_Alarm_Upload_Video_Enable
          this.iotConfigs.ContactCabinetSwitch_Closing_Event_Upload_Video_Enable = res.item[0].params.body.ContactCabinetSwitch_Closing_Event_Upload_Video_Enable
          this.iotConfigs.Group_1_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable = res.item[0].params.body.Group_1_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable
          this.iotConfigs.Group_1_GenSwitch_Closing_Event_Upload_Video_Enable = res.item[0].params.body.Group_1_GenSwitch_Closing_Event_Upload_Video_Enable
          this.iotConfigs.Group_2_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable = res.item[0].params.body.Group_2_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable
          this.iotConfigs.Group_2_GenSwitch_Closing_Event_Upload_Video_Enable = res.item[0].params.body.Group_2_GenSwitch_Closing_Event_Upload_Video_Enable
          this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable = res.item[0].params.body.Group_1_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable
          this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable = res.item[0].params.body.Group_2_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable
          this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable = res.item[0].params.body.Group_3_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable
          this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable = res.item[0].params.body.Group_4_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable
          this.iotConfigs.CompensationCabinetCapacity_1_Opening_Event_Upload_Video_Enable = res.item[0].params.body.CompensationCabinetCapacity_1_Opening_Event_Upload_Video_Enable
          this.iotConfigs.CompensationCabinetCapacity_2_Opening_Event_Upload_Video_Enable = res.item[0].params.body.CompensationCabinetCapacity_2_Opening_Event_Upload_Video_Enable
          this.iotConfigs.CompensationCabinetCapacity_3_Opening_Event_Upload_Video_Enable = res.item[0].params.body.CompensationCabinetCapacity_3_Opening_Event_Upload_Video_Enable
          this.iotConfigs.CompensationCabinetCapacity_4_Opening_Event_Upload_Video_Enable = res.item[0].params.body.CompensationCabinetCapacity_4_Opening_Event_Upload_Video_Enable
          this.iotConfigs.MoistureProofLamps_On_Event_Upload_Video_Enable = res.item[0].params.body.MoistureProofLamps_On_Event_Upload_Video_Enable
          this.iotConfigs.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Video_Enable = res.item[0].params.body.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Video_Enable
          this.iotConfigs.Group_1_Transformer_Fan_Start_Event_Upload_Video_Enable = res.item[0].params.body.Group_1_Transformer_Fan_Start_Event_Upload_Video_Enable
          this.iotConfigs.Group_2_Transformer_Fan_Start_Event_Upload_Video_Enable = res.item[0].params.body.Group_2_Transformer_Fan_Start_Event_Upload_Video_Enable
          this.iotConfigs.Group_1_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable = res.item[0].params.body.Group_1_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable
          this.iotConfigs.Group_2_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable = res.item[0].params.body.Group_2_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable
          this.iotConfigs.IncomingCabinetSwitch_AllOpening_Alarm_Upload_Video_Enable = res.item[0].params.body.IncomingCabinetSwitch_AllOpening_Alarm_Upload_Video_Enable
          this.iotConfigs.IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Video_Enable = res.item[0].params.body.IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Video_Enable
          this.iotConfigs.IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Video_Enable = res.item[0].params.body.IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Video_Enable
          this.iotConfigs.IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Video_Enable = res.item[0].params.body.IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Video_Enable
          this.iotConfigs.IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Video_Enable = res.item[0].params.body.IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Video_Enable
          this.iotConfigs.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Video_Enable = res.item[0].params.body.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Video_Enable
          this.iotConfigs.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Video_Enable = res.item[0].params.body.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Video_Enable
          this.iotConfigs.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Video_Enable = res.item[0].params.body.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Video_Enable
          this.iotConfigs.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Video_Enable = res.item[0].params.body.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Video_Enable
          this.iotConfigs.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Video_Enable = res.item[0].params.body.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Video_Enable
          this.iotConfigs.CompensationCabinetSwitch_1_Opening_Alarm_Upload_Video_Enable = res.item[0].params.body.CompensationCabinetSwitch_1_Opening_Alarm_Upload_Video_Enable
          this.iotConfigs.CompensationCabinetSwitch_2_Opening_Alarm_Upload_Video_Enable = res.item[0].params.body.CompensationCabinetSwitch_2_Opening_Alarm_Upload_Video_Enable
          this.iotConfigs.CompensationCabinetSwitch_3_Opening_Alarm_Upload_Video_Enable = res.item[0].params.body.CompensationCabinetSwitch_3_Opening_Alarm_Upload_Video_Enable
          this.iotConfigs.CompensationCabinetSwitch_4_Opening_Alarm_Upload_Video_Enable = res.item[0].params.body.CompensationCabinetSwitch_4_Opening_Alarm_Upload_Video_Enable
          this.iotConfigs.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable = res.item[0].params.body.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable
          this.iotConfigs.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable = res.item[0].params.body.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable
          this.iotConfigs.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable = res.item[0].params.body.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable
          this.iotConfigs.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable = res.item[0].params.body.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable
          this.iotConfigs.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable = res.item[0].params.body.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable
          this.iotConfigs.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable = res.item[0].params.body.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable
          this.iotConfigs.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable = res.item[0].params.body.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable
          this.iotConfigs.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable = res.item[0].params.body.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable
          this.iotConfigs.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable = res.item[0].params.body.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable
          this.iotConfigs.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable = res.item[0].params.body.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable
          this.iotConfigs.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Video_Enable = res.item[0].params.body.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Video_Enable
          this.iotConfigs.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Video_Enable = res.item[0].params.body.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Video_Enable
          this.iotConfigs.Transformer_1_TempHigh_Alarm_Upload_Video_Enable = res.item[0].params.body.Transformer_1_TempHigh_Alarm_Upload_Video_Enable
          this.iotConfigs.Transformer_2_TempHigh_Alarm_Upload_Video_Enable = res.item[0].params.body.Transformer_2_TempHigh_Alarm_Upload_Video_Enable
          this.iotConfigs.Transformer_1_TempHigh_FanStop_Alarm_Upload_Video_Enable = res.item[0].params.body.Transformer_1_TempHigh_FanStop_Alarm_Upload_Video_Enable
          this.iotConfigs.Transformer_2_TempHigh_FanStop_Alarm_Upload_Video_Enable = res.item[0].params.body.Transformer_2_TempHigh_FanStop_Alarm_Upload_Video_Enable

          //IO绑定事件参数
          this.iotConfigs.SwitchingHouse_Door_Open_Bind_Port = res.item[0].params.body.SwitchingHouse_Door_Open_Bind_Port
          this.iotConfigs.Group_1_Transformer_Temp_Bind_Port = res.item[0].params.body.Group_1_Transformer_Temp_Bind_Port
          this.iotConfigs.Group_1_Transformer_Fan_Start_Bind_Port = res.item[0].params.body.Group_1_Transformer_Fan_Start_Bind_Port
          this.iotConfigs.Group_2_Transformer_Temp_Bind_Port = res.item[0].params.body.Group_2_Transformer_Temp_Bind_Port
          this.iotConfigs.Group_2_Transformer_Fan_Start_Bind_Port = res.item[0].params.body.Group_2_Transformer_Fan_Start_Bind_Port
          this.iotConfigs.Voip_Alarm_Bind_Port = res.item[0].params.body.Voip_Alarm_Bind_Port
          this.iotConfigs.IncomingCabinet_1_Switching_Bind_Port = res.item[0].params.body.IncomingCabinet_1_Switching_Bind_Port
          this.iotConfigs.IncomingCabinet_2_Switching_Bind_Port = res.item[0].params.body.IncomingCabinet_2_Switching_Bind_Port
          this.iotConfigs.ContactCabinetSwitch_Bind_Port = res.item[0].params.body.ContactCabinetSwitch_Bind_Port
          this.iotConfigs.Group_1_ElectricSupplySwitch_Bind_Port = res.item[0].params.body.Group_1_ElectricSupplySwitch_Bind_Port
          this.iotConfigs.Group_1_GenSwitch_Bind_Port = res.item[0].params.body.Group_1_GenSwitch_Bind_Port
          this.iotConfigs.Group_2_ElectricSupplySwitch_Bind_Port = res.item[0].params.body.Group_2_ElectricSupplySwitch_Bind_Port
          this.iotConfigs.Group_2_GenSwitch_Bind_Port = res.item[0].params.body.Group_2_GenSwitch_Bind_Port
          this.iotConfigs.Group_1_CompensationCabinetSwitch_Bind_Port = res.item[0].params.body.Group_1_CompensationCabinetSwitch_Bind_Port
          this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Bind_Port = res.item[0].params.body.Group_1_CompensationCabinetCapacity_Run_Bind_Port
          this.iotConfigs.Group_2_CompensationCabinetSwitch_Bind_Port = res.item[0].params.body.Group_2_CompensationCabinetSwitch_Bind_Port
          this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Bind_Port = res.item[0].params.body.Group_2_CompensationCabinetCapacity_Run_Bind_Port
          this.iotConfigs.Group_3_CompensationCabinetSwitch_Bind_Port = res.item[0].params.body.Group_3_CompensationCabinetSwitch_Bind_Port
          this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Bind_Port = res.item[0].params.body.Group_3_CompensationCabinetCapacity_Run_Bind_Port
          this.iotConfigs.Group_4_CompensationCabinetSwitch_Bind_Port = res.item[0].params.body.Group_4_CompensationCabinetSwitch_Bind_Port
          this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Bind_Port = res.item[0].params.body.Group_4_CompensationCabinetCapacity_Run_Bind_Port
          this.iotConfigs.MoistureProofLamps_Bind_Port = res.item[0].params.body.MoistureProofLamps_Bind_Port
          this.iotConfigs.SwitchingHouse_FeedFan_Start_Bind_Port = res.item[0].params.body.SwitchingHouse_FeedFan_Start_Bind_Port
          this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Bind_Port = res.item[0].params.body.SwitchingHouse_ExhaustFan_Start_Bind_Port


          //IO参数
          this.iotConfigs.SwitchingHouse_Door_Open_Gpio_State = res.item[0].params.body.SwitchingHouse_Door_Open_Gpio_State
          // this.iotConfigs.Group_1_FeederCircuit_A_Current_Gpio_State = res.item[0].params.body.Group_1_FeederCircuit_A_Current_Gpio_State
          // this.iotConfigs.Group_1_FeederCircuit_B_Current_Gpio_State = res.item[0].params.body.Group_1_FeederCircuit_B_Current_Gpio_State
          // this.iotConfigs.Group_1_FeederCircuit_C_Current_Gpio_State = res.item[0].params.body.Group_1_FeederCircuit_C_Current_Gpio_State
          // this.iotConfigs.Group_2_FeederCircuit_A_Current_Gpio_State = res.item[0].params.body.Group_2_FeederCircuit_A_Current_Gpio_State
          // this.iotConfigs.Group_2_FeederCircuit_B_Current_Gpio_State = res.item[0].params.body.Group_2_FeederCircuit_B_Current_Gpio_State
          // this.iotConfigs.Group_2_FeederCircuit_C_Current_Gpio_State = res.item[0].params.body.Group_2_FeederCircuit_C_Current_Gpio_State
          this.iotConfigs.Group_1_Transformer_Temp_Gpio_State = res.item[0].params.body.Group_1_Transformer_Temp_Gpio_State
          this.iotConfigs.Group_1_Transformer_Fan_Start_Gpio_State = res.item[0].params.body.Group_1_Transformer_Fan_Start_Gpio_State
          this.iotConfigs.Group_2_Transformer_Temp_Gpio_State = res.item[0].params.body.Group_2_Transformer_Temp_Gpio_State
          this.iotConfigs.Group_2_Transformer_Fan_Start_Gpio_State = res.item[0].params.body.Group_2_Transformer_Fan_Start_Gpio_State
          this.iotConfigs.Voip_Alarm_Gpio_State = res.item[0].params.body.Voip_Alarm_Gpio_State
          this.iotConfigs.IncomingCabinet_1_Switching_Gpio_State = res.item[0].params.body.IncomingCabinet_1_Switching_Gpio_State
          this.iotConfigs.IncomingCabinet_2_Switching_Gpio_State = res.item[0].params.body.IncomingCabinet_2_Switching_Gpio_State
          this.iotConfigs.ContactCabinetSwitch_Gpio_State = res.item[0].params.body.ContactCabinetSwitch_Gpio_State
          this.iotConfigs.Group_1_ElectricSupplySwitch_Gpio_State = res.item[0].params.body.Group_1_ElectricSupplySwitch_Gpio_State
          this.iotConfigs.Group_1_GenSwitch_Gpio_State = res.item[0].params.body.Group_1_GenSwitch_Gpio_State
          this.iotConfigs.Group_2_ElectricSupplySwitch_Gpio_State = res.item[0].params.body.Group_2_ElectricSupplySwitch_Gpio_State
          this.iotConfigs.Group_2_GenSwitch_Gpio_State = res.item[0].params.body.Group_2_GenSwitch_Gpio_State
          this.iotConfigs.Group_1_CompensationCabinetSwitch_Gpio_State = res.item[0].params.body.Group_1_CompensationCabinetSwitch_Gpio_State
          this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Gpio_State = res.item[0].params.body.Group_1_CompensationCabinetCapacity_Run_Gpio_State
          this.iotConfigs.Group_2_CompensationCabinetSwitch_Gpio_State = res.item[0].params.body.Group_2_CompensationCabinetSwitch_Gpio_State
          this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Gpio_State = res.item[0].params.body.Group_2_CompensationCabinetCapacity_Run_Gpio_State
          this.iotConfigs.Group_3_CompensationCabinetSwitch_Gpio_State = res.item[0].params.body.Group_3_CompensationCabinetSwitch_Gpio_State
          this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Gpio_State = res.item[0].params.body.Group_3_CompensationCabinetCapacity_Run_Gpio_State
          this.iotConfigs.Group_4_CompensationCabinetSwitch_Gpio_State = res.item[0].params.body.Group_4_CompensationCabinetSwitch_Gpio_State
          this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Gpio_State = res.item[0].params.body.Group_4_CompensationCabinetCapacity_Run_Gpio_State
          this.iotConfigs.MoistureProofLamps_Gpio_State = res.item[0].params.body.MoistureProofLamps_Gpio_State
          this.iotConfigs.SwitchingHouse_FeedFan_Start_Gpio_State = res.item[0].params.body.SwitchingHouse_FeedFan_Start_Gpio_State
          this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Gpio_State = res.item[0].params.body.SwitchingHouse_ExhaustFan_Start_Gpio_State
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    setParamsConfig() {
      let paramsBody = {}
      if (this.electricitySystemIotFlag.iotFlag == 0) {
        paramsBody = {
          IO_Calculation_Interval:this.iotConfigs.IO_Calculation_Interval,
          IO_Sampling_Interval:this.iotConfigs.IO_Sampling_Interval,
          IO_State_Effective_Threshold:this.iotConfigs.IO_State_Effective_Threshold,
          Celsius_Temperature_Max_Threshold:this.iotConfigs.Celsius_Temperature_Max_Threshold,
          Celsius_Temperature_Min_Threshold:this.iotConfigs.Celsius_Temperature_Min_Threshold,
          Relative_Humidity_Max_Threshold:this.iotConfigs.Relative_Humidity_Max_Threshold,
          Relative_Humidity_Min_Threshold:this.iotConfigs.Relative_Humidity_Min_Threshold,
          Celsius_Temperature_State_List_Max:this.iotConfigs.Celsius_Temperature_State_List_Max,
          Celsius_Temperature_State_List_Threshold:this.iotConfigs.Celsius_Temperature_State_List_Threshold,
          Relative_Humidity_State_List_Max:this.iotConfigs.Relative_Humidity_State_List_Max,
          Relative_Humidity_State_List_Threshold:this.iotConfigs.Relative_Humidity_State_List_Threshold,
          Person_Detect_List_Max:this.iotConfigs.Person_Detect_List_Max,
          Person_Detect_List_Threshold:this.iotConfigs.Person_Detect_List_Threshold,
          IncomingCabinet_Minimum_Voltage_Threshold:this.iotConfigs.IncomingCabinet_Minimum_Voltage_Threshold,
          IncomingCabinet_Maximum_Voltage_Threshold:this.iotConfigs.IncomingCabinet_Maximum_Voltage_Threshold,
          Transformer_Cos_Minimum_Threshold:this.iotConfigs.Transformer_Cos_Minimum_Threshold,
          FeederCircuit_Maximum_Current_Threshold:this.iotConfigs.FeederCircuit_Maximum_Current_Threshold,
          Transformer_LoadRate_Minimum_Threshold:this.iotConfigs.Transformer_LoadRate_Minimum_Threshold,
          Transformer_Overheat_Fan_Run_Time_Threshold:this.iotConfigs.Transformer_Overheat_Fan_Run_Time_Threshold,
          Transformer_Rated_Power:this.iotConfigs.Transformer_Rated_Power,
          Transformer_1_Cos_Minimum_Threshold:this.iotConfigs.Transformer_1_Cos_Minimum_Threshold,
          Transformer_2_Cos_Minimum_Threshold:this.iotConfigs.Transformer_2_Cos_Minimum_Threshold,
          FeederCircuit_1_Maximum_Current_Threshold:this.iotConfigs.FeederCircuit_1_Maximum_Current_Threshold,
          FeederCircuit_2_Maximum_Current_Threshold:this.iotConfigs.FeederCircuit_2_Maximum_Current_Threshold,
          Voip_Alarm_Enable:this.iotConfigs.Voip_Alarm_Enable,
          Celsius_Temperature_Too_High_Event_Enable:this.iotConfigs.Celsius_Temperature_Too_High_Event_Enable,
          Celsius_Temperature_Too_Low_Event_Enable:this.iotConfigs.Celsius_Temperature_Too_Low_Event_Enable,
          Relative_Humidity_Too_High_Event_Enable:this.iotConfigs.Relative_Humidity_Too_High_Event_Enable,
          Relative_Humidity_Too_Low_Event_Enable:this.iotConfigs.Relative_Humidity_Too_Low_Event_Enable,
          SwitchingHouse_Person_Event_Enable:this.iotConfigs.SwitchingHouse_Person_Event_Enable,
          SwitchingHouse_Door_Open_Event_Enable:this.iotConfigs.SwitchingHouse_Door_Open_Event_Enable,
          EngineRoom_FeedFan_Start_Event_Enable:this.iotConfigs.EngineRoom_FeedFan_Start_Event_Enable,
          EngineRoom_ExhaustFan_Start_Event_Enable:this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Enable,
          IncomingCabinet_1_Opening_Event_Enable:this.iotConfigs.IncomingCabinet_1_Opening_Event_Enable,
          IncomingCabinet_2_Opening_Event_Enable:this.iotConfigs.IncomingCabinet_2_Opening_Event_Enable,
          Transformer_1_Cos_TooLow_Alarm_Enable:this.iotConfigs.Transformer_1_Cos_TooLow_Alarm_Enable,
          Transformer_2_Cos_TooLow_Alarm_Enable:this.iotConfigs.Transformer_2_Cos_TooLow_Alarm_Enable,
          ContactCabinetSwitch_Closing_Event_Enable:this.iotConfigs.ContactCabinetSwitch_Closing_Event_Enable,
          Group_1_ElectricSupplySwitch_Opening_Event_Enable:this.iotConfigs.Group_1_ElectricSupplySwitch_Opening_Event_Enable,
          Group_1_GenSwitch_Closing_Event_Enable:this.iotConfigs.Group_1_GenSwitch_Closing_Event_Enable,
          Group_2_ElectricSupplySwitch_Opening_Event_Enable:this.iotConfigs.Group_2_ElectricSupplySwitch_Opening_Event_Enable,
          Group_2_GenSwitch_Closing_Event_Enable:this.iotConfigs.Group_2_GenSwitch_Closing_Event_Enable,
          Group_1_CompensationCabinetCapacity_Run_Event_Enable:this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Event_Enable,
          CompensationCabinetCapacity_1_Opening_Event_Enable:this.iotConfigs.CompensationCabinetCapacity_1_Opening_Event_Enable,
          Group_2_CompensationCabinetCapacity_Run_Event_Enable:this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Event_Enable,
          CompensationCabinetCapacity_2_Opening_Event_Enable:this.iotConfigs.CompensationCabinetCapacity_2_Opening_Event_Enable,
          Group_3_CompensationCabinetCapacity_Run_Event_Enable:this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Event_Enable,
          CompensationCabinetCapacity_3_Opening_Event_Enable:this.iotConfigs.CompensationCabinetCapacity_3_Opening_Event_Enable,
          Group_4_CompensationCabinetCapacity_Run_Event_Enable:this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Event_Enable,
          CompensationCabinetCapacity_4_Opening_Event_Enable:this.iotConfigs.CompensationCabinetCapacity_4_Opening_Event_Enable,
          SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Enable:this.iotConfigs.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Enable,
          SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Enable:this.iotConfigs.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Enable,
          MoistureProofLamps_On_Event_Enable:this.iotConfigs.MoistureProofLamps_On_Event_Enable,
          Group_1_Transformer_Fan_Start_Event_Enable:this.iotConfigs.Group_1_Transformer_Fan_Start_Event_Enable,
          Group_2_Transformer_Fan_Start_Event_Enable:this.iotConfigs.Group_2_Transformer_Fan_Start_Event_Enable,
          Group_1_Transformer_LoadRate_TooLow_Event_Enable:this.iotConfigs.Group_1_Transformer_LoadRate_TooLow_Event_Enable,
          Group_2_Transformer_LoadRate_TooLow_Event_Enable:this.iotConfigs.Group_2_Transformer_LoadRate_TooLow_Event_Enable,
          IncomingCabinetSwitch_AllOpening_Alarm_Enable:this.iotConfigs.IncomingCabinetSwitch_AllOpening_Alarm_Enable,
          IncomingCabinet_1_Voltage_TooLow_Alarm_Enable:this.iotConfigs.IncomingCabinet_1_Voltage_TooLow_Alarm_Enable,
          IncomingCabinet_2_Voltage_TooLow_Alarm_Enable:this.iotConfigs.IncomingCabinet_2_Voltage_TooLow_Alarm_Enable,
          IncomingCabinet_1_Voltage_TooHigh_Alarm_Enable:this.iotConfigs.IncomingCabinet_1_Voltage_TooHigh_Alarm_Enable,
          IncomingCabinet_2_Voltage_TooHigh_Alarm_Enable:this.iotConfigs.IncomingCabinet_2_Voltage_TooHigh_Alarm_Enable,
          ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Enable:this.iotConfigs.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Enable,
          ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Enable:this.iotConfigs.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Enable,
          ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Enable:this.iotConfigs.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Enable,
          ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Enable:this.iotConfigs.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Enable,
          ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Enable:this.iotConfigs.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Enable,
          CompensationCabinetSwitch_1_Opening_Alarm_Enable:this.iotConfigs.CompensationCabinetSwitch_1_Opening_Alarm_Enable,
          CompensationCabinetSwitch_2_Opening_Alarm_Enable:this.iotConfigs.CompensationCabinetSwitch_2_Opening_Alarm_Enable,
          CompensationCabinetSwitch_3_Opening_Alarm_Enable:this.iotConfigs.CompensationCabinetSwitch_3_Opening_Alarm_Enable,
          CompensationCabinetSwitch_4_Opening_Alarm_Enable:this.iotConfigs.CompensationCabinetSwitch_4_Opening_Alarm_Enable,
          Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable:this.iotConfigs.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable,
          Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable:this.iotConfigs.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable,
          Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable:this.iotConfigs.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable,
          Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable:this.iotConfigs.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable,
          Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Enable:this.iotConfigs.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Enable,
          Transformer_1_TempHigh_Alarm_Enable:this.iotConfigs.Transformer_1_TempHigh_Alarm_Enable,
          Transformer_2_TempHigh_Alarm_Enable:this.iotConfigs.Transformer_2_TempHigh_Alarm_Enable,
          Transformer_1_TempHigh_FanStop_Alarm_Enable:this.iotConfigs.Transformer_1_TempHigh_FanStop_Alarm_Enable,
          Transformer_2_TempHigh_FanStop_Alarm_Enable:this.iotConfigs.Transformer_2_TempHigh_FanStop_Alarm_Enable,
          Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Enable:this.iotConfigs.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Enable,
          Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Enable:this.iotConfigs.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Enable,
          Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Enable:this.iotConfigs.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Enable,
          Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Enable:this.iotConfigs.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Enable,
          Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Enable:this.iotConfigs.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Enable,
          Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Enable:this.iotConfigs.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Enable,
          Voip_Alarm_Upload_Video_Enable:this.iotConfigs.Voip_Alarm_Upload_Video_Enable,
          Celsius_Temperature_Too_High_Event_Upload_Video_Enable:this.iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Video_Enable,
          Celsius_Temperature_Too_Low_Event_Upload_Video_Enable:this.iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Video_Enable,
          Relative_Humidity_Too_High_Event_Upload_Video_Enable:this.iotConfigs.Relative_Humidity_Too_High_Event_Upload_Video_Enable,
          Relative_Humidity_Too_Low_Event_Upload_Video_Enable:this.iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Video_Enable,
          SwitchingHouse_Person_Event_Upload_Video_Enable:this.iotConfigs.SwitchingHouse_Person_Event_Upload_Video_Enable,
          SwitchingHouse_Door_Open_Event_Upload_Video_Enable:this.iotConfigs.SwitchingHouse_Door_Open_Event_Upload_Video_Enable,
          EngineRoom_FeedFan_Start_Event_Upload_Video_Enable:this.iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Video_Enable,
          EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable:this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable,
          IncomingCabinet_1_Opening_Event_Upload_Video_Enable:this.iotConfigs.IncomingCabinet_1_Opening_Event_Upload_Video_Enable,
          IncomingCabinet_2_Opening_Event_Upload_Video_Enable:this.iotConfigs.IncomingCabinet_2_Opening_Event_Upload_Video_Enable,
          Transformer_1_Cos_TooLow_Alarm_Upload_Video_Enable:this.iotConfigs.Transformer_1_Cos_TooLow_Alarm_Upload_Video_Enable,
          Transformer_2_Cos_TooLow_Alarm_Upload_Video_Enable:this.iotConfigs.Transformer_2_Cos_TooLow_Alarm_Upload_Video_Enable,
          ContactCabinetSwitch_Closing_Event_Upload_Video_Enable:this.iotConfigs.ContactCabinetSwitch_Closing_Event_Upload_Video_Enable,
          Group_1_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable:this.iotConfigs.Group_1_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable,
          Group_1_GenSwitch_Closing_Event_Upload_Video_Enable:this.iotConfigs.Group_1_GenSwitch_Closing_Event_Upload_Video_Enable,
          Group_2_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable:this.iotConfigs.Group_2_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable,
          Group_2_GenSwitch_Closing_Event_Upload_Video_Enable:this.iotConfigs.Group_2_GenSwitch_Closing_Event_Upload_Video_Enable,
          Group_1_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable:this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable,
          Group_2_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable:this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable,
          Group_3_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable:this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable,
          Group_4_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable:this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable,
          CompensationCabinetCapacity_1_Opening_Event_Upload_Video_Enable:this.iotConfigs.CompensationCabinetCapacity_1_Opening_Event_Upload_Video_Enable,
          CompensationCabinetCapacity_2_Opening_Event_Upload_Video_Enable:this.iotConfigs.CompensationCabinetCapacity_2_Opening_Event_Upload_Video_Enable,
          CompensationCabinetCapacity_3_Opening_Event_Upload_Video_Enable:this.iotConfigs.CompensationCabinetCapacity_3_Opening_Event_Upload_Video_Enable,
          CompensationCabinetCapacity_4_Opening_Event_Upload_Video_Enable:this.iotConfigs.CompensationCabinetCapacity_4_Opening_Event_Upload_Video_Enable,
          MoistureProofLamps_On_Event_Upload_Video_Enable:this.iotConfigs.MoistureProofLamps_On_Event_Upload_Video_Enable,
          Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Video_Enable:this.iotConfigs.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Video_Enable,
          Group_1_Transformer_Fan_Start_Event_Upload_Video_Enable:this.iotConfigs.Group_1_Transformer_Fan_Start_Event_Upload_Video_Enable,
          Group_2_Transformer_Fan_Start_Event_Upload_Video_Enable:this.iotConfigs.Group_2_Transformer_Fan_Start_Event_Upload_Video_Enable,
          Group_1_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable:this.iotConfigs.Group_1_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable,
          Group_2_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable:this.iotConfigs.Group_2_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable,
          IncomingCabinetSwitch_AllOpening_Alarm_Upload_Video_Enable:this.iotConfigs.IncomingCabinetSwitch_AllOpening_Alarm_Upload_Video_Enable,
          IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Video_Enable:this.iotConfigs.IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Video_Enable,
          IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Video_Enable:this.iotConfigs.IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Video_Enable,
          IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Video_Enable:this.iotConfigs.IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Video_Enable,
          IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Video_Enable:this.iotConfigs.IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Video_Enable,
          ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Video_Enable:this.iotConfigs.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Video_Enable,
          ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Video_Enable:this.iotConfigs.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Video_Enable,
          ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Video_Enable:this.iotConfigs.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Video_Enable,
          ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Video_Enable:this.iotConfigs.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Video_Enable,
          ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Video_Enable:this.iotConfigs.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Video_Enable,
          CompensationCabinetSwitch_1_Opening_Alarm_Upload_Video_Enable:this.iotConfigs.CompensationCabinetSwitch_1_Opening_Alarm_Upload_Video_Enable,
          CompensationCabinetSwitch_2_Opening_Alarm_Upload_Video_Enable:this.iotConfigs.CompensationCabinetSwitch_2_Opening_Alarm_Upload_Video_Enable,
          CompensationCabinetSwitch_3_Opening_Alarm_Upload_Video_Enable:this.iotConfigs.CompensationCabinetSwitch_3_Opening_Alarm_Upload_Video_Enable,
          CompensationCabinetSwitch_4_Opening_Alarm_Upload_Video_Enable:this.iotConfigs.CompensationCabinetSwitch_4_Opening_Alarm_Upload_Video_Enable,
          Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable:this.iotConfigs.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable,
          Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable:this.iotConfigs.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable,
          Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable:this.iotConfigs.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable,
          Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable:this.iotConfigs.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable,
          Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable:this.iotConfigs.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable,
          Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable:this.iotConfigs.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable,
          Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable:this.iotConfigs.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable,
          Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable:this.iotConfigs.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable,
          Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable:this.iotConfigs.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable,
          Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable:this.iotConfigs.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable,
          SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Video_Enable:this.iotConfigs.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Video_Enable,
          SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Video_Enable:this.iotConfigs.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Video_Enable,
          Transformer_1_TempHigh_Alarm_Upload_Video_Enable:this.iotConfigs.Transformer_1_TempHigh_Alarm_Upload_Video_Enable,
          Transformer_2_TempHigh_Alarm_Upload_Video_Enable:this.iotConfigs.Transformer_2_TempHigh_Alarm_Upload_Video_Enable,
          Transformer_1_TempHigh_FanStop_Alarm_Upload_Video_Enable:this.iotConfigs.Transformer_1_TempHigh_FanStop_Alarm_Upload_Video_Enable,
          Transformer_2_TempHigh_FanStop_Alarm_Upload_Video_Enable:this.iotConfigs.Transformer_2_TempHigh_FanStop_Alarm_Upload_Video_Enable,

          Voip_Alarm_Upload_Image_Enable:this.iotConfigs.Voip_Alarm_Upload_Image_Enable,
          Celsius_Temperature_Too_High_Event_Upload_Image_Enable:this.iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Image_Enable,
          Celsius_Temperature_Too_Low_Event_Upload_Image_Enable:this.iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Image_Enable,
          Relative_Humidity_Too_High_Event_Upload_Image_Enable:this.iotConfigs.Relative_Humidity_Too_High_Event_Upload_Image_Enable,
          Relative_Humidity_Too_Low_Event_Upload_Image_Enable:this.iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Image_Enable,
          SwitchingHouse_Person_Event_Upload_Image_Enable:this.iotConfigs.SwitchingHouse_Person_Event_Upload_Image_Enable,
          SwitchingHouse_Door_Open_Event_Upload_Image_Enable:this.iotConfigs.SwitchingHouse_Door_Open_Event_Upload_Image_Enable,
          EngineRoom_FeedFan_Start_Event_Upload_Image_Enable:this.iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Image_Enable,
          EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable:this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable,
          IncomingCabinet_1_Opening_Event_Upload_Image_Enable:this.iotConfigs.IncomingCabinet_1_Opening_Event_Upload_Image_Enable,
          IncomingCabinet_2_Opening_Event_Upload_Image_Enable:this.iotConfigs.IncomingCabinet_2_Opening_Event_Upload_Image_Enable,
          Transformer_1_Cos_TooLow_Alarm_Upload_Image_Enable:this.iotConfigs.Transformer_1_Cos_TooLow_Alarm_Upload_Image_Enable,
          Transformer_2_Cos_TooLow_Alarm_Upload_Image_Enable:this.iotConfigs.Transformer_2_Cos_TooLow_Alarm_Upload_Image_Enable,
          ContactCabinetSwitch_Closing_Event_Upload_Image_Enable:this.iotConfigs.ContactCabinetSwitch_Closing_Event_Upload_Image_Enable,
          Group_1_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable:this.iotConfigs.Group_1_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable,
          Group_1_GenSwitch_Closing_Event_Upload_Image_Enable:this.iotConfigs.Group_1_GenSwitch_Closing_Event_Upload_Image_Enable,
          Group_2_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable:this.iotConfigs.Group_2_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable,
          Group_2_GenSwitch_Closing_Event_Upload_Image_Enable:this.iotConfigs.Group_2_GenSwitch_Closing_Event_Upload_Image_Enable,
          Group_1_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable:this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable,
          Group_2_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable:this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable,
          Group_3_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable:this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable,
          Group_4_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable:this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable,
          CompensationCabinetCapacity_1_Opening_Event_Upload_Image_Enable:this.iotConfigs.CompensationCabinetCapacity_1_Opening_Event_Upload_Image_Enable,
          CompensationCabinetCapacity_2_Opening_Event_Upload_Image_Enable:this.iotConfigs.CompensationCabinetCapacity_2_Opening_Event_Upload_Image_Enable,
          CompensationCabinetCapacity_3_Opening_Event_Upload_Image_Enable:this.iotConfigs.CompensationCabinetCapacity_3_Opening_Event_Upload_Image_Enable,
          CompensationCabinetCapacity_4_Opening_Event_Upload_Image_Enable:this.iotConfigs.CompensationCabinetCapacity_4_Opening_Event_Upload_Image_Enable,
          MoistureProofLamps_On_Event_Upload_Image_Enable:this.iotConfigs.MoistureProofLamps_On_Event_Upload_Image_Enable,
          Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Image_Enable:this.iotConfigs.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Image_Enable,
          Group_1_Transformer_Fan_Start_Event_Upload_Image_Enable:this.iotConfigs.Group_1_Transformer_Fan_Start_Event_Upload_Image_Enable,
          Group_2_Transformer_Fan_Start_Event_Upload_Image_Enable:this.iotConfigs.Group_2_Transformer_Fan_Start_Event_Upload_Image_Enable,
          Group_1_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable:this.iotConfigs.Group_1_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable,
          Group_2_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable:this.iotConfigs.Group_2_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable,
          IncomingCabinetSwitch_AllOpening_Alarm_Upload_Image_Enable:this.iotConfigs.IncomingCabinetSwitch_AllOpening_Alarm_Upload_Image_Enable,
          IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Image_Enable:this.iotConfigs.IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Image_Enable,
          IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Image_Enable:this.iotConfigs.IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Image_Enable,
          IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Image_Enable:this.iotConfigs.IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Image_Enable,
          IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Image_Enable:this.iotConfigs.IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Image_Enable,
          ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Image_Enable:this.iotConfigs.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Image_Enable,
          ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Image_Enable:this.iotConfigs.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Image_Enable,
          ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Image_Enable:this.iotConfigs.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Image_Enable,
          ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Image_Enable:this.iotConfigs.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Image_Enable,
          ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Image_Enable:this.iotConfigs.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Image_Enable,
          CompensationCabinetSwitch_1_Opening_Alarm_Upload_Image_Enable:this.iotConfigs.CompensationCabinetSwitch_1_Opening_Alarm_Upload_Image_Enable,
          CompensationCabinetSwitch_2_Opening_Alarm_Upload_Image_Enable:this.iotConfigs.CompensationCabinetSwitch_2_Opening_Alarm_Upload_Image_Enable,
          CompensationCabinetSwitch_3_Opening_Alarm_Upload_Image_Enable:this.iotConfigs.CompensationCabinetSwitch_3_Opening_Alarm_Upload_Image_Enable,
          CompensationCabinetSwitch_4_Opening_Alarm_Upload_Image_Enable:this.iotConfigs.CompensationCabinetSwitch_4_Opening_Alarm_Upload_Image_Enable,
          Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable:this.iotConfigs.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable,
          Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable:this.iotConfigs.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable,
          Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable:this.iotConfigs.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable,
          Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable:this.iotConfigs.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable,
          Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable:this.iotConfigs.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable,
          Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable:this.iotConfigs.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable,
          Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable:this.iotConfigs.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable,
          Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable:this.iotConfigs.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable,
          Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable:this.iotConfigs.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable,
          Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable:this.iotConfigs.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable,
          SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Image_Enable:this.iotConfigs.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Image_Enable,
          SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Image_Enable:this.iotConfigs.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Image_Enable,
          Transformer_1_TempHigh_Alarm_Upload_Image_Enable:this.iotConfigs.Transformer_1_TempHigh_Alarm_Upload_Image_Enable,
          Transformer_2_TempHigh_Alarm_Upload_Image_Enable:this.iotConfigs.Transformer_2_TempHigh_Alarm_Upload_Image_Enable,
          Transformer_1_TempHigh_FanStop_Alarm_Upload_Image_Enable:this.iotConfigs.Transformer_1_TempHigh_FanStop_Alarm_Upload_Image_Enable,
          Transformer_2_TempHigh_FanStop_Alarm_Upload_Image_Enable:this.iotConfigs.Transformer_2_TempHigh_FanStop_Alarm_Upload_Image_Enable,
        }
        this.paramsdata = "IO状态判断时间间隔" + "," + this.iotConfigs.IO_Calculation_Interval + ","
            + "IO状态采样时间间隔" + "," + this.iotConfigs.IO_Sampling_Interval + ","
            + "时间窗内IO有效数值占比" + "," + this.iotConfigs.IO_State_Effective_Threshold + ","
            + "温度过高阈值" + "," + this.iotConfigs.Celsius_Temperature_Max_Threshold + ","
            + "温度过低阈值" + "," + this.iotConfigs.Celsius_Temperature_Min_Threshold + ","
            + "相对湿度过高阈值" + "," + this.iotConfigs.Relative_Humidity_Max_Threshold + ","
            + "相对湿度过低阈值" + "," + this.iotConfigs.Relative_Humidity_Min_Threshold + ","
            + "温度队列长度" + "," + this.iotConfigs.Celsius_Temperature_State_List_Max + ","
            + "温度队列阈值" + "," + this.iotConfigs.Celsius_Temperature_State_List_Threshold + ","
            + "湿度队列长度" + "," + this.iotConfigs.Relative_Humidity_State_List_Max + ","
            + "湿度队列阈值" + "," + this.iotConfigs.Relative_Humidity_State_List_Threshold + ","
            + "人体检测队列长度" + "," + this.iotConfigs.Person_Detect_List_Max + ","
            + "人体检测队列阈值" + "," + this.iotConfigs.Person_Detect_List_Threshold + ","
            + "进线电压最小阈值" + "," + this.iotConfigs.IncomingCabinet_Minimum_Voltage_Threshold + ","
            + "进线电压最大阈值" + "," + this.iotConfigs.IncomingCabinet_Maximum_Voltage_Threshold + ","
            + "变压器功率因数最小阈值" + "," + this.iotConfigs.Transformer_Cos_Minimum_Threshold + ","
            + "馈电回路电流最大阈值" + "," + this.iotConfigs.FeederCircuit_Maximum_Current_Threshold + ","
            + "变压器负载率最小阈值" + "," + this.iotConfigs.Transformer_LoadRate_Minimum_Threshold + ","
            + "变压器超温后风扇最迟启动时间阈值" + "," + this.iotConfigs.Transformer_Overheat_Fan_Run_Time_Threshold + ","
            + "变压器额定功率" + "," + this.iotConfigs.Transformer_Rated_Power + ","
            + "第一组变压器功率因数最小阈值" + "," + this.iotConfigs.Transformer_1_Cos_Minimum_Threshold + ","
            + "第二组变压器功率因数最小阈值" + "," + this.iotConfigs.Transformer_2_Cos_Minimum_Threshold + ","
            + "第一组馈电回路电流最大阈值" + "," + this.iotConfigs.FeederCircuit_1_Maximum_Current_Threshold + ","
            + "第二组馈电回路电流最大阈值" + "," + this.iotConfigs.FeederCircuit_2_Maximum_Current_Threshold + ","
            + "拨打值班室电话使能" + "," + this.iotConfigs.Voip_Alarm_Enable + ","
            + "摄氏温度过高事件使能" + "," + this.iotConfigs.Celsius_Temperature_Too_High_Event_Enable + ","
            + "摄氏温度过低事件使能" + "," + this.iotConfigs.Celsius_Temperature_Too_Low_Event_Enable + ","
            + "相对湿度过高事件使能" + "," + this.iotConfigs.Relative_Humidity_Too_High_Event_Enable + ","
            + "相对湿度过低事件使能" + "," + this.iotConfigs.Relative_Humidity_Too_Low_Event_Enable + ","
            + "机房有人事件使能" + "," + this.iotConfigs.SwitchingHouse_Person_Event_Enable + ","
            + "机房门打开事件使能" + "," + this.iotConfigs.SwitchingHouse_Door_Open_Event_Enable + ","
            + "机房送风机启动事件使能" + "," + this.iotConfigs.EngineRoom_FeedFan_Start_Event_Enable + ","
            + "机房排风机启动事件使能" + "," + this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Enable + ","
            + "第一路进线分闸事件使能" + "," + this.iotConfigs.IncomingCabinet_1_Opening_Event_Enable + ","
            + "第二路进线分闸事件使能" + "," + this.iotConfigs.IncomingCabinet_2_Opening_Event_Enable + ","
            + "联络柜开关合闸事件使能" + "," + this.iotConfigs.ContactCabinetSwitch_Closing_Event_Enable + ","
            + "第一组市/发电柜市电分闸事件使能" + "," + this.iotConfigs.Group_1_ElectricSupplySwitch_Opening_Event_Enable + ","
            + "第一组市/发电柜发电合闸事件使能" + "," + this.iotConfigs.Group_1_GenSwitch_Closing_Event_Enable + ","
            + "第二组市/发电柜市电分闸事件使能" + "," + this.iotConfigs.Group_2_ElectricSupplySwitch_Opening_Event_Enable + ","
            + "第二组市/发电柜发电合闸事件使能" + "," + this.iotConfigs.Group_2_GenSwitch_Closing_Event_Enable + ","
            + "第一组补偿电容运行事件使能" + "," + this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Event_Enable + ","
            + "第一组补偿柜电容分闸事件使能" + "," + this.iotConfigs.CompensationCabinetCapacity_1_Opening_Event_Enable + ","
            + "第二组补偿电容运行事件使能" + "," + this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Event_Enable + ","
            + "第二组补偿柜电容分闸事件使能" + "," + this.iotConfigs.CompensationCabinetCapacity_2_Opening_Event_Enable + ","
            + "第三组补偿电容运行事件使能" + "," + this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Event_Enable + ","
            + "第三组补偿柜电容分闸事件使能" + "," + this.iotConfigs.CompensationCabinetCapacity_3_Opening_Event_Enable + ","
            + "第四组补偿电容运行事件使能" + "," + this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Event_Enable + ","
            + "第四组补偿柜电容分闸事件使能" + "," + this.iotConfigs.CompensationCabinetCapacity_4_Opening_Event_Enable + ","
            + "机房温度过高送风机停止报警使能" + "," + this.iotConfigs.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Enable + ","
            + "机房温度过高排风机停止报警使能" + "," + this.iotConfigs.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Enable + ","
            + "防潮灯开启事件使能" + "," + this.iotConfigs.MoistureProofLamps_On_Event_Enable + ","
            + "第一组变压器降温风扇启动事件使能" + "," + this.iotConfigs.Group_1_Transformer_Fan_Start_Event_Enable + ","
            + "第二组变压器降温风扇启动事件使能" + "," + this.iotConfigs.Group_2_Transformer_Fan_Start_Event_Enable + ","
            + "第一组变压器负载率过低事件使能" + "," + this.iotConfigs.Group_1_Transformer_LoadRate_TooLow_Event_Enable + ","
            + "第二组变压器负载率过低事件使能" + "," + this.iotConfigs.Group_2_Transformer_LoadRate_TooLow_Event_Enable + ","
            + "两路进线柜同时分闸报警使能" + "," + this.iotConfigs.IncomingCabinetSwitch_AllOpening_Alarm_Enable + ","
            + "第一路进线柜电压过低报警使能" + "," + this.iotConfigs.IncomingCabinet_1_Voltage_TooLow_Alarm_Enable + ","
            + "第二路进线柜电压过低报警使能" + "," + this.iotConfigs.IncomingCabinet_2_Voltage_TooLow_Alarm_Enable + ","
            + "第一路进线柜电压过高报警使能" + "," + this.iotConfigs.IncomingCabinet_1_Voltage_TooHigh_Alarm_Enable + ","
            + "第二路进线柜电压过高报警使能" + "," + this.iotConfigs.IncomingCabinet_2_Voltage_TooHigh_Alarm_Enable + ","
            + "两路进线柜同时合闸联络柜合闸报警使能" + "," + this.iotConfigs.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Enable + ","
            + "第一组市/发电柜同时合闸报警使能" + "," + this.iotConfigs.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Enable + ","
            + "第二组市/发电柜同时合闸报警使能" + "," + this.iotConfigs.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Enable + ","
            + "第二组市/发电柜同时分闸报警使能" + "," + this.iotConfigs.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Enable + ","
            + "第二组市/发电柜同时分闸报警使能" + "," + this.iotConfigs.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Enable + ","
            + "第一组变压器功率因数过低报警使能" + "," + this.iotConfigs.Transformer_1_Cos_TooLow_Alarm_Enable + ","
            + "第二组变压器功率因数过低报警使能" + "," + this.iotConfigs.Transformer_2_Cos_TooLow_Alarm_Enable + ","
            + "第一组补偿柜分闸报警使能" + "," + this.iotConfigs.CompensationCabinetSwitch_1_Opening_Alarm_Enable + ","
            + "第二组补偿柜分闸报警使能" + "," + this.iotConfigs.CompensationCabinetSwitch_2_Opening_Alarm_Enable + ","
            + "第三组补偿柜分闸报警使能" + "," + this.iotConfigs.CompensationCabinetSwitch_3_Opening_Alarm_Enable + ","
            + "第四组补偿柜分闸报警使能" + "," + this.iotConfigs.CompensationCabinetSwitch_4_Opening_Alarm_Enable + ","
            + "功率因数过低第一组补偿电容停止运行报警使能" + "," + this.iotConfigs.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable + ","
            + "功率因数过低第二组补偿电容停止运行报警使能" + "," + this.iotConfigs.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable + ","
            + "功率因数过低第三组补偿电容停止运行报警使能" + "," + this.iotConfigs.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable + ","
            + "功率因数过低第四组补偿电容停止运行报警使能" + "," + this.iotConfigs.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Enable + ","
            + "机房湿度过高防潮灯关闭报警使能" + "," + this.iotConfigs.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Enable + ","
            + "第一组变压器超温报警使能" + "," + this.iotConfigs.Transformer_1_TempHigh_Alarm_Enable + ","
            + "第二组变压器超温报警使能" + "," + this.iotConfigs.Transformer_2_TempHigh_Alarm_Enable + ","
            + "第一组变压器超温降温风扇未启动报警使能" + "," + this.iotConfigs.Transformer_1_TempHigh_FanStop_Alarm_Enable + ","
            + "第二组变压器超温降温风扇未启动报警使能" + "," + this.iotConfigs.Transformer_2_TempHigh_FanStop_Alarm_Enable + ","
            + "第一组馈电回路A相电流过大报警使能" + "," + this.iotConfigs.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Enable + ","
            + "第一组馈电回路B相电流过大报警使能" + "," + this.iotConfigs.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Enable + ","
            + "第一组馈电回路C相电流过大报警使能" + "," + this.iotConfigs.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Enable + ","
            + "第二组馈电回路A相电流过大报警使能" + "," + this.iotConfigs.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Enable + ","
            + "第二组馈电回路B相电流过大报警使能" + "," + this.iotConfigs.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Enable + ","
            + "第二组馈电回路C相电流过大报警使能" + "," + this.iotConfigs.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Enable + ","
            + "拨打值班室电话事件上传录像使能" + "," + this.iotConfigs.Voip_Alarm_Upload_Video_Enable + ","
            + "摄氏温度过高事件上传录像使能" + "," + this.iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Video_Enable + ","
            + "摄氏温度过低事件上传录像使能" + "," + this.iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Video_Enable + ","
            + "相对湿度过高事件上传录像使能" + "," + this.iotConfigs.Relative_Humidity_Too_High_Event_Upload_Video_Enable + ","
            + "相对湿度过低事件上传录像使能" + "," + this.iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Video_Enable + ","
            + "机房有人事件上传录像使能" + "," + this.iotConfigs.SwitchingHouse_Person_Event_Upload_Video_Enable + ","
            + "机房门打开事件上传录像使能" + "," + this.iotConfigs.SwitchingHouse_Door_Open_Event_Upload_Video_Enable + ","
            + "机房送风机启动事件上传录像使能" + "," + this.iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Video_Enable + ","
            + "机房排风机启动事件上传录像使能" + "," + this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Video_Enable + ","
            + "第一路进线分闸事件上传录像使能" + "," + this.iotConfigs.IncomingCabinet_1_Opening_Event_Upload_Video_Enable + ","
            + "第二路进线分闸事件上传录像使能" + "," + this.iotConfigs.IncomingCabinet_2_Opening_Event_Upload_Video_Enable + ","
            + "第一组变压器功率因数过低报警上传录像使能" + "," + this.iotConfigs.Transformer_1_Cos_TooLow_Alarm_Upload_Video_Enable + ","
            + "第二组变压器功率因数过低报警上传录像使能" + "," + this.iotConfigs.Transformer_2_Cos_TooLow_Alarm_Upload_Video_Enable + ","
            + "联络柜开关合闸事件上传录像使能" + "," + this.iotConfigs.ContactCabinetSwitch_Closing_Event_Upload_Video_Enable + ","
            + "第一组市/发电柜市电分闸事件上传录像使能" + "," + this.iotConfigs.Group_1_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable + ","
            + "第一组市/发电柜发电合闸事件上传录像使能" + "," + this.iotConfigs.Group_1_GenSwitch_Closing_Event_Upload_Video_Enable + ","
            + "第二组市/发电柜市电分闸事件上传录像使能" + "," + this.iotConfigs.Group_2_ElectricSupplySwitch_Opening_Event_Upload_Video_Enable + ","
            + "第二组市/发电柜发电合闸事件上传录像使能" + "," + this.iotConfigs.Group_2_GenSwitch_Closing_Event_Upload_Video_Enable + ","
            + "第一组补偿电容运行事件上传录像使能" + "," + this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable + ","
            + "第二组补偿电容运行事件上传录像使能" + "," + this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable + ","
            + "第三组补偿电容运行事件上传录像使能" + "," + this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable + ","
            + "第四组补偿电容运行事件上传录像使能" + "," + this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Event_Upload_Video_Enable + ","
            + "第一组补偿柜电容分闸事件上传录像使能" + "," + this.iotConfigs.CompensationCabinetCapacity_1_Opening_Event_Upload_Video_Enable + ","
            + "第二组补偿柜电容分闸事件上传录像使能" + "," + this.iotConfigs.CompensationCabinetCapacity_2_Opening_Event_Upload_Video_Enable + ","
            + "第三组补偿柜电容分闸事件上传录像使能" + "," + this.iotConfigs.CompensationCabinetCapacity_3_Opening_Event_Upload_Video_Enable + ","
            + "第四组补偿柜电容分闸事件上传录像使能" + "," + this.iotConfigs.CompensationCabinetCapacity_4_Opening_Event_Upload_Video_Enable + ","
            + "防潮灯开启事件上传录像使能" + "," + this.iotConfigs.MoistureProofLamps_On_Event_Upload_Video_Enable + ","
            + "机房湿度过高防潮灯关闭报警上传录像使能" + "," + this.iotConfigs.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Video_Enable + ","
            + "第一组变压器降温风扇启动事件上传录像使能" + "," + this.iotConfigs.Group_1_Transformer_Fan_Start_Event_Upload_Video_Enable + ","
            + "第二组变压器降温风扇启动事件上传录像使能" + "," + this.iotConfigs.Group_2_Transformer_Fan_Start_Event_Upload_Video_Enable + ","
            + "第一组变压器负载率过低事件上传录像使能" + "," + this.iotConfigs.Group_1_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable + ","
            + "第二组变压器负载率过低事件上传录像使能" + "," + this.iotConfigs.Group_2_Transformer_LoadRate_TooLow_Event_Upload_Video_Enable + ","
            + "两路进线柜同时分闸报警上传录像使能" + "," + this.iotConfigs.IncomingCabinetSwitch_AllOpening_Alarm_Upload_Video_Enable + ","
            + "第一路进线柜电压过低报警上传录像使能" + "," + this.iotConfigs.IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Video_Enable + ","
            + "第二路进线柜电压过低报警上传录像使能" + "," + this.iotConfigs.IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Video_Enable + ","
            + "第一路进线柜电压过高报警上传录像使能" + "," + this.iotConfigs.IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Video_Enable + ","
            + "第二路进线柜电压过高报警上传录像使能" + "," + this.iotConfigs.IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Video_Enable + ","
            + "两路进线柜同时分闸联络柜合闸报警上传录像使能" + "," + this.iotConfigs.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Video_Enable + ","
            + "第一组市/发电柜同时分闸报警上传录像使能" + "," + this.iotConfigs.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Video_Enable + ","
            + "第二组市/发电柜同时分闸报警上传录像使能" + "," + this.iotConfigs.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Video_Enable + ","
            + "第二组市/发电柜同时合闸报警上传录像使能" + "," + this.iotConfigs.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Video_Enable + ","
            + "第二组市/发电柜同时合闸报警上传录像使能" + "," + this.iotConfigs.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Video_Enable + ","
            + "第一组补偿柜分闸报警上传录像使能" + "," + this.iotConfigs.CompensationCabinetSwitch_1_Opening_Alarm_Upload_Video_Enable + ","
            + "第二组补偿柜分闸报警上传录像使能" + "," + this.iotConfigs.CompensationCabinetSwitch_2_Opening_Alarm_Upload_Video_Enable + ","
            + "第三组补偿柜分闸报警上传录像使能" + "," + this.iotConfigs.CompensationCabinetSwitch_3_Opening_Alarm_Upload_Video_Enable + ","
            + "第四组补偿柜分闸报警上传录像使能" + "," + this.iotConfigs.CompensationCabinetSwitch_4_Opening_Alarm_Upload_Video_Enable + ","
            + "功率因数过低第一组补偿电容停止运行报警上传录像使能" + "," + this.iotConfigs.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable + ","
            + "功率因数过低第二组补偿电容停止运行报警上传录像使能" + "," + this.iotConfigs.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable + ","
            + "功率因数过低第三组补偿电容停止运行报警上传录像使能" + "," + this.iotConfigs.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable + ","
            + "功率因数过低第四组补偿电容停止运行报警上传录像使能" + "," + this.iotConfigs.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Video_Enable + ","
            + "第一组馈电回路A相电流过大报警上传录像使能" + "," + this.iotConfigs.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable + ","
            + "第一组馈电回路B相电流过大报警上传录像使能" + "," + this.iotConfigs.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable + ","
            + "第一组馈电回路C相电流过大事件上传录像使能" + "," + this.iotConfigs.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable + ","
            + "第二组馈电回路A相电流过大事件上传录像使能" + "," + this.iotConfigs.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Video_Enable + ","
            + "第二组馈电回路B相电流过大事件上传录像使能" + "," + this.iotConfigs.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Video_Enable + ","
            + "第二组馈电回路C相电流过大事件上传录像使能" + "," + this.iotConfigs.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Video_Enable + ","
            + "机房温度过高送风机停止事件上传录像使能" + "," + this.iotConfigs.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Video_Enable + ","
            + "机房温度过高排风机停止事件上传录像使能" + "," + this.iotConfigs.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Video_Enable + ","
            + "第一组变压器超温报警上传录像使能" + "," + this.iotConfigs.Transformer_1_TempHigh_Alarm_Upload_Video_Enable + ","
            + "第二组变压器超温报警上传录像使能" + "," + this.iotConfigs.Transformer_2_TempHigh_Alarm_Upload_Video_Enable + ","
            + "第一组变压器超温降温风扇未启动报警上传录像使能" + "," + this.iotConfigs.Transformer_1_TempHigh_FanStop_Alarm_Upload_Video_Enable + ","
            + "第二组变压器超温降温风扇未启动报警上传录像使能" + "," + this.iotConfigs.Transformer_2_TempHigh_FanStop_Alarm_Upload_Video_Enable + ","

            + "拨打值班室电话事件上传图片使能" + "," + this.iotConfigs.Voip_Alarm_Upload_Image_Enable + ","
            + "摄氏温度过高事件上传图片使能" + "," + this.iotConfigs.Celsius_Temperature_Too_High_Event_Upload_Image_Enable + ","
            + "摄氏温度过低事件上传图片使能" + "," + this.iotConfigs.Celsius_Temperature_Too_Low_Event_Upload_Image_Enable + ","
            + "相对湿度过高事件上传图片使能" + "," + this.iotConfigs.Relative_Humidity_Too_High_Event_Upload_Image_Enable + ","
            + "相对湿度过低事件上传图片使能" + "," + this.iotConfigs.Relative_Humidity_Too_Low_Event_Upload_Image_Enable + ","
            + "机房有人事件上传图片使能" + "," + this.iotConfigs.SwitchingHouse_Person_Event_Upload_Image_Enable + ","
            + "机房门打开事件上传图片使能" + "," + this.iotConfigs.SwitchingHouse_Door_Open_Event_Upload_Image_Enable + ","
            + "机房送风机启动事件上传图片使能" + "," + this.iotConfigs.EngineRoom_FeedFan_Start_Event_Upload_Image_Enable + ","
            + "机房排风机启动事件上传图片使能" + "," + this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Upload_Image_Enable + ","
            + "第一路进线分闸事件上传图片使能" + "," + this.iotConfigs.IncomingCabinet_1_Opening_Event_Upload_Image_Enable + ","
            + "第二路进线分闸事件上传图片使能" + "," + this.iotConfigs.IncomingCabinet_2_Opening_Event_Upload_Image_Enable + ","
            + "第一组变压器功率因数过低报警上传图片使能" + "," + this.iotConfigs.Transformer_1_Cos_TooLow_Alarm_Upload_Image_Enable + ","
            + "第二组变压器功率因数过低报警上传图片使能" + "," + this.iotConfigs.Transformer_2_Cos_TooLow_Alarm_Upload_Image_Enable + ","
            + "联络柜开关合闸事件上传图片使能" + "," + this.iotConfigs.ContactCabinetSwitch_Closing_Event_Upload_Image_Enable + ","
            + "第一组市/发电柜市电分闸事件上传图片使能" + "," + this.iotConfigs.Group_1_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable + ","
            + "第一组市/发电柜发电合闸事件上传图片使能" + "," + this.iotConfigs.Group_1_GenSwitch_Closing_Event_Upload_Image_Enable + ","
            + "第二组市/发电柜市电分闸事件上传图片使能" + "," + this.iotConfigs.Group_2_ElectricSupplySwitch_Opening_Event_Upload_Image_Enable + ","
            + "第二组市/发电柜发电合闸事件上传图片使能" + "," + this.iotConfigs.Group_2_GenSwitch_Closing_Event_Upload_Image_Enable + ","
            + "第一组补偿电容运行事件上传图片使能" + "," + this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable + ","
            + "第二组补偿电容运行事件上传图片使能" + "," + this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable + ","
            + "第三组补偿电容运行事件上传图片使能" + "," + this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable + ","
            + "第四组补偿电容运行事件上传图片使能" + "," + this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Event_Upload_Image_Enable + ","
            + "第一组补偿柜电容分闸事件上传图片使能" + "," + this.iotConfigs.CompensationCabinetCapacity_1_Opening_Event_Upload_Image_Enable + ","
            + "第二组补偿柜电容分闸事件上传图片使能" + "," + this.iotConfigs.CompensationCabinetCapacity_2_Opening_Event_Upload_Image_Enable + ","
            + "第三组补偿柜电容分闸事件上传图片使能" + "," + this.iotConfigs.CompensationCabinetCapacity_3_Opening_Event_Upload_Image_Enable + ","
            + "第四组补偿柜电容分闸事件上传图片使能" + "," + this.iotConfigs.CompensationCabinetCapacity_4_Opening_Event_Upload_Image_Enable + ","
            + "防潮灯开启事件上传图片使能" + "," + this.iotConfigs.MoistureProofLamps_On_Event_Upload_Image_Enable + ","
            + "机房湿度过高防潮灯关闭报警上传图片使能" + "," + this.iotConfigs.Relative_Humidity_TooHigh_MoistureProofLamps_Off_Alarm_Upload_Image_Enable + ","
            + "第一组变压器降温风扇启动事件上传图片使能" + "," + this.iotConfigs.Group_1_Transformer_Fan_Start_Event_Upload_Image_Enable + ","
            + "第二组变压器降温风扇启动事件上传图片使能" + "," + this.iotConfigs.Group_2_Transformer_Fan_Start_Event_Upload_Image_Enable + ","
            + "第一组变压器负载率过低事件上传图片使能" + "," + this.iotConfigs.Group_1_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable + ","
            + "第二组变压器负载率过低事件上传图片使能" + "," + this.iotConfigs.Group_2_Transformer_LoadRate_TooLow_Event_Upload_Image_Enable + ","
            + "两路进线柜同时分闸报警上传图片使能" + "," + this.iotConfigs.IncomingCabinetSwitch_AllOpening_Alarm_Upload_Image_Enable + ","
            + "第一路进线柜电压过低报警上传图片使能" + "," + this.iotConfigs.IncomingCabinet_1_Voltage_TooLow_Alarm_Upload_Image_Enable + ","
            + "第二路进线柜电压过低报警上传图片使能" + "," + this.iotConfigs.IncomingCabinet_2_Voltage_TooLow_Alarm_Upload_Image_Enable + ","
            + "第一路进线柜电压过高报警上传图片使能" + "," + this.iotConfigs.IncomingCabinet_1_Voltage_TooHigh_Alarm_Upload_Image_Enable + ","
            + "第二路进线柜电压过高报警上传图片使能" + "," + this.iotConfigs.IncomingCabinet_2_Voltage_TooHigh_Alarm_Upload_Image_Enable + ","
            + "两路进线柜同时分闸联络柜合闸报警上传图片使能" + "," + this.iotConfigs.ContactCabinetSwitch_Closing_IncomingCabinetSwitch_AllClosing_Alarm_Upload_Image_Enable + ","
            + "第一组市/发电柜同时分闸报警上传图片使能" + "," + this.iotConfigs.ElectricSupply_Gen_1_Switch_AllOpening_Alarm_Upload_Image_Enable + ","
            + "第二组市/发电柜同时分闸报警上传图片使能" + "," + this.iotConfigs.ElectricSupply_Gen_2_Switch_AllOpening_Alarm_Upload_Image_Enable + ","
            + "第二组市/发电柜同时合闸报警上传图片使能" + "," + this.iotConfigs.ElectricSupply_Gen_1_Switch_AllClosing_Alarm_Upload_Image_Enable + ","
            + "第二组市/发电柜同时合闸报警上传图片使能" + "," + this.iotConfigs.ElectricSupply_Gen_2_Switch_AllClosing_Alarm_Upload_Image_Enable + ","
            + "第一组补偿柜分闸报警上传图片使能" + "," + this.iotConfigs.CompensationCabinetSwitch_1_Opening_Alarm_Upload_Image_Enable + ","
            + "第二组补偿柜分闸报警上传图片使能" + "," + this.iotConfigs.CompensationCabinetSwitch_2_Opening_Alarm_Upload_Image_Enable + ","
            + "第三组补偿柜分闸报警上传图片使能" + "," + this.iotConfigs.CompensationCabinetSwitch_3_Opening_Alarm_Upload_Image_Enable + ","
            + "第四组补偿柜分闸报警上传图片使能" + "," + this.iotConfigs.CompensationCabinetSwitch_4_Opening_Alarm_Upload_Image_Enable + ","
            + "功率因数过低第一组补偿电容停止运行报警上传图片使能" + "," + this.iotConfigs.Group_1_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable + ","
            + "功率因数过低第二组补偿电容停止运行报警上传图片使能" + "," + this.iotConfigs.Group_2_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable + ","
            + "功率因数过低第三组补偿电容停止运行报警上传图片使能" + "," + this.iotConfigs.Group_3_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable + ","
            + "功率因数过低第四组补偿电容停止运行报警上传图片使能" + "," + this.iotConfigs.Group_4_CompensationCabinetCapacity_Stop_Cos_TooLow_Alarm_Upload_Image_Enable + ","
            + "第一组馈电回路A相电流过大报警上传图片使能" + "," + this.iotConfigs.Group_1_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable + ","
            + "第一组馈电回路B相电流过大报警上传图片使能" + "," + this.iotConfigs.Group_1_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable + ","
            + "第一组馈电回路C相电流过大事件上传图片使能" + "," + this.iotConfigs.Group_1_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable + ","
            + "第二组馈电回路A相电流过大事件上传图片使能" + "," + this.iotConfigs.Group_2_FeederCircuit_A_Current_TooHigh_Alarm_Upload_Image_Enable + ","
            + "第二组馈电回路B相电流过大事件上传图片使能" + "," + this.iotConfigs.Group_2_FeederCircuit_B_Current_TooHigh_Alarm_Upload_Image_Enable + ","
            + "第二组馈电回路C相电流过大事件上传图片使能" + "," + this.iotConfigs.Group_2_FeederCircuit_C_Current_TooHigh_Alarm_Upload_Image_Enable + ","
            + "机房温度过高送风机停止事件上传图片使能" + "," + this.iotConfigs.SwitchingHouse_Temp_TooHigh_FeedFan_Stop_Alarm_Upload_Image_Enable + ","
            + "机房温度过高排风机停止事件上传图片使能" + "," + this.iotConfigs.SwitchingHouse_Temp_TooHigh_ExhaustFan_Stop_Alarm_Upload_Image_Enable + ","
            + "第一组变压器超温报警上传图片使能" + "," + this.iotConfigs.Transformer_1_TempHigh_Alarm_Upload_Image_Enable + ","
            + "第二组变压器超温报警上传图片使能" + "," + this.iotConfigs.Transformer_2_TempHigh_Alarm_Upload_Image_Enable + ","
            + "第一组变压器超温降温风扇未启动报警上传图片使能" + "," + this.iotConfigs.Transformer_1_TempHigh_FanStop_Alarm_Upload_Image_Enable + ","
            + "第二组变压器超温降温风扇未启动报警上传图片使能" + "," + this.iotConfigs.Transformer_2_TempHigh_FanStop_Alarm_Upload_Image_Enable + ","
      }else{
        paramsBody = {
          SwitchingHouse_Door_Open_Bind_Port:this.iotConfigs.SwitchingHouse_Door_Open_Bind_Port,
          Group_1_Transformer_Temp_Bind_Port:this.iotConfigs.Group_1_Transformer_Temp_Bind_Port,
          Group_1_Transformer_Fan_Start_Bind_Port:this.iotConfigs.Group_1_Transformer_Fan_Start_Bind_Port,
          Group_2_Transformer_Temp_Bind_Port:this.iotConfigs.Group_2_Transformer_Temp_Bind_Port,
          Group_2_Transformer_Fan_Start_Bind_Port:this.iotConfigs.Group_2_Transformer_Fan_Start_Bind_Port,
          Voip_Alarm_Bind_Port:this.iotConfigs.Voip_Alarm_Bind_Port,
          IncomingCabinet_1_Switching_Bind_Port:this.iotConfigs.IncomingCabinet_1_Switching_Bind_Port,
          IncomingCabinet_2_Switching_Bind_Port:this.iotConfigs.IncomingCabinet_2_Switching_Bind_Port,
          ContactCabinetSwitch_Bind_Port:this.iotConfigs.ContactCabinetSwitch_Bind_Port,
          Group_1_ElectricSupplySwitch_Bind_Port:this.iotConfigs.Group_1_ElectricSupplySwitch_Bind_Port,
          Group_1_GenSwitch_Bind_Port:this.iotConfigs.Group_1_GenSwitch_Bind_Port,
          Group_2_ElectricSupplySwitch_Bind_Port:this.iotConfigs.Group_2_ElectricSupplySwitch_Bind_Port,
          Group_2_GenSwitch_Bind_Port:this.iotConfigs.Group_2_GenSwitch_Bind_Port,
          Group_1_CompensationCabinetSwitch_Bind_Port:this.iotConfigs.Group_1_CompensationCabinetSwitch_Bind_Port,
          Group_1_CompensationCabinetCapacity_Run_Bind_Port:this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Bind_Port,
          Group_2_CompensationCabinetSwitch_Bind_Port:this.iotConfigs.Group_2_CompensationCabinetSwitch_Bind_Port,
          Group_2_CompensationCabinetCapacity_Run_Bind_Port:this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Bind_Port,
          Group_3_CompensationCabinetSwitch_Bind_Port:this.iotConfigs.Group_3_CompensationCabinetSwitch_Bind_Port,
          Group_3_CompensationCabinetCapacity_Run_Bind_Port:this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Bind_Port,
          Group_4_CompensationCabinetSwitch_Bind_Port:this.iotConfigs.Group_4_CompensationCabinetSwitch_Bind_Port,
          Group_4_CompensationCabinetCapacity_Run_Bind_Port:this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Bind_Port,
          MoistureProofLamps_Bind_Port:this.iotConfigs.MoistureProofLamps_Bind_Port,
          SwitchingHouse_FeedFan_Start_Bind_Port:this.iotConfigs.SwitchingHouse_FeedFan_Start_Bind_Port,
          SwitchingHouse_ExhaustFan_Start_Bind_Port:this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Bind_Port,


          SwitchingHouse_Door_Open_Gpio_State:this.iotConfigs.SwitchingHouse_Door_Open_Gpio_State,
          // Group_1_FeederCircuit_A_Current_Gpio_State:this.iotConfigs.Group_1_FeederCircuit_A_Current_Gpio_State,
          // Group_1_FeederCircuit_B_Current_Gpio_State:this.iotConfigs.Group_1_FeederCircuit_B_Current_Gpio_State,
          // Group_1_FeederCircuit_C_Current_Gpio_State:this.iotConfigs.Group_1_FeederCircuit_C_Current_Gpio_State,
          // Group_2_FeederCircuit_A_Current_Gpio_State:this.iotConfigs.Group_2_FeederCircuit_A_Current_Gpio_State,
          // Group_2_FeederCircuit_B_Current_Gpio_State:this.iotConfigs.Group_2_FeederCircuit_B_Current_Gpio_State,
          // Group_2_FeederCircuit_C_Current_Gpio_State:this.iotConfigs.Group_2_FeederCircuit_C_Current_Gpio_State,
          Group_1_Transformer_Temp_Gpio_State:this.iotConfigs.Group_1_Transformer_Temp_Gpio_State,
          Group_1_Transformer_Fan_Start_Gpio_State:this.iotConfigs.Group_1_Transformer_Fan_Start_Gpio_State,
          Group_2_Transformer_Temp_Gpio_State:this.iotConfigs.Group_2_Transformer_Temp_Gpio_State,
          Group_2_Transformer_Fan_Start_Gpio_State:this.iotConfigs.Group_2_Transformer_Fan_Start_Gpio_State,
          Voip_Alarm_Gpio_State:this.iotConfigs.Voip_Alarm_Gpio_State,
          IncomingCabinet_1_Switching_Gpio_State:this.iotConfigs.IncomingCabinet_1_Switching_Gpio_State,
          IncomingCabinet_2_Switching_Gpio_State:this.iotConfigs.IncomingCabinet_2_Switching_Gpio_State,
          ContactCabinetSwitch_Gpio_State:this.iotConfigs.ContactCabinetSwitch_Gpio_State,
          Group_1_ElectricSupplySwitch_Gpio_State:this.iotConfigs.Group_1_ElectricSupplySwitch_Gpio_State,
          Group_1_GenSwitch_Gpio_State:this.iotConfigs.Group_1_GenSwitch_Gpio_State,
          Group_2_ElectricSupplySwitch_Gpio_State:this.iotConfigs.Group_2_ElectricSupplySwitch_Gpio_State,
          Group_2_GenSwitch_Gpio_State:this.iotConfigs.Group_2_GenSwitch_Gpio_State,
          Group_1_CompensationCabinetSwitch_Gpio_State:this.iotConfigs.Group_1_CompensationCabinetSwitch_Gpio_State,
          Group_1_CompensationCabinetCapacity_Run_Gpio_State:this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Gpio_State,
          Group_2_CompensationCabinetSwitch_Gpio_State:this.iotConfigs.Group_2_CompensationCabinetSwitch_Gpio_State,
          Group_2_CompensationCabinetCapacity_Run_Gpio_State:this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Gpio_State,
          Group_3_CompensationCabinetSwitch_Gpio_State:this.iotConfigs.Group_3_CompensationCabinetSwitch_Gpio_State,
          Group_3_CompensationCabinetCapacity_Run_Gpio_State:this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Gpio_State,
          Group_4_CompensationCabinetSwitch_Gpio_State:this.iotConfigs.Group_4_CompensationCabinetSwitch_Gpio_State,
          Group_4_CompensationCabinetCapacity_Run_Gpio_State:this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Gpio_State,
          MoistureProofLamps_Gpio_State:this.iotConfigs.MoistureProofLamps_Gpio_State,
          SwitchingHouse_FeedFan_Start_Gpio_State:this.iotConfigs.SwitchingHouse_FeedFan_Start_Gpio_State,
          SwitchingHouse_ExhaustFan_Start_Gpio_State:this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Gpio_State,
        }
        this.paramsdata = "机房门打开信号有效电平" + "," + this.iotConfigs.SwitchingHouse_Door_Open_Gpio_State + ","
            // + "第一组馈电回路A相电流信号有效电平" + "," + this.iotConfigs.Group_1_FeederCircuit_A_Current_Gpio_State + ","
            // + "第一组馈电回路B相电流信号有效电平" + "," + this.iotConfigs.Group_1_FeederCircuit_B_Current_Gpio_State + ","
            // + "第一组馈电回路C相电流信号有效电平" + "," + this.iotConfigs.Group_1_FeederCircuit_C_Current_Gpio_State + ","
            // + "第二组馈电回路A相电流信号有效电平" + "," + this.iotConfigs.Group_2_FeederCircuit_A_Current_Gpio_State + ","
            // + "第二组馈电回路B相电流信号有效电平" + "," + this.iotConfigs.Group_2_FeederCircuit_B_Current_Gpio_State + ","
            // + "第二组馈电回路C相电流信号有效电平" + "," + this.iotConfigs.Group_2_FeederCircuit_C_Current_Gpio_State + ","
            + "第一组变压器温度过高信号有效电平" + "," + this.iotConfigs.Group_1_Transformer_Temp_Gpio_State + ","
            + "第一组变压器降温风扇启动信号有效电平" + "," + this.iotConfigs.Group_1_Transformer_Fan_Start_Gpio_State + ","
            + "第二组变压器温度过高信号有效电平" + "," + this.iotConfigs.Group_2_Transformer_Temp_Gpio_State + ","
            + "第二组变压器降温风扇启动信号有效电平" + "," + this.iotConfigs.Group_2_Transformer_Fan_Start_Gpio_State + ","
            + "拨打值班室电话信号有效电平" + "," + this.iotConfigs.Voip_Alarm_Gpio_State + ","
            + "第一路进线分闸信号有效电平" + "," + this.iotConfigs.IncomingCabinet_1_Switching_Gpio_State + ","
            + "第二路进线分闸信号有效电平" + "," + this.iotConfigs.IncomingCabinet_2_Switching_Gpio_State + ","
            + "联络柜合闸信号有效电平" + "," + this.iotConfigs.ContactCabinetSwitch_Gpio_State + ","
            + "第一组市/发电柜市电分闸信号有效电平" + "," + this.iotConfigs.Group_1_ElectricSupplySwitch_Gpio_State + ","
            + "第一组市/发电柜发电合闸信号有效电平" + "," + this.iotConfigs.Group_1_GenSwitch_Gpio_State + ","
            + "第二组市/发电柜市电分闸信号有效电平" + "," + this.iotConfigs.Group_2_ElectricSupplySwitch_Gpio_State + ","
            + "第二组市/发电柜发电合闸信号有效电平" + "," + this.iotConfigs.Group_2_GenSwitch_Gpio_State + ","
            + "第一组补偿柜分闸信号有效电平" + "," + this.iotConfigs.Group_1_CompensationCabinetSwitch_Gpio_State + ","
            + "第一组补偿电容运行信号有效电平" + "," + this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Gpio_State + ","
            + "第二组补偿柜分闸信号有效电平" + "," + this.iotConfigs.Group_2_CompensationCabinetSwitch_Gpio_State + ","
            + "第二组补偿电容运行信号有效电平" + "," + this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Gpio_State + ","
            + "第三组补偿柜分闸信号有效电平" + "," + this.iotConfigs.Group_3_CompensationCabinetSwitch_Gpio_State + ","
            + "第三组补偿电容运行信号有效电平" + "," + this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Gpio_State + ","
            + "第四组补偿柜分闸信号有效电平" + "," + this.iotConfigs.Group_4_CompensationCabinetSwitch_Gpio_State + ","
            + "第四组补偿电容运行信号有效电平" + "," + this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Gpio_State + ","
            + "防潮灯开启信号有效电平" + "," + this.iotConfigs.MoistureProofLamps_Gpio_State + ","
            + "机房送风机启动信号有效电平" + "," + this.iotConfigs.SwitchingHouse_FeedFan_Start_Gpio_State + ","
            + "机房排风机启动信号有效电平" + "," + this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Gpio_State + ","
            + "机房门打开信号绑定IO端口" + "," + this.iotConfigs.SwitchingHouse_Door_Open_Bind_Port + ","
            + "第一组变压器温度过高信号绑定IO端口" + "," + this.iotConfigs.Group_1_Transformer_Temp_Bind_Port + ","
            + "第一组变压器降温风扇启动信号绑定IO端口" + "," + this.iotConfigs.Group_1_Transformer_Fan_Start_Bind_Port + ","
            + "第二组变压器温度过高信号绑定IO端口" + "," + this.iotConfigs.Group_2_Transformer_Temp_Bind_Port + ","
            + "第二组变压器降温风扇启动信号绑定IO端口" + "," + this.iotConfigs.Group_2_Transformer_Fan_Start_Bind_Port + ","
            + "拨打值班室电话信号绑定IO端口" + "," + this.iotConfigs.Voip_Alarm_Bind_Port + ","
            + "第一路进线分闸信号绑定IO端口" + "," + this.iotConfigs.IncomingCabinet_1_Switching_Bind_Port + ","
            + "第二路进线分闸信号绑定IO端口" + "," + this.iotConfigs.IncomingCabinet_2_Switching_Bind_Port + ","
            + "联络柜合闸信号绑定IO端口" + "," + this.iotConfigs.ContactCabinetSwitch_Bind_Port + ","
            + "第一组市/发电柜市电分闸信号绑定IO端口" + "," + this.iotConfigs.Group_1_ElectricSupplySwitch_Bind_Port + ","
            + "第一组市/发电柜发电合闸信号绑定IO端口" + "," + this.iotConfigs.Group_1_GenSwitch_Bind_Port + ","
            + "第二组市/发电柜市电分闸信号绑定IO端口" + "," + this.iotConfigs.Group_2_ElectricSupplySwitch_Bind_Port + ","
            + "第二组市/发电柜发电合闸信号绑定IO端口" + "," + this.iotConfigs.Group_2_GenSwitch_Bind_Port + ","
            + "第一组补偿柜分闸信号绑定IO端口" + "," + this.iotConfigs.Group_1_CompensationCabinetSwitch_Bind_Port + ","
            + "第一组补偿电容运行信号绑定IO端口" + "," + this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Bind_Port + ","
            + "第二组补偿柜分闸信号绑定IO端口" + "," + this.iotConfigs.Group_2_CompensationCabinetSwitch_Bind_Port + ","
            + "第二组补偿电容运行信号绑定IO端口" + "," + this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Bind_Port + ","
            + "第三组补偿柜分闸信号绑定IO端口" + "," + this.iotConfigs.Group_3_CompensationCabinetSwitch_Bind_Port + ","
            + "第三组补偿电容运行信号绑定IO端口" + "," + this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Bind_Port + ","
            + "第四组补偿柜分闸信号绑定IO端口" + "," + this.iotConfigs.Group_4_CompensationCabinetSwitch_Bind_Port + ","
            + "第四组补偿电容运行信号绑定IO端口" + "," + this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Bind_Port + ","
            + "防潮灯开启信号绑定IO端口" + "," + this.iotConfigs.MoistureProofLamps_Bind_Port + ","
            + "机房送风机启动信号绑定IO端口" + "," + this.iotConfigs.SwitchingHouse_FeedFan_Start_Bind_Port + ","
            + "机房排风机启动信号绑定IO端口" + "," + this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Bind_Port + ","
      }
      let paramsdate = moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username: this.username,
        paramsobj: this.devicename,
        paramsdate: paramsdate,
        devicenum: this.devicenum,
        devicegroupid: "00",
        orderresult: '失败',
        paramstype: '0',
        parentid: "",
        paramsdata: this.paramsdata,
      }
      addParamsBatchLog(param)
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'PUT',
              url: '/iot',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.$message.success('操作成功');
          let param = {
            username: this.username,
            paramsobj: this.devicename,
            paramsdate: paramsdate,
            devicenum: this.devicenum,
            devicegroupid: "00",
            orderresult: '成功',
            paramstype: '0',
            parentid: "",
            paramsdata: this.paramsdata,
          }
          modifyParamsBatchLog(param)
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    setDefaultParamsConfig() {
      this.$confirm('提示消息：“您确认要下发出厂默认参数配置吗？如果您已经有修改过该终端参数，那么该终端参数会被清除，请仔细确认该操作是否继续，谢谢！”',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.type='all'
        let paramsBody = {}
        if (this.electricitySystemIotFlag.iotFlag == 0) {
          paramsBody = {}
        }else{
          paramsBody = {
            SwitchingHouse_Door_Open_Bind_Port:this.iotConfigs.SwitchingHouse_Door_Open_Bind_Port,
            Group_1_Transformer_Temp_Bind_Port:this.iotConfigs.Group_1_Transformer_Temp_Bind_Port,
            Group_1_Transformer_Fan_Start_Bind_Port:this.iotConfigs.Group_1_Transformer_Fan_Start_Bind_Port,
            Group_2_Transformer_Temp_Bind_Port:this.iotConfigs.Group_2_Transformer_Temp_Bind_Port,
            Group_2_Transformer_Fan_Start_Bind_Port:this.iotConfigs.Group_2_Transformer_Fan_Start_Bind_Port,
            Voip_Alarm_Bind_Port:this.iotConfigs.Voip_Alarm_Bind_Port,
            IncomingCabinet_1_Switching_Bind_Port:this.iotConfigs.IncomingCabinet_1_Switching_Bind_Port,
            IncomingCabinet_2_Switching_Bind_Port:this.iotConfigs.IncomingCabinet_2_Switching_Bind_Port,
            ContactCabinetSwitch_Bind_Port:this.iotConfigs.ContactCabinetSwitch_Bind_Port,
            Group_1_ElectricSupplySwitch_Bind_Port:this.iotConfigs.Group_1_ElectricSupplySwitch_Bind_Port,
            Group_1_GenSwitch_Bind_Port:this.iotConfigs.Group_1_GenSwitch_Bind_Port,
            Group_2_ElectricSupplySwitch_Bind_Port:this.iotConfigs.Group_2_ElectricSupplySwitch_Bind_Port,
            Group_2_GenSwitch_Bind_Port:this.iotConfigs.Group_2_GenSwitch_Bind_Port,
            Group_1_CompensationCabinetSwitch_Bind_Port:this.iotConfigs.Group_1_CompensationCabinetSwitch_Bind_Port,
            Group_1_CompensationCabinetCapacity_Run_Bind_Port:this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Bind_Port,
            Group_2_CompensationCabinetSwitch_Bind_Port:this.iotConfigs.Group_2_CompensationCabinetSwitch_Bind_Port,
            Group_2_CompensationCabinetCapacity_Run_Bind_Port:this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Bind_Port,
            Group_3_CompensationCabinetSwitch_Bind_Port:this.iotConfigs.Group_3_CompensationCabinetSwitch_Bind_Port,
            Group_3_CompensationCabinetCapacity_Run_Bind_Port:this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Bind_Port,
            Group_4_CompensationCabinetSwitch_Bind_Port:this.iotConfigs.Group_4_CompensationCabinetSwitch_Bind_Port,
            Group_4_CompensationCabinetCapacity_Run_Bind_Port:this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Bind_Port,
            MoistureProofLamps_Bind_Port:this.iotConfigs.MoistureProofLamps_Bind_Port,
            SwitchingHouse_FeedFan_Start_Bind_Port:this.iotConfigs.SwitchingHouse_FeedFan_Start_Bind_Port,
            SwitchingHouse_ExhaustFan_Start_Bind_Port:this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Bind_Port,

            SwitchingHouse_Door_Open_Gpio_State:this.iotConfigs.SwitchingHouse_Door_Open_Gpio_State,
            Group_1_Transformer_Temp_Gpio_State:this.iotConfigs.Group_1_Transformer_Temp_Gpio_State,
            Group_1_Transformer_Fan_Start_Gpio_State:this.iotConfigs.Group_1_Transformer_Fan_Start_Gpio_State,
            Group_2_Transformer_Temp_Gpio_State:this.iotConfigs.Group_2_Transformer_Temp_Gpio_State,
            Group_2_Transformer_Fan_Start_Gpio_State:this.iotConfigs.Group_2_Transformer_Fan_Start_Gpio_State,
            Voip_Alarm_Gpio_State:this.iotConfigs.Voip_Alarm_Gpio_State,
            IncomingCabinet_1_Switching_Gpio_State:this.iotConfigs.IncomingCabinet_1_Switching_Gpio_State,
            IncomingCabinet_2_Switching_Gpio_State:this.iotConfigs.IncomingCabinet_2_Switching_Gpio_State,
            ContactCabinetSwitch_Gpio_State:this.iotConfigs.ContactCabinetSwitch_Gpio_State,
            Group_1_ElectricSupplySwitch_Gpio_State:this.iotConfigs.Group_1_ElectricSupplySwitch_Gpio_State,
            Group_1_GenSwitch_Gpio_State:this.iotConfigs.Group_1_GenSwitch_Gpio_State,
            Group_2_ElectricSupplySwitch_Gpio_State:this.iotConfigs.Group_2_ElectricSupplySwitch_Gpio_State,
            Group_2_GenSwitch_Gpio_State:this.iotConfigs.Group_2_GenSwitch_Gpio_State,
            Group_1_CompensationCabinetSwitch_Gpio_State:this.iotConfigs.Group_1_CompensationCabinetSwitch_Gpio_State,
            Group_1_CompensationCabinetCapacity_Run_Gpio_State:this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Gpio_State,
            Group_2_CompensationCabinetSwitch_Gpio_State:this.iotConfigs.Group_2_CompensationCabinetSwitch_Gpio_State,
            Group_2_CompensationCabinetCapacity_Run_Gpio_State:this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Gpio_State,
            Group_3_CompensationCabinetSwitch_Gpio_State:this.iotConfigs.Group_3_CompensationCabinetSwitch_Gpio_State,
            Group_3_CompensationCabinetCapacity_Run_Gpio_State:this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Gpio_State,
            Group_4_CompensationCabinetSwitch_Gpio_State:this.iotConfigs.Group_4_CompensationCabinetSwitch_Gpio_State,
            Group_4_CompensationCabinetCapacity_Run_Gpio_State:this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Gpio_State,
            MoistureProofLamps_Gpio_State:this.iotConfigs.MoistureProofLamps_Gpio_State,
            SwitchingHouse_FeedFan_Start_Gpio_State:this.iotConfigs.SwitchingHouse_FeedFan_Start_Gpio_State,
            SwitchingHouse_ExhaustFan_Start_Gpio_State:this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Gpio_State,
          }
          this.paramsdata = "机房门打开信号绑定IO端口" + "," + this.iotConfigs.SwitchingHouse_Door_Open_Bind_Port + ","
              + "第一组变压器温度过高信号绑定IO端口" + "," + this.iotConfigs.Group_1_Transformer_Temp_Bind_Port + ","
              + "第一组变压器降温风扇启动信号绑定IO端口" + "," + this.iotConfigs.Group_1_Transformer_Fan_Start_Bind_Port + ","
              + "第二组变压器温度过高信号绑定IO端口" + "," + this.iotConfigs.Group_2_Transformer_Temp_Bind_Port + ","
              + "第二组变压器降温风扇启动信号绑定IO端口" + "," + this.iotConfigs.Group_2_Transformer_Fan_Start_Bind_Port + ","
              + "拨打值班室电话信号绑定IO端口" + "," + this.iotConfigs.Voip_Alarm_Bind_Port + ","
              + "第一路进线分闸信号绑定IO端口" + "," + this.iotConfigs.IncomingCabinet_1_Switching_Bind_Port + ","
              + "第二路进线分闸信号绑定IO端口" + "," + this.iotConfigs.IncomingCabinet_2_Switching_Bind_Port + ","
              + "联络柜合闸信号绑定IO端口" + "," + this.iotConfigs.ContactCabinetSwitch_Bind_Port + ","
              + "第一组市/发电柜市电分闸信号绑定IO端口" + "," + this.iotConfigs.Group_1_ElectricSupplySwitch_Bind_Port + ","
              + "第一组市/发电柜发电合闸信号绑定IO端口" + "," + this.iotConfigs.Group_1_GenSwitch_Bind_Port + ","
              + "第二组市/发电柜市电分闸信号绑定IO端口" + "," + this.iotConfigs.Group_2_ElectricSupplySwitch_Bind_Port + ","
              + "第二组市/发电柜发电合闸信号绑定IO端口" + "," + this.iotConfigs.Group_2_GenSwitch_Bind_Port + ","
              + "第一组补偿柜分闸信号绑定IO端口" + "," + this.iotConfigs.Group_1_CompensationCabinetSwitch_Bind_Port + ","
              + "第一组补偿电容运行信号绑定IO端口" + "," + this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Bind_Port + ","
              + "第二组补偿柜分闸信号绑定IO端口" + "," + this.iotConfigs.Group_2_CompensationCabinetSwitch_Bind_Port + ","
              + "第二组补偿电容运行信号绑定IO端口" + "," + this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Bind_Port + ","
              + "第三组补偿柜分闸信号绑定IO端口" + "," + this.iotConfigs.Group_3_CompensationCabinetSwitch_Bind_Port + ","
              + "第三组补偿电容运行信号绑定IO端口" + "," + this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Bind_Port + ","
              + "第四组补偿柜分闸信号绑定IO端口" + "," + this.iotConfigs.Group_4_CompensationCabinetSwitch_Bind_Port + ","
              + "第四组补偿电容运行信号绑定IO端口" + "," + this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Bind_Port + ","
              + "防潮灯开启信号绑定IO端口" + "," + this.iotConfigs.MoistureProofLamps_Bind_Port + ","
              + "机房送风机启动信号绑定IO端口" + "," + this.iotConfigs.SwitchingHouse_FeedFan_Start_Bind_Port + ","
              + "机房排风机启动信号绑定IO端口" + "," + this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Bind_Port + ","
              +"机房门打开信号有效电平" + "," + this.iotConfigs.SwitchingHouse_Door_Open_Gpio_State + ","
              + "第一组变压器温度过高信号有效电平" + "," + this.iotConfigs.Group_1_Transformer_Temp_Gpio_State + ","
              + "第一组变压器降温风扇启动信号有效电平" + "," + this.iotConfigs.Group_1_Transformer_Fan_Start_Gpio_State + ","
              + "第二组变压器温度过高信号有效电平" + "," + this.iotConfigs.Group_2_Transformer_Temp_Gpio_State + ","
              + "第二组变压器降温风扇启动信号有效电平" + "," + this.iotConfigs.Group_2_Transformer_Fan_Start_Gpio_State + ","
              + "拨打值班室电话信号有效电平" + "," + this.iotConfigs.Voip_Alarm_Gpio_State + ","
              + "第一路进线分闸信号有效电平" + "," + this.iotConfigs.IncomingCabinet_1_Switching_Gpio_State + ","
              + "第二路进线分闸信号有效电平" + "," + this.iotConfigs.IncomingCabinet_2_Switching_Gpio_State + ","
              + "联络柜合闸信号有效电平" + "," + this.iotConfigs.ContactCabinetSwitch_Gpio_State + ","
              + "第一组市/发电柜市电分闸信号有效电平" + "," + this.iotConfigs.Group_1_ElectricSupplySwitch_Gpio_State + ","
              + "第一组市/发电柜发电合闸信号有效电平" + "," + this.iotConfigs.Group_1_GenSwitch_Gpio_State + ","
              + "第二组市/发电柜市电分闸信号有效电平" + "," + this.iotConfigs.Group_2_ElectricSupplySwitch_Gpio_State + ","
              + "第二组市/发电柜发电合闸信号有效电平" + "," + this.iotConfigs.Group_2_GenSwitch_Gpio_State + ","
              + "第一组补偿柜分闸信号有效电平" + "," + this.iotConfigs.Group_1_CompensationCabinetSwitch_Gpio_State + ","
              + "第一组补偿电容运行信号有效电平" + "," + this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Gpio_State + ","
              + "第二组补偿柜分闸信号有效电平" + "," + this.iotConfigs.Group_2_CompensationCabinetSwitch_Gpio_State + ","
              + "第二组补偿电容运行信号有效电平" + "," + this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Gpio_State + ","
              + "第三组补偿柜分闸信号有效电平" + "," + this.iotConfigs.Group_3_CompensationCabinetSwitch_Gpio_State + ","
              + "第三组补偿电容运行信号有效电平" + "," + this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Gpio_State + ","
              + "第四组补偿柜分闸信号有效电平" + "," + this.iotConfigs.Group_4_CompensationCabinetSwitch_Gpio_State + ","
              + "第四组补偿电容运行信号有效电平" + "," + this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Gpio_State + ","
              + "防潮灯开启信号有效电平" + "," + this.iotConfigs.MoistureProofLamps_Gpio_State + ","
              + "机房送风机启动信号有效电平" + "," + this.iotConfigs.SwitchingHouse_FeedFan_Start_Gpio_State + ","
              + "机房排风机启动信号有效电平" + "," + this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Gpio_State + ","
        }
        let paramsdate = moment(new Date()).format("YYYYMMDDHHmmss")
        let param = {
          username: this.username,
          paramsobj: this.devicename,
          paramsdate: paramsdate,
          devicenum: this.devicenum,
          devicegroupid: "00",
          orderresult: '失败',
          paramstype: '0',
          parentid: "",
          paramsdata: this.paramsdata,
        }
        addParamsBatchLog(param)
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              modeltype: this.devicemoduletype,
              modelnum: this.modelnum,
              params: {
                methord: 'PUT',
                url: '/iot',
                body: paramsBody,
              }
            }
          ]
        };
        this.showLoading();
        setDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            let param = {
              username: this.username,
              paramsobj: this.devicename,
              paramsdate: paramsdate,
              devicenum: this.devicenum,
              devicegroupid: "00",
              orderresult: '成功',
              paramstype: '0',
              parentid: "",
              paramsdata: this.paramsdata,
            }
            modifyParamsBatchLog(param)
          }
        }).catch(err => {
          this.hideLoading();
          this.$message.error('操作失败');
        })
      }).catch(()=>{
      });
    },
    getOrSet(type){
      if(this.status){
        if(type==='default'){
          this.setDefaultParamsConfig()
        }else{
          this.setParamsConfig();
        }
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
    setDefault(type){
      if(type==='UK01'){
        this.GPIOList=this.GPIOListBackups.filter(item=>!item.dickey.includes('BOARD'))
        this.status=true
        this.type="default"
        this.iotConfigs= {
          //机房门打开信号绑定IO端口，默认I1
          SwitchingHouse_Door_Open_Bind_Port: 'I1',
          //第一组变压器温度过高信号绑定IO端口，默认B1
          Group_1_Transformer_Temp_Bind_Port: 'I8/B1',
          //第一组变压器降温风扇启动信号绑定IO端口，默认B2
          Group_1_Transformer_Fan_Start_Bind_Port: 'I9/B2',
          //第二组变压器温度过高信号绑定IO端口，默认B3
          Group_2_Transformer_Temp_Bind_Port: 'I10/B3',
          //第二组变压器降温风扇启动信号绑定IO端口，默认B4
          Group_2_Transformer_Fan_Start_Bind_Port: 'I11/B4',
          //拨打值班室电话信号绑定IO端口，默认B5
          Voip_Alarm_Bind_Port: 'I12/B5',
          //第一路进线分闸信号绑定IO端口，默认H1
          IncomingCabinet_1_Switching_Bind_Port: 'H1',
          //第二路进线分闸信号绑定IO端口，默认H2
          IncomingCabinet_2_Switching_Bind_Port: 'H2',
          //联络柜合闸信号绑定IO端口，默认H3
          ContactCabinetSwitch_Bind_Port: 'H3',
          //第一组市/发电柜市电分闸信号绑定IO端口，默认H4
          Group_1_ElectricSupplySwitch_Bind_Port: 'H4',
          //第一组市/发电柜发电合闸信号绑定IO端口，默认H5
          Group_1_GenSwitch_Bind_Port: 'H5',
          //第二组市/发电柜市电分闸信号绑定IO端口，默认H6
          Group_2_ElectricSupplySwitch_Bind_Port: 'H6',
          //第二组市/发电柜发电合闸信号绑定IO端口，默认H7
          Group_2_GenSwitch_Bind_Port: 'H7',
          //第一组补偿柜分闸信号绑定IO端口，默认H8
          Group_1_CompensationCabinetSwitch_Bind_Port: 'H8',
          //I2第一组补偿电容运行信号绑定IO端口，默认I2
          Group_1_CompensationCabinetCapacity_Run_Bind_Port: 'I2',
          //第二组补偿柜分闸信号绑定IO端口，默认H9
          Group_2_CompensationCabinetSwitch_Bind_Port: 'H9',
          //第二组补偿电容运行信号绑定IO端口，默认I3
          Group_2_CompensationCabinetCapacity_Run_Bind_Port: 'I3',
          //第三组补偿柜分闸信号绑定IO端口，默认H10
          Group_3_CompensationCabinetSwitch_Bind_Port: 'H10',
          //第三组补偿电容运行信号绑定IO端口，默认I4
          Group_3_CompensationCabinetCapacity_Run_Bind_Port: 'I4',
          //第四组补偿柜分闸信号绑定IO端口，默认H11
          Group_4_CompensationCabinetSwitch_Bind_Port: 'H11',
          //第四组补偿电容运行信号绑定IO端口，默认I5
          Group_4_CompensationCabinetCapacity_Run_Bind_Port: 'I5',
          //防潮灯开启信号绑定IO端口，默认H12
          MoistureProofLamps_Bind_Port: 'H12',
          //机房送风机启动信号绑定IO端口，默认H13
          SwitchingHouse_FeedFan_Start_Bind_Port: 'H13',
          //机房排风机启动信号绑定IO端口，默认H14
          SwitchingHouse_ExhaustFan_Start_Bind_Port: 'H14',

          //机房门打开信号有效电平，1为高电平有效，0为低电平有效，默认0
          SwitchingHouse_Door_Open_Gpio_State: 1,
          //第一组变压器温度过高信号有效电平，1为高电平有效，0为低电平有效，默认0
          Group_1_Transformer_Temp_Gpio_State: 0,
          //第一组变压器降温风扇启动信号有效电平，1为高电平有效，0为低电平有效，默认0
          Group_1_Transformer_Fan_Start_Gpio_State: 0,
          //第二组变压器温度过高信号有效电平，1为高电平有效，0为低电平有效，默认0
          Group_2_Transformer_Temp_Gpio_State: 0,
          //第二组变压器降温风扇启动信号有效电平，1为高电平有效，0为低电平有效，默认0
          Group_2_Transformer_Fan_Start_Gpio_State: 0,
          //拨打值班室电话信号有效电平，1为高电平有效，0为低电平有效，默认0
          Voip_Alarm_Gpio_State: 0,
          //第一路进线分闸信号有效电平
          IncomingCabinet_1_Switching_Gpio_State: 0,
          //第二路进线分闸信号有效电平
          IncomingCabinet_2_Switching_Gpio_State: 0,
          //联络柜合闸信号有效电平
          ContactCabinetSwitch_Gpio_State: 1,
          //第一组市/发电柜市电分闸信号有效电平
          Group_1_ElectricSupplySwitch_Gpio_State: 0,
          //第一组市/发电柜发电合闸信号有效电平
          Group_1_GenSwitch_Gpio_State: 1,
          //第二组市/发电柜市电分闸信号有效电平
          Group_2_ElectricSupplySwitch_Gpio_State: 0,
          //第二组市/发电柜发电合闸信号有效电平
          Group_2_GenSwitch_Gpio_State: 1,
          //第一组补偿柜分闸信号有效电平
          Group_1_CompensationCabinetSwitch_Gpio_State: 0,
          //第一组补偿电容运行信号有效电平
          Group_1_CompensationCabinetCapacity_Run_Gpio_State: 0,
          //第二组补偿柜分闸信号有效电平
          Group_2_CompensationCabinetSwitch_Gpio_State: 0,
          //第二组补偿电容运行信号有效电平
          Group_2_CompensationCabinetCapacity_Run_Gpio_State: 0,
          //第三组补偿柜分闸信号有效电平
          Group_3_CompensationCabinetSwitch_Gpio_State: 0,
          //第三组补偿电容运行信号有效电平
          Group_3_CompensationCabinetCapacity_Run_Gpio_State: 0,
          //第四组补偿柜分闸信号有效电平
          Group_4_CompensationCabinetSwitch_Gpio_State: 0,
          //第四组补偿电容运行信号有效电平
          Group_4_CompensationCabinetCapacity_Run_Gpio_State: 0,
          //防潮灯开启信号有效电平
          MoistureProofLamps_Gpio_State: 1,
          //机房送风机启动信号有效电平
          SwitchingHouse_FeedFan_Start_Gpio_State: 1,
          //机房排风机启动信号有效电平
          SwitchingHouse_ExhaustFan_Start_Gpio_State: 1,
        }
      }else if(type==='UK_16IOTo485_V01'){
        this.GPIOList=this.GPIOListBackups.filter(item=>item.dickey.includes('BOARD'))
        this.status=true
        this.type="default"
        this.iotConfigs= {
          //机房门打开信号绑定IO端口，默认BOARD1_X1
          SwitchingHouse_Door_Open_Bind_Port: 'BOARD1_X1',
          //第一组变压器温度过高信号绑定IO端口，默认BOARD1_X6
          Group_1_Transformer_Temp_Bind_Port: 'BOARD1_X6',
          //第一组变压器降温风扇启动信号绑定IO端口，默认BOARD1_X7
          Group_1_Transformer_Fan_Start_Bind_Port: 'BOARD1_X7',
          //第二组变压器温度过高信号绑定IO端口，默认BOARD1_X8
          Group_2_Transformer_Temp_Bind_Port: 'BOARD1_X8',
          //第二组变压器降温风扇启动信号绑定IO端口，默认BOARD1_X9
          Group_2_Transformer_Fan_Start_Bind_Port: 'BOARD1_X9',
          //拨打值班室电话信号绑定IO端口，默认BOARD2_X16
          Voip_Alarm_Bind_Port: 'BOARD2_X16',
          //第一路进线分闸信号绑定IO端口，默认BOARD1_X10
          IncomingCabinet_1_Switching_Bind_Port: 'BOARD1_X10',
          //第二路进线分闸信号绑定IO端口，默认BOARD1_X11
          IncomingCabinet_2_Switching_Bind_Port: 'BOARD1_X11',
          //联络柜合闸信号绑定IO端口，默认BOARD1_X12
          ContactCabinetSwitch_Bind_Port: 'BOARD1_X12',
          //第一组市/发电柜市电分闸信号绑定IO端口，默认BOARD1_X13
          Group_1_ElectricSupplySwitch_Bind_Port: 'BOARD1_X13',
          //第一组市/发电柜发电合闸信号绑定IO端口，默认BOARD1_X14
          Group_1_GenSwitch_Bind_Port: 'BOARD1_X14',
          //第二组市/发电柜市电分闸信号绑定IO端口，默认BOARD1_X15
          Group_2_ElectricSupplySwitch_Bind_Port: 'BOARD1_X15',
          //第二组市/发电柜发电合闸信号绑定IO端口，默认BOARD1_X16
          Group_2_GenSwitch_Bind_Port: 'BOARD1_X16',
          //第一组补偿柜分闸信号绑定IO端口，默认BOARD2_X1
          Group_1_CompensationCabinetSwitch_Bind_Port: 'BOARD2_X1',
          //第一组补偿电容运行信号绑定IO端口，默认BOARD1_X2
          Group_1_CompensationCabinetCapacity_Run_Bind_Port: 'BOARD1_X2',
          //第二组补偿柜分闸信号绑定IO端口，默认BOARD2_X2
          Group_2_CompensationCabinetSwitch_Bind_Port: 'BOARD2_X2',
          //第二组补偿电容运行信号绑定IO端口，默认BOARD1_X3
          Group_2_CompensationCabinetCapacity_Run_Bind_Port: 'BOARD1_X3',
          //第三组补偿柜分闸信号绑定IO端口，默认BOARD2_X3
          Group_3_CompensationCabinetSwitch_Bind_Port: 'BOARD2_X3',
          //第三组补偿电容运行信号绑定IO端口，默认BOARD1_X4
          Group_3_CompensationCabinetCapacity_Run_Bind_Port: 'BOARD1_X4',
          //第四组补偿柜分闸信号绑定IO端口，默认BOARD2_X4
          Group_4_CompensationCabinetSwitch_Bind_Port: 'BOARD2_X4',
          //第四组补偿电容运行信号绑定IO端口，默认BOARD1_X5
          Group_4_CompensationCabinetCapacity_Run_Bind_Port: 'BOARD1_X5',
          //防潮灯开启信号绑定IO端口，默认BOARD2_X5
          MoistureProofLamps_Bind_Port: 'BOARD2_X5',
          //机房送风机启动信号绑定IO端口，默认BOARD2_X6
          SwitchingHouse_FeedFan_Start_Bind_Port: 'BOARD2_X6',
          //机房排风机启动信号绑定IO端口，默认BOARD2_X7
          SwitchingHouse_ExhaustFan_Start_Bind_Port: 'BOARD2_X7',

          //机房门打开信号有效电平，1为高电平有效，0为低电平有效，默认0
          SwitchingHouse_Door_Open_Gpio_State: 0,
          //第一组变压器温度过高信号有效电平，1为高电平有效，0为低电平有效，默认1
          Group_1_Transformer_Temp_Gpio_State: 1,
          //第一组变压器降温风扇启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          Group_1_Transformer_Fan_Start_Gpio_State: 1,
          //第二组变压器温度过高信号有效电平，1为高电平有效，0为低电平有效，默认1
          Group_2_Transformer_Temp_Gpio_State: 1,
          //第二组变压器降温风扇启动信号有效电平，1为高电平有效，0为低电平有效，默认1
          Group_2_Transformer_Fan_Start_Gpio_State: 1,
          //拨打值班室电话信号有效电平，1为高电平有效，0为低电平有效，默认1
          Voip_Alarm_Gpio_State: 1,
          //第一路进线分闸信号有效电平
          IncomingCabinet_1_Switching_Gpio_State: 0,
          //第二路进线分闸信号有效电平
          IncomingCabinet_2_Switching_Gpio_State: 0,
          //联络柜合闸信号有效电平
          ContactCabinetSwitch_Gpio_State: 1,
          //第一组市/发电柜市电分闸信号有效电平
          Group_1_ElectricSupplySwitch_Gpio_State: 0,
          //第一组市/发电柜发电合闸信号有效电平
          Group_1_GenSwitch_Gpio_State: 1,
          //第二组市/发电柜市电分闸信号有效电平
          Group_2_ElectricSupplySwitch_Gpio_State: 0,
          //第二组市/发电柜发电合闸信号有效电平
          Group_2_GenSwitch_Gpio_State: 1,
          //第一组补偿柜分闸信号有效电平
          Group_1_CompensationCabinetSwitch_Gpio_State: 0,
          //第一组补偿电容运行信号有效电平
          Group_1_CompensationCabinetCapacity_Run_Gpio_State: 1,
          //第二组补偿柜分闸信号有效电平
          Group_2_CompensationCabinetSwitch_Gpio_State: 0,
          //第二组补偿电容运行信号有效电平
          Group_2_CompensationCabinetCapacity_Run_Gpio_State: 1,
          //第三组补偿柜分闸信号有效电平
          Group_3_CompensationCabinetSwitch_Gpio_State: 0,
          //第三组补偿电容运行信号有效电平
          Group_3_CompensationCabinetCapacity_Run_Gpio_State: 1,
          //第四组补偿柜分闸信号有效电平
          Group_4_CompensationCabinetSwitch_Gpio_State: 0,
          //第四组补偿电容运行信号有效电平
          Group_4_CompensationCabinetCapacity_Run_Gpio_State: 1,
          //防潮灯开启信号有效电平
          MoistureProofLamps_Gpio_State: 1,
          //机房送风机启动信号有效电平
          SwitchingHouse_FeedFan_Start_Gpio_State: 1,
          //机房排风机启动信号有效电平
          SwitchingHouse_ExhaustFan_Start_Gpio_State: 1,
        }
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
}
</script>
<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>