<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 5%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model v-if="IotFlag.iotFlag==0" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:14}" :wrapper-col="{span:10}">
      <a-form-model-item label="播放欢迎语音楼层" prop="welcome_floor_num">
        <a-input v-model="iotConfigs.welcome_floor_num" placeholder="" suffix="层"></a-input>
      </a-form-model-item>
      <a-form-model-item label="播放语音的音量" prop="master">
        <a-input v-model="iotConfigs.master" placeholder="默认为80%" suffix="%"></a-input>
      </a-form-model-item>
      <a-form-model-item label="欢迎语音功能使能" prop="welcome_audio_enable">
        <a-select v-model="iotConfigs.welcome_audio_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="电梯是否是双开门" prop="double_door_enable">
        <a-select v-model="iotConfigs.double_door_enable" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="待机楼层" prop="STANDBY_FLOOR">
        <a-input v-model="iotConfigs.STANDBY_FLOOR" placeholder="待机楼层之间请用英文逗号（,）连接"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电梯楼层" prop="floors">
        <a-input v-model="iotConfigs.floors" placeholder="" suffix="层"></a-input>
      </a-form-model-item>
      <a-form-model-item label="楼层每层高度序列" prop="floor_height">
        <a-input v-model="iotConfigs.floor_height" placeholder="楼层高度之间请用英文逗号（,）连接"></a-input>
      </a-form-model-item>
      <a-form-model-item label="当前标定楼层" prop="calibrate_floor_num">
        <a-input v-model="iotConfigs.calibrate_floor_num" placeholder="" suffix="层"></a-input>
      </a-form-model-item>
      <a-form-model-item label="当前楼层测量高度和实际高度差值的阈值" prop="hei_threshold_base">
        <a-input v-model="iotConfigs.hei_threshold_base" placeholder="默认为0.5m" suffix="米"></a-input>
      </a-form-model-item>
      <a-form-model-item label="开锁区域外停止故障撤销时间" prop="out_cancle_time_span">
        <a-input v-model="iotConfigs.out_cancle_time_span" placeholder="默认为10分钟" suffix="分钟"></a-input>
      </a-form-model-item>
      <a-form-model-item label="开门最大超时时间" prop="FAULE_OPEN_DOOR_TIME">
        <a-input v-model="iotConfigs.FAULE_OPEN_DOOR_TIME" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="关门最大超时时间" prop="FAULE_CLOSE_DOOR_TIME">
        <a-input v-model="iotConfigs.FAULE_CLOSE_DOOR_TIME" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电梯物联网故障使能标志" prop="all_fault_enable">
        <a-select v-model="iotConfigs.all_fault_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="轿厢在开锁区域外停止故障使能标志" prop="out_detect_enable_flag">
        <a-select v-model="iotConfigs.out_detect_enable_flag" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="无源报警功能使能" prop="passive_alarm_enable">
        <a-select v-model="iotConfigs.passive_alarm_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="有源报警使能标志" prop="alarm_active_enable">
        <a-select v-model="iotConfigs.alarm_active_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="生产模式使能" prop="Manufacture_mode">
        <a-select v-model="iotConfigs.Manufacture_mode" placeholder="1使能，0禁用，默认禁用">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="安全回路断路故障使能" prop="safety_loop_enable">
        <a-select v-model="iotConfigs.safety_loop_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="关门故障使能" prop="close_enable">
        <a-select v-model="iotConfigs.close_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="开门故障使能" prop="open_enable">
        <a-select v-model="iotConfigs.open_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="困人故障使能" prop="trapped_enable">
        <a-select v-model="iotConfigs.trapped_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="轿厢意外移动故障使能" prop="accidental_movement_enable">
        <a-select v-model="iotConfigs.accidental_movement_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="电动机运转时间限制器动作使能" prop="motor_fault_enable">
        <a-select v-model="iotConfigs.motor_fault_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="楼层位置丢失故障使能" prop="floor_loss_enable">
        <a-select v-model="iotConfigs.floor_loss_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="其它阻止电梯再启动的故障使能" prop="other_fault_enable">
        <a-select v-model="iotConfigs.other_fault_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="困人绑定报警使能标志" prop="trapped_alarm_enable">
        <a-select v-model="iotConfigs.trapped_alarm_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="故障撤销功能使能标志" prop="repeatedly_open_close_max">
        <a-select v-model="iotConfigs.recall_event_flag" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="开门运行故障使能标志" prop="open_run_fault_enable">
        <a-select v-model="iotConfigs.open_run_fault_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="开门运行故障撤销时间" prop="open_run_recall_time_span">
        <a-input v-model="iotConfigs.open_run_recall_time_span" placeholder="默认10分钟" suffix="分钟"></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">显示屏参数</h1>
      <a-form-model-item label="显示屏幕使能" prop="Xianshi_module_enable">
        <a-select v-model="iotConfigs.Xianshi_module_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="显示屏幕编号" prop="Xianshi_device_sn_num">
        <a-input v-model="iotConfigs.Xianshi_device_sn_num" placeholder="" :disabled="true"></a-input>
      </a-form-model-item>
    </a-form-model>

    <a-form-model v-if="IotFlag.iotFlag==1" ref="modalForm" layout="inline" class="form-in-twocolumns" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">基础参数</h1>
      <a-form-model-item label="电梯物联网心跳周期" prop="heartbeat_cycle">
        <a-input v-model="iotConfigs.heartbeat_cycle" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="楼层气压参考值更新时间" prop="T_Pi_ref">
        <a-input v-model="iotConfigs.T_Pi_ref" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="AI模块实时状态失连最大超时时间" prop="AI_REALSTATE_RECEIVE_TIME">
        <a-input v-model="iotConfigs.AI_REALSTATE_RECEIVE_TIME" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="人流量统计常数" prop="cumulative_person_const">
        <a-input v-model="iotConfigs.cumulative_person_const" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="重启后不上报门区外停梯故障时间长度" prop="after_up_no_out_fault_time">
        <a-input v-model="iotConfigs.after_up_no_out_fault_time" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">气压计相关参数</h1>
      <a-form-model-item label="气压计测量精度" prop="PRESSURE_PRECISION">
        <a-input v-model="iotConfigs.PRESSURE_PRECISION" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="气压计测量精度的倍数" prop="BASE_PTH">
        <a-input v-model="iotConfigs.BASE_PTH" placeholder="" suffix="倍"></a-input>
      </a-form-model-item>
      <a-form-model-item label="气压计平滑队列最大长度" prop="PRESSUER_LIST_MAX">
        <a-input v-model="iotConfigs.PRESSUER_LIST_MAX" placeholder=""></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">楼层判断相关参数</h1>
      <a-form-model-item label="楼层判断方式" prop="floor_sensor_mode">
        <a-select v-model="iotConfigs.floor_sensor_mode" placeholder="1代表气压计，2代表干簧管，3代表互感器，默认为1">
          <a-select-option :value="1">气压计</a-select-option>
          <a-select-option :value="2">干簧管</a-select-option>
          <a-select-option :value="3">互感器</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="气压和高度换算常数" prop="pressure_height_constant">
        <a-input v-model="iotConfigs.pressure_height_constant" placeholder="默认为0.111"></a-input>
      </a-form-model-item>
      <a-form-model-item label="气压和高度换算常数阈值" prop="pressure_height_constant_threshold">
        <a-input v-model="iotConfigs.pressure_height_constant_threshold" placeholder="默认为0.005"></a-input>
      </a-form-model-item>
      <a-form-model-item label="气压和高度换算常数的队列个数" prop="pressure_height_constant_list_max_len">
        <a-input v-model="iotConfigs.pressure_height_constant_list_max_len" placeholder="默认为8个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="气压和高度换算常数参考值最大队列长度" prop="pressure_height_constant_reference_list_max">
        <a-input v-model="iotConfigs.pressure_height_constant_reference_list_max" placeholder="默认为5000"></a-input>
      </a-form-model-item>
      <a-form-model-item label="气压和高度换算常数与参考值的比值上限" prop="repeatedly_open_close_max">
        <a-input v-model="iotConfigs.pressure_height_constant_reference_threshold" placeholder="默认为0.08"></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">困人故障相关参数</h1>
      <a-form-model-item label="困人过程中有人的概率" prop="trapped_person_appear_probability">
        <a-input v-model="iotConfigs.trapped_person_appear_probability" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="发生困人长时间驻留前的时间" prop="before_long_stay">
        <a-input v-model="iotConfigs.before_long_stay" placeholder="默认为3分钟" suffix="分钟"></a-input>
      </a-form-model-item>
      <a-form-model-item label="发生困人长时间驻留后的时间" prop="after_long_stay">
        <a-input v-model="iotConfigs.after_long_stay" placeholder="默认为30分钟" suffix="分钟"></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">轿厢在开锁区域外停止故障相关参数</h1>
      <a-form-model-item label="每米增加的误差" prop="step_threshold">
        <a-input v-model="iotConfigs.step_threshold" placeholder="默认为0.01m" suffix="米"></a-input>
      </a-form-model-item>
      <a-form-model-item label="高度差值阈值不变的最大距离" prop="hei_threshold_hold_max_distance">
        <a-input v-model="iotConfigs.hei_threshold_hold_max_distance" placeholder="默认为40m" suffix="米"></a-input>
      </a-form-model-item>
      <a-form-model-item label="高度差值阈值上限" prop="hei_threshold_hold_max_distance">
        <a-input v-model="iotConfigs.hei_threshold_max" placeholder="默认为1m" suffix="米"></a-input>
      </a-form-model-item>
      <a-form-model-item label="轿厢外停止故障取消电梯连续运行状态数" prop="out_cancel_car_move_continuation_times">
        <a-input v-model="iotConfigs.out_cancel_car_move_continuation_times" placeholder=""></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">开关门故障检测参数</h1>
      <a-form-model-item label="反复开关门时间阈值" prop="repeatedly_open_close_time_threshold">
        <a-input v-model="iotConfigs.repeatedly_open_close_time_threshold" placeholder="默认为3分钟" suffix="分钟"></a-input>
      </a-form-model-item>
      <a-form-model-item label="反复开关门次数" prop="repeatedly_open_close_max">
        <a-input v-model="iotConfigs.repeatedly_open_close_max" placeholder="默认为10次" suffix="次"></a-input>
      </a-form-model-item>
      <a-form-model-item label="开门故障撤销时间" prop="open_cancle_time_span">
        <a-input v-model="iotConfigs.open_cancle_time_span" placeholder="默认为10分钟（如果十分钟内出现一次开关门）" suffix="分钟"></a-input>
      </a-form-model-item>
      <a-form-model-item label="关门故障撤销时间" prop="close_cancle_time_span">
        <a-input v-model="iotConfigs.close_cancle_time_span" placeholder="默认为10分钟（如果十分钟内出现一次开关门）" suffix="分钟"></a-input>
      </a-form-model-item>
      <a-form-model-item label="挡门时间阈值" prop="block_door_time_threshold">
        <a-input v-model="iotConfigs.block_door_time_threshold" placeholder="默认120秒" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="挡门事件阈值" prop="block_door_acc_threshold">
        <a-input v-model="iotConfigs.block_door_acc_threshold" placeholder="默认0.92"></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">电动机限制器动作故障检测参数</h1>
      <a-form-model-item label="电动机限制器运作气压阈值" prop="motor_threshold">
        <a-input v-model="iotConfigs.motor_threshold" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="电动机运作检测连续个数" prop="motor_continuation_times">
        <a-input v-model="iotConfigs.motor_continuation_times" placeholder="" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电动机限制器运作时间阈值" prop="motor_time_threshold">
        <a-input v-model="iotConfigs.motor_time_threshold" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电动机运作故障取消时间" prop="motor_cancel_time">
        <a-input v-model="iotConfigs.motor_cancel_time" placeholder="" suffix="秒"></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">楼层丢失故障检测参数</h1>
      <a-form-model-item label="楼层丢失气压最低阈值" prop="loss_floor_pressure_lowest_threshold">
        <a-input v-model="iotConfigs.loss_floor_pressure_lowest_threshold" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="楼层丢失气压最高阈值" prop="loss_floor_pressure_highest_threshold">
        <a-input v-model="iotConfigs.loss_floor_pressure_highest_threshold" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="楼层丢失检测连续个数" prop="loss_floor_continuation_times">
        <a-input v-model="iotConfigs.loss_floor_continuation_times" placeholder="" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="楼层丢失故障取消阈值" prop="loss_fault_cancel_threshold">
        <a-input v-model="iotConfigs.loss_fault_cancel_threshold" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="楼层丢失故障取消检测连续个数" prop="loss_fault_cancel_continuation_times">
        <a-input v-model="iotConfigs.loss_fault_cancel_continuation_times" placeholder="" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="楼层丢失参考陀螺仪个数" prop="mpu_floor_loss_num">
        <a-input v-model="iotConfigs.mpu_floor_loss_num" placeholder="默认1个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="楼层丢失参考陀螺仪阈值" prop="mpu_floor_loss_threshold">
        <a-input v-model="iotConfigs.mpu_floor_loss_threshold" placeholder="默认0.03"></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">楼层自动纠正功能参数</h1>
      <span v-if="IotFlag.iotFlag==1" style="margin-left: 3%;font-size: 30px">楼层自动纠正功能参数：</span>
      <a-form-model-item label="楼层自动纠正，电梯运行最大次数" prop="floor_auto_check_run_times">
        <a-input v-model="iotConfigs.floor_auto_check_run_times" placeholder="默认为8次" suffix="次"></a-input>
      </a-form-model-item>
      <a-form-model-item label="楼层自动纠正时间间隔" prop="floor_auto_check_time">
        <a-input v-model="iotConfigs.floor_auto_check_time" placeholder="默认为3分钟" suffix="分钟"></a-input>
      </a-form-model-item>
      <a-form-model-item label="楼层自动纠正，停靠最多楼层" prop="floor_auto_check_lowest_num">
        <a-input v-model="iotConfigs.floor_auto_check_lowest_num" placeholder="默认为最小正楼层" suffix="层"></a-input>
      </a-form-model-item>
      <a-form-model-item label="楼层自动纠正功能使能标志" prop="floor_auto_check_time">
        <a-select v-model="iotConfigs.floor_auto_check_enable" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">其他故障检测参数</h1>
      <a-form-model-item label="其他故障参考队列最大长度" prop="other_fault_reference_list_max">
        <a-input v-model="iotConfigs.other_fault_reference_list_max" placeholder="默认500"></a-input>
      </a-form-model-item>
      <a-form-model-item label="其他故障超速阈值" prop="other_fault_threshold">
        <a-input v-model="iotConfigs.other_fault_threshold" placeholder="默认0.5"></a-input>
      </a-form-model-item>
      <a-form-model-item label="其他故障解除阈值" prop="other_fault_cancel_threshold">
        <a-input v-model="iotConfigs.other_fault_cancel_threshold" placeholder="默认0.05"></a-input>
      </a-form-model-item>
      <a-form-model-item label="其他故障实时队列长度" prop="other_fault_realtime_list_num">
        <a-input v-model="iotConfigs.other_fault_realtime_list_num" placeholder="默认3个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电梯最大初始速度" prop="speed_max">
        <a-input v-model="iotConfigs.speed_max" placeholder="单位：m/s" suffix="米/秒"></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">陀螺仪相关参数</h1>
      <a-form-model-item label="陀螺仪读取周期" prop="mpu_read_cycle">
        <a-input v-model="iotConfigs.mpu_read_cycle" placeholder="默认10ms" suffix="毫秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="陀螺仪重力加速度队列长度" prop="mpu_acceleration_list_num">
        <a-input v-model="iotConfigs.mpu_acceleration_list_num" placeholder="默认5个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="陀螺仪重力加速度参考队列长度" prop="mpu_acceleration_ref_list_num">
        <a-input v-model="iotConfigs.mpu_acceleration_ref_list_num" placeholder="默认500"></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">干簧管相关参数</h1>
      <a-form-model-item label="干簧管采集信号周期" prop="double_door_calculation_cycle">
        <a-input v-model="iotConfigs.double_door_calculation_cycle" placeholder="默认10ms" suffix="毫秒"></a-input>
      </a-form-model-item>
      <a-form-model-item label="干簧管开关门到位队列长度" prop="double_door_list_sum">
        <a-input v-model="iotConfigs.double_door_list_sum" placeholder="默认10个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="干簧管开关门判断阈值" prop="double_door_list_max">
        <a-input v-model="iotConfigs.double_door_list_max" placeholder="默认7个" suffix="个"></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">干簧管相关参数</h1>
      <a-form-model-item label="有源报警有效电平配置" prop="alarm_active_voltage_enable">
        <a-select v-model="iotConfigs.alarm_active_voltage_enable" placeholder="1为高电平，0为低电平">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="无源源报警有效电平配置" prop="alarm_voltage_enable">
        <a-select v-model="iotConfigs.alarm_voltage_enable" placeholder="1为高电平，0为低电平">
          <a-select-option :value="1">高电平</a-select-option>
          <a-select-option :value="0">低电平</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="gpio触发时长" prop="floor_auto_check_time">
        <a-input v-model="iotConfigs.gpio_active_time" placeholder="默认为100ms" suffix="毫秒"></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">运动停止相关参数</h1>
      <a-form-model-item label="判断轿厢停止状态对列长度" prop="car_state_continuation_times">
        <a-input v-model="iotConfigs.car_state_continuation_times" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="判断轿厢运行状态对列长度" prop="car_move_state_continuation_times">
        <a-input v-model="iotConfigs.car_move_state_continuation_times" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="电梯运动参考陀螺仪个数" prop="mpu_car_move_num">
        <a-input v-model="iotConfigs.mpu_car_move_num" placeholder="默认1个" suffix="个"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电梯运动参考陀螺仪阈值" prop="mpu_car_move_threshold">
        <a-input v-model="iotConfigs.mpu_car_move_threshold" placeholder="默认0.03"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电梯运动参考陀螺仪队列个数" prop="mpu_car_move_list_num">
        <a-input v-model="iotConfigs.mpu_car_move_list_num" placeholder="默认5个" suffix="个"></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">意外移动故障相关参数</h1>
      <a-form-model-item label="意外移动故障连续检测的次数" prop="detect_other_fault_continuation_times">
        <a-input v-model="iotConfigs.detect_other_fault_continuation_times" placeholder="" suffix="次"></a-input>
      </a-form-model-item>
      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">串口切换方向延迟时间</h1>
      <a-form-model-item label="意外移动故障连续检测的次数" prop="SERIAL_485_DIRECTION_DELAY_TIME">
        <a-input v-model="iotConfigs.SERIAL_485_DIRECTION_DELAY_TIME" placeholder="默认3毫秒" suffix="毫秒"></a-input>
      </a-form-model-item>

      <a-divider></a-divider>
      <h1 style="color: #1890ff;font-weight: bold;margin-left: 5%">故障/事件联动终端通话相关参数</h1>
      <a-form-model-item label="故障联动终端通话使能" prop="eventcode_fault">
        <a-input placeholder="请选择" @click="faultModal=true"></a-input>
      </a-form-model-item>
      <a-form-model-item label="事件联动终端通话使能" prop="eventcode_event">
        <a-input placeholder="请选择" @click="eventModal=true"></a-input>
      </a-form-model-item>
    </a-form-model>
    <a-modal v-model="faultModal" title="故障联动通话使能分配" :bodyStyle="{minHeight: '200px', maxHeight: '400px', overflow: 'auto'}">
      <template slot="footer">
        <a-button @click="faultModal=false">取消</a-button>
        <a-button type="primary" @click="setFaultAble">确认</a-button>
      </template>
      <div class="role-modal-container">
        <a-transfer :titles="['禁用', '使能']" :render="item => item.title" :target-keys="checkedFaultKeys" :data-source="faultData" @change="faultAbleCheckedChange" />
      </div>
    </a-modal>
    <a-modal v-model="eventModal" title="事件联动通话使能分配" :bodyStyle="{minHeight: '200px', maxHeight: '400px', overflow: 'auto'}">
      <template slot="footer">
        <a-button @click="eventModal=false">取消</a-button>
        <a-button type="primary" @click="setEventAble">确认</a-button>
      </template>
      <div class="role-modal-container">
        <a-transfer :titles="['禁用', '使能']" :render="item => item.title" :target-keys="checkedEventKeys" :data-source="EventData" @change="eventAbleCheckedChange" />
      </div>
    </a-modal>
  </div>
</template>
<script>
import {
  addParamsBatchLog, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList, modifyParamsBatchLog,
  setDeviceparamValueList
} from "A/ai";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "iot",
  props:{
    IotFlag:{
      default: null
    }
  },
  data() {
    return {
      faultModal:false,
      checkedFaultKeys:[],
      faultData:[
        {key:"safety_loop_alarm_enable",title:"安全回路故障联动终端通话使能"},
        {key:"open_alarm_enable",title:"开门故障联动终端通话使能"},
        {key:"close_alarm_enable",title:"关门故障联动终端通话使能"},
        {key:"out_alarm_enable",title:"开锁区域外故障联动终端通话使能"},
        {key:"accidental_movement_alarm_enable",title:"意外移动故障联动终端通话使能"},
        {key:"motor_alarm_enable",title:"电动机运转时间限制器动作故障联动终端通话使能"},
        {key:"floor_loss_alarm_enable",title:"楼层位置丢失故障联动终端通话使能"},
        {key:"other_alarm_enable",title:"其他阻止电梯再启动故障联动终端通话使能"},
        {key:"trapped_fault_alarm_enable",title:"困人故障联动终端通话使能"},
        {key:"repeatedly_open_close_alarm_enable",title:"反复开关门故障联动终端通话使能"},
        {key:"open_run_alarm_enable",title:"开门运行故障联动终端通话使能"},
      ],
      eventModal:false,
      checkedEventKeys:[],
      EventData:[
        {key:"long_stay_alarm_enable",title:"轿厢内有人长时间驻留联动终端通话使能"},
        {key:"block_door_alarm_enable",title:"挡门事件联动终端通话使能"},
      ],
      iotConfigs:{
        PRESSURE_PRECISION:'',
        BASE_PTH:'',
        T_Pi_ref:'',
        PRESSUER_LIST_MAX:'',
        FAULE_CLOSE_DOOR_TIME:'',
        FAULE_OPEN_DOOR_TIME:'',
        TRAPPED_PEOPLE_TIME:'',
        AI_REALSTATE_RECEIVE_TIME:'',
        STANDBY_FLOOR:'',
        detect_other_fault_continuation_times:'',
        SERIAL_485_DIRECTION_DELAY_TIME:'',
        cumulative_person_const:'',
        trapped_person_appear_probability:'',
        floor_height:'',
        out_cancel_car_move_continuation_times:'',
        car_state_continuation_times:'',
        out_detect_enable_flag:'',
        after_up_no_out_fault_time:'',
        heartbeat_cycle:'',
        all_fault_enable:'',
        passive_alarm_enable:'',
        welcome_audio_enable:'',
        motor_threshold:'',
        motor_continuation_times:'',
        motor_time_threshold:'',
        motor_cancel_time:'',
        loss_floor_pressure_lowest_threshold:'',
        loss_floor_pressure_highest_threshold:'',
        loss_floor_continuation_times:'',
        loss_fault_cancel_threshold:'',
        loss_fault_cancel_continuation_times:'',
        alarm_active_enable:'',
        Manufacture_mode:'',
        alarm_active_voltage_enable:'',
        alarm_voltage_enable:'',
        safety_loop_enable:'',
        close_enable:'',
        open_enable:'',
        trapped_enable:'',
        other_fault_enable:'',
        accidental_movement_enable:'',
        motor_fault_enable:'',
        floor_loss_enable:'',
        floors:'',
        car_move_state_continuation_times:'',
        welcome_floor_num:'',
        calibrate_floor_num:'',
        floor_auto_check_run_times:'',
        floor_auto_check_lowest_num:'',
        floor_auto_check_time:'',
        floor_auto_check_enable:'',
        gpio_active_time:'',
        master:'',
        pressure_height_constant:'',
        pressure_height_constant_threshold:'',
        pressure_height_constant_list_max_len:'',
        out_cancle_time_span:'',
        before_long_stay:'',
        after_long_stay:'',
        trapped_alarm_enable:'',
        hei_threshold_base:'',
        step_threshold:'',
        hei_threshold_hold_max_distance:'',
        hei_threshold_max:'',
        floor_sensor_mode:'',
        pressure_height_constant_study_begin:'',
        pressure_height_constant_study_end:'',
        repeatedly_open_close_time_threshold:'',
        repeatedly_open_close_max:'',
        recall_event_flag:'',
        open_cancle_time_span:'',
        close_cancle_time_span:'',
        pressure_height_constant_reference_list_max:'',
        pressure_height_constant_reference_threshold:'',
        double_door_enable:'',
        double_door_calculation_cycle:'',
        double_door_list_sum:'',
        double_door_list_max:'',
        other_fault_reference_list_max:'',
        other_fault_threshold:'',
        other_fault_cancel_threshold:'',
        mpu_read_cycle:'',
        mpu_acceleration_list_num:'',
        mpu_acceleration_ref_list_num:'',
        mpu_car_move_num:'',
        mpu_car_move_threshold:'',
        mpu_floor_loss_num:'',
        mpu_floor_loss_threshold:'',
        mpu_car_move_list_num:'',
        block_door_time_threshold:'',
        block_door_acc_threshold:'',
        other_fault_realtime_list_num:'',
        open_run_fault_enable:'',
        Xianshi_device_sn_num:'',
        open_run_recall_time_span:'',
        Xianshi_module_enable:'',
        long_stay_event_enable:'',

        //故障联动通话使能参数
        safety_loop_alarm_enable:'',
        open_alarm_enable:'',
        close_alarm_enable:'',
        out_alarm_enable:'',
        accidental_movement_alarm_enable:'',
        motor_alarm_enable:'',
        floor_loss_alarm_enable:'',
        other_alarm_enable:'',
        trapped_fault_alarm_enable:'',
        repeatedly_open_close_alarm_enable:'',
        open_run_alarm_enable:'',

        //事件联动通话使能参数
        long_stay_alarm_enable:'',
        block_door_alarm_enable:'',

        speed_max:'',

        //消防等报警使能
        FIRE_ALARM_ENABLE:'',
        DOOR_ACCESS_ALARM_ENABLE:'',
        LIFT_ALARM_ENABLE:'',
        OVERFLOW_ALARM_ENABLE:'',
        GAS_ALARM_ENABLE:'',

        //录像上传使能
        FIRE_ALARM_UPLOAD_VIDEO_ENABLE:'',
        DOOR_ACCESS_ALARM_UPLOAD_VIDEO_ENABLE:'',
        LIFT_ALARM_UPLOAD_VIDEO_ENABLE:'',
        OVERFLOW_ALARM_UPLOAD_VIDEO_ENABLE:'',
        GAS_ALARM_UPLOAD_VIDEO_ENABLE:'',

        //第三方消防报警参数
        SERIAL_485_ENABLE:'',
        FIRE_EQUIPMENT_MODEL:'',
        FIRE_EQUIPMENT_MANUFACTURER_NAME:'',

        //IO状态判断时间间隔,默认1秒
        IO_Calculation_Interval:'',
        //IO状态采样时间间隔，默认15ms
        IO_Sampling_Interval:'',
        //IO状态判断队列长度，默认8个
        IO_Sampling_List_Max:'',
        //IO状态确认变化阈值，默认2个
        IO_State_Changed_Threshold:'',
        //温度过高阈值，默认45摄氏度
        Celsius_Temperature_Max_Threshold:'',
        //温度过低阈值，默认0摄氏度
        Celsius_Temperature_Min_Threshold:'',
        //相对湿度过高阈值，默认90%
        Relative_Humidity_Max_Threshold:'',
        //相对湿度过低阈值，默认10%
        Relative_Humidity_Min_Threshold:'',
        //温度队列长度，默认3个
        Celsius_Temperature_State_List_Max:'',
        //温度队列阈值，默认2个
        Celsius_Temperature_State_List_Threshold:'',
        //湿度队列长度，默认3个
        Relative_Humidity_State_List_Max:'',
        //湿度队列阈值，默认2个
        Relative_Humidity_State_List_Threshold:'',
        //人体检测队列长度，默认3个
        Person_Detect_List_Max:'',
        //人体检测队列阈值，默认2个
        Person_Detect_List_Threshold:'',
        //摄氏温度过高事件使能，1为使能，0为禁止，默认1
        Celsius_Temperature_Too_High_Event_Enable:'',
        //摄氏温度过低事件使能，1为使能，0为禁止，默认1
        Celsius_Temperature_Too_Low_Event_Enable:'',
        //相对湿度过高事件使能，1为使能，0为禁止，默认1
        Relative_Humidity_Too_High_Event_Enable:'',
        //相对湿度过低事件使能，1为使能，0为禁止，默认1
        Relative_Humidity_Too_Low_Event_Enable:'',
        //机房有人事件使能，1为使能，0为禁止，默认1
        WaterPumpHouse_Person_Event_Enable:'',
        //机房门打开事件使能，1为使能，0为禁止，默认1
        EngineRoom_Door_Open_Event_Enable:'',
        //机房送风机启动事件使能，1为使能，0为禁止，默认1
        EngineRoom_FeedFan_Start_Event_Enable:'',
        //机房排风机启动事件使能，1为使能，0为禁止，默认1
        EngineRoom_ExhaustFan_Start_Event_Enable:'',
        //潜水泵1启动事件使能，1为使能，0为禁止，默认1
        SubmersiblePump_1_Start_Event_Enable:'',
        //潜水泵2启动事件使能，1为使能，0为禁止，默认1
        SubmersiblePump_2_Start_Event_Enable:'',
        //水泵组1主泵1启动事件使能，1为使能，0为禁止，默认1
        WaterPumpGroup_1_FirstMainPump_Start_Event_Enable:'',
        //水泵组1主泵2启动事件使能，1为使能，0为禁止，默认1
        WaterPumpGroup_1_SecondMainPump_Start_Event_Enable:'',
        //水泵组1主泵3启动事件使能，1为使能，0为禁止，默认1
        WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable:'',
        //水泵组1稳压泵启动事件使能，1为使能，0为禁止，默认1
        WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable:'',
        //水泵组2主泵1启动事件使能，1为使能，0为禁止，默认1
        WaterPumpGroup_2_FirstMainPump_Start_Event_Enable:'',
        //水泵组2主泵2启动事件使能，1为使能，0为禁止，默认1
        WaterPumpGroup_2_SecondMainPump_Start_Event_Enable:'',
        //水泵组2主泵3启动事件使能，1为使能，0为禁止，默认1
        WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable:'',
        //水泵组2稳压泵启动事件使能，1为使能，0为禁止，默认1
        WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable:'',
        //水箱补水开始事件使能，1为使能，0为禁止，默认1
        Radiator_Supply_Start_Event_Enable:'',
        //水箱溢水开始事件使能，1为使能，0为禁止，默认1
        Radiator_Overflow_Start_Event_Enable:'',
        //水箱缺水开始事件使能，1为使能，0为禁止，默认1
        Radiator_Lack_Start_Event_Enable:'',
        //机房走水报警使能，1为使能，0为禁止，默认1
        EngineRoom_OnFire_Alarm_Enable:'',
        //潜水泵断电报警使能，1为使能，0为禁止，默认1
        SubmersiblePump_Power_Cut_Alarm_Enable:'',
        //潜水泵1故障报警使能，1为使能，0为禁止，默认1
        SubmersiblePump_1_Fault_Alarm_Enable:'',
        //潜水泵2故障报警使能，1为使能，0为禁止，默认1
        SubmersiblePump_2_Fault_Alarm_Enable:'',
        //水泵组1故障报警使能，1为使能，0为禁止，默认1
        WaterPumpGroup_1_Fault_Alarm_Enable:'',
        //水泵组1超压报警使能，1为使能，0为禁止，默认1
        WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable:'',
        //水泵组1失压报警使能，1为使能，0为禁止，默认1
        WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable:'',
        //水泵组2故障报警使能，1为使能，0为禁止，默认1
        WaterPumpGroup_2_Fault_Alarm_Enable:'',
        //水泵组2超压报警使能，1为使能，0为禁止，默认1
        WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable:'',
        //水泵组2失压报警使能，1为使能，0为禁止，默认1
        WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable:'',
        //水箱盖打开报警使能，1为使能，0为禁止，默认1
        Radiator_Cap_Open_Alarm_Enable:'',
        //补水故障，高水位故障(111)报警使能，1为使能，0为禁止，默认1
        Radiator_Supply_HighWaterLevel_Alarm_Enable:'',
        //补水故障，高、缺水位故障(110)报警使能，1为使能，0为禁止，默认1
        Radiator_Supply_High_LackWaterLevel_Alarm_Enable:'',
        //正在补水，水位过低(100)报警使能，1为使能，0为禁止，默认1
        Radiator_Supply_LackWaterLevel_Alarm_Enable:'',
        //高水位故障(011)报警使能，1为使能，0为禁止，默认1
        Radiator_HighWaterLevel_Alarm_Enable:'',
        //高、缺水位故障(010)报警使能，1为使能，0为禁止，默认1
        Radiator_High_LackWaterLevel_Alarm_Enable:'',
        //补水故障或无外水；低水位故障(000)报警使能，1为使能，0为禁止，默认1
        Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable:'',
      },
      devicemoduletype: '',
      modelnum: '',
      methodList: [],
      devicenum:'',
      devicename:'',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
    },
    setFaultAble(){
      this.iotConfigs.safety_loop_alarm_enable="0"
      this.iotConfigs.open_alarm_enable="0"
      this.iotConfigs.close_alarm_enable="0"
      this.iotConfigs.out_alarm_enable="0"
      this.iotConfigs.accidental_movement_alarm_enable="0"
      this.iotConfigs.motor_alarm_enable="0"
      this.iotConfigs.floor_loss_alarm_enable="0"
      this.iotConfigs.other_alarm_enable="0"
      this.iotConfigs.trapped_fault_alarm_enable="0"
      this.iotConfigs.repeatedly_open_close_alarm_enable="0"
      this.iotConfigs.open_run_alarm_enable="0"
      for(let i=0;i<this.checkedFaultKeys.length;i++){
        if(this.checkedFaultKeys[i]=="safety_loop_alarm_enable"){
          this.iotConfigs.safety_loop_alarm_enable="1"
        }else if(this.checkedFaultKeys[i]=="open_alarm_enable"){
          this.iotConfigs.open_alarm_enable="1"
        }else if(this.checkedFaultKeys[i]=="close_alarm_enable"){
          this.iotConfigs.close_alarm_enable="1"
        }else if(this.checkedFaultKeys[i]=="out_alarm_enable"){
          this.iotConfigs.out_alarm_enable="1"
        }else if(this.checkedFaultKeys[i]=="accidental_movement_alarm_enable"){
          this.iotConfigs.accidental_movement_alarm_enable="1"
        }else if(this.checkedFaultKeys[i]=="motor_alarm_enable"){
          this.iotConfigs.motor_alarm_enable="1"
        }else if(this.checkedFaultKeys[i]=="floor_loss_alarm_enable"){
          this.iotConfigs.floor_loss_alarm_enable="1"
        }else if(this.checkedFaultKeys[i]=="other_alarm_enable"){
          this.iotConfigs.other_alarm_enable="1"
        }else if(this.checkedFaultKeys[i]=="trapped_fault_alarm_enable"){
          this.iotConfigs.trapped_fault_alarm_enable="1"
        }else if(this.checkedFaultKeys[i]=="repeatedly_open_close_alarm_enable"){
          this.iotConfigs.repeatedly_open_close_alarm_enable="1"
        }else if(this.checkedFaultKeys[i]=="open_run_alarm_enable"){
          this.iotConfigs.open_run_alarm_enable="1"
        }
      }
      this.faultModal=false
    },
    faultAbleCheckedChange(targetKeys){
      this.checkedFaultKeys = targetKeys;
    },
    setEventAble(){
      this.iotConfigs.long_stay_alarm_enable="0"
      this.iotConfigs.block_door_alarm_enable="0"
      for(let i=0;i<this.checkedEventKeys.length;i++){
        if(this.checkedEventKeys[i]=="long_stay_alarm_enable"){
          this.iotConfigs.long_stay_alarm_enable="1"
        }else if(this.checkedEventKeys[i]=="block_door_alarm_enable"){
          this.iotConfigs.block_door_alarm_enable="1"
        }
      }
      this.eventModal=false
    },
    eventAbleCheckedChange(targetKeys){
      this.checkedEventKeys = targetKeys;
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
          this.iotConfigs.Xianshi_device_sn_num=res.item[0].monitornum
        }
      })
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      this.paramsList = [];
      this.checkedFaultKeys = [];
      this.checkedEventKeys = [];
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'GET',
              url: '/iot',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.iotConfigs.PRESSURE_PRECISION = res.item[0].params.body.PRESSURE_PRECISION
          this.iotConfigs.BASE_PTH = res.item[0].params.body.BASE_PTH
          this.iotConfigs.T_Pi_ref = res.item[0].params.body.T_Pi_ref
          this.iotConfigs.PRESSUER_LIST_MAX = res.item[0].params.body.PRESSUER_LIST_MAX
          this.iotConfigs.FAULE_CLOSE_DOOR_TIME = res.item[0].params.body.FAULE_CLOSE_DOOR_TIME
          this.iotConfigs.FAULE_OPEN_DOOR_TIME = res.item[0].params.body.FAULE_OPEN_DOOR_TIME
          this.iotConfigs.TRAPPED_PEOPLE_TIME = res.item[0].params.body.TRAPPED_PEOPLE_TIME
          this.iotConfigs.AI_REALSTATE_RECEIVE_TIME = res.item[0].params.body.AI_REALSTATE_RECEIVE_TIME
          this.iotConfigs.STANDBY_FLOOR = res.item[0].params.body.STANDBY_FLOOR
          this.iotConfigs.detect_other_fault_continuation_times = res.item[0].params.body.detect_other_fault_continuation_times
          this.iotConfigs.SERIAL_485_DIRECTION_DELAY_TIME = res.item[0].params.body.SERIAL_485_DIRECTION_DELAY_TIME
          this.iotConfigs.cumulative_person_const = res.item[0].params.body.cumulative_person_const
          this.iotConfigs.trapped_person_appear_probability = res.item[0].params.body.trapped_person_appear_probability
          this.iotConfigs.floor_height = res.item[0].params.body.floor_height
          this.iotConfigs.out_cancel_car_move_continuation_times = res.item[0].params.body.out_cancel_car_move_continuation_times
          this.iotConfigs.car_state_continuation_times = res.item[0].params.body.car_state_continuation_times
          this.iotConfigs.out_detect_enable_flag = res.item[0].params.body.out_detect_enable_flag
          this.iotConfigs.after_up_no_out_fault_time = res.item[0].params.body.after_up_no_out_fault_time
          this.iotConfigs.heartbeat_cycle = res.item[0].params.body.heartbeat_cycle
          this.iotConfigs.all_fault_enable = res.item[0].params.body.all_fault_enable
          this.iotConfigs.passive_alarm_enable = res.item[0].params.body.passive_alarm_enable
          this.iotConfigs.welcome_audio_enable = res.item[0].params.body.welcome_audio_enable
          this.iotConfigs.motor_threshold = res.item[0].params.body.motor_threshold
          this.iotConfigs.motor_continuation_times = res.item[0].params.body.motor_continuation_times
          this.iotConfigs.motor_time_threshold = res.item[0].params.body.motor_time_threshold
          this.iotConfigs.motor_cancel_time = res.item[0].params.body.motor_cancel_time
          this.iotConfigs.loss_floor_pressure_lowest_threshold = res.item[0].params.body.loss_floor_pressure_lowest_threshold
          this.iotConfigs.loss_floor_pressure_highest_threshold = res.item[0].params.body.loss_floor_pressure_highest_threshold
          this.iotConfigs.loss_floor_continuation_times = res.item[0].params.body.loss_floor_continuation_times
          this.iotConfigs.loss_fault_cancel_threshold = res.item[0].params.body.loss_fault_cancel_threshold
          this.iotConfigs.loss_fault_cancel_continuation_times = res.item[0].params.body.loss_fault_cancel_continuation_times
          this.iotConfigs.alarm_active_enable = res.item[0].params.body.alarm_active_enable
          this.iotConfigs.Manufacture_mode = res.item[0].params.body.Manufacture_mode
          this.iotConfigs.alarm_active_voltage_enable = res.item[0].params.body.alarm_active_voltage_enable
          this.iotConfigs.alarm_voltage_enable = res.item[0].params.body.alarm_voltage_enable
          this.iotConfigs.safety_loop_enable = res.item[0].params.body.safety_loop_enable
          this.iotConfigs.close_enable = res.item[0].params.body.close_enable
          this.iotConfigs.open_enable = res.item[0].params.body.open_enable
          this.iotConfigs.trapped_enable = res.item[0].params.body.trapped_enable
          this.iotConfigs.other_fault_enable = res.item[0].params.body.other_fault_enable
          this.iotConfigs.accidental_movement_enable = res.item[0].params.body.accidental_movement_enable
          this.iotConfigs.motor_fault_enable = res.item[0].params.body.motor_fault_enable
          this.iotConfigs.floor_loss_enable = res.item[0].params.body.floor_loss_enable
          this.iotConfigs.floors = res.item[0].params.body.floors
          this.iotConfigs.car_move_state_continuation_times = res.item[0].params.body.car_move_state_continuation_times
          this.iotConfigs.welcome_floor_num = res.item[0].params.body.welcome_floor_num
          this.iotConfigs.calibrate_floor_num = res.item[0].params.body.calibrate_floor_num
          this.iotConfigs.floor_auto_check_run_times = res.item[0].params.body.floor_auto_check_run_times
          this.iotConfigs.floor_auto_check_lowest_num = res.item[0].params.body.floor_auto_check_lowest_num
          this.iotConfigs.floor_auto_check_time = res.item[0].params.body.floor_auto_check_time
          this.iotConfigs.floor_auto_check_enable = res.item[0].params.body.floor_auto_check_enable
          this.iotConfigs.gpio_active_time = res.item[0].params.body.gpio_active_time
          this.iotConfigs.master = res.item[0].params.body.master
          this.iotConfigs.pressure_height_constant = res.item[0].params.body.pressure_height_constant
          this.iotConfigs.pressure_height_constant_threshold = res.item[0].params.body.pressure_height_constant_threshold
          this.iotConfigs.pressure_height_constant_list_max_len = res.item[0].params.body.pressure_height_constant_list_max_len
          this.iotConfigs.out_cancle_time_span = res.item[0].params.body.out_cancle_time_span
          this.iotConfigs.before_long_stay = res.item[0].params.body.before_long_stay
          this.iotConfigs.after_long_stay = res.item[0].params.body.after_long_stay
          this.iotConfigs.trapped_alarm_enable = res.item[0].params.body.trapped_alarm_enable
          this.iotConfigs.hei_threshold_base = res.item[0].params.body.hei_threshold_base
          this.iotConfigs.step_threshold = res.item[0].params.body.step_threshold
          this.iotConfigs.hei_threshold_hold_max_distance = res.item[0].params.body.hei_threshold_hold_max_distance
          this.iotConfigs.hei_threshold_max = res.item[0].params.body.hei_threshold_max
          this.iotConfigs.floor_sensor_mode = res.item[0].params.body.floor_sensor_mode
          this.iotConfigs.pressure_height_constant_study_begin = res.item[0].params.body.pressure_height_constant_study_begin
          this.iotConfigs.pressure_height_constant_study_end = res.item[0].params.body.pressure_height_constant_study_end
          this.iotConfigs.repeatedly_open_close_time_threshold = res.item[0].params.body.repeatedly_open_close_time_threshold
          this.iotConfigs.repeatedly_open_close_max = res.item[0].params.body.repeatedly_open_close_max
          this.iotConfigs.recall_event_flag = res.item[0].params.body.recall_event_flag
          this.iotConfigs.open_cancle_time_span = res.item[0].params.body.open_cancle_time_span
          this.iotConfigs.close_cancle_time_span = res.item[0].params.body.close_cancle_time_span
          this.iotConfigs.pressure_height_constant_reference_list_max = res.item[0].params.body.pressure_height_constant_reference_list_max
          this.iotConfigs.pressure_height_constant_reference_threshold = res.item[0].params.body.pressure_height_constant_reference_threshold
          this.iotConfigs.double_door_enable = res.item[0].params.body.double_door_enable
          this.iotConfigs.double_door_calculation_cycle = res.item[0].params.body.double_door_calculation_cycle
          this.iotConfigs.double_door_list_sum = res.item[0].params.body.double_door_list_sum
          this.iotConfigs.double_door_list_max = res.item[0].params.body.double_door_list_max
          this.iotConfigs.other_fault_reference_list_max = res.item[0].params.body.other_fault_reference_list_max
          this.iotConfigs.other_fault_threshold = res.item[0].params.body.other_fault_threshold
          this.iotConfigs.other_fault_cancel_threshold = res.item[0].params.body.other_fault_cancel_threshold
          this.iotConfigs.mpu_read_cycle = res.item[0].params.body.mpu_read_cycle
          this.iotConfigs.mpu_acceleration_list_num = res.item[0].params.body.mpu_acceleration_list_num
          this.iotConfigs.mpu_acceleration_ref_list_num = res.item[0].params.body.mpu_acceleration_ref_list_num
          this.iotConfigs.mpu_car_move_num = res.item[0].params.body.mpu_car_move_num
          this.iotConfigs.mpu_car_move_threshold = res.item[0].params.body.mpu_car_move_threshold
          this.iotConfigs.mpu_floor_loss_num = res.item[0].params.body.mpu_floor_loss_num
          this.iotConfigs.mpu_floor_loss_threshold = res.item[0].params.body.mpu_floor_loss_threshold
          this.iotConfigs.mpu_car_move_list_num = res.item[0].params.body.mpu_car_move_list_num
          this.iotConfigs.block_door_time_threshold = res.item[0].params.body.block_door_time_threshold
          this.iotConfigs.block_door_acc_threshold = res.item[0].params.body.block_door_acc_threshold
          this.iotConfigs.other_fault_realtime_list_num = res.item[0].params.body.other_fault_realtime_list_num
          this.iotConfigs.open_run_fault_enable = res.item[0].params.body.open_run_fault_enable
          this.iotConfigs.open_run_recall_time_span = res.item[0].params.body.open_run_recall_time_span
          this.iotConfigs.Xianshi_module_enable = res.item[0].params.body.Xianshi_module_enable
          this.iotConfigs.long_stay_event_enable = res.item[0].params.body.long_stay_event_enable
          this.iotConfigs.safety_loop_alarm_enable = res.item[0].params.body.safety_loop_alarm_enable
          if (this.iotConfigs.safety_loop_alarm_enable == "1") {
            this.checkedFaultKeys.push("safety_loop_alarm_enable")
          }
          this.iotConfigs.open_alarm_enable = res.item[0].params.body.open_alarm_enable
          if (this.iotConfigs.open_alarm_enable == "1") {
            this.checkedFaultKeys.push("open_alarm_enable")
          }
          this.iotConfigs.close_alarm_enable = res.item[0].params.body.close_alarm_enable
          if (this.iotConfigs.close_alarm_enable == "1") {
            this.checkedFaultKeys.push("close_alarm_enable")
          }
          this.iotConfigs.out_alarm_enable = res.item[0].params.body.out_alarm_enable
          if (this.iotConfigs.out_alarm_enable == "1") {
            this.checkedFaultKeys.push("out_alarm_enable")
          }
          this.iotConfigs.accidental_movement_alarm_enable = res.item[0].params.body.accidental_movement_alarm_enable
          if (this.iotConfigs.accidental_movement_alarm_enable == "1") {
            this.checkedFaultKeys.push("accidental_movement_alarm_enable")
          }
          this.iotConfigs.motor_alarm_enable = res.item[0].params.body.motor_alarm_enable
          if (this.iotConfigs.motor_alarm_enable == "1") {
            this.checkedFaultKeys.push("motor_alarm_enable")
          }
          this.iotConfigs.floor_loss_alarm_enable = res.item[0].params.body.floor_loss_alarm_enable
          if (this.iotConfigs.floor_loss_alarm_enable == "1") {
            this.checkedFaultKeys.push("floor_loss_alarm_enable")
          }
          this.iotConfigs.other_alarm_enable = res.item[0].params.body.other_alarm_enable
          if (this.iotConfigs.other_alarm_enable == "1") {
            this.checkedFaultKeys.push("other_alarm_enable")
          }
          this.iotConfigs.trapped_fault_alarm_enable = res.item[0].params.body.trapped_fault_alarm_enable
          if (this.iotConfigs.trapped_fault_alarm_enable == "1") {
            this.checkedFaultKeys.push("trapped_fault_alarm_enable")
          }
          this.iotConfigs.repeatedly_open_close_alarm_enable = res.item[0].params.body.repeatedly_open_close_alarm_enable
          if (this.iotConfigs.repeatedly_open_close_alarm_enable == "1") {
            this.checkedFaultKeys.push("repeatedly_open_close_alarm_enable")
          }
          this.iotConfigs.open_run_alarm_enable = res.item[0].params.body.open_run_alarm_enable
          if (this.iotConfigs.open_run_alarm_enable == "1") {
            this.checkedFaultKeys.push("open_run_alarm_enable")
          }
          this.iotConfigs.long_stay_alarm_enable = res.item[0].params.body.long_stay_alarm_enable
          if (this.iotConfigs.long_stay_alarm_enable == "1") {
            this.checkedEventKeys.push("long_stay_alarm_enable")
          }
          this.iotConfigs.block_door_alarm_enable = res.item[0].params.body.block_door_alarm_enable
          if (this.iotConfigs.block_door_alarm_enable == "1") {
            this.checkedEventKeys.push("block_door_alarm_enable")
          }
          this.iotConfigs.speed_max = res.item[0].params.body.speed_max
          this.iotConfigs.FIRE_ALARM_ENABLE = res.item[0].params.body.FIRE_ALARM_ENABLE
          this.iotConfigs.FIRE_ALARM_UPLOAD_VIDEO_ENABLE = res.item[0].params.body.FIRE_ALARM_UPLOAD_VIDEO_ENABLE
          this.iotConfigs.DOOR_ACCESS_ALARM_ENABLE = res.item[0].params.body.DOOR_ACCESS_ALARM_ENABLE
          this.iotConfigs.DOOR_ACCESS_ALARM_UPLOAD_VIDEO_ENABLE = res.item[0].params.body.DOOR_ACCESS_ALARM_UPLOAD_VIDEO_ENABLE
          this.iotConfigs.LIFT_ALARM_ENABLE = res.item[0].params.body.LIFT_ALARM_ENABLE
          this.iotConfigs.LIFT_ALARM_UPLOAD_VIDEO_ENABLE = res.item[0].params.body.LIFT_ALARM_UPLOAD_VIDEO_ENABLE
          this.iotConfigs.OVERFLOW_ALARM_ENABLE = res.item[0].params.body.OVERFLOW_ALARM_ENABLE
          this.iotConfigs.OVERFLOW_ALARM_UPLOAD_VIDEO_ENABLE = res.item[0].params.body.OVERFLOW_ALARM_UPLOAD_VIDEO_ENABLE
          this.iotConfigs.GAS_ALARM_ENABLE = res.item[0].params.body.GAS_ALARM_ENABLE
          this.iotConfigs.GAS_ALARM_UPLOAD_VIDEO_ENABLE = res.item[0].params.body.GAS_ALARM_UPLOAD_VIDEO_ENABLE
          this.iotConfigs.SERIAL_485_ENABLE = res.item[0].params.body.SERIAL_485_ENABLE
          this.iotConfigs.FIRE_EQUIPMENT_MODEL = res.item[0].params.body.FIRE_EQUIPMENT_MODEL
          this.iotConfigs.FIRE_EQUIPMENT_MANUFACTURER_NAME = res.item[0].params.body.FIRE_EQUIPMENT_MANUFACTURER_NAME
          this.iotConfigs.IO_Calculation_Interval = res.item[0].params.body.IO_Calculation_Interval
          this.iotConfigs.IO_Sampling_Interval = res.item[0].params.body.IO_Sampling_Interval
          this.iotConfigs.IO_Sampling_List_Max = res.item[0].params.body.IO_Sampling_List_Max
          this.iotConfigs.IO_State_Changed_Threshold = res.item[0].params.body.IO_State_Changed_Threshold
          this.iotConfigs.Celsius_Temperature_Max_Threshold = res.item[0].params.body.Celsius_Temperature_Max_Threshold
          this.iotConfigs.Celsius_Temperature_Min_Threshold = res.item[0].params.body.Celsius_Temperature_Min_Threshold
          this.iotConfigs.Relative_Humidity_Max_Threshold = res.item[0].params.body.Relative_Humidity_Max_Threshold
          this.iotConfigs.Relative_Humidity_Min_Threshold = res.item[0].params.body.Relative_Humidity_Min_Threshold
          this.iotConfigs.Celsius_Temperature_State_List_Max = res.item[0].params.body.Celsius_Temperature_State_List_Max
          this.iotConfigs.Celsius_Temperature_State_List_Threshold = res.item[0].params.body.Celsius_Temperature_State_List_Threshold
          this.iotConfigs.Relative_Humidity_State_List_Max = res.item[0].params.body.Relative_Humidity_State_List_Max
          this.iotConfigs.Relative_Humidity_State_List_Threshold = res.item[0].params.body.Relative_Humidity_State_List_Threshold
          this.iotConfigs.Person_Detect_List_Max = res.item[0].params.body.Person_Detect_List_Max
          this.iotConfigs.Person_Detect_List_Threshold = res.item[0].params.body.Person_Detect_List_Threshold
          this.iotConfigs.Celsius_Temperature_Too_High_Event_Enable = res.item[0].params.body.Celsius_Temperature_Too_High_Event_Enable
          this.iotConfigs.Celsius_Temperature_Too_Low_Event_Enable = res.item[0].params.body.Celsius_Temperature_Too_Low_Event_Enable
          this.iotConfigs.Relative_Humidity_Too_High_Event_Enable = res.item[0].params.body.Relative_Humidity_Too_High_Event_Enable
          this.iotConfigs.Relative_Humidity_Too_Low_Event_Enable = res.item[0].params.body.Relative_Humidity_Too_Low_Event_Enable
          this.iotConfigs.WaterPumpHouse_Person_Event_Enable = res.item[0].params.body.WaterPumpHouse_Person_Event_Enable
          this.iotConfigs.EngineRoom_Door_Open_Event_Enable = res.item[0].params.body.EngineRoom_Door_Open_Event_Enable
          this.iotConfigs.EngineRoom_FeedFan_Start_Event_Enable = res.item[0].params.body.EngineRoom_FeedFan_Start_Event_Enable
          this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Enable = res.item[0].params.body.EngineRoom_ExhaustFan_Start_Event_Enable
          this.iotConfigs.SubmersiblePump_1_Start_Event_Enable = res.item[0].params.body.SubmersiblePump_1_Start_Event_Enable
          this.iotConfigs.SubmersiblePump_2_Start_Event_Enable = res.item[0].params.body.SubmersiblePump_2_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_1_FirstMainPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_1_SecondMainPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_2_FirstMainPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_2_SecondMainPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable
          this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable = res.item[0].params.body.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable
          this.iotConfigs.Radiator_Supply_Start_Event_Enable = res.item[0].params.body.Radiator_Supply_Start_Event_Enable
          this.iotConfigs.Radiator_Overflow_Start_Event_Enable = res.item[0].params.body.Radiator_Overflow_Start_Event_Enable
          this.iotConfigs.Radiator_Lack_Start_Event_Enable = res.item[0].params.body.Radiator_Lack_Start_Event_Enable
          this.iotConfigs.EngineRoom_OnFire_Alarm_Enable = res.item[0].params.body.EngineRoom_OnFire_Alarm_Enable
          this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Enable = res.item[0].params.body.SubmersiblePump_Power_Cut_Alarm_Enable
          this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Enable = res.item[0].params.body.SubmersiblePump_1_Fault_Alarm_Enable
          this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Enable = res.item[0].params.body.SubmersiblePump_2_Fault_Alarm_Enable
          this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Enable = res.item[0].params.body.WaterPumpGroup_1_Fault_Alarm_Enable
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable
          this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Enable = res.item[0].params.body.WaterPumpGroup_2_Fault_Alarm_Enable
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable
          this.iotConfigs.Radiator_Cap_Open_Alarm_Enable = res.item[0].params.body.Radiator_Cap_Open_Alarm_Enable
          this.iotConfigs.Radiator_Supply_HighWaterLevel_Alarm_Enable = res.item[0].params.body.Radiator_Supply_HighWaterLevel_Alarm_Enable
          this.iotConfigs.Radiator_Supply_High_LackWaterLevel_Alarm_Enable = res.item[0].params.body.Radiator_Supply_High_LackWaterLevel_Alarm_Enable
          this.iotConfigs.Radiator_Supply_LackWaterLevel_Alarm_Enable = res.item[0].params.body.Radiator_Supply_LackWaterLevel_Alarm_Enable
          this.iotConfigs.Radiator_HighWaterLevel_Alarm_Enable = res.item[0].params.body.Radiator_HighWaterLevel_Alarm_Enable
          this.iotConfigs.Radiator_High_LackWaterLevel_Alarm_Enable = res.item[0].params.body.Radiator_High_LackWaterLevel_Alarm_Enable
          this.iotConfigs.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable = res.item[0].params.body.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    setParamsConfig() {
      let paramsBody = {}
      if (this.IotFlag.iotFlag == 0) {
        paramsBody = {
          welcome_floor_num: this.iotConfigs.welcome_floor_num,
          master: this.iotConfigs.master,
          welcome_audio_enable: this.iotConfigs.welcome_audio_enable,
          double_door_enable: this.iotConfigs.double_door_enable,
          STANDBY_FLOOR: this.iotConfigs.STANDBY_FLOOR,
          floors: this.iotConfigs.floors,
          floor_height: this.iotConfigs.floor_height,
          calibrate_floor_num: this.iotConfigs.calibrate_floor_num,
          hei_threshold_base: this.iotConfigs.hei_threshold_base,
          out_cancle_time_span: this.iotConfigs.out_cancle_time_span,
          FAULE_OPEN_DOOR_TIME: this.iotConfigs.FAULE_OPEN_DOOR_TIME,
          FAULE_CLOSE_DOOR_TIME: this.iotConfigs.FAULE_CLOSE_DOOR_TIME,
          out_detect_enable_flag: this.iotConfigs.out_detect_enable_flag,
          all_fault_enable: this.iotConfigs.all_fault_enable,
          TRAPPED_PEOPLE_TIME: this.iotConfigs.TRAPPED_PEOPLE_TIME,
          passive_alarm_enable: this.iotConfigs.passive_alarm_enable,
          alarm_active_enable: this.iotConfigs.alarm_active_enable,
          Manufacture_mode: this.iotConfigs.Manufacture_mode,
          trapped_enable: this.iotConfigs.trapped_enable,
          safety_loop_enable: this.iotConfigs.safety_loop_enable,
          close_enable: this.iotConfigs.close_enable,
          open_enable: this.iotConfigs.open_enable,
          accidental_movement_enable: this.iotConfigs.accidental_movement_enable,
          motor_fault_enable: this.iotConfigs.motor_fault_enable,
          floor_loss_enable: this.iotConfigs.floor_loss_enable,
          trapped_alarm_enable: this.iotConfigs.trapped_alarm_enable,
          other_fault_enable: this.iotConfigs.other_fault_enable,
          recall_event_flag: this.iotConfigs.recall_event_flag,
          open_run_fault_enable: this.iotConfigs.open_run_fault_enable,
          Xianshi_device_sn_num: this.iotConfigs.Xianshi_device_sn_num,
          open_run_recall_time_span: this.iotConfigs.open_run_recall_time_span,
          Xianshi_module_enable: this.iotConfigs.Xianshi_module_enable,
          FIRE_ALARM_ENABLE: this.iotConfigs.FIRE_ALARM_ENABLE,
          FIRE_ALARM_UPLOAD_VIDEO_ENABLE:this.iotConfigs.FIRE_ALARM_UPLOAD_VIDEO_ENABLE,
          DOOR_ACCESS_ALARM_ENABLE: this.iotConfigs.DOOR_ACCESS_ALARM_ENABLE,
          DOOR_ACCESS_ALARM_UPLOAD_VIDEO_ENABLE:this.iotConfigs.DOOR_ACCESS_ALARM_UPLOAD_VIDEO_ENABLE,
          LIFT_ALARM_ENABLE: this.iotConfigs.LIFT_ALARM_ENABLE,
          LIFT_ALARM_UPLOAD_VIDEO_ENABLE:this.iotConfigs.LIFT_ALARM_UPLOAD_VIDEO_ENABLE,
          OVERFLOW_ALARM_ENABLE: this.iotConfigs.OVERFLOW_ALARM_ENABLE,
          OVERFLOW_ALARM_UPLOAD_VIDEO_ENABLE:this.iotConfigs.OVERFLOW_ALARM_UPLOAD_VIDEO_ENABLE,
          GAS_ALARM_ENABLE: this.iotConfigs.GAS_ALARM_ENABLE,
          GAS_ALARM_UPLOAD_VIDEO_ENABLE:this.iotConfigs.GAS_ALARM_UPLOAD_VIDEO_ENABLE,
          SERIAL_485_ENABLE:this.iotConfigs.SERIAL_485_ENABLE,
          FIRE_EQUIPMENT_MODEL:this.iotConfigs.FIRE_EQUIPMENT_MODEL,
          FIRE_EQUIPMENT_MANUFACTURER_NAME:this.iotConfigs.FIRE_EQUIPMENT_MANUFACTURER_NAME,
          IO_Calculation_Interval:this.iotConfigs.IO_Calculation_Interval,
          IO_Sampling_Interval:this.iotConfigs.IO_Sampling_Interval,
          IO_Sampling_List_Max:this.iotConfigs.IO_Sampling_List_Max,
          IO_State_Changed_Threshold:this.iotConfigs.IO_State_Changed_Threshold,
          Celsius_Temperature_Max_Threshold:this.iotConfigs.Celsius_Temperature_Max_Threshold,
          Celsius_Temperature_Min_Threshold:this.iotConfigs.Celsius_Temperature_Min_Threshold,
          Relative_Humidity_Max_Threshold:this.iotConfigs.Relative_Humidity_Max_Threshold,
          Relative_Humidity_Min_Threshold:this.iotConfigs.Relative_Humidity_Min_Threshold,
          Celsius_Temperature_State_List_Max:this.iotConfigs.Celsius_Temperature_State_List_Max,
          Celsius_Temperature_State_List_Threshold:this.iotConfigs.Celsius_Temperature_State_List_Threshold,
          Relative_Humidity_State_List_Max:this.iotConfigs.Relative_Humidity_State_List_Max,
          Relative_Humidity_State_List_Threshold:this.iotConfigs.Relative_Humidity_State_List_Threshold,
          Person_Detect_List_Max:this.iotConfigs.Person_Detect_List_Max,
          Person_Detect_List_Threshold:this.iotConfigs.Person_Detect_List_Threshold,
          Celsius_Temperature_Too_High_Event_Enable:this.iotConfigs.Celsius_Temperature_Too_High_Event_Enable,
          Celsius_Temperature_Too_Low_Event_Enable:this.iotConfigs.Celsius_Temperature_Too_Low_Event_Enable,
          Relative_Humidity_Too_High_Event_Enable:this.iotConfigs.Relative_Humidity_Too_High_Event_Enable,
          Relative_Humidity_Too_Low_Event_Enable:this.iotConfigs.Relative_Humidity_Too_Low_Event_Enable,
          WaterPumpHouse_Person_Event_Enable:this.iotConfigs.WaterPumpHouse_Person_Event_Enable,
          EngineRoom_Door_Open_Event_Enable:this.iotConfigs.EngineRoom_Door_Open_Event_Enable,
          EngineRoom_FeedFan_Start_Event_Enable:this.iotConfigs.EngineRoom_FeedFan_Start_Event_Enable,
          EngineRoom_ExhaustFan_Start_Event_Enable:this.iotConfigs.EngineRoom_ExhaustFan_Start_Event_Enable,
          SubmersiblePump_1_Start_Event_Enable:this.iotConfigs.SubmersiblePump_1_Start_Event_Enable,
          SubmersiblePump_2_Start_Event_Enable:this.iotConfigs.SubmersiblePump_2_Start_Event_Enable,
          WaterPumpGroup_1_FirstMainPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Event_Enable,
          WaterPumpGroup_1_SecondMainPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Event_Enable,
          WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Event_Enable,
          WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Event_Enable,
          WaterPumpGroup_2_FirstMainPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Event_Enable,
          WaterPumpGroup_2_SecondMainPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Event_Enable,
          WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Event_Enable,
          WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable:this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Event_Enable,
          Radiator_Supply_Start_Event_Enable:this.iotConfigs.Radiator_Supply_Start_Event_Enable,
          Radiator_Overflow_Start_Event_Enable:this.iotConfigs.Radiator_Overflow_Start_Event_Enable,
          Radiator_Lack_Start_Event_Enable:this.iotConfigs.Radiator_Lack_Start_Event_Enable,
          EngineRoom_OnFire_Alarm_Enable:this.iotConfigs.EngineRoom_OnFire_Alarm_Enable,
          SubmersiblePump_Power_Cut_Alarm_Enable:this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Enable,
          SubmersiblePump_1_Fault_Alarm_Enable:this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Enable,
          SubmersiblePump_2_Fault_Alarm_Enable:this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Enable,
          WaterPumpGroup_1_Fault_Alarm_Enable:this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Enable,
          WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable:this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Enable,
          WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable:this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Enable,
          WaterPumpGroup_2_Fault_Alarm_Enable:this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Enable,
          WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable:this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Enable,
          WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable:this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Enable,
          Radiator_Cap_Open_Alarm_Enable:this.iotConfigs.Radiator_Cap_Open_Alarm_Enable,
          Radiator_Supply_HighWaterLevel_Alarm_Enable:this.iotConfigs.Radiator_Supply_HighWaterLevel_Alarm_Enable,
          Radiator_Supply_High_LackWaterLevel_Alarm_Enable:this.iotConfigs.Radiator_Supply_High_LackWaterLevel_Alarm_Enable,
          Radiator_Supply_LackWaterLevel_Alarm_Enable:this.iotConfigs.Radiator_Supply_LackWaterLevel_Alarm_Enable,
          Radiator_HighWaterLevel_Alarm_Enable:this.iotConfigs.Radiator_HighWaterLevel_Alarm_Enable,
          Radiator_High_LackWaterLevel_Alarm_Enable:this.iotConfigs.Radiator_High_LackWaterLevel_Alarm_Enable,
          Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable:this.iotConfigs.Radiator_SupplyFault_WaterSourceOff_LackWaterLevel_Alarm_Enable,
        }
        this.paramsdata = "播放欢迎语音楼层" + "," + this.iotConfigs.heartbeat_cycle + ","
            + "播放语音的音量" + "," + this.iotConfigs.T_Pi_ref + ","
            + "欢迎语音功能使能" + "," + this.iotConfigs.AI_REALSTATE_RECEIVE_TIME + ","
            + "电梯是否是双开门" + "," + this.iotConfigs.cumulative_person_const + ","
            + "待机楼层" + "," + this.iotConfigs.after_up_no_out_fault_time + ","
            + "电梯楼层" + "," + this.iotConfigs.PRESSURE_PRECISION + ","
            + "楼层每层高度序列" + "," + this.iotConfigs.BASE_PTH + ","
            + "当前标定楼层" + "," + this.iotConfigs.PRESSUER_LIST_MAX + ","
            + "当前楼层测量高度和实际高度差值的阈值" + "," + this.iotConfigs.floor_sensor_mode + ","
            + "开锁区域外停止故障撤销时间" + "," + this.iotConfigs.pressure_height_constant + ","
            + "开门最大超时时间" + "," + this.iotConfigs.pressure_height_constant_threshold + ","
            + "关门最大超时时间" + "," + this.iotConfigs.pressure_height_constant_list_max_len + ","
            + "长时间驻留最大超时时间" + "," + this.iotConfigs.pressure_height_constant_reference_list_max + ","
            + "电梯物联网故障使能标志" + "," + this.iotConfigs.pressure_height_constant_reference_threshold + ","
            + "轿厢在开锁区域外停止故障使能标志" + "," + this.iotConfigs.trapped_person_appear_probability + ","
            + "无源报警功能使能" + "," + this.iotConfigs.before_long_stay + ","
            + "有源报警使能标志" + "," + this.iotConfigs.after_long_stay + ","
            + "生产模式使能" + "," + this.iotConfigs.step_threshold + ","
            + "安全回路断路故障使能" + "," + this.iotConfigs.hei_threshold_hold_max_distance + ","
            + "关门故障使能" + "," + this.iotConfigs.hei_threshold_max + ","
            + "开门故障使能" + "," + this.iotConfigs.out_cancel_car_move_continuation_times + ","
            + "困人故障使能" + "," + this.iotConfigs.repeatedly_open_close_time_threshold + ","
            + "轿厢意外移动故障使能" + "," + this.iotConfigs.repeatedly_open_close_max + ","
            + "电动机运转时间限制器动作使能" + "," + this.iotConfigs.open_cancle_time_span + ","
            + "楼层位置丢失故障使能" + "," + this.iotConfigs.close_cancle_time_span + ","
            + "其它阻止电梯再启动的故障使能" + "," + this.iotConfigs.block_door_time_threshold + ","
            + "困人绑定报警使能标志" + "," + this.iotConfigs.block_door_acc_threshold + ","
            + "故障撤销功能使能标志" + "," + this.iotConfigs.motor_threshold + ","
            + "开门运行故障使能标志" + "," + this.iotConfigs.open_run_fault_enable + ","
            + "开门运行故障撤销时间" + "," + this.iotConfigs.open_run_recall_time_span + ","
            + "仙视屏幕使能" + "," + this.iotConfigs.Xianshi_module_enable + ","
      } else {
        paramsBody = {
          floor_auto_check_lowest_num: this.iotConfigs.floor_auto_check_lowest_num,
          floor_auto_check_enable: this.iotConfigs.floor_auto_check_enable,
          PRESSURE_PRECISION: this.iotConfigs.PRESSURE_PRECISION,
          BASE_PTH: this.iotConfigs.BASE_PTH,
          T_Pi_ref: this.iotConfigs.T_Pi_ref,
          PRESSUER_LIST_MAX: this.iotConfigs.PRESSUER_LIST_MAX,
          // all_fault_enable: this.iotConfigs.all_fault_enable,
          out_detect_enable_flag: this.iotConfigs.out_detect_enable_flag,
          AI_REALSTATE_RECEIVE_TIME: this.iotConfigs.AI_REALSTATE_RECEIVE_TIME,
          detect_other_fault_continuation_times: this.iotConfigs.detect_other_fault_continuation_times,
          SERIAL_485_DIRECTION_DELAY_TIME: this.iotConfigs.SERIAL_485_DIRECTION_DELAY_TIME,
          cumulative_person_const: this.iotConfigs.cumulative_person_const,
          trapped_person_appear_probability: this.iotConfigs.trapped_person_appear_probability,
          out_cancel_car_move_continuation_times: this.iotConfigs.out_cancel_car_move_continuation_times,
          car_state_continuation_times: this.iotConfigs.car_state_continuation_times,
          after_up_no_out_fault_time: this.iotConfigs.after_up_no_out_fault_time,
          heartbeat_cycle: this.iotConfigs.heartbeat_cycle,
          motor_threshold: this.iotConfigs.motor_threshold,
          motor_continuation_times: this.iotConfigs.motor_continuation_times,
          motor_time_threshold: this.iotConfigs.motor_time_threshold,
          motor_cancel_time: this.iotConfigs.motor_cancel_time,
          loss_floor_pressure_lowest_threshold: this.iotConfigs.loss_floor_pressure_lowest_threshold,
          loss_floor_pressure_highest_threshold: this.iotConfigs.loss_floor_pressure_highest_threshold,
          loss_floor_continuation_times: this.iotConfigs.loss_floor_continuation_times,
          loss_fault_cancel_threshold: this.iotConfigs.loss_fault_cancel_threshold,
          loss_fault_cancel_continuation_times: this.iotConfigs.loss_fault_cancel_continuation_times,
          alarm_active_voltage_enable: this.iotConfigs.alarm_active_voltage_enable,
          alarm_voltage_enable: this.iotConfigs.alarm_voltage_enable,
          car_move_state_continuation_times: this.iotConfigs.car_move_state_continuation_times,
          floor_auto_check_run_times: this.iotConfigs.floor_auto_check_run_times,
          floor_auto_check_time: this.iotConfigs.floor_auto_check_time,
          gpio_active_time: this.iotConfigs.gpio_active_time,
          pressure_height_constant: this.iotConfigs.pressure_height_constant,
          pressure_height_constant_threshold: this.iotConfigs.pressure_height_constant_threshold,
          pressure_height_constant_list_max_len: this.iotConfigs.pressure_height_constant_list_max_len,
          before_long_stay: this.iotConfigs.before_long_stay,
          after_long_stay: this.iotConfigs.after_long_stay,
          step_threshold: this.iotConfigs.step_threshold,
          hei_threshold_hold_max_distance: this.iotConfigs.hei_threshold_hold_max_distance,
          hei_threshold_max: this.iotConfigs.hei_threshold_max,
          floor_sensor_mode: this.iotConfigs.floor_sensor_mode,
          pressure_height_constant_study_begin: this.iotConfigs.pressure_height_constant_study_begin,
          pressure_height_constant_study_end: this.iotConfigs.pressure_height_constant_study_end,
          repeatedly_open_close_time_threshold: this.iotConfigs.repeatedly_open_close_time_threshold,
          repeatedly_open_close_max: this.iotConfigs.repeatedly_open_close_max,
          open_cancle_time_span: this.iotConfigs.open_cancle_time_span,
          close_cancle_time_span: this.iotConfigs.close_cancle_time_span,
          pressure_height_constant_reference_list_max: this.iotConfigs.pressure_height_constant_reference_list_max,
          pressure_height_constant_reference_threshold: this.iotConfigs.pressure_height_constant_reference_threshold,
          double_door_calculation_cycle: this.iotConfigs.double_door_calculation_cycle,
          double_door_list_sum: this.iotConfigs.double_door_list_sum,
          double_door_list_max: this.iotConfigs.double_door_list_max,
          other_fault_reference_list_max: this.iotConfigs.other_fault_reference_list_max,
          other_fault_threshold: this.iotConfigs.other_fault_threshold,
          other_fault_cancel_threshold: this.iotConfigs.other_fault_cancel_threshold,
          mpu_read_cycle: this.iotConfigs.mpu_read_cycle,
          mpu_acceleration_list_num: this.iotConfigs.mpu_acceleration_list_num,
          mpu_acceleration_ref_list_num: this.iotConfigs.mpu_acceleration_ref_list_num,
          mpu_car_move_num: this.iotConfigs.mpu_car_move_num,
          mpu_car_move_threshold: this.iotConfigs.mpu_car_move_threshold,
          mpu_floor_loss_num: this.iotConfigs.mpu_floor_loss_num,
          mpu_floor_loss_threshold: this.iotConfigs.mpu_floor_loss_threshold,
          mpu_car_move_list_num: this.iotConfigs.mpu_car_move_list_num,
          block_door_time_threshold: this.iotConfigs.block_door_time_threshold,
          block_door_acc_threshold: this.iotConfigs.block_door_acc_threshold,
          other_fault_realtime_list_num: this.iotConfigs.other_fault_realtime_list_num,
          safety_loop_alarm_enable: this.iotConfigs.safety_loop_alarm_enable,
          open_alarm_enable: this.iotConfigs.open_alarm_enable,
          close_alarm_enable: this.iotConfigs.close_alarm_enable,
          out_alarm_enable: this.iotConfigs.out_alarm_enable,
          accidental_movement_alarm_enable: this.iotConfigs.accidental_movement_alarm_enable,
          motor_alarm_enable: this.iotConfigs.motor_alarm_enable,
          floor_loss_alarm_enable: this.iotConfigs.floor_loss_alarm_enable,
          other_alarm_enable: this.iotConfigs.other_alarm_enable,
          trapped_fault_alarm_enable: this.iotConfigs.trapped_fault_alarm_enable,
          repeatedly_open_close_alarm_enable: this.iotConfigs.repeatedly_open_close_alarm_enable,
          open_run_alarm_enable: this.iotConfigs.open_run_alarm_enable,
          long_stay_alarm_enable: this.iotConfigs.long_stay_alarm_enable,
          block_door_alarm_enable: this.iotConfigs.block_door_alarm_enable,
          speed_max: this.iotConfigs.speed_max,
        }
        this.paramsdata = "电梯物联网心跳周期" + "," + this.iotConfigs.heartbeat_cycle + ","
            + "楼层气压参考值更新时间" + "," + this.iotConfigs.T_Pi_ref + ","
            + "AI模块实时状态失连最大超时时间" + "," + this.iotConfigs.AI_REALSTATE_RECEIVE_TIME + ","
            + "人流量统计常数" + "," + this.iotConfigs.cumulative_person_const + ","
            + "重启后不上报门区外停梯故障时间长度" + "," + this.iotConfigs.after_up_no_out_fault_time + ","
            + "气压计测量精度" + "," + this.iotConfigs.PRESSURE_PRECISION + ","
            + "气压计测量精度的倍数" + "," + this.iotConfigs.BASE_PTH + ","
            + "气压计平滑队列最大长度" + "," + this.iotConfigs.PRESSUER_LIST_MAX + ","
            + "楼层判断方式" + "," + this.iotConfigs.floor_sensor_mode + ","
            + "气压和高度换算常数" + "," + this.iotConfigs.pressure_height_constant + ","
            + "气压和高度换算常数阈值" + "," + this.iotConfigs.pressure_height_constant_threshold + ","
            + "气压和高度换算常数的队列个数" + "," + this.iotConfigs.pressure_height_constant_list_max_len + ","
            + "气压和高度换算常数参考值最大队列长度" + "," + this.iotConfigs.pressure_height_constant_reference_list_max + ","
            + "气压和高度换算常数与参考值的比值上限" + "," + this.iotConfigs.pressure_height_constant_reference_threshold + ","
            + "困人过程中有人的概率" + "," + this.iotConfigs.trapped_person_appear_probability + ","
            + "发生困人长时间驻留前的时间" + "," + this.iotConfigs.before_long_stay + ","
            + "发生困人长时间驻留后的时间" + "," + this.iotConfigs.after_long_stay + ","
            + "每米增加的误差" + "," + this.iotConfigs.step_threshold + ","
            + "高度差值阈值不变的最大距离" + "," + this.iotConfigs.hei_threshold_hold_max_distance + ","
            + "高度差值阈值上限" + "," + this.iotConfigs.hei_threshold_max + ","
            + "轿厢外停止故障取消电梯连续运行状态数" + "," + this.iotConfigs.out_cancel_car_move_continuation_times + ","
            + "反复开关门时间阈值" + "," + this.iotConfigs.repeatedly_open_close_time_threshold + ","
            + "反复开关门次数" + "," + this.iotConfigs.repeatedly_open_close_max + ","
            + "开门故障撤销时间" + "," + this.iotConfigs.open_cancle_time_span + ","
            + "关门故障撤销时间" + "," + this.iotConfigs.close_cancle_time_span + ","
            + "挡门时间阈值" + "," + this.iotConfigs.block_door_time_threshold + ","
            + "挡门事件阈值" + "," + this.iotConfigs.block_door_acc_threshold + ","
            + "电动机限制器运作气压阈值" + "," + this.iotConfigs.motor_threshold + ","
            + "电动机运作检测连续个数" + "," + this.iotConfigs.motor_continuation_times + ","
            + "电动机限制器运作时间阈值" + "," + this.iotConfigs.motor_time_threshold + ","
            + "电动机运作故障取消时间" + "," + this.iotConfigs.motor_cancel_time + ","
            + "楼层丢失气压最低阈值" + "," + this.iotConfigs.loss_floor_pressure_lowest_threshold + ","
            + "楼层丢失气压最高阈值" + "," + this.iotConfigs.loss_floor_pressure_highest_threshold + ","
            + "楼层丢失检测连续个数" + "," + this.iotConfigs.loss_floor_continuation_times + ","
            + "楼层丢失故障取消阈值" + "," + this.iotConfigs.loss_fault_cancel_threshold + ","
            + "楼层丢失故障取消检测连续个数" + "," + this.iotConfigs.loss_fault_cancel_continuation_times + ","
            + "楼层丢失参考陀螺仪个数" + "," + this.iotConfigs.mpu_floor_loss_num + ","
            + "楼层丢失参考陀螺仪阈值" + "," + this.iotConfigs.mpu_floor_loss_threshold + ","
            + "楼层自动纠正，电梯运行最大次数" + "," + this.iotConfigs.floor_auto_check_run_times + ","
            + "楼层自动纠正时间间隔" + "," + this.iotConfigs.floor_auto_check_time + ","
            + "其他故障参考队列最大长度" + "," + this.iotConfigs.other_fault_reference_list_max + ","
            + "其他故障超速阈值" + "," + this.iotConfigs.other_fault_threshold + ","
            + "其他故障解除阈值" + "," + this.iotConfigs.other_fault_cancel_threshold + ","
            + "其他故障实时队列长度" + "," + this.iotConfigs.other_fault_realtime_list_num + ","
            + "电梯最大初始速度" + "," + this.iotConfigs.speed_max + ","
            + "陀螺仪读取周期" + "," + this.iotConfigs.mpu_read_cycle + ","
            + "陀螺仪重力加速度队列长度" + "," + this.iotConfigs.mpu_acceleration_list_num + ","
            + "陀螺仪重力加速度参考队列长度" + "," + this.iotConfigs.mpu_acceleration_ref_list_num + ","
            + "干簧管采集信号周期" + "," + this.iotConfigs.double_door_calculation_cycle + ","
            + "干簧管开关门到位队列长度" + "," + this.iotConfigs.double_door_list_sum + ","
            + "干簧管开关门判断阈值" + "," + this.iotConfigs.double_door_list_max + ","
            + "有源报警有效电平配置" + "," + this.iotConfigs.alarm_active_voltage_enable + ","
            + "无源源报警有效电平配置" + "," + this.iotConfigs.alarm_voltage_enable + ","
            + "gpio触发时长" + "," + this.iotConfigs.gpio_active_time + ","
            + "判断轿厢停止状态对列长度" + "," + this.iotConfigs.car_state_continuation_times + ","
            + "判断轿厢运行状态对列长度" + "," + this.iotConfigs.car_move_state_continuation_times + ","
            + "电梯运动参考陀螺仪个数" + "," + this.iotConfigs.mpu_car_move_num + ","
            + "电梯运动参考陀螺仪阈值" + "," + this.iotConfigs.mpu_car_move_threshold + ","
            + "电梯运动参考陀螺仪队列个数" + "," + this.iotConfigs.mpu_car_move_list_num + ","
            + "意外移动故障连续检测的次数" + "," + this.iotConfigs.detect_other_fault_continuation_times + ","
            + "串口切换方向延迟时间" + "," + this.iotConfigs.SERIAL_485_DIRECTION_DELAY_TIME + ","
            + "楼层自动纠正，停靠最多楼层" + "," + this.iotConfigs.floor_auto_check_lowest_num + ","
            + "楼层自动纠正功能使能标志" + "," + this.iotConfigs.floor_auto_check_enable + ","
            + "安全回路故障联动终端通话使能" + "," + this.iotConfigs.safety_loop_alarm_enable + ","
            + "开门故障联动终端通话使能" + "," + this.iotConfigs.open_alarm_enable + ","
            + "关门故障联动终端通话使能" + "," + this.iotConfigs.close_alarm_enable + ","
            + "开锁区域外故障联动终端通话使能" + "," + this.iotConfigs.out_alarm_enable + ","
            + "意外移动故障联动终端通话使能" + "," + this.iotConfigs.accidental_movement_alarm_enable + ","
            + "电动机运转时间限制器动作故障联动终端通话使能" + "," + this.iotConfigs.motor_alarm_enable + ","
            + "楼层位置丢失故障联动终端通话使能" + "," + this.iotConfigs.floor_loss_alarm_enable + ","
            + "其他阻止电梯再启动故障联动终端通话使能" + "," + this.iotConfigs.other_alarm_enable + ","
            + "困人故障联动终端通话使能" + "," + this.iotConfigs.trapped_fault_alarm_enable + ","
            + "反复开关门故障联动终端通话使能" + "," + this.iotConfigs.repeatedly_open_close_alarm_enable + ","
            + "开门运行故障联动终端通话使能" + "," + this.iotConfigs.open_run_alarm_enable + ","
            + "轿厢内有人长时间驻留联动终端通话使能" + "," + this.iotConfigs.long_stay_alarm_enable + ","
            + "挡门事件联动终端通话使能" + "," + this.iotConfigs.block_door_alarm_enable + ","
      }
      let paramsdate = moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username: this.username,
        paramsobj: this.devicename,
        paramsdate: paramsdate,
        devicenum: this.devicenum,
        devicegroupid: "00",
        orderresult: '失败',
        paramstype: '0',
        parentid: "",
        paramsdata: this.paramsdata,
      }
      addParamsBatchLog(param)
      let params = {
        devicenum: this.devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'PUT',
              url: '/iot',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.$message.success('操作成功');
          let param = {
            username: this.username,
            paramsobj: this.devicename,
            paramsdate: paramsdate,
            devicenum: this.devicenum,
            devicegroupid: "00",
            orderresult: '成功',
            paramstype: '0',
            parentid: "",
            paramsdata: this.paramsdata,
          }
          modifyParamsBatchLog(param)
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>