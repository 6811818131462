<template>
  <div class="params">
    <div class="table-btns">
      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 6.1%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>
      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">
        {{status ?'下发当前参数':'获取最新参数' }}</a-button>
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:14}" :wrapper-col="{span:10}">
      <a-form-model-item label="OSD通道名称" prop="sChannelName">
        <a-input v-model="osdConfigs.channelNameOverlay.sChannelName" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="左右偏移量" prop="iPositionX">
        <a-input v-model="osdConfigs.channelNameOverlay.iPositionX" placeholder="数值变大，向右偏移"></a-input>
      </a-form-model-item>
      <a-form-model-item label="上下偏移量" prop="iPositionY">
        <a-input v-model="osdConfigs.channelNameOverlay.iPositionY" placeholder="数值变大，向下偏移"></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  addParamsBatchLog,
  getDeviceAudioparamValueList, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId, modifyParamsBatchLog, setDeviceAudioparamValueList,
} from "A/ai";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "osd",
  data() {
    return {
      osdConfigs:{
        channelNameOverlay: {
          sChannelName: '',
          iPositionX: null,
          iPositionY: null,
          iChannelNameOverlayEnabled: null
        },
        dateTimeOverlay: {
          sDateStyle: "",
          iPositionX: null,
          iPositionY: null,
          sTimeStyle: "",
          iDisplayWeekEnabled: null,
          iDateTimeOverlayEnabled: null
        },
        normalizedScreenSize: {
          iNormalizedScreenWidth: null,
          iNormalizedScreenHeight: null
        },
        attribute: {
          sOSDAttribute: "",
          sOSDFrontColorMode: "",
          iBoundary: null,
          sOSDFrontColor: "",
          sAlignment: "",
          sOSDFontSize: ""
        },
        characterOverlay: [
          {
            iTextOverlayEnabled: null,
            iPositionX: null,
            iPositionY: null,
            sDisplayText: "",
            id: null,
            sIsPersistentText: ""
          },
          {
            iTextOverlayEnabled: null,
            iPositionX: null,
            iPositionY: null,
            sDisplayText: "",
            id: null,
            sIsPersistentText: ""
          },
          {
            iTextOverlayEnabled: null,
            iPositionX: null,
            iPositionY: null,
            sDisplayText: "",
            id: null,
            sIsPersistentText: ""
          },
          {
            iTextOverlayEnabled: null,
            iPositionX: null,
            iPositionY: null,
            sDisplayText: "",
            id: null,
            sIsPersistentText: ""
          },
          {
            iTextOverlayEnabled: null,
            iPositionX: null,
            iPositionY: null,
            sDisplayText: "",
            id: null,
            sIsPersistentText: ""
          },
          {
            iTextOverlayEnabled: null,
            iPositionX: null,
            iPositionY: null,
            sDisplayText: "",
            id: null,
            sIsPersistentText: ""
          },
          {
            iTextOverlayEnabled: null,
            iPositionX: null,
            iPositionY: null,
            sDisplayText: "",
            id: null,
            sIsPersistentText: ""
          },
          {
            iTextOverlayEnabled: null,
            iPositionX: null,
            iPositionY: null,
            sDisplayText: "",
            id: null,
            sIsPersistentText: ""
          }
        ]
      },
      methodList: [],
      devicenum:'',
      devicename:'',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
      this.getDevicenum();
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              params: {
                method: 'GET',
                url: '/osd/overlays',
              }
            }
          ]
        };
        getDeviceAudioparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.osdConfigs=res.item[0].params.body
            this.osdConfigs.channelNameOverlay.sChannelName=res.item[0].params.body.channelNameOverlay.sChannelName
          }
        }).catch(err => {
          this.hideLoading();
        })
    },
    setParamsConfig(){
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              params: {
                method: 'PUT',
                url:'/osd/overlays',
                body: this.osdConfigs,
              }
            }
          ]
        };
        this.paramsdata="OSD通道名称"+","+this.osdConfigs.channelNameOverlay.sChannelName+","
            +"左右偏移量"+","+this.osdConfigs.channelNameOverlay.iPositionX+","
            +"上下偏移量"+","+this.osdConfigs.channelNameOverlay.iPositionY+","
      let paramsdate=moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username:this.username,
        paramsobj:this.devicename,
        paramsdate:paramsdate,
        devicenum:this.devicenum,
        devicegroupid:"00",
        orderresult:'失败',
        paramstype:'0',
        parentid:"",
        paramsdata:this.paramsdata,
      }
      addParamsBatchLog(param)
        this.showLoading();
        setDeviceAudioparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0' ) {
            this.$message.success('操作成功');
            let param = {
              username:this.username,
              paramsobj:this.devicename,
              paramsdate:paramsdate,
              devicenum:this.devicenum,
              devicegroupid:"00",
              orderresult:'成功',
              paramstype:'0',
              parentid:"",
              paramsdata:this.paramsdata,
            }
            modifyParamsBatchLog(param)
          }
        }).catch(err => {
          this.hideLoading();
          this.$message.error('操作失败');
        })
      // }
    },
    getDevicenum(){
      getDeviceinfoListByCondition({devicenum:this.devicenum}).then(res=>{
        if(res&&res.returncode=='0'){
          this.devicename=res.item[0].devicename
        }
      })
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>